import * as types from 'constants/ActionTypes';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import PaymentDetails from 'models/PaymentDetails';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import { IQuestionConfigs } from 'src/models/IQuestionConfigs';
import { IConvertGuestToMember } from '../reducers/ConvertGuestToMember';

export function resetConvertGuestToMemberState() {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_RESET_STATE
  };
}

export function setConvertGuestToMemberPackagePlanSearchFilter(
  membershipSearchFilter: MembershipSearchFilter
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_SEARCH_FILTER,
    membershipSearchFilter
  };
}

export function setConvertGuestToMemberQuestionConfigs(questionConfigs : IQuestionConfigs) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_QUESTION_CONFIGS,
    questionConfigs
  };
}
export function setConvertGuestToMemberGuestCorporateId(
  guestCorporateId: number
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_GUEST_CORPORATE_ID,
    guestCorporateId
  }
}
export function setConvertGuestToMemberFieldConfigs(fieldConfigs) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_QUESTION_CONFIGS,
    fieldConfigs
  };
}

export function setConvertGuestToMemberConfiguration(
  membershipConfiguration: MembershipConfiguration
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_CONFIGURATION,
    membershipConfiguration
  };
}

export function setConvertGuestToMemberProductExtras(
  selectedPromotions: IPromotionCatergory[],
  selectedAddons: AddOn[]
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PRODUCT_EXTRAS,
    selectedPromotions,
    selectedAddons
  };
}

export function setConvertGuestToMemberSelectedPackagePlan(
  packagePlan: IPackagePlanItem
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PACKAGE_PLAN,
    packagePlan
  };
}

export function setConvertGuestToMemberPersonalDetails(
  personalDetails: PersonalDetailsModel
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_PERSONAL_DETAILS,
    data: personalDetails
  };
}

export function setConvertGuestToMemberAllPackagePlans(
  packagePlans: IAllPackagePlans
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_ALL_PACKAGE_PLAN,
    packagePlans
  };
}

export function setConvertGuestToMemberSummary(memberSummary) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_MEMBER_SUMMARY,
    memberSummary
  };
}

export function setConvertGuestToMemberPaymentDetails(
  paymentDetails: PaymentDetails
) {
  return {
    type: types.CONVERT_GUEST_TO_MEMBER_SET_PAYMENT_DETAILS,
    paymentDetails
  };
}

export function replaceConvertGuestToMemberData(data: IConvertGuestToMember) {
  return { type: types.CONVERT_GUEST_TO_MEMBER_REPLACE_DATA, data };
}
