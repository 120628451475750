import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const initI18n = () => {
  if (!i18n.isInitialized) {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        backend: {
          loadPath: `/locales/en.json`
        },
        lng: 'en',
        debug: false,
        keySeparator: false,
        interpolation: {
          escapeValue: false
        },
        react: {
          wait: true
        }
      });
  }
  return i18n;
};

export default initI18n;
