import React, { useCallback } from 'react';
import { getSubTenantId } from 'services/common-service';
import { ITemplate } from './interface';
import { useDispatch } from 'react-redux';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import PaymentDetails from 'models/PaymentDetails';
import { useTypedSelector } from 'reduxs';
import { STORAGE } from 'constants/Constants';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import SelectPackagePlan from 'features/SelectPackagePlanAddMember';
import SelectPaymentDetails from 'features/SelectPaymentDetail';
import MemberSummary from 'features/MemberSummary';
import SelectProductExtras from 'features/SelectProductExtras';
import SelectPersonalDetails from 'features/SelectPersonalDetailsAddMember';

const NewMembership = ({
  handleCancel,
  handleCreateMember,
  configs,
  operatorId
}) => {
  const newMembership = useTypedSelector(state => state.newMembership);
  const payment = useTypedSelector(state => state.payment);
  const { membershipConfiguration } = newMembership;
  const { stepIndex, stepLatest } = membershipConfiguration || {};
  const dispatch = useDispatch();

  const setAllPackageAction = useCallback(
    (allPackagePlans: IAllPackagePlans) => {
      dispatch(MembershipActions.setAddMemberAllPackagePlans(allPackagePlans));
    },
    []
  );

  const setProductExtrasAction = useCallback(
    (selectedPromotions: IPromotionCatergory[], selectedAddons: AddOn[]) => {
      dispatch(
        MembershipActions.setAddMemberProductExtras(
          selectedPromotions,
          selectedAddons
        )
      );
    },
    []
  );

  const setPackagePlanSearchFilterAction = useCallback(
    (membershipSearchFilter: MembershipSearchFilter) => {
      dispatch(
        MembershipActions.setAddMemberPackagePlanSearchFilter(
          membershipSearchFilter
        )
      );
    },
    []
  );

  const setConfigurationAction = useCallback(
    (membershipConfiguration: MembershipConfiguration) => {
      dispatch(
        MembershipActions.setAddMemberConfiguration(membershipConfiguration)
      );
    },
    []
  );

  const setSelectedPackagePlanAction = useCallback(
    (packagePlan: IPackagePlanItem) => {
      dispatch(MembershipActions.setAddMemberSelectedPackagePlan(packagePlan));
    },
    []
  );

  const setPersonalDetailsAction = useCallback(
    (personalDetails: PersonalDetailsModel) => {
      dispatch(MembershipActions.setAddMemberPersonalDetails(personalDetails));
    },
    []
  );

  const setQuestionConfigsAction = useCallback(
    (questionConfigs: IQuestionConfigs) => {
      dispatch(MembershipActions.setsAddMemberQuestionConfig(questionConfigs));
    },
    []
  );

  const setPaymentDetailsAction = useCallback(
    (paymentDetails: PaymentDetails) => {
      dispatch(MembershipActions.setAddMemberPaymentDetails(paymentDetails));
    },
    []
  );

  if (!membershipConfiguration || membershipConfiguration.stepIndex === 1) {
    return (
      <SelectPackagePlan
        configs={configs}
        setAllPackageAction={setAllPackageAction}
        setSelectedPackagePlanAction={setSelectedPackagePlanAction}
        setConfigurationAction={setConfigurationAction}
        setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
        setProductExtrasAction={setProductExtrasAction}
        onCancel={handleCancel}
      />
    );
  }
  switch (true) {
    case stepIndex === 2 && stepLatest > 1:
      return (
        <SelectProductExtras
          isCorporate={false}
          membership={newMembership}
          configs={configs}
          onCancel={handleCancel}
          setSelectedPackagePlanAction={setSelectedPackagePlanAction}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setProductExtrasAction={setProductExtrasAction}
        />
      );
    case stepIndex === 3 && stepLatest > 2:
      return (
        <SelectPersonalDetails
          membership={newMembership}
          onCancel={handleCancel}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setPersonalDetailsAction={setPersonalDetailsAction}
          setQuestionConfigsAction={setQuestionConfigsAction}
        />
      );
    case stepIndex === 4 && stepLatest > 3:
      return (
        <SelectPaymentDetails
          configs={configs}
          membership={newMembership}
          payment={payment}
          membershipStorage={STORAGE.NEW_MEMBERSHIP}
          onCancel={handleCancel}
          setPaymentDetailsAction={setPaymentDetailsAction}
          setConfigurationAction={setConfigurationAction}
          operatorId={operatorId}
        />
      );
    case stepIndex === 5 && stepLatest > 4:
      return (
        <MemberSummary
          membership={newMembership}
          onCancel={handleCancel}
          onFinish={handleCreateMember}
          setConfigurationAction={setConfigurationAction}
        />
      );
    default:
      return <></>;
  }
};

const Template: React.FC<ITemplate> = ({
  handleCancel,
  handleCreateMember,
  configs,
  operatorId
}) => {
  return (
    <div className="add-member-page">
      {getSubTenantId() ? (
        <NewMembership
          handleCancel={handleCancel}
          handleCreateMember={handleCreateMember}
          configs={configs}
          operatorId={operatorId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Template;
