import React from 'react';
import { useTranslation } from 'react-i18next';

interface IFormButton {
  isOnProductSummary?: boolean;
  handlePrevious?: () => void;
  handleNext?: () => void;
  handleCancel?: () => void;
  handleFinish?: () => void;
  isDisabledNext?: boolean;
  isFinalStep?: boolean;
}

const FormButton: React.FC<IFormButton> = ({
  isFinalStep,
  isOnProductSummary = false,
  isDisabledNext,
  handlePrevious,
  handleNext,
  handleCancel,
  handleFinish
}) => {
  const { t } = useTranslation();

  const renderFinalButtonGroup = () => (
    <>
      <div className="box-btn">
        <button
          className={`btn btn-secondary btn--aside ${
            handlePrevious ? '' : 'hidden'
          }`}
          onClick={() => (handlePrevious ? handlePrevious() : null)}
        >
          {t('BUTTON.PREVIOUS')}
        </button>
        {isFinalStep ? (
          <button
            className={`btn btn-primary btn--aside`}
            onClick={() => (handleFinish ? handleFinish() : null)}
          >
            {t('BUTTON.FINISH')}
          </button>
        ) : (
          <button
            className={`btn btn-primary btn--aside ${
              handleNext ? '' : 'hidden'
            }`}
            disabled={isDisabledNext}
            onClick={() => (handleNext ? handleNext() : null)}
          >
            {t('BUTTON.NEXT')}
          </button>
        )}
      </div>
      <div className="box-btn box-btn-mt0">
        <button
          className={`btn btn-dark btn--aside-block ${
            handleCancel ? '' : 'hidden'
          }`}
          onClick={() => (handleCancel ? handleCancel() : null)}
        >
          {t('BUTTON.CANCEL')}
        </button>
      </div>
    </>
  );

  const renderOnStepButtonGroup = () => (
    <div className="box-btn">
      <button
        className={`btn btn-secondary ${handlePrevious ? '' : 'hidden'}`}
        onClick={() => (handlePrevious ? handlePrevious() : null)}
      >
        {t('BUTTON.PREVIOUS')}
      </button>
      <button
        className={`btn btn-primary ${handleNext ? '' : 'hidden'}`}
        onClick={() => (handleNext ? handleNext() : null)}
      >
        {t('BUTTON.NEXT')}
      </button>
      <button
        className={`btn btn-dark ${handleCancel ? '' : 'hidden'}`}
        onClick={() => (handleCancel ? handleCancel() : null)}
      >
        {t('BUTTON.CANCEL')}
      </button>
    </div>
  );

  return isOnProductSummary
    ? renderFinalButtonGroup()
    : renderOnStepButtonGroup();
};

export default FormButton;
