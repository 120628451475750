import React from 'react';
import { ITemplate } from './interface';
import CollapseButton from 'components/CollapseButton';
import { useTranslation } from 'react-i18next';
import ConvertGuestToGuestPassBreadcrumb from 'components/Membership/ConvertGuestToGuestPassBreadcrumb';
import ConvertGuestToGuestPassProductSummary from 'components/Membership/ConvertGuestToGuestPassProductSummary';
import GuestDetails from './GuestDetails';
import GuestPassDetail from './GuestPassDetail';
import ReferrerDetails from './ReferrerDetails';

const Template: React.FC<ITemplate> = ({
  guest,
  answerMappingData,
  openDropdown,
  corporateNames,
  packageEndDate,
  goToStep,
  onCancel,
  onFinish,
  handleOpenDropdown,
  handlePrevious
}) => {
  const { searchFilter, configuration, selectedPackagePlan } = guest;
  const { t } = useTranslation();
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <ConvertGuestToGuestPassBreadcrumb
            configuration={configuration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <div className="content select-add-on personal">
            <div className="main-payment select-add-on-main">
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.PERSONAL_DETAILS')}
                content={
                  <GuestDetails
                    guest={guest}
                    answerMappingData={answerMappingData}
                    corporateNames={corporateNames}
                  />
                }
                isOpen={openDropdown['personalDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('personalDetails')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.REFERRER_DETAILS')}
                content={
                  <ReferrerDetails
                    guest={guest}
                  />
                }
                isOpen={openDropdown['referrerDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('referrerDetails')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.GUEST_PASS_DETAILS')}
                content={
                  <GuestPassDetail
                    guest={guest}
                    packageEndDate={packageEndDate}
                  />
                }
                isOpen={openDropdown['guestPassDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('guestPassDetails')
                }
              />
            </div>
            {selectedPackagePlan && (
              <ConvertGuestToGuestPassProductSummary
                productSummary={selectedPackagePlan}
                packageEndDate={packageEndDate}
                searchFilter={searchFilter}
                configuration={configuration}
                handlePrevious={() => handlePrevious()}
                handleCancel={onCancel}
                handleFinish={onFinish}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Template;
