export interface PayTo {
  accountHolderName: string;
  accountType: string;
  bbanBSB: string;
  bbanAccountNo: string;
  aliasType: string;
  aliasId: string;
  mandateTypeId: string;
  merchantId: string;
  mandateStatus: string;
  mandateId: string;
  mandateReason: string;
  description: string;
  frequency: string;
  validityStartDate: string;
  maximumPaymentAmount: number;
}

export const PayToUtil = {
  getBankAccountType: (payTo: PayTo) => {
    if (payTo.accountType === 'BBAN') {
      return 'Bank Account';
    }

    if (payTo.accountType === 'ALIA' && payTo.aliasType === 'EMAL') {
      return 'Email';
    }

    if (payTo.accountType === 'ALIA' && payTo.aliasType === 'TELI') {
      return 'Phone Number';
    }

    return '';
  },

  getBsbNumber: (payTo: PayTo) => {
    return payTo.accountType === 'BBAN' ? payTo.bbanBSB : 'N/A';
  },

  getDomesticNumber: (payTo: PayTo) => {
    return payTo.accountType === 'BBAN'
      ? '*'.repeat(5) + '' + payTo.bbanAccountNo.slice(-4)
      : 'N/A';
  },

  getAccountName: (payTo: PayTo) => {
    return payTo.accountHolderName ? payTo.accountHolderName : 'N/A';
  },

  getPayIdEmail: (payTo: PayTo) => {
    return payTo.accountType === 'ALIA' && payTo.aliasType === 'EMAL'
      ? payTo.aliasId
      : 'N/A';
  },

  getPayIdPhoneNumber: (payTo: PayTo) => {
    return payTo.accountType === 'ALIA' && payTo.aliasType === 'TELI'
      ? payTo.aliasId
      : 'N/A';
  },

  getMandateReason: (payTo: PayTo) => {
    return payTo.mandateReason.replace('.', '');
  }
};
