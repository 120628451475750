import React from 'react';
import { DisplayDateFormat } from 'util/ControlUtils';
import { useTranslation } from 'react-i18next';
import { PromotionIcon } from '../Icon';
import { IPackagePlanItemAddCorporateMember } from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { formatAmount, printUnits } from 'util/Common';
import _ from 'lodash';

interface IAddCorporateMemberPackagePlanItem {
  packagePlanItem: IPackagePlanItemAddCorporateMember;
  membershipSearchFilter: MembershipSearchFilter;
  membershipConfiguration: MembershipConfiguration;
  onSelected: (packagePlanId: number, agreementPartyID: number) => void;
}

const AddCorporateMemberPackagePlanItem: React.FunctionComponent<
  IAddCorporateMemberPackagePlanItem
> = ({
  packagePlanItem,
  membershipConfiguration,
  membershipSearchFilter,
  onSelected
}) => {
  let {
    name: packageName,
    payment: { payNow, regular, firstBilling },
    minimumContract: {
      value: contractDuration,
      unit: { id: contractUnit }
    },
    package: { timeGroup },
    originalPlanId: planId,
    name: planName,
    corporateAgreements,
    isLumpSum,
    isPromotionApplied
  } = packagePlanItem;
  packageName = packagePlanItem.package
    ? packagePlanItem.package.name
    : packageName;
  const paynowAmount = formatAmount(payNow ? payNow.value : 0);
  const regularAmount = formatAmount(regular ? regular.value : 0);
  const className =
    membershipSearchFilter.selectedPackagePlanId == planId && packagePlanItem.corporateAgreements &&
    packagePlanItem.corporateAgreements.some(
      (corporateAgreement) =>
        corporateAgreement.agreementPartyID ===
        membershipSearchFilter.agreementPartyId,
    ) ? "row-content d-flex active"
    : "row-content d-flex";

  const activeCorporateAgreement = (corporateAgreements || []).find(
    ca => ca.organizationPartyID === membershipSearchFilter.corporateId
  );

  const onSelectedPackagePlan = () => {
    const corporateAgreements = packagePlanItem.corporateAgreements || [];
    onSelected(
      packagePlanItem.originalPlanId,
      corporateAgreements.length > 0 ? corporateAgreements[0].agreementPartyID : 0
    );
  };

  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="col-content">
        <p className="title">{packageName}</p>
        <ul>
          {activeCorporateAgreement && (
            <li>{activeCorporateAgreement.corporateName}</li>
          )}
          <li>{packagePlanItem.tier.name}</li>
          <li>
            {!_.isUndefined(timeGroup) ? timeGroup.name : t('FULL.ACCESS')}{' '}
          </li>
          {!isLumpSum && (
            <li>
              {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.REGULAR_BILLING')}:{' '}
              {DisplayDateFormat(
                firstBilling.startDate,
                membershipConfiguration.newMemberTimeFormat
              )}
            </li>
          )}
        </ul>
      </div>
      <div className="col-content">
        <p className="title">{planName}</p>
        <ul>
          {activeCorporateAgreement && (
            <li>{activeCorporateAgreement.referenceNumber}</li>
          )}
          <li>{packagePlanItem.corporateSubsidy.name}</li>
          <li>
            {contractDuration +
              ' ' +
              printUnits(contractDuration, contractUnit)}
          </li>
          <li>{isLumpSum ? 'Lump sum payment' : 'Continuous payment'}</li>
        </ul>
      </div>
      <div className="col-content pay">
        <ul>
          <li className="pay-content">
            <div className="pay-now">
              <p>{t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PAY_NOW')}</p>
              <p className="number-pay-now">
                {paynowAmount[0]}.<sup>{paynowAmount[1]}</sup>
              </p>
            </div>
            {isPromotionApplied && <PromotionIcon />}
          </li>
          <li>
            <div className="regular">
              <p>
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.REGULAR')}
                <span className="number-regular">
                  {regularAmount[0]}.<sup>{regularAmount[1]}</sup>
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="col-content select">
        <button
          className="btn btn-primary text-uppercase"
          onClick={onSelectedPackagePlan}
        >
          {t('BUTTON.SELECT')}
        </button>
      </div>
    </div>
  );
};

export default AddCorporateMemberPackagePlanItem;
