export class GuestDetailsModel {
  address = '';
  channelCode = '';
  city = '';
  clubId = 0;
  clubName = '';
  contactMethod = '';
  corporateId = '';
  createdUtcDate = '';
  dateOfBirth = '';
  deleted: boolean | null = null;
  email = '';
  emailOptedOut: boolean | null = null;
  externalId = '';
  firstName = '';
  gender = '';
  homePhone = '';
  id = 0;
  isConverted: boolean | null = null;
  lastName = '';
  leadSource = '';
  leadSourceType = '';
  marketingCampaign = '';
  middleName = '';
  mobilePhone = '';
  preferredLanguage = '';
  salePersonId = 0;
  salePersonName = '';
  secondarySalePersonId = 0;
  secondarySalePersonName = '';
  smsOptedOut: boolean | null = null;
  sourceName = '';
  state = '';
  status = '';
  workPhone = '';
  zip = '';
  referrerCode : string | null = null;
  memberReferrerCodeInfo = {
    referrerMemberId: 0,
    referrerCode: null,
    referrerFirstName: null,
    referrerLastName: null,
    referrerMiddleName: null,
    isSuccess: false,
    message: null
  }
}
