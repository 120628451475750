type Value = string | null;

export interface Bank {
  accountHolderName: string;
  last4: Value;
  bankNumber: string;
  branchNumber: Value;
  suffixNumber: Value;
  countryCode: Value;
  bankTransferType: Value;
  iban: Value;
  swiftBic: Value;
  currency: Value;
  bankName: Value;
  bankAddress: {
    address1: Value;
    address2: Value;
    postalCode: Value;
    city: Value;
    state: Value;
    countryCode: Value;
  };
  routingCode: Value;
}

export const BankUtil = {
  getDomestic(bank: Bank) {
    return '*'.repeat(5) + bank.last4;
  }
};
