import React from 'react';

const Loading: React.FC<{}> = () => {
  return (
    <div className="loading-dot-holder">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loading;
