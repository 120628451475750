import React from 'react';
import { ITemplate } from './interface';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import { useTranslation } from 'react-i18next';
import Summary from './Summary';
import PersonalDetails from './PersonalDetails';
import HealthQuestion from './HealthQuestion';
import MembershipDetails from './MembershipDetails';
import PaymentDetail from './PaymentDetail';
import MemberPayments from './MemberPayments';

const Template: React.FC<ITemplate> = ({
  membership,
  openDropdown,
  fees,
  fieldConfigs,
  minContractEndDate,
  goToStep,
  onCancel,
  onFinish,
  handleOpenDropdown,
  handlePrevious
}) => {
  const {
    membershipSearchFilter,
    membershipConfiguration,
    selectedPackagePlan,
    selectedAddons,
    selectedPromotions
  } = membership;

  const { t } = useTranslation();
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <div className="content select-add-on personal">
            <div className="main-payment select-add-on-main">
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.MEMBER_SUMMARY')}
                content={
                  <Summary
                    membership={membership}
                    fieldConfigs={fieldConfigs}
                  />
                }
                isOpen={openDropdown['memberSummary']}
                maxHeight="1200px"
                openCollapsedContent={() => handleOpenDropdown('memberSummary')}
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.PERSONAL_DETAILS')}
                content={
                  <PersonalDetails
                    membership={membership}
                    fieldConfigs={fieldConfigs}
                  />
                }
                isOpen={openDropdown['personalDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('personalDetails')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.HEALTH_QUESTIONNAIRE')}
                content={<HealthQuestion membership={membership} />}
                isOpen={openDropdown['healthQuestion']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('healthQuestion')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_DETAILS')}
                content={
                  <MembershipDetails
                    membership={membership}
                    minContractEndDate={minContractEndDate}
                  />
                }
                isOpen={openDropdown['membershipDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('membershipDetails')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_PAYMENT_DETAIL')}
                content={<PaymentDetail membership={membership} />}
                isOpen={openDropdown['memberPaymentDetails']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('memberPaymentDetails')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_PAYMENTS')}
                content={<MemberPayments membership={membership} fees={fees} />}
                isOpen={openDropdown['memberPayments']}
                maxHeight="1200px"
                openCollapsedContent={() =>
                  handleOpenDropdown('memberPayments')
                }
              />
            </div>
            {selectedPackagePlan && (
              <ProductSummary
                productSummary={selectedPackagePlan}
                membershipSearchFilter={membershipSearchFilter}
                membershipConfiguration={membershipConfiguration}
                addons={selectedAddons}
                handlePrevious={() => handlePrevious()}
                handleCancel={onCancel}
                handleFinish={onFinish}
                promotions={selectedPromotions}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Template;
