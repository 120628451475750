import _ from 'lodash';

export default interface IssuingBankOptionModel {
  code: string;
  sortIndex: number;
  name: string;
  status: boolean;
}

export const sortedBanks = (banks: IssuingBankOptionModel[]) => {
  return _.orderBy(banks.filter(bank => bank.status), [bank => bank.code]).map(
    bank => ({ label: bank.name, value: bank.code })
  );
};
