import React, { MutableRefObject, useEffect } from 'react';
import { FormSelect, FormInput } from 'components/Form';
import {
  AddressDetailsModel,
  AddressDetailsFieldsModel,
  LookupModel
} from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { onChangeInput, required, validateInputField } from 'util/FormValidations';
import { safeLookupModel } from 'util/ControlUtils';

interface IAddressDetails {
  countries: LookupModel[];
  addressDetails: AddressDetailsModel;
  addressDetailsFieldConfigs: AddressDetailsFieldsModel;
  addressDetailRef: MutableRefObject<UseFormReturn<
    AddressDetailsFormInput
  > | null>;
}

export type AddressDetailsFormInput = {
  addressNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: LookupModel;
  postCode: string;
};

const AddressDetails: React.FC<IAddressDetails> = ({
  countries,
  addressDetails,
  addressDetailsFieldConfigs,
  addressDetailRef
}) => {
  let {
    addressNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postCode
  } = addressDetails;
  let {
    addressNumberConfig,
    addressLine1Config,
    addressLine2Config,
    cityConfig,
    stateConfig,
    countryConfig,
    postCodeConfig
  } = addressDetailsFieldConfigs;
  const { t } = useTranslation();

  const form = useForm<AddressDetailsFormInput>({
    mode: 'onChange',
    defaultValues: {
      addressNumber: addressNumber,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      postCode: postCode,
      country: safeLookupModel(
        countries.find(item => item.value === country.value)
      ),
    }
  });

  const {
    control,
    formState: { errors }
  } = form;

  useEffect(() => {
    if (addressDetailRef) {
      addressDetailRef.current = form;
    }
  }, []);

  return (
    <div className="personal-group">
      <div className="row">
        <div className="col-md-6">
          {addressNumberConfig.isVisible && (
            <Controller
              name="addressNumber"
              control={control}
              rules={{
                required: required(addressNumberConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(addressNumberConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NUMBER_NAME')}
                  maxlength={addressNumberConfig.maxLength}
                  require={addressNumberConfig.isRequired}
                  errorMsg={t(
                    (errors.addressNumber && errors.addressNumber.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {addressLine1Config.isVisible && (
            <Controller
              name="addressLine1"
              control={control}
              rules={{
                required: required(addressLine1Config.isRequired),
                validate: {
                  validateInputField: validateInputField(addressLine1Config.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LINE_1')}
                  maxlength={addressLine1Config.maxLength}
                  require={addressLine1Config.isRequired}
                  errorMsg={t(
                    (errors.addressLine1 && errors.addressLine1.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {addressLine2Config.isVisible && (
            <Controller
              name="addressLine2"
              control={control}
              rules={{
                required: required(addressLine2Config.isRequired),
                validate: {
                  validateInputField: validateInputField(addressLine1Config.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LINE_2')}
                  maxlength={addressLine2Config.maxLength}
                  require={addressLine2Config.isRequired}
                  errorMsg={t(
                    (errors.addressLine2 && errors.addressLine2.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {cityConfig.isVisible && (
            <Controller
              name="city"
              control={control}
              rules={{
                required: required(cityConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(cityConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.CITY_TOWN')}
                  maxlength={cityConfig.maxLength}
                  require={cityConfig.isRequired}
                  errorMsg={t((errors.city && errors.city.message) || '')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {stateConfig.isVisible && (
            <Controller
              name="state"
              control={control}
              rules={{
                required: required(stateConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(stateConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.STATE_COUNTY')}
                  maxlength={stateConfig.maxLength}
                  require={stateConfig.isRequired}
                  errorMsg={t((errors.state && errors.state.message) || '')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {postCodeConfig.isVisible && (
            <Controller
              name="postCode"
              control={control}
              rules={{
                required: required(postCodeConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(postCodeConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.POSTCODE')}
                  maxlength={postCodeConfig.maxLength}
                  require={postCodeConfig.isRequired}
                  errorMsg={t(
                    (errors.postCode && errors.postCode.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6 gr-gone-away">
          {countryConfig.isVisible && (
            <Controller
              name="country"
              control={control}
              rules={{
                required: required(countryConfig.isRequired)
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.COUNTRY')}
                  disabled={countries.length === 0}
                  require={countryConfig.isRequired}
                  options={countries}
                  errorMsg={t((errors.country && errors.country.message) || '')}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
