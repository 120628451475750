import React from 'react';
import { FormSelect, FormDatePicker } from 'components/Form';
import { useTranslation } from 'react-i18next';
import OptionModel from 'models/Option';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { FieldError } from 'interfaces/common/FieldError';
import { MAX_DATE_SYSTEM } from 'constants/Constants';

interface IConvertGuestToGuestPassFormInfo {
  searchFilter: ConvertGuestPassSearchFilter;
  configuration: ConvertGuestPassConfiguration;
  errors: FieldError;
  clubs: OptionModel[];
  salePeople: OptionModel[];
  secondarySalePeople: OptionModel[];
  handleChange: (key, event) => void;
  handleDateChange: (date, name) => void;
}

const ConvertGuestToGuestPassFormInfo: React.FC<
  IConvertGuestToGuestPassFormInfo
> = ({
  searchFilter,
  configuration,
  errors,
  clubs,
  salePeople,
  secondarySalePeople,
  handleChange,
  handleDateChange
}) => {
  function memberValue(searchFilter, key, arrList) {
    return searchFilter[key]
      ? arrList.find(e => e.value === searchFilter[key])
      : arrList[0];
  }
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="list-page">
        <p className="title-content room-name text-uppercase select-add-on-title--custom">
          {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.SELECT_PACKAGE_PLAN')}
        </p>
      </div>
      <div className="section-select">
        <FormSelect
          id="club"
          name="club"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB')}
          disabled={true}
          require
          onChange={event => handleChange('clubId', event)}
          value={memberValue(searchFilter, 'clubId', clubs)}
          options={clubs}
          errorMsg={errors.clubId}
        />
        <FormSelect
          id="primarySalePersonId"
          name="primarySalePersonId"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_SALE_PERSON')}
          disabled={salePeople.length === 0}
          require
          onChange={event => handleChange('primarySalePersonId', event)}
          value={memberValue(searchFilter, 'primarySalePersonId', salePeople)}
          options={salePeople}
          errorMsg={errors.primarySalePersonId}
        />
        <FormSelect
          id="secondarySalePersonId"
          name="secondarySalePersonId"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SECONDARY_SALE_PERSON')}
          disabled={secondarySalePeople.length === 0}
          onChange={event => handleChange('secondarySalePersonId', event)}
          value={memberValue(
            searchFilter,
            'secondarySalePersonId',
            secondarySalePeople
          )}
          options={secondarySalePeople}
          errorMsg={errors.secondarySalePersonId}
        />
        <FormDatePicker
          id="start-date"
          name="start-date"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE')}
          selectKey="startDate"
          className="datepicker-wrapper"
          minDate={new Date()}
          maxDate={new Date(MAX_DATE_SYSTEM)}
          required
          dateFormat={configuration.newMemberTimeFormat}
          selectedDate={searchFilter.startDate}
          onChange={value => handleDateChange(value, 'startDate')}
          errorMsg={errors.startDate}
        />
      </div>
    </React.Fragment>
  );
};

export default ConvertGuestToGuestPassFormInfo;
