import * as types from 'constants/ActionTypes';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { IExternalGuest } from 'models/ExternalGuest';

export function setConvertGuestPassSearchFilter(
  searchFilter: ConvertGuestPassSearchFilter
) {
  return {
    type: types.CONVERT_GUEST_TO_GUESTPASS_SET_SEARCH_FILTER,
    searchFilter
  };
}

export function setConvertGuestPassConfiguration(
  configuration: ConvertGuestPassConfiguration
) {
  return {
    type: types.CONVERT_GUEST_TO_GUEST_PASS_SET_CONFIGURATION,
    configuration
  };
}

export function setConvertGuestPassSelectedPackagePlan(
  packagePlan: IPackagePlanItem
) {
  return {
    type: types.CONVERT_GUEST_TO_GUESTPASS_SET_SELECTED_PACKAGE_PLAN,
    packagePlan
  };
}

export function setConvertGuestPassGuestDetails(guestDetails: IExternalGuest) {
  return {
    type: types.CONVERT_GUEST_TO_GUESTPASS_SET_GUEST_DETAILS,
    guestDetails
  };
}

export function setConvertGuestPassAllPackagePlans(
  packagePlans: IAllPackagePlans
) {
  return {
    type: types.CONVERT_GUEST_TO_GUESTPASS_SET_ALL_PACKAGE_PLAN,
    packagePlans
  };
}
