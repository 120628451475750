import React from 'react';
import { useTranslation } from 'react-i18next';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { ICounter } from 'interfaces/Membership';

interface IAddCorporateMemberFormFilter {
  isShowFilter: boolean;
  membershipSearchFilter: MembershipSearchFilter;
  reciprocalAccesses: ICounter[];
  minimumContracts: ICounter[];
  accesses: ICounter[];
  paymentMethods: ICounter[];
  subsidyTypes: ICounter[];
  filterRef: React.RefObject<HTMLDivElement>;
  toggleFilter: (e: any) => void;
  handleFilterChange: (key: any, event: any) => void;
  handleFilterPackagePlan: () => void;
  handleClearFilter: () => void;
}

const AddCorporateMemberFormFilter: React.FC<IAddCorporateMemberFormFilter> = ({
  isShowFilter,
  membershipSearchFilter,
  reciprocalAccesses,
  minimumContracts,
  accesses,
  paymentMethods,
  subsidyTypes,
  filterRef,
  toggleFilter,
  handleFilterChange,
  handleFilterPackagePlan,
  handleClearFilter
}) => {
  const { t } = useTranslation();
  return (
    <div
      ref={filterRef}
      className={`filter-sidebar ${isShowFilter ? 'collapsed-filter' : ''}`}
    >
      <div className="filter-header">
        <h3>{t('PAGE.MEMBERSHIPS.CREATE.FILTER')}</h3>
        <i
          className="icon icon-close-ico"
          onClick={event => toggleFilter(event)}
        />
      </div>
      <div className="filter-content">
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.RECIPROCAL_ACCESS')}</label>
          <select
            className="form-control"
            style={{ width: '100%' }}
            value={membershipSearchFilter.reciprocalAccessName}
            onChange={event =>
              handleFilterChange('reciprocalAccessName', event)
            }
          >
            <option value="">{t('COMMON.ANY')}</option>
            {reciprocalAccesses.map(item => {
              return (
                <option key={item.name} value={item.name}>{`${item.name} (${
                  item.count
                })`}</option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.MINIMUM_CONTRACT')}</label>
          <select
            className="form-control"
            style={{ width: '100%' }}
            value={membershipSearchFilter.minimumContractName}
            onChange={event => handleFilterChange('minimumContractName', event)}
          >
            <option value="">{t('COMMON.ANY')}</option>
            {minimumContracts.map(item => {
              return (
                <option key={item.name} value={item.name}>{`${item.name} (${
                  item.count
                })`}</option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.ACCESS')}</label>
          <select
            className="form-control"
            style={{ width: '100%' }}
            value={membershipSearchFilter.accessName}
            onChange={event => handleFilterChange('accessName', event)}
          >
            <option value="">{t('COMMON.ANY')}</option>
            {accesses.map(item => {
              return (
                <option key={item.name} value={item.name}>{`${item.name} (${
                  item.count
                })`}</option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.PAYMENT_METHOD')}</label>
          <select
            className="form-control"
            style={{ width: '100%' }}
            value={membershipSearchFilter.paymentMethodName}
            onChange={event => handleFilterChange('paymentMethodName', event)}
          >
            <option value="">{t('COMMON.ANY')}</option>
            {paymentMethods.map(item => {
              return (
                <option key={item.name} value={item.name}>{`${item.name} (${
                  item.count
                })`}</option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.SUBSIDY_TYPE')}</label>
          <select
            className="form-control"
            style={{ width: '100%' }}
            value={membershipSearchFilter.subsidyTypeName}
            onChange={event => handleFilterChange('subsidyTypeName', event)}
          >
            <option value="">{t('COMMON.ANY')}</option>
            {subsidyTypes.map(item => {
              return (
                <option key={item.name} value={item.name}>{`${item.name} (${
                  item.count
                })`}</option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="filter-footer">
        <div className="btn-box">
          <button className="btn btn-primary" onClick={handleFilterPackagePlan}>
            {t('BUTTON.APPLY')}
          </button>
          <button className="btn btn-dark" onClick={handleClearFilter}>
            {t('BUTTON.CLEAR')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCorporateMemberFormFilter;
