import moment from 'moment';
import {
  GENERAL_DATABASE_DATE_FORMAT,
  PAYMENT_METHOD_TYPES
} from 'constants/Constants';
import PaymentDetails from 'models/PaymentDetails';
import { IConvertGuestToCorporateMember } from 'reduxs/reducers/ConvertGuestToCorporateMember';
import { INewCorporateMembership } from 'reduxs/reducers/NewCorporateMembership';
import { INewMembership } from 'reduxs/reducers/NewMembership';
import { condition } from './Common';
import { DisplayDateFormat } from './ControlUtils';
import { IConvertGuestToMember } from 'reduxs/reducers/ConvertGuestToMember';
import IPromotionCatergory from 'interfaces/Promotion';
import { IConman } from 'reduxs/reducers/Conman';
import { IConfig } from 'interfaces/SystemConfig';
import {
  ENGLISH_PREFERRED_LANGUAGE,
  LANGUAGE_MAPPER_CONFIG
} from 'constants/DefaultConstants';
import {
  ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_POST,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
} from './FieldConfiguration/Keys';
import { IPackagePlanItem } from 'interfaces/Membership';
import { EzyPayInfo } from 'models/EzyPayInfo';
import { getEzyPaymentData } from './EzPay/PayLoad';

export function getPrimaryPaymentMethod(
  paymentDetails: PaymentDetails,
  selectedPackagePlan: IPackagePlanItem | null,
  isDisableEzyPay: boolean,
  ezyPaymentInfo: EzyPayInfo
) {
  if (!selectedPackagePlan) {
    return;
  }
  const paymentMethodTypes = selectedPackagePlan.paymentMethodTypes;
  const currentPayment = paymentMethodTypes.find(
    method =>
      method.paymentMethodTypeCode === paymentDetails.primaryPaymentMethodId
  );
  if (!currentPayment) {
    return;
  }
  if (isDisableEzyPay) {
    return getCyberSourceData(currentPayment, paymentDetails);
  } else {
    return getEzyPaymentData(currentPayment, paymentDetails, ezyPaymentInfo);
  }
}

const getPromotionIds = (promotionList: IPromotionCatergory[]) => {
  if (promotionList && promotionList.length) {
    const currentPromotions: IPromotionCatergory[] = promotionList.filter(
      (promo: IPromotionCatergory) => promo.promotions.length
    );
    const promotionIds = currentPromotions.map(
      (promo: IPromotionCatergory) => `${promo.promotions[0].id}`
    );
    return promotionIds;
  }
  return [];
};

const getPreferredLanguage = (conman: IConman, chosenLanguage: string) => {
  let result = ENGLISH_PREFERRED_LANGUAGE;
  const languageMapper = conman.configs.find(
    (cfg: IConfig) => cfg.key === LANGUAGE_MAPPER_CONFIG
  );
  if (languageMapper && chosenLanguage) {
    const mapper = languageMapper.value;
    const startIndex =
      mapper.indexOf(chosenLanguage) + chosenLanguage.length + 1;
    const endIndex = mapper.indexOf(';', startIndex);
    if (startIndex >= 0 && endIndex >= 0) {
      result = mapper.substring(startIndex, endIndex);
    }
  }
  return result;
};

export const generateHandleSaveDataPayload = (
  newMembership:
    | INewMembership
    | INewCorporateMembership
    | IConvertGuestToMember
    | IConvertGuestToCorporateMember,
  operatorId: number,
  conman: IConman,
  isDisableEzyPay: boolean,
  paymentInfo: EzyPayInfo
) => {
  const {
    membershipSearchFilter,
    selectedPackagePlan,
    selectedAddons,
    personalDetails,
    paymentDetails,
    questionConfigs,
    selectedPromotions
  } = newMembership;

  const {
    contactDetails,
    addressDetails,
    contactedDetails,
    emergencyContact,
    otherFields
  } = personalDetails;
  const preferredContactMethod = contactedDetails.preferredContactMethod.map(
    pc => pc.id
  );
  return {
    operatorId: operatorId,
    addonSelectedIds: selectedAddons.map(addon => addon.id),
    membershipNumber: '',
    applicationTypeId: condition(
      membershipSearchFilter.applicationTypeId,
      membershipSearchFilter.applicationTypeId
    ),
    salePersonName: membershipSearchFilter.primarySalePersonName,
    salePersonId: condition(
      membershipSearchFilter.primarySalePersonId,
      membershipSearchFilter.primarySalePersonId
    ),
    secondarySalePersonName: condition(
      membershipSearchFilter.secondarySalePersonName,
      membershipSearchFilter.secondarySalePersonName
    ),
    secondarySalePersonId: condition(
      membershipSearchFilter.secondarySalePersonId,
      membershipSearchFilter.secondarySalePersonId
    ),
    packageId:
      selectedPackagePlan &&
      condition(selectedPackagePlan.package.id, selectedPackagePlan.package.id),
    planId: selectedPackagePlan && selectedPackagePlan.originalPlanId,
    title: condition(personalDetails.title.label, personalDetails.title.label),
    clubId: condition(
      membershipSearchFilter.clubId,
      membershipSearchFilter.clubId
    ),
    firstName: condition(personalDetails.firstName, personalDetails.firstName),
    middleName: condition(
      personalDetails.middleName,
      personalDetails.middleName
    ),
    lastName: condition(personalDetails.lastName, personalDetails.lastName),
    nationalId: condition(
      personalDetails.nationalIdNumber,
      personalDetails.nationalIdNumber
    ),
    otherNationalId: condition(
      personalDetails.passportNumber,
      personalDetails.passportNumber
    ),
    dateOfBirth: condition(
      personalDetails.dateOfBirth,
      DisplayDateFormat(
        personalDetails.dateOfBirth,
        GENERAL_DATABASE_DATE_FORMAT
      ) + 'T00:00:00'
    ),
    isParentalConsentProvided: personalDetails.isParentalConsentProvided,
    isTaxExcempt: false,
    joinedDate: new Date().toISOString(),
    startedDate: condition(
      membershipSearchFilter.startDate,
      membershipSearchFilter.startDate
    ),
    preferenceLanguageCode: getPreferredLanguage(
      conman,
      condition(
        personalDetails.preferedLanguage,
        personalDetails.preferedLanguage
      ).value
    ),
    mobilePhone: condition(
      contactDetails.mobilePhone.phoneNumber,
      contactDetails.mobilePhone.phoneNumber
    ),
    mobilePhoneRegionCode: condition(
      contactDetails.mobilePhone.countryCode,
      contactDetails.mobilePhone.countryCode
    ),
    mobilePhoneInvalidStatus: condition(
      contactDetails.mobilePhone.phoneNumber,
      !contactDetails.mobilePhone.isValid,
      false
    ),
    mobilePhoneIsSMSPreferred: contactedDetails.preferredContactMethod.some(
      method => method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS
    ),
    mobilePhoneIsPreferred: contactedDetails.preferredContactMethod.some(
      method =>
        method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
    ),
    homePhone: condition(
      contactDetails.homePhone.phoneNumber,
      contactDetails.homePhone.phoneNumber
    ),
    homePhoneRegionCode: condition(
      contactDetails.homePhone.countryCode,
      contactDetails.homePhone.countryCode
    ),
    homePhoneInvalidStatus: condition(
      contactDetails.homePhone.phoneNumber,
      !contactDetails.homePhone.isValid,
      false
    ),
    homePhoneIsPreferred: contactedDetails.preferredContactMethod.some(
      method => method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
    ),
    workingPhone: condition(
      contactDetails.workPhone.phoneNumber,
      contactDetails.workPhone.phoneNumber
    ),
    workPhoneRegionCode: condition(
      contactDetails.workPhone.countryCode,
      contactDetails.workPhone.countryCode
    ),
    workPhoneInvalidStatus: condition(
      contactDetails.workPhone.phoneNumber,
      !contactDetails.workPhone.isValid,
      false
    ),
    addressIsPreferred: contactedDetails.preferredContactMethod.some(
      method => method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_POST
    ),
    workPhoneIsPreferred: contactedDetails.preferredContactMethod.some(
      method => method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
    ),
    email: condition(contactDetails.email, contactDetails.email),
    emailAddressIsPreferred: contactedDetails.preferredContactMethod.some(
      method => method.key === ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
    ),
    emailAddressInvalidStatus: !contactDetails.isEmailValid,
    addressNumber: condition(
      addressDetails.addressNumber,
      addressDetails.addressNumber
    ),
    line1: condition(addressDetails.addressLine1, addressDetails.addressLine1),
    line2: condition(addressDetails.addressLine2, addressDetails.addressLine2),
    city: condition(addressDetails.city, addressDetails.city),
    state: condition(addressDetails.state, addressDetails.state),
    postCode: condition(addressDetails.postCode, addressDetails.postCode),
    country: condition(
      addressDetails.country.label,
      addressDetails.country.label
    ),
    membershipApplicationRef: condition(
      contactedDetails.applicationRef,
      contactedDetails.applicationRef
    ),
    isHappyToBeContacted: contactedDetails.memberHappyToBeContact,
    isAllowContactByThirdParty: false,
    isDisabilityAccessRequired: true,
    preferredContactMethod: condition(
      contactedDetails.preferredContactMethod,
      preferredContactMethod
    ),
    sourceId: condition(
      contactedDetails.marketingSource.value,
      contactedDetails.marketingSource.value
    ),
    isGoneAway: false,
    emergencyContact: questionConfigs ?
      questionConfigs.emergencyContact.map(
        question => ({ ...question, answer: String(question.answer) })
      ) : [],
    customFields: questionConfigs ? questionConfigs.customFields : [],
    emergencyContactPhoneNumberRegionCode: condition(
      emergencyContact.contactNumber.countryCode,
      emergencyContact.contactNumber.countryCode
    ),
    companyname: condition(otherFields.companyName, otherFields.companyName),
    occupation: condition(otherFields.occupation, otherFields.occupation),
    referredByName: condition(
      otherFields.referredByMemberName,
      otherFields.referredByMemberName
    ),
    referredByMembershipNumber: condition(
      otherFields.referredByMembershipNumber,
      otherFields.referredByMembershipNumber
    ),
    otherCommisisionNote: condition(
      otherFields.otherCommissionNote,
      otherFields.otherCommissionNote
    ),
    generalNote: condition(otherFields.generalNote, otherFields.generalNote),
    recurringCardTypeId: condition(
      otherFields.recurringCardType.value,
      otherFields.recurringCardType.value
    ),
    genderId: condition(
      personalDetails.gender.value,
      personalDetails.gender.value
    ),
    payment: {
      primary: getPrimaryPaymentMethod(
        paymentDetails,
        selectedPackagePlan,
        isDisableEzyPay,
        paymentInfo
      ),
      secondary: {
        paymentMethodCode: 'SC',
        paymentMethodTypeCode: 'NA',
        status: 'AC'
      }
    },
    isExpress: false,
    isFullMember: false,
    isPersonalTrainer: false,
    securityQuestionCode: '',
    securityQuestionAnswer: '',
    genderCode: condition(
      personalDetails.gender.value,
      personalDetails.gender.value
    ),
    leadId: 1,
    isAgreeWithHealthStatement: true,
    promotionIds: getPromotionIds(selectedPromotions),
    promotionCode: '',
    reciprocalAccessCode: ''
  };
};

const getCyberSourceData = (currentPayment, paymentDetails) => {
  let primaryPayment: any = {
    paymentMethodCode: currentPayment.planMethodCode,
    paymentMethodTypeCode: currentPayment.paymentMethodTypeCode,
    paymentMethodTypeName: currentPayment.planPaymentMethodTypeName,
    tenderName: 'Cash',
    tenderTypeCode: 'CASH',
    status: 'AC'
  };
  if (
    paymentDetails.primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT
  ) {
    primaryPayment = {
      paymentMethodCode: currentPayment.planMethodCode,
      paymentMethodTypeCode: currentPayment.paymentMethodTypeCode,
      accountName: '',
      accountNumber: '',
      issuingBankReasonCode: '',
      status: 'AC',
      paymentMethod: PAYMENT_METHOD_TYPES.DIRECT_DEBIT,
      creationDate: currentTime()
    };
  } else if (
    paymentDetails.primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD
  ) {
    primaryPayment = {
      paymentMethodCode: currentPayment.planMethodCode,
      paymentMethodTypeCode: currentPayment.paymentMethodTypeCode,
      paymentMethodTypeName: currentPayment.planPaymentMethodTypeName,
      accountName: '',
      accountNumber: '',
      issueNumber: 0,
      status: 'AC',
      nationalID: paymentDetails.nationalIdNumber,
      recurringDetailReference: '',
      paymentTokenIdentifierId:
        paymentDetails.paymentTokenInstrumentIdentifierId,
      cardType: '',
      expiration: '',
      paymentProvider: 'CS',
      isRecurringDetailReferenceActive: false,
      tenderName: '',
      tenderTypeCode: '',
      paymentMethod: paymentDetails.primaryPaymentMethodId,
      creationDate: currentTime()
    };
  }
  return primaryPayment;
};

const currentTime = () => {
  return moment().format('YYYY-MM-DD') + 'T00:00:00';
};
