import { PAYMENT_METHOD_TYPES } from 'constants/Constants';

import { DataDescription } from '../interface';

import * as Utils from 'util/ControlUtils';

import { CardUtil } from 'models/EzyPay/Card';
import { EzyPayInfo } from 'models/EzyPay/EzyPayInfo';
import { BankUtil } from 'models/EzyPay/Bank';
import { PayToUtil } from 'models/EzyPay/PayTo';

export const usePaymentEzyPay = (
  paymentInfo: EzyPayInfo,
  paymentDetails,
  t
) => {
  const primaryPayment = paymentDetails.primaryPaymentMethod.find(
    payment => payment.value === paymentDetails.primaryPaymentMethodId
  );
  let ezyPayData: DataDescription[] = [
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_PAYMENT_METHOD'),
      data: Utils.getValueData(primaryPayment.label)
    }
  ];

  if (!paymentInfo) {
    return ezyPayData;
  }

  const isDirectDebit =
    paymentDetails &&
    paymentDetails.primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT;

  const isCreditCard =
    paymentDetails &&
    paymentDetails.primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD;

  const { card, payTo, bank } = paymentInfo;

  const isPayTo = isDirectDebit && payTo;
  const isBank = isDirectDebit && bank;
  const isCard = isCreditCard && card;

  if (isCard) {
    ezyPayData = ezyPayData.concat([
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_TYPE'),
        data: CardUtil.getCardType(card)
      },
      {
        fieldLabel: t(
          'PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_NUMBER'
        ),
        data: CardUtil.getCardNumber(card)
      },
      {
        fieldLabel: t(
          'PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_HOLDER_NAME'
        ),
        data: card.accountHolderName
      },
      {
        fieldLabel: t(
          'PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.EXPIRY_DATE'
        ),
        data: CardUtil.getExpireDate(card)
      }
    ]);
  }

  if (isBank) {
    ezyPayData = ezyPayData.concat([
      {
        fieldLabel: t('ACCOUNT.TYPE'),
        data: 'Bank'
      },
      {
        fieldLabel: t('BSB.NUMBER'),
        data: bank.bankNumber
      },
      {
        fieldLabel: t('DOMESTRIC.AC'),
        data: BankUtil.getDomestic(bank)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME'),
        data: bank.accountHolderName
      }
    ]);
  }

  if (isPayTo) {
    ezyPayData = ezyPayData.concat([
      {
        fieldLabel: t('ACCOUNT.TYPE'),
        data: 'PayTo'
      },
      {
        fieldLabel: t('PAYTO.AC'),
        data: PayToUtil.getBankAccountType(payTo)
      },
      {
        fieldLabel: t('BSB.NUMBER'),
        data: PayToUtil.getBsbNumber(payTo)
      },
      {
        fieldLabel: t('DOMESTRIC.AC'),
        data: PayToUtil.getDomesticNumber(payTo)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ACCOUNT_NAME'),
        data: PayToUtil.getAccountName(payTo)
      },
      {
        fieldLabel: t('PAYID.VIA.EMAIL'),
        data: PayToUtil.getPayIdEmail(payTo)
      },
      {
        fieldLabel: t('PAYID.VIA.PHONE'),
        data: PayToUtil.getPayIdPhoneNumber(payTo)
      },
      {
        fieldLabel: t('PAYTO.M.S'),
        data: PayToUtil.getMandateReason(payTo)
      }
    ]);
  }

  return ezyPayData;
};
