import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import { LookupModel, PhoneNumberModel } from 'models/PersonalDetails';
import {
  checkInvalidEmailField,
  checkInvalidInputField,
  DisplayDateFormat,
  isBeforeMinDateSystem,
  IsValidDateOfBirth
} from './ControlUtils';
import { getDuplicateEmail } from 'services/membership-service';

const required = (isRequired: boolean) => isRequired && 'COMMON.FIELD_REQUIRED';

const validateParentalConsent = (
  value: boolean,
  isParentalConsentRequired: boolean
) =>
  (value && isParentalConsentRequired) ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.PARENTAL_REQUIRE_MEMBER_UNDER_18_YEAR_OLD';

const validateEmptySelection = (
  selection: LookupModel | undefined,
  isRequired: boolean
) =>
  (isRequired && selection && !!selection.value) ||
  (!isRequired && selection && !selection.value) ||
  (!isRequired && selection && !!selection.value) ||
  'COMMON.FIELD_REQUIRED';

const validateFutureDate = (value: Date | null) =>
  (value && IsValidDateOfBirth(value)) ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_CAN_NOT_BE_FUTURE_DATE';

const validatePhoneNumber = (value: PhoneNumberModel) =>
  value.isValid ||
  !value.phoneNumber ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID';

const validateEmail = (regex: string) => (value: string) =>
  checkInvalidEmailField(value, new RegExp(regex)) ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.INVALID_EMAIL_ADDRESS';

const validateInputField = (regex: string) => (value: string) =>
  checkInvalidInputField(value, new RegExp(regex)) ||
  'PAGE.MEMBERSHIPS.NOT_VALID_FIELDS';

const validateRequireNationalIDOrPassport = (passportNumber: string) => (
  value: string
) =>
  !!passportNumber.length ||
  !!value.length ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.NATIONAL_ID_NUMBER_REQUIRED';

const validateRequireAtLeastOnePhone = (
  workPhone: PhoneNumberModel,
  homePhone: PhoneNumberModel
) => (value: PhoneNumberModel) =>
  !!workPhone.phoneNumber.length ||
  !!homePhone.phoneNumber.length ||
  !!value.phoneNumber.length ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED';

const validateAvailableEmail = async value => {
  const duplicateEmail = await getDuplicateEmail({
    email: encodeURIComponent(value)
  });
  if (duplicateEmail.data.length > 0) {
    return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.DUPLICATE_EMAIL_ADDRESS';
  }
  return '';
};

const validateGuardianPhone = (regex: string) => (value: string) =>
  (value && RegExp(regex).test(value)) ||
  !value ||
  'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID';

const onChangeDatePicker = <T extends FieldValues, K extends Path<T>>(
  field: ControllerRenderProps<T, K> & {
    name: K;
  }
) => (date: string) => {
  const { onChange } = field;
  if (date && !isBeforeMinDateSystem(date)) {
    const newDate = date
      ? new Date(DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT))
      : null;
    onChange(newDate);
  }
};

const onChangeInput = <T extends FieldValues, K extends Path<T>>(
  field: ControllerRenderProps<T, K> & {
    name: K;
  }
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  const { onChange } = field;
  onChange(event);
};

const onChangePhoneNumber = <T extends FieldValues, K extends Path<T>>(
  field: ControllerRenderProps<T, K> & {
    name: K;
  }
) => (phoneNumber, regionCode, isValid) => {
  const { onChange } = field;

  onChange({
    isValid,
    regionCode,
    phoneNumber
  });
};

export {
  required,
  validateParentalConsent,
  validateEmptySelection,
  validateFutureDate,
  validatePhoneNumber,
  validateEmail,
  validateAvailableEmail,
  validateGuardianPhone,
  validateRequireNationalIDOrPassport,
  validateRequireAtLeastOnePhone,
  onChangeInput,
  onChangeDatePicker,
  onChangePhoneNumber,
  validateInputField
};
