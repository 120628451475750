import React, { useEffect, useRef, useState } from 'react';
import Template from './template';
import ISelectePackagePlan from './interface';
import * as Utils from 'util/ControlUtils';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import MembershipConfiguration from 'models/MembershipConfiguration';
import OptionModel from 'models/Option';
import { useTranslation } from 'react-i18next';
import { FieldConfig } from 'models/PersonalDetails';
import observer from 'util/Observer';
import { CLUB_KEY_CONMAN } from 'constants/DefaultConstants';
import {
  IAllPackagePlans,
  ICounter,
  IPackagePlanItem
} from 'interfaces/Membership';
import { useTypedSelector } from 'reduxs';
import { IValidPromotion } from 'models/Promotion';
import { IConfig } from 'interfaces/SystemConfig';
import {
  defaultOption,
  getMembershipConfigurationFromDOB,
  handleSetData,
  getTemporaryData,
  handleFilterPackagePlanData,
  searchPackagePlan,
  onCountFilterData,
  getMembershipSearchFilterAfterClearFilter,
  storeDataToRedux,
  getPromotionId,
  handleErrorToNextStep,
  handleChangeDate,
  getErrorAfterHandle,
  getListPackagePlans,
  handlePromoCode,
  cloneMembershipSearchFilter,
  handleOnPackagePlanSelected,
  handleChangeSecondarySalePeople,
  handleChangeApplicationTypeId,
  handleChangeClub,
  getInitialData,
  handleChangePrimarySalePerson,
  handleOutSideClick,
  goToNextStep,
  toggleShowFilter,
  getPackagePlanForConvert,
  getInitialValue
} from 'common/SelectPackagePlan';
import { InitialProps } from '../SelectPackagePlanAddMember/interface';
import { Club } from 'models/Club';
import { updateFeeEzyPay } from 'reduxs/actions/Payment';
import { useDispatch } from 'react-redux';

const SelectPackagePlan: React.FC<ISelectePackagePlan> = ({
  configs,
  onCancel,
  setSelectedPackagePlanAction,
  setConfigurationAction,
  setPackagePlanSearchFilterAction,
  setProductExtrasAction,
  setAllPackageAction
}) => {
  const dispatch = useDispatch();
  const filterRef = useRef<HTMLDivElement>();
  const filterButtonRef = useRef<HTMLButtonElement>();
  const { t } = useTranslation();
  const newMembership = useTypedSelector(state => state.convertGuestToMember);
  const conman = useTypedSelector(state => state.conman);
  const [membershipSearchFilter, setMembershipSearchFilter] = useState(
    new MembershipSearchFilter()
  );
  const [membershipConfiguration, setMembershipConfiguration] = useState(
    new MembershipConfiguration()
  );
  const [selectedPackagePlan, setSelectedPackagePlan] = useState<
    IPackagePlanItem
  >();
  const [dateOfBirthConfig, setDateOfBirthConfig] = useState(new FieldConfig());

  const [packagePlans, setPackagePlans] = useState<IPackagePlanItem[]>([]);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clubs, setClubs] = useState<OptionModel[]>([]);
  const [allPackagePlans, setAllPackagePlans] = useState<IAllPackagePlans[]>(
    []
  );
  const [salePeople, setSalePeople] = useState<OptionModel[]>([]);
  const [secondarySalePeople, setSecondarySalePeople] = useState<OptionModel[]>(
    []
  );
  const [applicationTypes, setApplicationTypes] = useState<OptionModel[]>([]);
  const [reciprocalAccesses, setReciprocalAccesses] = useState<ICounter[]>([]);
  const [minimumContracts, setMinimumContracts] = useState<ICounter[]>([]);
  const [accesses, setAccesses] = useState<ICounter[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<ICounter[]>([]);
  const [errors, setErrors] = useState<any>([]);
  const [showCheckIcon, setShowCheckIcon] = useState<boolean>(false);
  const [validPromotion, setValidPromotion] = useState<IValidPromotion | null>(
    null
  );
  const [isEditClub, setIsEditClub] = useState<boolean>(false);
  const [allClubs, setAllClubs] = useState<Club[]>([]);
  const [initialValues, setInitialValues] = useState<InitialProps>(
    getInitialValue(membershipSearchFilter)
  );

  const handleClickOutSide = e => {
    const isFilterShown = handleOutSideClick(e, filterRef, filterButtonRef);
    if (isFilterShown) {
      setIsShowFilter(false);
    }
  };

  const setDOBFormat = () => {
    const membershipConfig = getMembershipConfigurationFromDOB(
      configs,
      membershipConfiguration
    );
    if (configs) {
      setMembershipConfiguration(membershipConfig);
      return;
    }
    setMembershipConfiguration(new MembershipConfiguration());
  };

  const handleSetClub = async (
    res,
    membershipSearchFilter
  ): Promise<[MembershipSearchFilter | undefined, OptionModel[]]> => {
    const {
      showCheckIcon,
      validPromotion,
      allPackagePlans,
      salePeople,
      membershipSearchFilterTemp,
      clubs,
      clubsData
    } = await handleSetData(res, membershipSearchFilter, newMembership);
    membershipSearchFilterTemp.clubSelect = clubsData.find(
      club => club.id === membershipSearchFilterTemp.clubId
    );
    setShowCheckIcon(showCheckIcon);
    setValidPromotion(validPromotion);
    setAllPackageAction(allPackagePlans);
    setSalePeople(salePeople);
    setSecondarySalePeople([defaultOption, ...salePeople]);
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setAllPackagePlans(allPackagePlans);
    setPackagePlans(allPackagePlans);
    setIsLoading(false);
    setAllClubs(clubsData);
    return [membershipSearchFilterTemp, clubs];
  };

  useEffect(() => {
    dispatch(updateFeeEzyPay(null));
    if (newMembership.selectedPackagePlan) {
      setInitialValues(getInitialValue(membershipSearchFilter));
      searchPackagePlan(membershipSearchFilter, handleFilterPackagePlan);
    } else {
      onCountFilter(allPackagePlans);
    }
  }, [newMembership.selectedPackagePlan, isLoading]);

  const getInitData = async membershipSearchFilter => {
    setIsLoading(true);
    const {
      dateOfBirthConfig,
      clubs,
      applicationTypes,
      searchFilterFinal
    } = await getInitialData(membershipSearchFilter, handleSetClub);
    setDateOfBirthConfig(dateOfBirthConfig);
    setClubs(clubs);
    setApplicationTypes(applicationTypes);
    setInitialValues(getInitialValue(searchFilterFinal));
    setMembershipSearchFilter(searchFilterFinal);
    setIsLoading(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    observer.publish('flagUnsavedChangeEvent', true);
    window.scrollTo(0, 0);
    const clubCfg = conman.configs.find(
      (cfg: IConfig) => cfg.key === CLUB_KEY_CONMAN && cfg.value
    );
    const {
      membershipSearchFilterTemp,
      membershipConfigurationTemp,
      selectedPackagePlanTemp
    } = getTemporaryData(
      membershipSearchFilter,
      membershipConfiguration,
      newMembership,
      selectedPackagePlan
    );
    if (clubCfg) {
      setIsEditClub(true);
    }
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setMembershipConfiguration(membershipConfigurationTemp);
    setSelectedPackagePlan(selectedPackagePlanTemp);
    setDOBFormat();
    getInitData(membershipSearchFilterTemp);
    setProductExtrasAction([], []);
  }, []);

  const handleFilterPackagePlan = () => {
    const result = handleFilterPackagePlanData(
      allPackagePlans,
      membershipSearchFilter
    );
    setPackagePlans(result);
    if (result.length > 0) {
      onCountFilter(allPackagePlans);
    }
  };

  const onCountFilter = (packagePlans: IAllPackagePlans[]) => {
    const {
      reciprocalAccesses,
      newContracts,
      accesses,
      paymentMethods
    } = onCountFilterData(getPackagePlanForConvert(packagePlans) as []);
    setReciprocalAccesses(reciprocalAccesses);
    setMinimumContracts(newContracts);
    setAccesses(accesses);
    setPaymentMethods(paymentMethods);
  };

  const handleClearFilter = () => {
    const membershipSearchFilterTemp = getMembershipSearchFilterAfterClearFilter(
      membershipSearchFilter
    );
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setPackagePlans(allPackagePlans);
    onCountFilter(allPackagePlans);
  };

  const toggleFilter = e => {
    const filterToggle = toggleShowFilter(e, isShowFilter);
    setIsShowFilter(filterToggle);
  };

  const handleSaveAndLeave = (stepIndex, isSave = false) => {
    const { membershipConfiguration } = newMembership;
    if (isSave) {
      storeDataToRedux(
        newMembership,
        membershipSearchFilter,
        setPackagePlanSearchFilterAction
      );
    }
    membershipConfiguration.stepIndex = stepIndex;
    setConfigurationAction(membershipConfiguration);
    observer.publish('closeDialogEvent', true);
  };

  const goToStep = stepIndex => {
    const membershipConfiguration = goToNextStep(
      stepIndex,
      newMembership,
      membershipSearchFilter,
      isReadyToNextStep,
      handleSaveAndLeave
    );
    if (membershipConfiguration) {
      setConfigurationAction(membershipConfiguration);
    }
  };

  const isReadyToNextStep = () => {
    let errors = handleErrorToNextStep(
      membershipSearchFilter,
      membershipConfiguration,
      dateOfBirthConfig
    );
    if (
      !Utils.isValidStartDateForGuestPass(
        membershipSearchFilter.startDate,
        newMembership.membershipSearchFilter.minStartDate
      )
    ) {
      errors = {
        ...errors,
        startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_NOT_VALID')
      };
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = async (key, event) => {
    if (!event.value) return;
    let membershipSearchFilterTemp = cloneMembershipSearchFilter(
      membershipSearchFilter
    );
    const newValue = event.value;

    if (key.includes('clubId')) {
      setIsLoading(true);
      membershipSearchFilterTemp[key] = newValue;
      membershipSearchFilterTemp.clubSelect =
        allClubs.find(club => club.id === newValue) || null;
      setMembershipSearchFilter(membershipSearchFilterTemp);
      const {
        allPackagePlans,
        salePersonList,
        membershipSearchFilterTmp
      } = await handleChangeClub(
        membershipSearchFilterTemp,
        validPromotion,
        clubs,
        secondarySalePeople,
        newValue
      );
      setMembershipSearchFilter(membershipSearchFilterTmp);
      setAllPackageAction(allPackagePlans);
      setSalePeople(salePersonList);
      setSecondarySalePeople([defaultOption, ...salePersonList]);
      setAllPackagePlans(allPackagePlans);
      setPackagePlans(allPackagePlans);
      setIsLoading(false);
      onCountFilter(allPackagePlans);

      return;
    }
    if (key.includes('primarySalePersonId')) {
      membershipSearchFilterTemp = handleChangePrimarySalePerson(
        membershipSearchFilterTemp,
        salePeople,
        newValue
      );
    }
    if (key.includes('secondarySalePersonId')) {
      membershipSearchFilterTemp = handleChangeSecondarySalePeople(
        membershipSearchFilterTemp,
        secondarySalePeople,
        newValue
      );
    }
    if (key.includes('applicationTypeId')) {
      membershipSearchFilterTemp = handleChangeApplicationTypeId(
        membershipSearchFilterTemp,
        applicationTypes,
        newValue
      );
    }
    membershipSearchFilterTemp[key] = newValue;
    setMembershipSearchFilter(membershipSearchFilterTemp);
  };

  const handleError = async (error: any, startDate: string) => {
    setShowCheckIcon(false);
    setValidPromotion(null);
    const err = await getErrorAfterHandle(
      error,
      startDate,
      getPackagePlanList,
      errors
    );
    setErrors(err);
  };

  const getPackagePlanList = async (
    startDate: string,
    promoId: number,
    dob: string
  ) => {
    setIsLoading(true);
    const allPackagePlans = await getListPackagePlans(
      membershipSearchFilter,
      startDate,
      promoId,
      dob
    );
    setPackagePlans(allPackagePlans);
    setAllPackagePlans(allPackagePlans);
    setIsLoading(false);
  };

  const handlePromotionCode = async promotionCode => {
    if (initialValues.promotionCode === promotionCode) {
      return;
    }
    setIsLoading(true);
    const {
      showCheckIcon,
      validPromotion,
      membershipSearchFilterTemp,
      error
    } = await handlePromoCode(
      false,
      promotionCode,
      membershipSearchFilter,
      getPackagePlanList,
      handleError
    );
    setErrors({ ...errors, promotionCode: error });
    setShowCheckIcon(showCheckIcon);
    setValidPromotion(validPromotion);
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setInitialValues({ ...initialValues, promotionCode });
    setIsLoading(false);
  };

  const applyPromotionCode = async event => {
    const promotionCode = event.target.value;
    if (event.nativeEvent.key && event.nativeEvent.key === 'Enter') {
      const inputElement = document.getElementById('promotion-input');
      if (inputElement) {
        inputElement.blur();
      }
      return;
    }
    await handlePromotionCode(promotionCode);
  };

  const onPackagePlanSelected = (packagePlanId: number) => {
    if (isReadyToNextStep()) {
      const {
        membershipConfigurationTemp,
        membershipSearchFilterTemp,
        packagePlan
      } = handleOnPackagePlanSelected(
        packagePlanId,
        membershipSearchFilter,
        membershipConfiguration,
        packagePlans,
        validPromotion
      );
      if (packagePlan) {
        // Check if promotion rules need to have dateOfBirth
        const isRequireAge = packagePlan.isRequireAgeForPromotion;
        if (isRequireAge && !membershipSearchFilter.dateOfBirth) {
          setErrors({
            ...errors,
            dateOfBirth: t(
              'PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_REQUIRED'
            )
          });
          return;
        }
        setSelectedPackagePlanAction(packagePlan);
      }
      setConfigurationAction(membershipConfigurationTemp);
      setPackagePlanSearchFilterAction(membershipSearchFilterTemp);
    }
  };

  const handleInputFilterChange = (key, event) => {
    const membershipSearchFilterTemp = cloneMembershipSearchFilter(
      membershipSearchFilter
    );
    if (key === 'promotionCode') {
      membershipSearchFilterTemp.promotion.code = event.target.value;
      setValidPromotion(null);
      const error = { ...errors, promotionCode: '' };
      setErrors(error);
    } else {
      membershipSearchFilterTemp[key] = event.target.value;
    }
    setMembershipSearchFilter(membershipSearchFilterTemp);
  };

  const handleDateChange = async (date, name) => {
    if (name === 'dateOfBirth' && Utils.isBeforeMinDateSystem(date)) {
      return;
    }
    const membershipSearchFilterTemp = await handleChangeDate(
      date,
      name,
      membershipSearchFilter
    );
    setDOBFormat();
    setMembershipSearchFilter(membershipSearchFilterTemp);
    if (name === 'startDate') {
      setErrors({ ...errors, startDate: '' });
    }
    if (name === 'dateOfBirth') {
      setErrors({ ...errors, startDate: '' });
    }
  };

  const loadPackagePlansAfterChangeDate = async (name: string) => {
    const startDate = Utils.getFormattedDateValue(
      membershipSearchFilter.startDate
    );
    const dob = Utils.getFormattedDateValue(membershipSearchFilter.dateOfBirth);
    if (
      (name === 'startDate' && initialValues.startDate !== startDate) ||
      (name === 'dateOfBirth' && initialValues.dateOfBirth !== dob)
    ) {
      await getPackagePlanList(startDate, getPromotionId(validPromotion), dob);
      setInitialValues({ ...initialValues, startDate, dateOfBirth: dob });
    }
  };

  return (
    <Template
      dateOfBirthConfig={dateOfBirthConfig}
      membershipConfiguration={membershipConfiguration}
      membershipSearchFilter={membershipSearchFilter}
      errors={errors}
      clubs={clubs}
      secondarySalePeople={secondarySalePeople}
      applicationTypes={applicationTypes}
      salePeople={salePeople}
      reciprocalAccesses={reciprocalAccesses}
      minimumContracts={minimumContracts}
      accesses={accesses}
      paymentMethods={paymentMethods}
      packagePlans={getPackagePlanForConvert(packagePlans)}
      isShowFilter={isShowFilter}
      isLoading={isLoading}
      filterRef={filterRef}
      filterButtonRef={filterButtonRef}
      showCheckIcon={showCheckIcon}
      toggleFilter={toggleFilter}
      handleChange={handleChange}
      handleInputFilterChange={handleInputFilterChange}
      handleDateChange={handleDateChange}
      onPackagePlanSelected={onPackagePlanSelected}
      handleFilterPackagePlan={handleFilterPackagePlan}
      handleClearFilter={handleClearFilter}
      goToStep={goToStep}
      onCancel={onCancel}
      applyPromotionCode={applyPromotionCode}
      loadPackagePlansAfterChangeDate={loadPackagePlansAfterChangeDate}
      isAllowEditClub={isEditClub}
    />
  );
};

export default SelectPackagePlan;
