import { API_GET_TOKEN_BY_ONETIME_TOKEN } from 'constants/Constants';
import { handleErrorHttp, idpApi } from './common-service';
import { IGetTokenByOneTimeTokenRequest } from './interfaces/requests/idp';

const getTokenByOneTimeToken = ({
  ref_token
}: IGetTokenByOneTimeTokenRequest) => {
  return idpApi
    .get(API_GET_TOKEN_BY_ONETIME_TOKEN.replace('{ref_token}', ref_token))
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

export { getTokenByOneTimeToken };
