declare const window: Record<string, Record<string, string>>;

export const BASE_API_URL_DEVELOPMENT = window._env.REACT_APP_API_URL;

const appURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''
  }`;

export const LANGUAGE_RESOURCE_URL =
  appURL.indexOf('localhost') > -1
    ? `${window._env.REACT_APP_DOMAIN}/i18n`
    : `${window._env.REACT_APP_BLOB_STORAGE_URL}/i18n`;

export const IDENTITY_SERVER_ENDPOINT_URL = `${window._env.REACT_APP_IDENTITY_SERVER_URL
  }`;
export const IDENTITY_SERVER_CLIENT_ID = `${window._env.REACT_APP_IDENTITY_SERVER_CLIENT_ID
  }`;
export const ESIGNATURE_URL = `${window._env.REACT_APP_ESIGNATURE_URL}`;

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     IFrame    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */

// General
export const GENERAL_DATE_FORMAT = `DD/MM/YYYY`;
export const GENERAL_DATABASE_DATE_FORMAT = `YYYY-MM-DD`;
export const RAW_RESPONSE_EXPIRY_DATE_FORMAT = 'MM-YYYY';
export const GENERAL_NA = 'N/A';
export const GENERAL_APPLICATION_TYPE_DIGITAL_CONTRACT = 'digital contract';
export const INVALID_DATE = 'Invalid date';
export const PAYMENT_METHODS = {
  CASH: 'Cash'
};
export const MIN_DATE_SYSTEM = '1900-01-01';
export const MAX_DATE_SYSTEM = '2099-12-31';
export const PAYMENT_METHOD_TYPES = {
  CREDIT_CARD: 'CC',
  DIRECT_DEBIT: 'DD',
  CASH: 'NO',
  NONE: 'NA'
};
export const PAYMENT_DECISION = {
  ACCEPT: 'ACCEPT',
  CANCEL: 'CANCEL',
  DECLINE: 'DECLINE'
};
export const STORAGE = {
  NEW_MEMBERSHIP: 'NEW_MEMBERSHIP',
  NEW_CORPORATE_MEMBERSHIP: 'NEW_CORPORATE_MEMBERSHIP',
  CONVERT_GUEST_TO_MEMBER: 'CONVERT_GUEST_TO_MEMBER',
  CONVERT_GUEST_TO_CORPORATE_MEMBER: 'CONVERT_GUEST_TO_CORPORATE_MEMBER',
  EXTERNAL_ID: 'EXTERNAL_ID',
  OPERATOR_ID: 'OPERATOR_ID'
};
export const PROMOTION_TYPE = {
  PROMOTION: 'Promotion',
  SPECIAL_PROMOTION: 'Discount'
};
export const PAYMENT_METHODS_TEXT = {
  ONLINE_PAYMENT: 'Online Payment',
  CREDIT_CARD: 'Credit Card'
};

export const UNITS = {
  BW: {
    PLURAL: 'Bi-Weekly',
    SINGULAR: 'Bi-Weekly'
  },
  DY: {
    PLURAL: 'Days',
    SINGULAR: 'Day'
  },
  MD: {
    PLURAL: 'Minus Days',
    SINGULAR: 'Minus Day'
  },
  MM: {
    PLURAL: 'Minus Months',
    SINGULAR: 'Minus Month'
  },
  MN: {
    PLURAL: 'Months',
    SINGULAR: 'Month'
  },
  WE: { PLURAL: 'Weekly', SINGULAR: 'Weekly' }
};

/* ----------------------------------------------------------------------------------- */
/* ---------------------------------     API    -------------------------------------- */
/* ----------------------------------------------------------------------------------- */

// Conman Get
export const API_GET_TOKEN_BY_ONETIME_TOKEN = `/idp/accounts/sso?token={ref_token}`;
export const API_GET_ADDON = `/prd/addonClasses?clubId={clubId}`;
export const API_GET_SYSTEM_CONFIGS = `/cnm/system/configs`;
export const API_GET_ISSUING_BANKS = `cnm/lookups/IB`;

// Add New Membership
// Step 1
export const API_MBS_GET_CLUBS = `/cnm/clubs?pageSize={0}`;
export const API_MBS_GET_APPLICATION_TYPES = `/mbs/applicationtypes?pageSize={0}`;
export const API_MBS_GET_SALE_PEOPLE = `/usr/salepersons?pageSize=999&clubId={0}&fields={1}`;
export const API_MBS_GET_PACKAGE_PLANS = `/plm/plans/clubs/{clubId}/packagePlans`;
export const CHECK_AVAILABLE_PROMOTION = 'prd/promotions';
export const API_GET_SYSTEM_DATE = '/cnm/system/configs/systemDate';
// Step 2
export const API_GET_PROMOTION_CATEGORIES = `prd/promotions/eligible-promotions`;
export const API_ARREARS_PREVIEW = `/mbs/members/arrears/mfpreview`;
// Step 3
export const API_MBS_GET_FIELDS_ADD_MEMBER = `/cnm/features/fields?code=OPG.AddPersonalDetail`;
export const API_MBS_GET_MEMBER_TITLES = `/cnm/lookups/SA`;
export const API_MBS_GET_MEMBER_GENDERS = `/cnm/lookups/GE`;
export const API_MBS_GET_MEMBER_LANGUAGE_PREFERENCES = `/cnm/lookups/ML`;
export const API_MBS_GET_OVERRIDE_EMAIL_OPTIONS = `/mbs/overrideemails`;
export const API_MBS_GET_COUNTRIES = `/cnm/SubTenants`;
export const API_MBS_GET_CONTACT_METHODS = `/mbs/contactmethods`;
export const API_MBS_GET_LOOK_UP = `/cnm/lookups/{0}`;
export const API_MBS_GET_EMERGENCY_CONFIG =
  '/cnm/questions?QuestionTypeCode={typeCode}&QuestionCode={code}';
export const API_MBS_GET_CONMAN_CONFIG =
  '/cnm/questions?QuestionTypeCode={typeCode}';
export const API_MBS_GET_AIA_VITALITY_CATEGORY = `/mbs/aiavitalitycategories`;
export const API_MBS_GET_RECURRING_CARD_TYPE = `/cnm/lookups/Q1`;
export const API_MBS_GET_GUARDIAN_IDENTITY_TYPE_ID = `/cnm/lookups/GT`;
export const API_MBS_GET_DUPLICATE_MEMBERS = `/mbs/members/duplicates`;
export const API_MBS_GET_DUPLICATE_MEMBERS_EMAIL = `/mbs/members/duplicates?EmailAddress={0}`;

// Step 4
export const API_MBS_GET_MEMBER_BANKS = `/mbs/banks`;
export const API_MBS_GET_MEMBER_CARD_TYPES = `/mbs/cardtype`;
export const API_GET_RAW_RESPONSE = `/pmt/cybersource/rawResponse`;
// Step 5
export const API_MBS_POST_MEMBER = `/mbs/members`;

// Member Summary
export const API_MBS_GET_MEMBER_BY_ID = `/mbs/members/{id}`;

//Convert guest to member
export const API_GET_GUEST_PASS_DETAIL = '/mbs/guests/{id}/guestpackageplan';
// Add New Corporate Member
// Step 1
export const API_MBS_GET_CORPORATE_NAMES = `/mbs/esignature/corporate/club/{clubId}`;

// Convert Guest to Guest Pass
export const API_MBS_GET_GUEST_DETAILS = `/mbs/guests`;
export const API_MBS_POST_GUEST_TO_GUESTPASS = `/mbs/guests/guestpackageplans`;
export const API_MBS_PATCH_GUEST_TO_MSCRM = `/mbs/guests/mscrm/{externalId}`;
export const API_MBS_GET_END_DATE = `/mbs/members/packageplan-enddate`;
