import { StringChain } from 'lodash';
import { EzyPayFee } from 'models/EzyPay/EzyPayFee';
import { baseApi, createHeader, handleErrorHttp } from './common-service';

export const getEzyToken = (): Promise<{
  access_token: string;
  expires_in: number;
  token_type: StringChain;
}> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get('/pmt/payments/ezypay/getHostedPageToken', {
      headers
    })
    .then(res => res.data)
    .catch(error => {
      handleErrorHttp(error);
    });
};

export const loadConfigDisabledUseEzyPay = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get('/cnm/system/configs/isEzypayDisabled', {
      headers
    })
    .then(res => res.data)
    .catch(error => {
      handleErrorHttp(error);
    });
};

export const getEzyFee = (): Promise<EzyPayFee[]> => {
  return baseApi
    .get('pmt/fee/ezypay/fees', {
      headers: createHeader({
        hasCache: false,
        subTenant: { useSubTenant: true },
        tenant: { useTenant: true }
      })
    })
    .then(res => res.data)
    .catch(error => {
      handleErrorHttp(error);
    });
};
