import React from 'react';
import FormButton from 'components/Membership/FormButton';
import { ITemplate } from './interface';
import ConvertGuestToGuestPassFormInfo from 'components/Membership/ConvertGuestToGuestPassFormInfo';
import ConvertGuestToGuestPassBreadcrumb from 'components/Membership/ConvertGuestToGuestPassBreadcrumb';
import ConvertGuestToGuestPassPackagePlanList from 'components/Membership/ConvertGuestToGuestPassPackagePlanList';

const Template: React.FC<ITemplate> = ({
  configuration,
  searchFilter,
  errors,
  secondarySalePeople,
  clubs,
  salePeople,
  packagePlans,
  isLoading,
  handleChange,
  handleDateChange,
  onPackagePlanSelected,
  goToStep,
  onCancel
}) => {
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <ConvertGuestToGuestPassBreadcrumb
            configuration={configuration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <ConvertGuestToGuestPassFormInfo
            searchFilter={searchFilter}
            configuration={configuration}
            errors={errors}
            clubs={clubs}
            salePeople={salePeople}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            secondarySalePeople={secondarySalePeople}
          />
          <ConvertGuestToGuestPassPackagePlanList
            isLoading={isLoading}
            packagePlans={packagePlans}
            selectedPackagePlanId={searchFilter.selectedPackagePlanId}
            onPackagePlanSelected={onPackagePlanSelected}
          />
          <FormButton handleCancel={onCancel} />
        </div>
      </div>
    </section>
  );
};

export default Template;
