import { ConmanFieldConfigModel } from 'models/ConmanConfig';
import {
  ACTIVE_STATUS_CODE,
  CHANNEL_CODE_TEXT,
  COMPANY_NAME_TEXT,
  EMERGENCY_CONTACT_NAME_TEXT,
  EMERGENCY_CONTACT_PHONE_NUMBER_TEXT,
  GENERAL_NOTE_TEXT,
  GUARDIAN_ADDRESS_TEXT,
  GUARDIAN_IDENTITY_NO_TEXT,
  GUARDIAN_IDENTITY_TYPE_ID_TEXT,
  GUARDIAN_NAME_TEXT,
  GUARDIAN_PASS_TEXT,
  GUARDIAN_PHONE_NUMBER_TEXT,
  GUARDIAN_RELATIONSHIP_TEXT,
  MARKETING_CAMPAIGN_TEXT,
  MARKETING_SOURCE_TEXT,
  OCCUPATION_TEXT,
  OTHER_COMMISSION_NOTE_TEXT,
  REFERRED_BY_MEMBERSHIP_NUMBER_TEXT,
  REFERRED_BY_NAME_TEXT,
  RELATIONSHIP_TEXT
} from 'constants/DefaultConstants';
import moment from 'moment';
import { getDuplicateMembers } from 'services/membership-service';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import {
  filterValuableFieldsOnly,
  getFieldConfig
} from 'util/FieldConfiguration';
import {
  AddressDetailsFieldsModel,
  AddressDetailsModel,
  ContactDetailsFieldsModel,
  ContactDetailsModel,
  ContactedDetailsFieldsModel,
  ContactedDetailsModel,
  EmergencyContactFieldsModel,
  EmergencyContactModel,
  LookupModel,
  OtherFieldsFieldsModel,
  OtherFieldsModel,
  PersonalDetailFieldsModel,
  PersonalDetailsModel,
  Question
} from 'models/PersonalDetails';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { INewMembership } from 'reduxs/reducers/NewMembership';
import { INewCorporateMembership } from 'reduxs/reducers/NewCorporateMembership';
import React from 'react';
import observer from 'util/Observer'; 
import { IDuplicateMember } from 'components/Membership/DuplicateMembers';
import { UseFormReturn } from 'react-hook-form';
import { PersonalDetailsFormInput } from 'components/Membership/PersonalDetails';
import { IsValidEmail } from 'util/ControlUtils';
import { IConfig } from 'interfaces/SystemConfig';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import { ContactDetailsFormInput } from 'components/Membership/ContactDetails';
import { AddressDetailsFormInput } from 'components/Membership/AddressDetails';
import { ContactedDetailsFormInput } from 'components/Membership/ContactedDetails';
import { EmergencyContactFormInput } from 'components/Membership/EmergencyContact';
import { HealthQuestionFormInput } from 'components/Membership/HealthQuestion';
import { OtherFieldsFormInput } from 'components/Membership/OtherFields';
import { IConvertGuestToMember } from 'reduxs/reducers/ConvertGuestToMember';
import { IConvertGuestToCorporateMember } from 'reduxs/reducers/ConvertGuestToCorporateMember';
import { t } from 'util/I18nMessages';

// general function in Select Personal Details
const defaultOption = {
  label: 'COMMON.PLEASE_SELECT',
  value: 0
};
export const findConmanConfig = (
  conmanConfigList: ConmanFieldConfigModel[],
  description: string
) => {
  return conmanConfigList.find(
    (cfg: ConmanFieldConfigModel) => cfg.questionDescriptorText === description
  );
};

export const isResponseValid = res => {
  return res && res.values && res.values.length > 0;
};

export const getOptionList = list => {
  if (isResponseValid(list)) {
    const availableList = list.values
      .filter(item => item.status)
      .sort((item1, item2) => item1.sortIndex - item2.sortIndex);
    return availableList.map(item => ({
      label: item.name,
      value: item.code
    }));
  }
  return [];
};

export const isRequiredConmanField = (
  fieldConfig: ConmanFieldConfigModel | undefined
) => {
  return (
    fieldConfig &&
    fieldConfig.isRequired &&
    fieldConfig.status === ACTIVE_STATUS_CODE
  );
};

export const getFieldQuestionConfig = (
  config: ConmanFieldConfigModel | undefined,
  value: string
) => {
  const res = {
    questionId: '',
    questionCode: '',
    questionTypeCode: '',
    answerTypeCode: '',
    answerCode: '',
    answer: '',
    regEx: ''
  };
  if (config) {
    return {
      questionId: config.questionId.toString() || '',
      questionCode: config.questionCode || '',
      questionTypeCode: config.questionTypeCode || '',
      answerTypeCode: config.answerTypeCode || '',
      answerCode: config.answerCode || '',
      answer: value,
      regEx: config.regEx || ''
    };
  }
  return res;
};

export const findConmanConfigList = (
  conmanConfigList: ConmanFieldConfigModel[]
) => {
  const relationshipCfg = findConmanConfig(conmanConfigList, RELATIONSHIP_TEXT);
  const guardianNameCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_NAME_TEXT
  );
  const guardianPhoneNumberCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_PHONE_NUMBER_TEXT
  );
  const guardianAddressCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_ADDRESS_TEXT
  );
  const guardianRelationshipCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_RELATIONSHIP_TEXT
  );
  const guardianIdentityNoCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_IDENTITY_NO_TEXT
  );
  const guardianIdentityTypeIdCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_IDENTITY_TYPE_ID_TEXT
  );
  const guardianPassCfg = findConmanConfig(
    conmanConfigList,
    GUARDIAN_PASS_TEXT
  );
  const emergencyNameCfg = findConmanConfig(
    conmanConfigList,
    EMERGENCY_CONTACT_NAME_TEXT
  );
  const emergencyPhoneNumberCfg = findConmanConfig(
    conmanConfigList,
    EMERGENCY_CONTACT_PHONE_NUMBER_TEXT
  );
  const channelCodeCfg = findConmanConfig(conmanConfigList, CHANNEL_CODE_TEXT);
  const marketingCampaignCfg = findConmanConfig(
    conmanConfigList,
    MARKETING_CAMPAIGN_TEXT
  );
  const marketingSouceCfg = findConmanConfig(
    conmanConfigList,
    MARKETING_SOURCE_TEXT
  );
  return {
    relationshipCfg,
    guardianNameCfg,
    guardianPhoneNumberCfg,
    guardianAddressCfg,
    guardianRelationshipCfg,
    guardianIdentityNoCfg,
    guardianIdentityTypeIdCfg,
    guardianPassCfg,
    emergencyNameCfg,
    emergencyPhoneNumberCfg,
    channelCodeCfg,
    marketingCampaignCfg,
    marketingSouceCfg
  };
};

export const getOtherFieldsConfigList = (
  otherFieldsConfigList: ConmanFieldConfigModel[]
) => {
  const companyNameCfg = findConmanConfig(
    otherFieldsConfigList,
    COMPANY_NAME_TEXT
  );
  const occupationCfg = findConmanConfig(
    otherFieldsConfigList,
    OCCUPATION_TEXT
  );
  const referredMemberByNameCfg = findConmanConfig(
    otherFieldsConfigList,
    REFERRED_BY_NAME_TEXT
  );
  const referredByMembershipNumberCfg = findConmanConfig(
    otherFieldsConfigList,
    REFERRED_BY_MEMBERSHIP_NUMBER_TEXT
  );
  const otherCommissionNoteCfg = findConmanConfig(
    otherFieldsConfigList,
    OTHER_COMMISSION_NOTE_TEXT
  );
  const generalNoteCfg = findConmanConfig(
    otherFieldsConfigList,
    GENERAL_NOTE_TEXT
  );
  return {
    companyNameCfg,
    occupationCfg,
    referredMemberByNameCfg,
    referredByMembershipNumberCfg,
    otherCommissionNoteCfg,
    generalNoteCfg
  };
};

export const findDuplicateMembersData = async (
  lastName: string,
  birthDate: Date,
  postCode: string
) => {
  const dateOfBirth = moment(birthDate);
  const data = await getDuplicateMembers({
    lastName,
    postCode,
    birthDate: dateOfBirth.isValid()
      ? dateOfBirth.format(GENERAL_DATABASE_DATE_FORMAT)
      : ''
  });
  if (data && data.length) {
    return data;
  }
  return null;
};

export const getCustomFieldsConfig = (
  otherFieldsConfigList: ConmanFieldConfigModel[] | undefined
) => {
  if (otherFieldsConfigList && otherFieldsConfigList.length) {
    const companyNameConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === COMPANY_NAME_TEXT
    );
    const occupationConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === OCCUPATION_TEXT
    );
    const referredMemberByNameConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === REFERRED_BY_NAME_TEXT
    );
    const referredByMembershipNumberConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === REFERRED_BY_MEMBERSHIP_NUMBER_TEXT
    );
    const otherCommissionNoteConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === OTHER_COMMISSION_NOTE_TEXT
    );
    const generalNoteConfig = otherFieldsConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GENERAL_NOTE_TEXT
    );
    return {
      companyNameConfig,
      occupationConfig,
      referredMemberByNameConfig,
      referredByMembershipNumberConfig,
      otherCommissionNoteConfig,
      generalNoteConfig
    };
  }
};

export const getFieldEmergencyConfig = (
  conmanConfigList: ConmanFieldConfigModel[] | undefined
) => {
  if (conmanConfigList && conmanConfigList.length) {
    const relationshipConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === RELATIONSHIP_TEXT
    );
    const guardianNameConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_NAME_TEXT
    );
    const guardianPhoneNumberConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_PHONE_NUMBER_TEXT
    );
    const guardianAddressConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_ADDRESS_TEXT
    );
    const guardianRelationshipConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_RELATIONSHIP_TEXT
    );
    const guardianIdentityNoConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_IDENTITY_NO_TEXT
    );
    const guardianIdentityTypeIdConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_IDENTITY_TYPE_ID_TEXT
    );

    const guardianPassConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === GUARDIAN_PASS_TEXT
    );
    const emergencyNameConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === EMERGENCY_CONTACT_NAME_TEXT
    );
    const emergencyPhoneNumberConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === EMERGENCY_CONTACT_PHONE_NUMBER_TEXT
    );
    const channelCodeConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === CHANNEL_CODE_TEXT
    );
    const marketingCampaignConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === MARKETING_CAMPAIGN_TEXT
    );
    const marketingSourceConfig = conmanConfigList.find(
      (cnm: ConmanFieldConfigModel) =>
        cnm.questionDescriptorText === MARKETING_SOURCE_TEXT
    );
    return {
      emergencyNameConfig,
      emergencyPhoneNumberConfig,
      relationshipConfig,
      guardianAddressConfig,
      guardianRelationshipConfig,
      guardianNameConfig,
      guardianPhoneNumberConfig,
      marketingCampaignConfig,
      marketingSourceConfig,
      channelCodeConfig,
      guardianPassConfig,
      guardianIdentityTypeIdConfig,
      guardianIdentityNoConfig
    };
  }
};

// Loading data from API list
export const loadConfiguration = fieldResponse => {
  //get field configs from service to override local config if any
  const personalDetailsFieldConfigs_ = filterValuableFieldsOnly(
    getPersonalFieldConfiguration(fieldResponse)
  );
  const contactDetailsFieldConfigs_ = filterValuableFieldsOnly(
    getContactFieldConfiguration(fieldResponse)
  );
  const addressDetailsFieldConfigs_ = filterValuableFieldsOnly(
    getAddressFieldConfiguration(fieldResponse)
  );
  const contactedDetailsFieldConfigs_ = filterValuableFieldsOnly(
    getContactedFieldConfiguration(fieldResponse)
  );
  const emergencyContactFieldsConfigs_ = filterValuableFieldsOnly(
    getEmergencyContactFieldConfiguration(fieldResponse)
  );
  const otherFieldsFieldsConfigs_ = filterValuableFieldsOnly(
    getOtherFieldsFieldConfiguration(fieldResponse)
  );
  return {
    personalDetailsFieldConfigs_,
    contactDetailsFieldConfigs_,
    addressDetailsFieldConfigs_,
    contactedDetailsFieldConfigs_,
    emergencyContactFieldsConfigs_,
    otherFieldsFieldsConfigs_
  };
};

// get Configuration for Personal Details, Contact Details,
// Address Details, Contacted Details, Emergency Contact,
// Health Questionnaire, Other Fields
export const getPersonalFieldConfiguration = (
  fieldConfiguration
): PersonalDetailFieldsModel => {
  const dateOfBirthConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_3
  );
  const titleConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_TITLE
  );
  const genderConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_GENDER
  );
  const firstNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_FIRST_NAME
  );
  const lastNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_LAST_NAME
  );
  const middleNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MIDDLE_NAME
  );
  const nationalConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NATIONAL
  );
  const nationalIdConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NATIONAL_ID
  );
  const passportNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_OTHER_PASSPORT_NUMBER
  );
  const parentalConsentConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_PARENTAL_CONSENT_PROVIDED
  );
  const preferedLanguageConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES
  );

  return {
    titleConfig,
    genderConfig,
    firstNameConfig,
    lastNameConfig,
    middleNameConfig,
    dateOfBirthConfig,
    nationalConfig,
    nationalIdConfig,
    preferedLanguageConfig,
    parentalConsentConfig,
    passportNumberConfig
  };
};

export const getContactFieldConfiguration = (
  fieldConfiguration
): ContactDetailsFieldsModel => {
  const telephoneNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_TELEPHONE_NUMBER
  );
  const emailConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMAIL_ADDRESS
  );

  const mobilePhoneConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_CONTACT_DETAILS_MOBILE_TELEPHONE
  );
  const homePhoneConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_CONTACT_DETAILS_MOBILE_TELEPHONE
  );
  const workPhoneConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_CONTACT_DETAILS_MOBILE_TELEPHONE
  );
  return {
    telephoneNumberConfig,
    emailConfig,
    mobilePhoneConfig,
    homePhoneConfig,
    workPhoneConfig
  };
};

export const getAddressFieldConfiguration = (
  fieldConfiguration
): AddressDetailsFieldsModel => {
  const addressNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NUMBER
  );
  const addressLine1Config = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_LINE_1
  );
  const addressLine2Config = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_LINE_2
  );
  const cityConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_CITY
  );
  const stateConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_STATE
  );
  const countryConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_COUNTRY
  );
  const postCodeConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_POSTCODE
  );
  return {
    addressNumberConfig,
    addressLine1Config,
    addressLine2Config,
    cityConfig,
    stateConfig,
    countryConfig,
    postCodeConfig
  };
};

export const getContactedFieldConfiguration = (
  fieldConfiguration
): ContactedDetailsFieldsModel => {
  const applicationRefConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBERSHIP_APPLICATION_REF
  );
  const contactMethodConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED
  );
  const preferredContactMethodConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD
  );
  const marketingSourceConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MARKETING_SOURCE
  );

  return {
    applicationRefConfig,
    memberHappyToBeContactConfig: contactMethodConfig,
    preferredContactMethodConfig,
    marketingSourceConfig
  };
};

export const getEmergencyContactFieldConfiguration = (
  fieldConfiguration
): EmergencyContactFieldsModel => {
  const contactNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_NAME
  );
  const contactNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER
  );

  return {
    contactNameConfig,
    contactNumberConfig
  };
};

export const getOtherFieldsFieldConfiguration = (
  fieldConfiguration
): OtherFieldsFieldsModel => {
  const companyNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_COMPANY_NAME
  );
  const occupationConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_OCCUPATION
  );
  const referredByMemberNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_REFERRED_BY_NAME
  );
  const referredByMembershipNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER
  );
  const otherCommissionNoteConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_OTHER_COMMISSION_NOTE
  );
  const generalNoteConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_GENERAL_NOTE
  );
  const recurringCardTypeConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_RECURRING_CARD_TYPE
  );

  return {
    companyNameConfig,
    occupationConfig,
    referredByMemberNameConfig,
    referredByMembershipNumberConfig,
    otherCommissionNoteConfig,
    generalNoteConfig,
    recurringCardTypeConfig
  };
};

export const loadOtherFieldsConfig = (
  otherFields_: OtherFieldsModel,
  recurringCardTypeResponse
) => {
  const recurringCardTypes_ = getOptionList(recurringCardTypeResponse);
  if (recurringCardTypes_.length > 0) {
    recurringCardTypes_.unshift(defaultOption);
    if (!otherFields_.recurringCardType.value) {
      otherFields_.recurringCardType =
        recurringCardTypes_[0] || new LookupModel();
    }
  }
  return recurringCardTypes_;
};

export const loadGuardianIdentityType = (
  guardianIdentityTypeIdResponse,
  emergencyContact_: EmergencyContactModel
) => {
  const guardianIdentityTypeIds_ = getOptionList(
    guardianIdentityTypeIdResponse
  );
  if (guardianIdentityTypeIds_.length > 0) {
       guardianIdentityTypeIds_.unshift({
         label: 'COMMON.PLEASE_SELECT',
         value: 0
       });
    if (!emergencyContact_.guardianIdentityTypeId.value) {
      emergencyContact_.guardianIdentityTypeId =
        guardianIdentityTypeIds_[0] || new LookupModel();
    }
  }
  return guardianIdentityTypeIds_;
};

export const loadMarketingsAndChannelCodes = (
  marketingSourceResponse,
  channelCodeResponse,
  marketingCampaignResponse,
  contactedDetails_: ContactedDetailsModel
) => {
  const marketingSources_ = getOptionList(marketingSourceResponse);
  if (marketingSources_.length > 0) {
    marketingSources_.unshift(defaultOption);
    if (!contactedDetails_.marketingSource.value) {
      contactedDetails_.marketingSource =
        marketingSources_[0] || new LookupModel();
    }
  }
  const channelCodes_ = getOptionList(channelCodeResponse);
  if (channelCodes_.length > 0) {
    channelCodes_.unshift(defaultOption);
    if (!contactedDetails_.channelCode.value) {
      contactedDetails_.channelCode = channelCodes_[0] || new LookupModel();
    }
  }
  const marketingCampaigns_ = getOptionList(marketingCampaignResponse);
  if (marketingCampaigns_.length > 0) {
    marketingCampaigns_.unshift(defaultOption);
    if (!contactedDetails_.marketingCampaign.value) {
      contactedDetails_.marketingCampaign =
        marketingCampaigns_[0] || new LookupModel();
    }
  }
  return {
    marketingSourcesData: marketingSources_,
    channelCodesData: channelCodes_,
    marketingCampaignData: marketingCampaigns_
  };
};

export const loadCountriesAndQuestionList = (
  countryResponse,
  questionResponse,
  addressDetails_
) => {
  const countries_ =
    countryResponse.length &&
    countryResponse.map((country: any) => ({
      label: country.name,
      value: country.id
    }));
  if (countries_.length && !addressDetails_.country.value) {
    addressDetails_.country = countries_[0] || new LookupModel();
  }

  const questions_: Question[] = questionResponse.map(
    (ques, index: number) => ({
      id: index,
      name: ques.questionDescriptorText,
      active: ques.status === ACTIVE_STATUS_CODE ? true : false,
      isRequired: ques.isRequired === 1 ? true : false
    })
  );
  return {
    countriesData: countries_,
    healthQuestionsData: questions_
  };
};

export const loadMember = (
  titleResponse,
  genderResponse,
  languageResponse,
  personalDetails_: PersonalDetailsModel
) => {
  const titles_ = getOptionList(titleResponse);
  if (titles_.length > 0) {
    titles_.unshift(defaultOption);
    if (!personalDetails_.title.value) {
      personalDetails_.title = titles_[0] || new LookupModel();
    }
  }
  const genders_ = getOptionList(genderResponse);
  if (genders_.length > 0) {
    genders_.unshift(defaultOption);
    if (!personalDetails_.gender.value) {
      personalDetails_.gender = genders_[0] || new LookupModel();
    }
  }
  const languages_ = getOptionList(languageResponse);
  if (languages_.length > 0 && !personalDetails_.preferedLanguage.value) {
    personalDetails_.preferedLanguage = languages_[0] || new LookupModel();
  }
  return {
    titles_,
    genders_,
    languages_
  };
};

// get data from redux store
export const populateEmergencyContactFromReduxStore = (
  membership: INewMembership
): EmergencyContactModel => {
  const { personalDetails } = membership;
  const { emergencyContact } = personalDetails || {};

  return emergencyContact || new EmergencyContactModel();
};

export const populateContactDetailsFromReduxStore = (
  membership: INewMembership
): ContactDetailsModel => {
  const { personalDetails } = membership;
  const { contactDetails } = personalDetails || {};

  return contactDetails || new ContactDetailsModel();
};

export const populateAddressDetailsFromReduxStore = (
  membership: INewMembership
): AddressDetailsModel => {
  const { personalDetails } = membership;
  const { addressDetails } = personalDetails || {};
  return addressDetails || new AddressDetailsModel();
};

export const populateHealthQuestionsFromReduxStore = (
  membership: INewMembership
): Question[] => {
  const { personalDetails } = membership;
  const { healthQuestions } = personalDetails || {};

  return healthQuestions || [];
};

export const populateContactedDetailsFromReduxStore = (
  membership: INewMembership
): ContactedDetailsModel => {
  const { personalDetails } = membership;
  const { contactedDetails } = personalDetails || {};
  return contactedDetails || new ContactedDetailsModel();
};

export const populateOtherFieldsFromReduxStore = (
  membership: INewMembership
): OtherFieldsModel => {
  const { personalDetails } = membership;
  const { otherFields } = personalDetails || {};
  return otherFields || new OtherFieldsModel();
};

export const populatePersonalDetailsFromReduxStore = (
  membership: INewMembership
): PersonalDetailsModel => {
  const { personalDetails, membershipSearchFilter } = membership;
  let {
    title = new LookupModel(),
    gender = new LookupModel(),
    firstName = '',
    lastName = '',
    middleName = '',
    nationalIdNumber = '',
    passportNumber = '',
    dateOfBirth = '',
    preferedLanguage = new LookupModel(),
    isParentalConsentProvided = false
  } = personalDetails || {};

  dateOfBirth = membershipSearchFilter.dateOfBirth
    ? new Date(membershipSearchFilter.dateOfBirth)
    : null;
  return {
    ...personalDetails,
    title,
    gender,
    firstName,
    lastName,
    middleName,
    nationalIdNumber,
    passportNumber,
    dateOfBirth,
    preferedLanguage,
    isParentalConsentProvided
  };
};

export const getMemberConfigNextStep = (
  stepIndex,
  membership: INewMembership | INewCorporateMembership,
  hasChanged: boolean,
  handleSaveAndLeave: (stepIndex: any, isSave?: boolean) => void
) => {
  const {
    membershipConfiguration,
    membershipConfiguration: { stepLatest }
  } = membership;

  if (hasChanged && stepLatest > 3) {
    const content = (
      <React.Fragment>
        <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page" />
        <p className="content-center add-member-page">
          {t('MSG.UNSAVED_CHANGES')}
        </p>
        <div className="modalSaas__row--btn">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => handleSaveAndLeave(stepIndex, true)}
          >
            {t('BUTTON.SAVE_AND_LEAVE')}
          </button>
          <button
            className="btn btn-dark text-uppercase"
            onClick={() => handleSaveAndLeave(stepIndex)}
          >
            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
          </button>
        </div>
      </React.Fragment>
    );
    observer.publish('openDialogEvent', {
      content: content
    });
  } else {
    membershipConfiguration.stepIndex = stepIndex;
    membershipConfiguration.stepLatest = stepIndex;
  }
  return membershipConfiguration;
};

export const handleSaveAndLeaveAction = (
  membership: INewMembership | INewCorporateMembership,
  stepIndex
) => {
  const { membershipConfiguration } = membership;
  membershipConfiguration.stepIndex = stepIndex;

  observer.publish('closeDialogEvent', true);
  return membershipConfiguration;
};

export const handleNextStep = (
  duplicateMembers: React.MutableRefObject<IDuplicateMember[]>,
  membership: INewMembership | INewCorporateMembership,
  personalRef: React.RefObject<UseFormReturn<PersonalDetailsFormInput>>,
  saveCallBack: () => void
) => {
  duplicateMembers.current = [];
  const { membershipConfiguration, membershipSearchFilter } = membership;
  if (membershipConfiguration.stepLatest < 4) {
    membershipConfiguration.stepLatest = 4;
  }
  membershipConfiguration.stepIndex = 4;

  if (personalRef.current && personalRef.current.getValues('dateOfBirth')) {
    membershipSearchFilter.dateOfBirth = new Date(
      personalRef.current.getValues('dateOfBirth') || ''
    );
  }
  saveCallBack();
  return {
    membershipSearchFilterRes: membershipSearchFilter,
    membershipConfigurationRes: membershipConfiguration
  };
};

export const handleSaveData = async (
  refList,
  configList,
  emailCfgConman: IConfig | undefined,
  duplicateMemberProcessed: boolean
) => {
  const {
    personalRef,
    contactDetailRef,
    addressDetailRef,
    contactedDetailRef,
    emergencyContactRef,
    healthQuestionRef,
    otherFieldsRef
  } = refList;
  const {
    guardianNameConfig,
    guardianIdentityNoConfig,
    guardianRelationshipConfig,
    guardianAddressConfig,
    guardianPhoneNumberConfig,
    guardianPassConfig,
    emergencyContactNameConfig,
    relationshipConfig,
    emergencyContactPhoneNumConfig,
    guardianIdentityTypeIdConfig,
    marketingSourceConfig,
    marketingCampaignConfig,
    channelCodeConfig,
    companyNameConfig,
    occupationConfig,
    referredByNameConfig,
    referredByMembershipNumberConfig,
    otherCommissionNoteConfig,
    generalNoteConfig
  } = configList;
  contactedDetailRef.clearErrors('preferredContactMethod');
  let isNeedToCheckDuplicate: boolean,
    openDropdown,
    fieldValues: PersonalDetailsModel,
    questionConfigs: IQuestionConfigs,
    isFormValidated: boolean;
  try {
    await Promise.all([
      personalRef.handleSubmit(() => {})(),
      contactDetailRef.handleSubmit(() => {})(),
      addressDetailRef.handleSubmit(() => {})(),
      contactedDetailRef.handleSubmit(() => {})(),
      emergencyContactRef.handleSubmit(() => {})(),
      healthQuestionRef.handleSubmit(() => {})(),
      otherFieldsRef.handleSubmit(() => {})()
    ]);
  } catch (error) {
  } finally {
    const formErrors = {
      ...personalRef.formState.errors,
      ...contactDetailRef.formState.errors,
      ...addressDetailRef.formState.errors,
      ...contactedDetailRef.formState.errors,
      ...emergencyContactRef.formState.errors,
      ...healthQuestionRef.formState.errors,
      ...otherFieldsRef.formState.errors
    };
    isFormValidated = Object.keys(formErrors).length === 0;

    fieldValues = {
      ...personalRef.getValues(),
      contactDetails: {
        ...contactDetailRef.getValues(),
        isEmailValid: IsValidEmail(
          contactDetailRef.getValues('email'),
          new RegExp(emailCfgConman ? emailCfgConman.value : '')
        )
      },
      addressDetails: addressDetailRef.getValues(),
      contactedDetails: contactedDetailRef.getValues(),
      emergencyContact: emergencyContactRef.getValues(),
      healthQuestions: healthQuestionRef.getValues('healthQuestions'),
      otherFields: otherFieldsRef.getValues()
    };

    openDropdown = {
      personalDetails: Object.keys(personalRef.formState.errors).length > 0,
      contactDetails: Object.keys(contactDetailRef.formState.errors).length > 0,
      addressDetails: Object.keys(addressDetailRef.formState.errors).length > 0,
      contactedDetails:
        Object.keys(contactedDetailRef.formState.errors).length > 0,
      emergencyContact:
        Object.keys(emergencyContactRef.formState.errors).length > 0,
      healthQuestion:
        Object.keys(healthQuestionRef.formState.errors).length > 0,
      otherFields: Object.keys(otherFieldsRef.formState.errors).length > 0
    };

    questionConfigs = {
      emergencyContact: [
        getFieldQuestionConfig(
          guardianNameConfig,
          fieldValues.emergencyContact.guardianName
        ),
        getFieldQuestionConfig(
          guardianIdentityNoConfig,
          fieldValues.emergencyContact.guardianIdentityNo
        ),
        getFieldQuestionConfig(
          guardianRelationshipConfig,
          fieldValues.emergencyContact.guardianRelationship
        ),
        getFieldQuestionConfig(
          guardianAddressConfig,
          fieldValues.emergencyContact.guardianAddress
        ),
        getFieldQuestionConfig(
          guardianPhoneNumberConfig,
          fieldValues.emergencyContact.guardianPhoneNumber
        ),
        getFieldQuestionConfig(
          guardianPassConfig,
          fieldValues.emergencyContact.guardianPass
        ),
        getFieldQuestionConfig(
          emergencyContactNameConfig,
          fieldValues.emergencyContact.contactName
        ),
        getFieldQuestionConfig(
          relationshipConfig,
          fieldValues.emergencyContact.relationship
        ),
        getFieldQuestionConfig(
          emergencyContactPhoneNumConfig,
          fieldValues.emergencyContact.contactNumber.phoneNumber
        ),
        getFieldQuestionConfig(
          guardianIdentityTypeIdConfig,
          fieldValues.emergencyContact.guardianIdentityTypeId.value
        ),
        getFieldQuestionConfig(
          marketingSourceConfig,
          fieldValues.contactedDetails.marketingSource
            ? fieldValues.contactedDetails.marketingSource.value
            : ''
        ),
        getFieldQuestionConfig(
          marketingCampaignConfig,
          fieldValues.contactedDetails.marketingCampaign
            ? fieldValues.contactedDetails.marketingCampaign.value
            : ''
        ),
        getFieldQuestionConfig(
          channelCodeConfig,
          fieldValues.contactedDetails.channelCode
            ? fieldValues.contactedDetails.channelCode.value
            : ''
        )
      ],
      customFields: [
        getFieldQuestionConfig(
          companyNameConfig,
          fieldValues.otherFields.companyName
        ),
        getFieldQuestionConfig(
          occupationConfig,
          fieldValues.otherFields.occupation
        ),
        getFieldQuestionConfig(
          referredByNameConfig,
          fieldValues.otherFields.referredByMemberName
        ),
        getFieldQuestionConfig(
          referredByMembershipNumberConfig,
          fieldValues.otherFields.referredByMembershipNumber
        ),
        getFieldQuestionConfig(
          otherCommissionNoteConfig,
          fieldValues.otherFields.otherCommissionNote
        ),
        getFieldQuestionConfig(
          generalNoteConfig,
          fieldValues.otherFields.generalNote
        )
      ]
    };
    const {
      lastName,
      dateOfBirth,
      addressDetails: { postCode }
    } = fieldValues;

    isNeedToCheckDuplicate = !!(
      isFormValidated &&
      dateOfBirth &&
      !duplicateMemberProcessed &&
      ((lastName && dateOfBirth) ||
        (lastName && postCode) ||
        (dateOfBirth && postCode))
    );
  }
  return {
    isNeedToCheckDuplicate,
    openDropdown,
    fieldValues,
    questionConfigs,
    isFormValidated
  };
};

export const getCurrentFieldValues = (
  personalRef: UseFormReturn<PersonalDetailsFormInput>,
  contactDetailRef: UseFormReturn<ContactDetailsFormInput>,
  emailCfgConman: IConfig | undefined,
  addressDetailRef: UseFormReturn<AddressDetailsFormInput>,
  contactedDetailRef: UseFormReturn<ContactedDetailsFormInput>,
  emergencyContactRef: UseFormReturn<EmergencyContactFormInput>,
  healthQuestionRef: UseFormReturn<HealthQuestionFormInput>,
  otherFieldsRef: UseFormReturn<OtherFieldsFormInput>
) => {
  const fieldValues: PersonalDetailsModel = {
    ...personalRef.getValues(),
    dateOfBirth: personalRef.getValues('dateOfBirth'),
    contactDetails: {
      ...contactDetailRef.getValues(),
      isEmailValid: IsValidEmail(
        contactDetailRef.getValues('email'),
        new RegExp(emailCfgConman ? emailCfgConman.value : '')
      )
    },
    addressDetails: addressDetailRef.getValues(),
    contactedDetails: contactedDetailRef.getValues(),
    emergencyContact: emergencyContactRef.getValues(),
    healthQuestions: healthQuestionRef.getValues('healthQuestions'),
    otherFields: otherFieldsRef.getValues()
  };
  return fieldValues;
};

export const handleSavePersonalDetailsData = (
  membership:
    | IConvertGuestToMember
    | IConvertGuestToCorporateMember
    | INewMembership
    | INewCorporateMembership,
  refList,
  setPersonalDetailsAction,
  setPackagePlanSearchFilterAction,
  emailCfgConman: IConfig | undefined
) => {
  const { membershipSearchFilter } = membership;
  const {
    personalRef,
    contactDetailRef,
    addressDetailRef,
    contactedDetailRef,
    emergencyContactRef,
    healthQuestionRef,
    otherFieldsRef
  } = refList;
  if (
    personalRef &&
    contactDetailRef &&
    addressDetailRef &&
    contactedDetailRef &&
    emergencyContactRef &&
    healthQuestionRef &&
    otherFieldsRef
  ) {
    const fieldValues = getCurrentFieldValues(
      personalRef,
      contactDetailRef,
      emailCfgConman,
      addressDetailRef,
      contactedDetailRef,
      emergencyContactRef,
      healthQuestionRef,
      otherFieldsRef
    );
    setPersonalDetailsAction(fieldValues);
    setPackagePlanSearchFilterAction({
      ...membershipSearchFilter,
      dateOfBirth: fieldValues.dateOfBirth
    });
  }
};
