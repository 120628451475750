import * as types from 'constants/ActionTypes';
import OnlinePayment from 'models/OnlinePayment';
import {
  getEzyFee,
  loadConfigDisabledUseEzyPay
} from 'services/payment-service';

export enum PaymentState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INITIAL = 'INITIAL',
  DECLINE = 'DECLINE'
}

export function setOnlinePaymentData(onlinePayment: OnlinePayment) {
  return { type: types.SET_ONLINE_PAYMENT, onlinePayment };
}

export function setOnlinePaymentDataState(state: PaymentState) {
  return { type: types.SET_ONLINE_PAYMENT_STATE, state };
}

export function resetOnlinePayment() {
  return { type: types.RESET_ONLINE_PAYMENT };
}

export const loadDisabledEzyPay = () => async dispatch => {
  try {
    const isDisabledEzyPay = await loadConfigDisabledUseEzyPay();
    dispatch({
      type: types.UPDATE_CAN_USE_EZY_PAY,
      isDisabledEzyPay
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateFeeEzyPay = fees => {
  return {
    type: types.UPDATE_LOAD_FEE_EZYPAY,
    fees
  };
};

export const loadFeeEzyPay = () => async dispatch => {
  try {
    const fees = await getEzyFee();
    dispatch(updateFeeEzyPay(fees));
  } catch (error) {
    dispatch({
      type: types.UPDATE_LOAD_FAIL_EZYPAY,
      failLoadEzyPay: true
    });
  }
};
