import React, { useEffect, useRef } from 'react';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import { useTranslation } from 'react-i18next';
import {
  DisplayDateFormat,
  getParamFromWindowSearch,
  transformOptions
} from 'util/ControlUtils';
import {
  convertGuestToGuestPass,
  getChannelCodes,
  getMarketingCampaign,
  getMarketingSources,
  patchGuestToMSCRM
} from 'services/membership-service';
import Popup from 'util/Popup';
import { getEsignatureUrl, getSubTenantId } from 'services/common-service';
import IConvertGuestToGuestPassPage from './interface';
import Template from './template';
import { useTypedSelector } from 'reduxs';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import { useDispatch } from 'react-redux';
import { setConvertGuestPassConfiguration } from 'reduxs/actions/ConvertGuestToGuestPass';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { GO_TO_HOME_PAGE_MESSAGE } from 'constants/Iframe';

const ConvertGuestToGuestPassPage: React.FC<
  IConvertGuestToGuestPassPage
> = () => {
  const conman = useTypedSelector(state => state.conman);
  const guest = useTypedSelector(state => state.convertGuestToGuestPass);
  const answerMappingData = useRef({
    marketingSource: [],
    channelCode: [],
    marketingCampaign: []
  });
  const externalId = getParamFromWindowSearch('externalId');
  const operatorId = Number(getParamFromWindowSearch('operator_id'));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      getMarketingSources(),
      getChannelCodes(),
      getMarketingCampaign()
    ]).then(([marketingSource, channelCode, marketingCampaign]) => {
      answerMappingData.current = {
        marketingSource: transformOptions(
          marketingSource.values,
          'name',
          'code'
        ),
        channelCode: transformOptions(channelCode.values, 'name', 'code'),
        marketingCampaign: transformOptions(
          marketingCampaign.values,
          'name',
          'code'
        )
      };
    });
  }, []);

  const handleCancel = () => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('COMMON.CANCEL_NOTIFICATION'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        const configuration = new ConvertGuestPassConfiguration();
        dispatch(setConvertGuestPassConfiguration(configuration));
        window.parent.postMessage(GO_TO_HOME_PAGE_MESSAGE, getEsignatureUrl());
      }
    });
  };

  const handleCreateMember = (endDate: string) => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('MSG.ARE_YOU_SURE_TO_FINISH'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        handleSave(endDate);
      }
    });
  };

  const handleSave = (endDate: string) => {
    const { searchFilter, selectedPackagePlan, guestDetails } = guest;

    const data = {
      operatorId: operatorId,
      leadId: guestDetails.id,
      packagePlanId: selectedPackagePlan
        ? selectedPackagePlan.originalPlanId
        : 0,
      startDate: DisplayDateFormat(
        searchFilter.startDate,
        GENERAL_DATABASE_DATE_FORMAT
      ),
      endDate: endDate,
      primarySalePersonId: searchFilter.primarySalePersonId,
      secondarySalePersonId: searchFilter.secondarySalePersonId
    };
    Popup.showProcessing();
    Promise.all([
      convertGuestToGuestPass(data),
      patchGuestToMSCRM({ externalId })
    ])
      .then(() => {
        observer.publish('flagUnsavedChangeEvent', false);
        window.parent.location.href = `${getEsignatureUrl()}/?subTenantId=${getSubTenantId()}`;
      })
      .catch(() => {
        Popup.showMessageCustom({
          text: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR'),
          type: 'error'
        });
      });
  };

  return (
    <Template
      configs={conman.configs}
      externalId={externalId}
      answerMappingData={answerMappingData.current}
      handleCancel={handleCancel}
      handleCreateMember={handleCreateMember}
    />
  );
};

export default ConvertGuestToGuestPassPage;
