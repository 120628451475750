import _ from 'lodash';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { FieldConfig } from 'models/PersonalDetails';

// find correct field config in list default field config in DefaultConstants
export const getFieldConfig = (fieldConfiguration: any, fieldKey: string) => {
  const titleConfig: FieldConfig = fieldConfiguration.find(
    fc => fc.key === fieldKey
  );
  return titleConfig || new FieldConfig();
};

export const filterValuableFieldsOnly = fieldConfigs => {
  const defaultFieldConfig = JSON.stringify(new FieldConfig());
  const fieldConfigsProperties = Object.keys(fieldConfigs);
  const valuableProps = fieldConfigsProperties.filter(
    propName => JSON.stringify(fieldConfigs[propName]) !== defaultFieldConfig
  );
  return _.pick(fieldConfigs, valuableProps);
};

export const isFieldRequired = (field: FieldConfig) => {
  return field.isVisible && field.isRequired;
};

export const getFieldConfiguration = (fieldConfiguration: any) => {
  const dateOfBirthConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_3
  );
  const titleConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_TITLE
  );
  const genderConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_GENDER
  );
  const firstNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_FIRST_NAME
  );
  const lastNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_LAST_NAME
  );
  const nationalConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NATIONAL
  );
  const nationalIdConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NATIONAL_ID
  );
  const parentalConsentConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_PARENTAL_CONSENT_PROVIDED
  );
  const preferedLanguageConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES
  );
  const telephoneNumberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_TELEPHONE_NUMBER
  );
  const emailConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMAIL_ADDRESS
  );
  const numberConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_NUMBER
  );
  const line1Config = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_LINE_1
  );
  const line2Config = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_LINE_2
  );
  const cityConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_CITY
  );
  const stateConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_STATE
  );
  const postcodeConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_POSTCODE
  );
  const countryConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_ADDRESS_COUNTRY
  );
  const applicationRefConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBERSHIP_APPLICATION_REF
  );
  const marketingConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED
  );
  const preferredContactMethodsConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD
  );
  const sourceConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_MARKETING_SOURCE
  );
  const emergencyNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_NAME
  );
  const emergencyPhoneConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER
  );
  const companyNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_COMPANY_NAME
  );
  const occupationConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_OCCUPATION
  );
  const referredByNameConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_REFERRED_BY_NAME
  );
  const referredByMembershipConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER
  );
  const otherCommissonNoteConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_OTHER_COMMISSION_NOTE
  );
  const generalNoteConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_GENERAL_NOTE
  );
  const recurringCardTypeConfig = getFieldConfig(
    fieldConfiguration,
    FieldKeys.ADDMEMBER_RECURRING_CARD_TYPE
  );

  return {
    titleConfig,
    genderConfig,
    firstNameConfig,
    lastNameConfig,
    dateOfBirthConfig,
    nationalConfig,
    nationalIdConfig,
    preferedLanguageConfig,
    parentalConsentConfig,
    telephoneNumberConfig,
    emailConfig,
    numberConfig,
    line1Config,
    line2Config,
    cityConfig,
    stateConfig,
    postcodeConfig,
    countryConfig,
    applicationRefConfig,
    marketingConfig,
    preferredContactMethodsConfig,
    sourceConfig,
    emergencyNameConfig,
    emergencyPhoneConfig,
    companyNameConfig,
    occupationConfig,
    referredByNameConfig,
    referredByMembershipConfig,
    otherCommissonNoteConfig,
    generalNoteConfig,
    recurringCardTypeConfig
  };
};
