import React from 'react';
import { useTranslation } from 'react-i18next';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import Dots from '../Dots';

interface IConvertGuestToGuestPassBreadcrumb {
  configuration: ConvertGuestPassConfiguration;
  goToStep: (stepIndex: number) => void;
}

const ConvertGuestToGuestPassBreadcrumb: React.FC<
  IConvertGuestToGuestPassBreadcrumb
> = ({ configuration, goToStep }) => {
  const { stepIndex, stepLatest } = configuration;
  const { t } = useTranslation();

  if (!configuration) {
    return <></>;
  }
  return (
    <div className="sticky-breadcrum">
      <div className="paging-steps d-flex">
        <a
          className={`step active ${
            stepLatest > 0 && stepIndex > 1 ? 'go-to-step' : ''
          }`}
          onClick={() => stepLatest > 0 && stepIndex !== 1 && goToStep(1)}
        >
          <div className="number-step">
            <span>01</span>
          </div>
          <p className="text-step">
            {t('PAGE.MEMBERSHIPS.BREADCRUMB.PACKAGE_PLAN')}
          </p>
        </a>
        <div
          className={`section-dots ${
            stepLatest > 1 || stepIndex === 2 ? 'dots-primary' : ''
          }`}
        >
          <Dots />
        </div>
        <a
          className={`step
          ${stepLatest > 1 || stepIndex === 2 ? 'active' : ''}
          ${stepIndex !== 2 ? 'go-to-step' : ''}`}
          onClick={() => stepLatest > 1 && stepIndex !== 2 && goToStep(2)}
        >
          <div className="number-step">
            <span>02</span>
          </div>
          <p className="text-step">
            {t('PAGE.MEMBERSHIPS.BREADCRUMB.GUEST_SUMMARY')}
          </p>
        </a>
      </div>
    </div>
  );
};

export default ConvertGuestToGuestPassBreadcrumb;
