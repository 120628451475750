import * as types from 'constants/ActionTypes';
import IPromotionCatergory from 'interfaces/Promotion';
import {
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember
} from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import PaymentDetails from 'models/PaymentDetails';
import AddOn from 'models/AddOn';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import { IGuestDetailsResponse } from 'services/interfaces/responses/membership';

export interface INewMembership {
  membershipSearchFilter: MembershipSearchFilter;
  membershipConfiguration: MembershipConfiguration;
  selectedPackagePlan:
    | IPackagePlanItem
    | IPackagePlanItemAddCorporateMember
    | null;
  selectedAddons: AddOn[];
  selectedPromotions: IPromotionCatergory[];
  personalDetails: PersonalDetailsModel;
  paymentDetails: PaymentDetails;
  membershipReview: null;
  packagePlans: never[];
  memberSummary: IGuestDetailsResponse | null;
  questionConfigs: IQuestionConfigs | null;
}

const newMembership: INewMembership = {
  membershipSearchFilter: new MembershipSearchFilter(), // Step 1
  membershipConfiguration: new MembershipConfiguration(), // Step 1
  selectedPackagePlan: null, // Step 1
  selectedAddons: [], // Step 2
  selectedPromotions: [], // Step 2
  personalDetails: new PersonalDetailsModel(), // Step 3
  paymentDetails: new PaymentDetails(), // Step 4
  membershipReview: null, // Step 5
  packagePlans: [],
  memberSummary: null,
  questionConfigs: null
};

const newMembershipReducer = (state = newMembership, action) => {
  switch (action.type) {
    case types.ADD_MEMBER_RESET_STATE:
      return newMembership;
    case types.ADD_MEMBER_SET_SEARCH_FILTER:
      return {
        ...state,
        membershipSearchFilter: action.membershipSearchFilter
      };
    case types.ADD_MEMBER_SET_CONFIGURATION:
      return {
        ...state,
        membershipConfiguration: action.membershipConfiguration
      };
    case types.ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN:
      return {
        ...state,
        selectedPackagePlan: action.packagePlan
      };
    case types.ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS:
      return {
        ...state,
        selectedPromotions: action.selectedPromotions,
        selectedAddons: action.selectedAddons
      };
    case types.ADD_MEMBER_SET_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          ...action.data
        }
      };
    case types.ADD_MEMBER_SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.paymentDetails
      };
    case types.ADD_MEMBER_SET_ALL_PACKAGE_PLAN:
      return {
        ...state,
        packagePlans: action.packagePlans
      };
    case types.ADD_MEMBER_SET_MEMBER_SUMMARY:
      return {
        ...state,
        memberSummary: action.memberSummary
      };
    case types.REPLACE_NEW_MEMBERSHIP:
      return {
        ...state,
        ...action.data
      };
    case types.ADD_MEMBER_SET_QUESTION_CONFIGS:
      return {
        ...state,
        questionConfigs: action.questionConfigs
      };
    case types.UPDATE_PAYMENT_INFO:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentInfo: action.paymentInfo
        }
      };
    case types.UPDATE_PAYMENT_SHOW_INFO:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentInfo: {
            ...state.paymentDetails.paymentInfo,
            disableShowIframe: action.disableShowIframe
          }
        }
      };
    case types.UPDATE_IS_LOADING_EZY_PAY:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          isLoadingEzyPay: action.isLoadingEzyPay
        }
      };
    default:
      return state;
  }
};

export default newMembershipReducer;
