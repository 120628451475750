import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import * as Interface from 'interfaces';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HomePage: React.FC<Interface.PagePropsInterface> = () => {
    const { t } = useTranslation();
    const conman = useSelector<any, any>(state => state.conman);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (conman && conman.operator) {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }, [conman]);

    return (
        <div className="app-wrapper main-page">
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    {conman && conman.operator ? (
                        <h2 className="heading">
                            {t('PAGE.HOME.WELCOME', { name: conman.operator.fullName })}
                        </h2>
                    ) : (
                        <></>
                    )}
                    <p className="body-text padding-top15">
                        {t('PAGE.HOME.INSTRUCTION_LINE_1')}
                    </p>
                    <p className="body-text">{t('PAGE.HOME.INSTRUCTION_LINE_2')}</p>
                </div>
            )}
        </div>
    );
};

export default HomePage;
