import React from 'react';

interface IDots {
  number?: number;
}

const Dots: React.FC<IDots> = ({ number = 4 }) => {
  return (
    <>
      {Array.from({ length: number }, (_, i) => (
        <span className="dot" key={i} />
      ))}
    </>
  );
};

export default Dots;
