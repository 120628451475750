import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import {
  ADDMEMBER_BIRTH_DATE_STEP_3,
  ADDMEMBER_FIRST_NAME,
  ADDMEMBER_GENDER,
  ADDMEMBER_LAST_NAME,
  ADDMEMBER_MIDDLE_NAME,
  ADDMEMBER_NATIONAL,
  ADDMEMBER_NATIONAL_ID,
  ADDMEMBER_OTHER_PASSPORT_NUMBER,
  ADDMEMBER_PARENTAL_CONSENT_PROVIDED,
  ADDMEMBER_TITLE,
  ADDMEMBER_OTHER_NATIONAL_ID,
  ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES,
  ADDMEMBER_TELEPHONE_NUMBER,
  ADDMEMBER_EMAIL_ADDRESS,
  ADDMEMBER_NUMBER,
  ADDMEMBER_ADDRESS_LINE_1,
  ADDMEMBER_ADDRESS_LINE_2,
  ADDMEMBER_ADDRESS_CITY,
  ADDMEMBER_ADDRESS_STATE,
  ADDMEMBER_ADDRESS_POSTCODE,
  ADDMEMBER_ADDRESS_COUNTRY,
  ADDMEMBER_MEMBERSHIP_APPLICATION_REF,
  ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED,
  ADDMEMBER_PREFERRED_CONTACT_METHOD,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_POST,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
  ADDMEMBER_MARKETING_SOURCE,
  ADDMEMBER_EMERGENCY_CONTACT_NAME,
  ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER,
  ADDMEMBER_COMPANY_NAME,
  ADDMEMBER_OCCUPATION,
  ADDMEMBER_REFERRED_BY_NAME,
  ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER,
  ADDMEMBER_OTHER_COMMISSION_NOTE,
  ADDMEMBER_GENERAL_NOTE,
  ADDMEMBER_RECURRING_CARD_TYPE
} from './Keys';

export const InitialFieldConfigs = {
  data: [
    {
      key: ADDMEMBER_TITLE,
      name: 'Title',
      isRequired: true,
      isVisible: true,
      regEx: '',
      maxLength: 0
    },
    {
      key: ADDMEMBER_GENDER,
      name: 'Gender',
      isRequired: true,
      isVisible: true,
      regEx: '',
      maxLength: 50
    },
    {
      key: ADDMEMBER_FIRST_NAME,
      name: 'First Name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_LAST_NAME,
      name: 'Last Name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_MIDDLE_NAME,
      name: 'Middle Name',
      isRequired: false,
      isVisible: true,
      regEx: '^[A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_BIRTH_DATE_STEP_3,
      name: 'Date of Birth',
      isRequired: true,
      isVisible: true,
      regEx: GENERAL_DATE_FORMAT,
      maxLength: 0
    },
    {
      key: ADDMEMBER_PARENTAL_CONSENT_PROVIDED,
      name: 'Parental consent provided',
      isRequired: true,
      isVisible: true,
      regEx: '',
      maxLength: 0
    },
    {
      key: ADDMEMBER_NATIONAL,
      name: 'National Number',
      isRequired: true,
      isVisible: true,
      regEx: '^[0-9]+$',
      maxLength: 12
    },
    {
      key: ADDMEMBER_NATIONAL_ID,
      name: 'National Id',
      isRequired: true,
      isVisible: true,
      regEx: '^[0-9]+$',
      maxLength: 12
    },
    {
      key: ADDMEMBER_OTHER_PASSPORT_NUMBER,
      name: 'Passport Number',
      isRequired: true,
      isVisible: true,
      regEx: '^[0-9]+$',
      maxLength: 15
    },
    {
      key: ADDMEMBER_OTHER_NATIONAL_ID,
      name: 'Other National Id',
      isRequired: false,
      isVisible: true,
      regEx: '^\\d+$',
      maxLength: 15
    },
    {
      key: ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES,
      name: 'Member Language Preferences',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_TELEPHONE_NUMBER,
      name: 'Telephone Number',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_EMAIL_ADDRESS,
      name: 'Email Address',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: 254
    },
    {
      key: ADDMEMBER_NUMBER,
      name: 'Number/Name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_ADDRESS_LINE_1,
      name: 'Line 1',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,/s]+$',
      maxLength: 100
    },
    {
      key: ADDMEMBER_ADDRESS_LINE_2,
      name: 'Line 2',
      isRequired: false,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,/s]+$',
      maxLength: 100
    },
    {
      key: ADDMEMBER_ADDRESS_CITY,
      name: 'City/Town',
      isRequired: false,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,/s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_ADDRESS_STATE,
      name: 'State/County',
      isRequired: false,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,/s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_ADDRESS_POSTCODE,
      name: 'Postcode',
      isRequired: true,
      isVisible: true,
      regEx: '^[0-9]+$',
      maxLength: 12
    },
    {
      key: ADDMEMBER_ADDRESS_COUNTRY,
      name: 'Country',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_MEMBERSHIP_APPLICATION_REF,
      name: 'Membership Application Ref',
      isRequired: false,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,/s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED,
      name: 'Member happy to be contacted',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD,
      name: 'Preferred contact method',
      isRequired: false,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_POST,
      name: 'Post',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
      name: 'Home Telephone',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE,
      name: 'Work Telephone',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
      name: 'Mobile Telephone',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
      name: 'SMS',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
      name: 'Email',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_MARKETING_SOURCE,
      name: 'Source',
      isRequired: false,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_EMERGENCY_CONTACT_NAME,
      name: 'Emergency Contact Name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER,
      name: 'Emergency Contact Phone Number',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    },
    {
      key: ADDMEMBER_COMPANY_NAME,
      name: 'Company Name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,s]+$',
      maxLength: 100
    },
    {
      key: ADDMEMBER_OCCUPATION,
      name: 'Occupation',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.,s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_REFERRED_BY_NAME,
      name: 'Referred by name',
      isRequired: true,
      isVisible: true,
      regEx: '^[A-Za-z0-9\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF-.s]+$',
      maxLength: 50
    },
    {
      key: ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER,
      name: 'Referred by Membership number',
      isRequired: true,
      isVisible: true,
      regEx: '^[0-9]+$',
      maxLength: 20
    },
    {
      key: ADDMEMBER_OTHER_COMMISSION_NOTE,
      name: 'Other Commission Note',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: 200
    },
    {
      key: ADDMEMBER_GENERAL_NOTE,
      name: 'General Note',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: 200
    },
    {
      key: ADDMEMBER_RECURRING_CARD_TYPE,
      name: 'Recurring Card Type',
      isRequired: true,
      isVisible: true,
      regEx: null,
      maxLength: null
    }
  ]
};
