import React from 'react';
import Loading from 'components/Loading';
import { IPackagePlanItemAddCorporateMember } from 'interfaces/Membership';
import AddCorporateMemberPackagePlanItem from './AddCorporateMemberPackagePlanItem';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';

interface IAddCorporateMemberPackagePlanList {
  isLoading: boolean;
  packagePlans: IPackagePlanItemAddCorporateMember[];
  membershipSearchFilter: MembershipSearchFilter;
  membershipConfiguration: MembershipConfiguration;
  onPackagePlanSelected: (packagePlanId: number, agreementPartyID: number) => void;
}

const AddCorporateMemberPackagePlanList: React.FunctionComponent<
  IAddCorporateMemberPackagePlanList
> = ({
  isLoading,
  packagePlans,
  membershipSearchFilter,
  membershipConfiguration,
  onPackagePlanSelected
}) => {
  return (
   
    <div className="content select-package-plan">
      {isLoading ? (
        <Loading />
      ) : (
      
        packagePlans &&
        packagePlans.map((packagePlanItem : IPackagePlanItemAddCorporateMember) => (
          <AddCorporateMemberPackagePlanItem
            key={packagePlanItem.planArgreementId}
            packagePlanItem={packagePlanItem}
            membershipSearchFilter={membershipSearchFilter}
            membershipConfiguration={membershipConfiguration}
            onSelected={onPackagePlanSelected}
          />
        ))
      )}
    </div>
  );
};

export default AddCorporateMemberPackagePlanList;
