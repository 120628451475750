import React, { useCallback } from 'react';
import SelectPackagePlan from './SelectPackagePlan';
import MemberSummary from './MemberSummary';
import { ITemplate } from './interface';
import { useDispatch } from 'react-redux';
import * as ConvertGuestPassActions from 'reduxs/actions/ConvertGuestToGuestPass';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { useTypedSelector } from 'reduxs';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import { IExternalGuest } from 'models/ExternalGuest';

const ConvertToGuestPass = ({
  handleCancel,
  handleCreateMember,
  configs,
  answerMappingData,
  externalId
}) => {
  const guest = useTypedSelector(state => state.convertGuestToGuestPass);
  const { configuration } = guest;
  const { stepIndex, stepLatest } = configuration || {};
  const dispatch = useDispatch();

  const setAllPackageAction = useCallback(
    (allPackagePlans: IAllPackagePlans) => {
      dispatch(
        ConvertGuestPassActions.setConvertGuestPassAllPackagePlans(
          allPackagePlans
        )
      );
    },
    []
  );

  const setPackagePlanSearchFilterAction = useCallback(
    (membershipSearchFilter: ConvertGuestPassSearchFilter) => {
      dispatch(
        ConvertGuestPassActions.setConvertGuestPassSearchFilter(
          membershipSearchFilter
        )
      );
    },
    []
  );

  const setConfigurationAction = useCallback(
    (membershipConfiguration: ConvertGuestPassConfiguration) => {
      dispatch(
        ConvertGuestPassActions.setConvertGuestPassConfiguration(
          membershipConfiguration
        )
      );
    },
    []
  );

  const setSelectedPackagePlanAction = useCallback(
    (packagePlan: IPackagePlanItem) => {
      dispatch(
        ConvertGuestPassActions.setConvertGuestPassSelectedPackagePlan(
          packagePlan
        )
      );
    },
    []
  );

  const setGuestDetailsAction = useCallback((guestDetails: IExternalGuest) => {
    dispatch(
      ConvertGuestPassActions.setConvertGuestPassGuestDetails(guestDetails)
    );
  }, []);

  if (!configuration || configuration.stepIndex === 1) {
    return (
      <SelectPackagePlan
        configs={configs}
        externalId={externalId}
        setAllPackageAction={setAllPackageAction}
        setGuestDetailsAction={setGuestDetailsAction}
        setSelectedPackagePlanAction={setSelectedPackagePlanAction}
        setConfigurationAction={setConfigurationAction}
        setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
        onCancel={handleCancel}
      />
    );
  }
  if (stepIndex === 2 && stepLatest > 1) {
    return (
      <MemberSummary
        guest={guest}
        guestId={externalId}
        answerMappingData={answerMappingData}
        onCancel={handleCancel}
        onFinish={handleCreateMember}
        setConfigurationAction={setConfigurationAction}
      />
    );
  }

  return <></>;
};

const Template: React.FC<ITemplate> = ({
  handleCancel,
  handleCreateMember,
  configs,
  externalId,
  answerMappingData
}) => {
  return (
    <div className="add-member-page">
      <ConvertToGuestPass
        handleCancel={handleCancel}
        handleCreateMember={handleCreateMember}
        configs={configs}
        externalId={externalId}
        answerMappingData={answerMappingData}
      />
    </div>
  );
};

export default Template;
