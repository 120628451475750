import * as types from 'constants/ActionTypes';
import IPromotionCatergory from 'interfaces/Promotion';
import { IPackagePlanItem } from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import PaymentDetails from 'models/PaymentDetails';
import AddOn from 'models/AddOn';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import { IGuestDetailsResponse } from 'services/interfaces/responses/membership';

export interface IConvertGuestToCorporateMember {
  membershipSearchFilter: MembershipSearchFilter;
  membershipConfiguration: MembershipConfiguration;
  selectedPackagePlan: IPackagePlanItem | null;
  selectedAddons: AddOn[];
  selectedPromotions: IPromotionCatergory[];
  personalDetails: PersonalDetailsModel;
  paymentDetails: PaymentDetails;
  membershipReview: null;
  packagePlans: never[];
  memberSummary: IGuestDetailsResponse | null;
  questionConfigs: IQuestionConfigs | null;
  guestCorporateId: number;
}

const convertGuestToCorporateMember: IConvertGuestToCorporateMember = {
  membershipSearchFilter: new MembershipSearchFilter(), // Step 1
  membershipConfiguration: new MembershipConfiguration(), // Step 1
  selectedPackagePlan: null, // Step 1
  selectedAddons: [], // Step 2
  selectedPromotions: [], // Step 2
  personalDetails: new PersonalDetailsModel(), // Step 3
  paymentDetails: new PaymentDetails(), // Step 4
  membershipReview: null, // Step 5
  packagePlans: [],
  memberSummary: null,
  questionConfigs: null,
  guestCorporateId: 0
};

const convertGuestToCorporateMemberReducer = (
  state = convertGuestToCorporateMember,
  action
) => {
  switch (action.type) {
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_RESET_STATE:
      return convertGuestToCorporateMember;
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SEARCH_FILTER:
      return {
        ...state,
        membershipSearchFilter: action.membershipSearchFilter
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_CONFIGURATION:
      return {
        ...state,
        membershipConfiguration: action.membershipConfiguration
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN:
      return {
        ...state,
        selectedPackagePlan: action.packagePlan
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS:
      return {
        ...state,
        selectedPromotions: action.selectedPromotions,
        selectedAddons: action.selectedAddons
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          ...action.data
        }
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.paymentDetails
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN:
      return {
        ...state,
        packagePlans: action.packagePlans
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_MEMBER_SUMMARY:
      return {
        ...state,
        memberSummary: action.memberSummary
      };
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_QUESTION_CONFIGS:
      return {
        ...state,
        questionConfigs: action.questionConfigs
      }
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_GUEST_CORPORATE_ID: 
      return {
        ...state,
        guestCorporateId: action.guestCorporateId
      }
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_REPLACE_DATA: 
      return {
        ...state,
        ...action.data
      }
    case types.CONVERT_GUEST_TO_CORPORATE_MEMBER_GUEST_CORPORATE_ID: 
      return {
        ...state,
        guestCorporateId: action.guestCorporateId
      }
    case types.REPLACE_NEW_MEMBERSHIP:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default convertGuestToCorporateMemberReducer;
