import React from 'react';
import * as Interface from 'interfaces';
import Select from './Select';
import { useTranslation } from 'react-i18next';
import Label from './Label';
import OptionModel from 'models/Option';

interface FormSelectProps extends Interface.InputInterface {
  options: OptionModel[];
  defaultValue?: string | number;
  isClearable?: boolean;
  searchIcon?: boolean;
  isLoading?: boolean;
  onInputChange?: (value: string) => void;
}

const FormSelect: React.FC<FormSelectProps> = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    label,
    id,
    name,
    options,
    value = null,
    onChange,
    disabled = false,
    defaultValue,
    require = false,
    className = '',
    labelClass = '',
    inputClass = '',
    isClearable = false,
    searchIcon = false,
    errorMsg = '',
    onInputChange = null,
    isLoading = false
  } = props;
  return (
    <div className={`form-group ${className} form-select`}>
      <Label label={label} labelClass={labelClass} isRequire={require} />
      <Select
        ref={ref}
        {...props}
        className={inputClass}
        options={
          options
            ? options.map(item => ({ ...item, label: t(item.label) }))
            : options
        }
        name={name}
        id={id}
        value={value ? { ...value, label: t(value.label) } : value}
        onChange={onChange}
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={isLoading ? t('COMMON.LOADING') : t('COMMON.SELECT')}
        isClearable={isClearable}
        searchIcon={searchIcon}
        errorMsg={errorMsg}
        onInputChange={onInputChange!}
        isLoading={isLoading}
      />
    </div>
  );
});

export default FormSelect;
