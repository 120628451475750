import React, { lazy, Suspense } from 'react';
import { PAYMENT_METHOD_TYPES } from 'constants/Constants';
import { useTypedSelector } from 'reduxs';
import { isPaymentInfoExist } from 'selectors/member';
import PaymentDetails from 'models/PaymentDetails';

const EzyPayFrame = lazy(() => import('./EzyPayFrame'));
const EzyPaymentInfo = lazy(() => import('./EzyPaymentInfo'));

interface EzPayProps {
  paymentDetails: PaymentDetails;
}

const EzPay = (props: EzPayProps) => {
  const { paymentDetails } = props;
  const { primaryPaymentMethodId } = paymentDetails;
  const showPaymentInfo = useTypedSelector(state =>
    isPaymentInfoExist(state, primaryPaymentMethodId)
  );

  return (
    <>
      {(primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT ||
        primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD) &&
        !showPaymentInfo && (
          <Suspense fallback={<></>}>
            <EzyPayFrame paymentMethodId={primaryPaymentMethodId} />
          </Suspense>
        )}
      {showPaymentInfo && (
        <Suspense fallback={<></>}>
          <EzyPaymentInfo />
        </Suspense>
      )}
    </>
  );
};

export default EzPay;
