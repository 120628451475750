import React from 'react';
import { FormInput, FormSelect } from 'components/Form';
import * as Utils from 'util/ControlUtils';
import { IDirectDebitCard } from '../../interface';
import { useTranslation } from 'react-i18next';

const DirectDebitCard: React.FC<IDirectDebitCard> = ({
  paymentDetails,
  issuingBanks,
  errors,
  handleChange,
  handleInputChange
}) => {
  const { accountName, accountNumber } = paymentDetails;
  const { t } = useTranslation();

  return (
    <div className="front-card">
      <div className="info-card">
        <FormInput
          id="account-name"
          name="account-name"
          inputClass="form-control"
          value={accountName}
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
          placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
          maxlength={100}
          require
          onChange={event =>
            handleInputChange('accountName', event.target.value)
          }
          errorMsg={t(errors.accountName)}
        />
        <FormSelect
          id="issuing-bank"
          name="issuing-bank"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK')}
          disabled={issuingBanks.length === 0}
          require
          onChange={event => handleChange('issuingBankId', event)}
          value={Utils.getProperty(
            paymentDetails,
            'issuingBankId',
            issuingBanks
          )}
          options={issuingBanks}
          errorMsg={t(errors.issuingBankId)}
        />
        <FormInput
          id="account-number"
          name="account-number"
          inputClass="form-control"
          value={accountNumber}
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.BANK_ACCOUNT_NUMBER')}
          placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.BANK_ACCOUNT_NUMBER')}
          require
          onChange={event =>
            handleInputChange('accountNumber', event.target.value)
          }
          errorMsg={t(errors.accountNumber)}
        />
      </div>
    </div>
  );
};

export default DirectDebitCard;
