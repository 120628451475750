import React, { useEffect, useState } from 'react';
import Template from './template';
import ISelectePackagePlan from './interface';
import * as Utils from 'util/ControlUtils';
import OptionModel from 'models/Option';
import { useTranslation } from 'react-i18next';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import observer from 'util/Observer';
import {
  GENERAL_DATE_FORMAT,
  GENERAL_DATABASE_DATE_FORMAT
} from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import {
  getClubs,
  getGuestDetails,
  getPackagePlansForAddGuestPass,
  getSalePeople
} from 'services/membership-service';
import { condition } from 'util/Common';
import { IPackagePlanItem } from 'interfaces/Membership';
import { useTypedSelector } from 'reduxs';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { FieldError } from 'interfaces/common/FieldError';
import Popup from 'util/Popup';

const SelectPackagePlan: React.FC<ISelectePackagePlan> = ({
  configs,
  externalId,
  onCancel,
  setGuestDetailsAction,
  setSelectedPackagePlanAction,
  setConfigurationAction,
  setPackagePlanSearchFilterAction,
  setAllPackageAction
}) => {
  const { t } = useTranslation();
  const convertGuestToGuestPass = useTypedSelector(
    state => state.convertGuestToGuestPass
  );
  const [searchFilter, setSearchFilter] = useState(
    new ConvertGuestPassSearchFilter()
  );
  const [configuration, setConfiguration] = useState(
    new ConvertGuestPassConfiguration()
  );
  const [selectedPackagePlan, setSelectedPackagePlan] = useState<
    IPackagePlanItem
  >();

  const [packagePlans, setPackagePlans] = useState<IPackagePlanItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clubs, setClubs] = useState<OptionModel[]>([]);
  const [salePeople, setSalePeople] = useState<OptionModel[]>([]);
  const [secondarySalePeople, setSecondarySalePeople] = useState<OptionModel[]>(
    []
  );
  const [errors, setErrors] = useState<FieldError>({});

  const defaultOption = {
    label: 'COMMON.PLEASE_SELECT',
    value: '0'
  };

  useEffect(() => {
    getGuestDetails({
      externalId
    })
      .then(data => {
        const guestData = data.data[0];        
        if (guestData) {
          let searchFilter_ = searchFilter;
          let configuration_ = configuration;
          let selectedPackagePlan_ = selectedPackagePlan;
          searchFilter_.clubId = guestData.clubId;
          searchFilter_.clubName = guestData.clubName;
          searchFilter_.primarySalePersonId = guestData.salePersonId;
          searchFilter_.primarySalePersonName = guestData.salePersonName;
          searchFilter_.secondarySalePersonId = guestData.secondarySalePersonId;
          searchFilter_.secondarySalePersonName =
            guestData.secondarySalePersonName;
          setGuestDetailsAction(guestData);
          if (
            convertGuestToGuestPass &&
            convertGuestToGuestPass.selectedPackagePlan
          ) {
            searchFilter_ = convertGuestToGuestPass.searchFilter;
            configuration_ = convertGuestToGuestPass.configuration;
            selectedPackagePlan_ = convertGuestToGuestPass.selectedPackagePlan;
          } else {
            configuration_.newMemberDOBFormat = GENERAL_DATE_FORMAT;
            configuration_.newMemberTimeFormat = GENERAL_DATE_FORMAT;
          }
          setConfiguration(configuration_);
          setSelectedPackagePlan(selectedPackagePlan_);
          setDOBFormat();
          getInitData(searchFilter_);
        }
      })
      .catch(() => {
        Popup.showMessageCustom({
          text: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR'),
          type: 'error'
        });
      });
  }, []);

  const getInitData = searchFilter => {
    setIsLoading(true);
    getClubs()
      .then(res => {
        const [searchFilter_, clubs] = handleSetClub(res, searchFilter);
        if (searchFilter_) {
          setClubs(clubs);
          setSearchFilter(searchFilter_);
        } else {
          return;
        }
      })
      .catch(() => {
        setIsLoading(false);
        setClubs([]);
        setSearchFilter(convertGuestToGuestPass.searchFilter);
      });
  };

  const setDOBFormat = () => {
    if (configs) {
      const configurationTemp = configuration;
      const dobFormat = configs.find(
        e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DOB_FORMAT
      );
      if (dobFormat) {
        configurationTemp.newMemberDOBFormat = dobFormat.value;
      } else {
        configurationTemp.newMemberDOBFormat = GENERAL_DATE_FORMAT;
      }
      const dateFormat = configs.find(
        e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DATE_FORMAT
      );
      if (dateFormat) {
        configurationTemp.newMemberTimeFormat = dateFormat.value;
      } else {
        configurationTemp.newMemberTimeFormat = GENERAL_DATE_FORMAT;
      }
      setConfiguration(configurationTemp);
    } else {
      setConfiguration(new ConvertGuestPassConfiguration());
    }
  };

  const handleSetClub = (
    res,
    searchFilter: ConvertGuestPassSearchFilter
  ): [ConvertGuestPassSearchFilter | undefined, OptionModel[]] => {
    const searchFilterTemp = searchFilter;
    const clubs: OptionModel[] = Utils.alphabetSort(
      res.data.map(e => ({
        value: e.id,
        label: e.name
      })),
      'label'
    );
    if (clubs.length > 0 && clubs[0].value) {
      if (!searchFilterTemp.clubId) {
        searchFilterTemp.clubId = Number(clubs[0].value);
        searchFilterTemp.clubName = clubs[0].label;
      }
      const startDate = DisplayDateFormat(
        searchFilterTemp.startDate,
        GENERAL_DATABASE_DATE_FORMAT
      );
      if (!searchFilterTemp.startDate) {
        setIsLoading(false);
        setPackagePlans([]);
        return [searchFilterTemp, clubs];
      }
      Promise.all([
        getSalePeople({ clubId: searchFilterTemp.clubId }),
        getPackagePlansForAddGuestPass({
          clubId: searchFilterTemp.clubId.toString(),
          startDate,
          planCategoryCode: 'GP'
        })
      ])
        .then(([res1, res2]) => {
          let salePeople: OptionModel[] = res1.data.map(e => ({
            value: e.id,
            label: e.name
          }));
          let allPackagePlans = res2.data || [];
          allPackagePlans = allPackagePlans.map(obj => ({
            ...obj,
            minimumContractValue: `${obj.minimumContract.value} ${obj.minimumContract.unit.name
              }`
          }));

          if (!searchFilterTemp.primarySalePersonId && salePeople.length > 0) {
            searchFilterTemp.primarySalePersonId = Number(salePeople[0].value);
            searchFilterTemp.primarySalePersonName = salePeople[0].label;
          }
          setAllPackageAction(allPackagePlans);
          setSalePeople(salePeople);
          setSecondarySalePeople([defaultOption, ...salePeople]);
          setPackagePlans(allPackagePlans);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setPackagePlans([]);
        });
    }

    return [searchFilterTemp, clubs];
  };

  useEffect(() => {
    observer.publish('flagUnsavedChangeEvent', true);
    window.scrollTo(0, 0);
  }, []);

  const storeDataToRedux = () => {
    const { searchFilter: searchFilter_ } = convertGuestToGuestPass;
    searchFilter_.primarySalePersonId = searchFilter.primarySalePersonId;
    searchFilter_.primarySalePersonName = searchFilter.primarySalePersonName;
    searchFilter_.secondarySalePersonId = searchFilter.secondarySalePersonId;
    searchFilter_.secondarySalePersonName =
      searchFilter.secondarySalePersonName;
    searchFilter_.startDate = searchFilter.startDate;
    setPackagePlanSearchFilterAction(searchFilter_);
  };

  const hasChangeControlValue = () => {
    const { searchFilter: propsearchFilter } = convertGuestToGuestPass;
    const statesearchFilter = searchFilter;
    if (
      statesearchFilter.startDate !== propsearchFilter.startDate ||
      (statesearchFilter.primarySalePersonName !==
        propsearchFilter.primarySalePersonName &&
        statesearchFilter.clubId === propsearchFilter.clubId)
    ) {
      return true;
    }
    return false;
  };

  const handleSaveAndLeave = (stepIndex, isSave = false) => {
    if (isSave) {
      storeDataToRedux();
    }
    configuration.stepIndex = stepIndex;
    setConfigurationAction(configuration);
    observer.publish('closeDialogEvent', true);
  };

  const goToStep = stepIndex => {
    const {
      configuration: { stepLatest }
    } = convertGuestToGuestPass;

    if (stepLatest > 1 && hasChangeControlValue()) {
      if (!isReadyToNextStep()) return;
      const content = (
        <React.Fragment>
          <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page" />
          <p className="content-center add-member-page">
            {t('MSG.UNSAVED_CHANGES')}
          </p>
          <div className="modalSaas__row--btn">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => handleSaveAndLeave(stepIndex, true)}
            >
              {t('BUTTON.SAVE_AND_LEAVE')}
            </button>
            <button
              className="btn btn-dark text-uppercase"
              onClick={() => handleSaveAndLeave(stepIndex)}
            >
              {t('BUTTON.LEAVE_AND_NOT_SAVE')}
            </button>
          </div>
        </React.Fragment>
      );
      observer.publish('openDialogEvent', {
        content: content
      });
    } else {
      configuration.stepIndex = stepIndex;
      setConfigurationAction(configuration);
    }
  };

  const isReadyToNextStep = () => {
    let errors: FieldError = {};
    const { clubId, primarySalePersonId, startDate } = searchFilter;
    const { newMemberTimeFormat } = configuration;

    if (!clubId || clubs.findIndex(club => Number(club.value) === clubId) < 0) {
      errors = {
        ...errors,
        clubId: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB_REQUIRED')
      };
    }
    if (
      !primarySalePersonId ||
      salePeople.findIndex(
        person => Number(person.value) === primarySalePersonId
      ) < 0
    ) {
      errors = {
        ...errors,
        primarySalePersonId: t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_1.SALE_PERSON_REQUIRED'
        )
      };
    }

    if (!startDate) {
      errors = {
        ...errors,
        startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_REQUIRED')
      };
    } else if (!Utils.IsValidDate(startDate, newMemberTimeFormat)) {
      errors = {
        ...errors,
        startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_INVALID')
      };
    } else if (!Utils.IsValidStartDate(startDate)) {
      errors = {
        ...errors,
        startDate: t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_CAN_NOT_BE_PAST_DATE'
        )
      };
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (key, event) => {
    if (!event.value) return;
    const searchFilterTemp = clonesearchFilter(searchFilter);
    const newValue = event.value;

    if (key.includes('clubId')) {
      setIsLoading(true);
      const startDate = DisplayDateFormat(
        searchFilterTemp.startDate,
        GENERAL_DATABASE_DATE_FORMAT
      );
      Promise.all([
        getSalePeople({ clubId: newValue }),
        getPackagePlansForAddGuestPass({
          clubId: newValue,
          startDate,
          planCategoryCode: 'GP'
        })
      ]).then(([res1, res2]) => {
        const salePersonList: OptionModel[] = res1.data.map(e => ({
          value: e.id,
          label: e.name
        }));
        const clubList = clubs.filter(cl => cl.value === newValue);
        searchFilterTemp.clubName = condition(
          clubList.length > 0,
          clubList[0].label,
          ''
        );
        if (salePersonList.length > 0) {
          searchFilterTemp.primarySalePersonId = condition(
            salePersonList[0],
            salePersonList[0].value,
            ''
          );
          searchFilterTemp.primarySalePersonName = condition(
            salePersonList[0],
            salePersonList[0].label,
            ''
          );
          searchFilterTemp.secondarySalePersonId = condition(
            secondarySalePeople[0],
            secondarySalePeople[0].value,
            ''
          );
          searchFilterTemp.secondarySalePersonName = condition(
            secondarySalePeople[0],
            secondarySalePeople[0].label,
            ''
          );
        }

        let allPackagePlans = res2.data;
        allPackagePlans = allPackagePlans.map(obj => ({
          ...obj,
          minimumContractValue: `${obj.minimumContract.value} ${obj.minimumContract.unit.name
            }`
        }));
        setAllPackageAction(allPackagePlans);
        setSalePeople(salePersonList);
        setSecondarySalePeople([defaultOption, ...salePersonList]);
        setSearchFilter(searchFilterTemp);
        setPackagePlans(allPackagePlans);
        setIsLoading(false);
      });
    }
    if (key.includes('primarySalePersonId')) {
      const primarySalePersonName = salePeople.filter(
        s => s.value === newValue
      );

      searchFilterTemp.primarySalePersonId = condition(
        primarySalePersonName.length > 0,
        primarySalePersonName[0].value,
        ''
      );
      searchFilterTemp.primarySalePersonName = condition(
        primarySalePersonName.length > 0,
        primarySalePersonName[0].label,
        ''
      );
    }
    if (key.includes('secondarySalePersonId')) {
      const secondarySalePersonName = secondarySalePeople.filter(
        s => s.value === newValue
      );
      searchFilterTemp.secondarySalePersonId = condition(
        secondarySalePersonName.length > 0,
        secondarySalePersonName[0].value,
        ''
      );
      searchFilterTemp.secondarySalePersonName = condition(
        secondarySalePersonName.length > 0,
        secondarySalePersonName[0].label,
        ''
      );
    }
    searchFilterTemp[key] = newValue;
    setSearchFilter(searchFilterTemp);
  };

  const getPackagePlanList = async (startDate: string) => {
    setIsLoading(true);
    const res = await getPackagePlansForAddGuestPass({
      clubId: searchFilter.clubId.toString(),
      startDate,
      planCategoryCode: 'GP'
    });
    let allPackagePlans = res.data || [];
    allPackagePlans = allPackagePlans.map(obj => ({
      ...obj,
      minimumContractValue: `${obj.minimumContract.value} ${obj.minimumContract.unit.name
        }`
    }));
    setPackagePlans(allPackagePlans);
    setIsLoading(false);
  };

  const clonesearchFilter = (searchFilterObj: ConvertGuestPassSearchFilter) => {
    let searchFilter = new ConvertGuestPassSearchFilter();
    searchFilter.primarySalePersonId = searchFilterObj.primarySalePersonId;
    searchFilter.primarySalePersonName = searchFilterObj.primarySalePersonName;
    searchFilter.secondarySalePersonId = searchFilterObj.secondarySalePersonId;
    searchFilter.secondarySalePersonName =
      searchFilterObj.secondarySalePersonName;
    searchFilter.clubId = searchFilterObj.clubId;
    searchFilter.clubName = searchFilterObj.clubName;
    searchFilter.startDate = searchFilterObj.startDate;
    searchFilter.selectedPackagePlanId = searchFilterObj.selectedPackagePlanId;
    return searchFilter;
  };

  const onPackagePlanSelected = (packagePlanId: number) => {
    if (isReadyToNextStep()) {
      const searchFilterTemp = searchFilter;
      const configurationTemp = configuration;
      const packagePlan = packagePlans.find(
        pl => pl.originalPlanId === packagePlanId
      );

      if (
        (searchFilter.secondarySalePersonId &&
          searchFilter.secondarySalePersonId.toString() ===
          defaultOption.value) ||
        salePeople.findIndex(
          person => Number(person.value) === searchFilter.secondarySalePersonId
        ) < 0
      ) {
        searchFilter.secondarySalePersonId = 0;
        searchFilter.secondarySalePersonName = '';
      }
      if (packagePlan) {
        setSelectedPackagePlanAction(packagePlan);

        searchFilterTemp.selectedPackagePlanId = packagePlanId;
        if (configurationTemp.stepLatest < 2) {
          configurationTemp.stepLatest = 2;
        }
        configurationTemp.stepIndex = 2;
        setConfigurationAction(configurationTemp);
        setPackagePlanSearchFilterAction(searchFilterTemp);
      }
    }
  };

  const handleDateChange = async (date, name) => {
    const searchFilterTemp = clonesearchFilter(searchFilter);
    switch (name) {
      case 'dateOfBirth':
        searchFilterTemp[name] = date
          ? new Date(
            Utils.DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT)
          )
          : null;
        setSearchFilter(searchFilterTemp);
        break;
      case 'startDate':
        searchFilterTemp[name] = date;
        setDOBFormat();
        setSearchFilter(searchFilterTemp);
        const startDate = DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT);
        await getPackagePlanList(startDate);
        break;
      default:
        searchFilterTemp[name] = date;
        break;
    }
  };

  return (
    <Template
      configuration={configuration}
      searchFilter={searchFilter}
      errors={errors}
      clubs={clubs}
      secondarySalePeople={secondarySalePeople}
      salePeople={salePeople}
      isLoading={isLoading}
      packagePlans={packagePlans}
      handleChange={handleChange}
      handleDateChange={handleDateChange}
      onPackagePlanSelected={onPackagePlanSelected}
      goToStep={goToStep}
      onCancel={onCancel}
    />
  );
};

export default React.memo(SelectPackagePlan);
