import React, { useState, useMemo, forwardRef, Ref } from 'react';
import { FormGroup } from 'reactstrap';
import IntlTelInput from 'react-intl-tel-input';
import * as Interface from 'interfaces';
import {
  isValidNumber,
  IsValidPhoneNumberWithCountryCode
} from 'util/ControlUtils';
import 'react-intl-tel-input/dist/main.css';
import Label from './Label';
import { FieldConfig } from 'src/models/PersonalDetails';

interface Props extends Interface.InputInterface {
  countryCode: string;
  onPhoneNumberChange: (
    phoneNumber: string,
    dialCode: string,
    isValid: boolean,
    fieldName?: string
  ) => void;
  phoneConfig?: FieldConfig;
  phoneType?: string;
  requireMsg?: string;
}

interface PhoneNumberDetail {
  areaCodes: React.ReactNode;
  dialCode: string;
  iso2: string;
  name: string;
  priority: number;
}

const FormPhoneInput = forwardRef(
  (
    {
      label,
      labelClass = '',
      require,
      inputClass = '',
      className = '',
      phoneType = 'MOBILE',
      value = '',
      countryCode = '',
      onPhoneNumberChange,
      errorMsg,
      requireMsg,
      phoneConfig
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    let phoneNumber = useMemo(() => value, [value]);
    const [selectedCountryCode, setSelectedCountryCode] = useState(
      countryCode.toLowerCase()
    );
    const [phoneValid, setPhoneValid] = useState(true);
    const [placeholder, setPlaceholder] = useState('');

    function changePhoneNumber(
      _: boolean,
      phoneNum: string,
      selectedCountry: PhoneNumberDetail,
    ) {
      const regex = phoneConfig ? phoneConfig.regEx : '';
      if (!IsValidPhoneNumberWithCountryCode(phoneNum)) return;

      const { iso2 } = selectedCountry;
      const isPhoneNumberValid = phoneNum
        ? isValidNumber(phoneNum, regex)
        : true;
      phoneNumber = phoneNum;
      setSelectedCountryCode(iso2);
      setPhoneValid(isPhoneNumberValid);
      if (value !== phoneNum) {
        onPhoneNumberChange(phoneNum, iso2.toUpperCase(), isPhoneNumberValid);
      }
    }

    function customPlaceholder(selectedCountryPlaceholder: string) {
      setPlaceholder(`e.g: ${selectedCountryPlaceholder}`);
    }

    let errorMessage = errorMsg;
    if (require && !phoneNumber && requireMsg) {
      errorMessage = requireMsg;
    } else if (!phoneValid && errorMsg) {
      errorMessage = errorMsg;
    }

    return (
      <FormGroup className={`form-group ${className} phone-input`}>
        <Label label={label} labelClass={labelClass} isRequire={require} />

        <IntlTelInput
          inputClassName={`form-control ${inputClass}`}
          separateDialCode
          numberType={phoneType}
          value={phoneNumber}
          onPhoneNumberChange={changePhoneNumber}
          preferredCountries={['hk', 'th', 'my', 'sg', 'id', 'ph']}
          defaultCountry={selectedCountryCode}
          customPlaceholder={customPlaceholder}
          placeholder={placeholder}
        />
        <div className="text-danger input-error">{errorMessage}</div>
      </FormGroup>
    );
  }
);

FormPhoneInput.displayName = 'FormPhoneInput';

export default FormPhoneInput;
