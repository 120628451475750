import React, { MutableRefObject, useEffect } from 'react';
import { FormInput, FormPhoneInput } from 'components/Form';
import {
  LookupModel,
  ContactDetailsModel,
  ContactDetailsFieldsModel,
  PhoneNumberModel
} from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import {
  onChangeInput,
  onChangePhoneNumber,
  required,
  validateAvailableEmail,
  validateEmail,
  validatePhoneNumber,
  validateRequireAtLeastOnePhone
} from 'util/FormValidations';
import { IConfig } from 'interfaces/SystemConfig';
import { ContactedDetailsFormInput } from './ContactedDetails';

interface IContactDetails {
  overrideEmailOptions: LookupModel[];
  contactDetails: ContactDetailsModel;
  contactDetailsFieldConfigs: ContactDetailsFieldsModel;
  contactedDetailRef: React.MutableRefObject<UseFormReturn<
    ContactedDetailsFormInput
  > | null>;
  contactDetailRef: MutableRefObject<UseFormReturn<
    ContactDetailsFormInput,
    any
  > | null>;
  emailCfgConman: IConfig | undefined;
}

export type ContactDetailsFormInput = {
  email: string;
  mobilePhone: PhoneNumberModel;
  homePhone: PhoneNumberModel;
  workPhone: PhoneNumberModel;
};

const ContactDetails: React.FC<IContactDetails> = ({
  contactDetailRef,
  contactedDetailRef,
  contactDetails,
  contactDetailsFieldConfigs,
  emailCfgConman
}) => {
  const { mobilePhone, homePhone, workPhone, email } = contactDetails;
  const {
    telephoneNumberConfig,
    emailConfig,
    mobilePhoneConfig,
    homePhoneConfig,
    workPhoneConfig
  } = contactDetailsFieldConfigs;

  const { t } = useTranslation();
  const form = useForm<ContactDetailsFormInput>({
    mode: 'onChange',
    defaultValues: {
      email: email,
      mobilePhone: mobilePhone,
      homePhone: homePhone,
      workPhone: workPhone
    }
  });

  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = form;

  useEffect(() => {
    if (contactDetailRef) {
      contactDetailRef.current = form;
    }
  }, []);

  const checkDuplicateEmail = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value) {
      const error = await validateAvailableEmail(event.target.value);
      if (error) {
        setError('email', {
          type: 'manual',
          message: t(error as string)
        });
        return;
      }
      clearErrors('email');
    }
  };

  return (
    <div className="personal-group">
      <p className="title">
        {t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_3.PLEASE_PROVIDE_AT_LEAST_ONE_TELEPHONE_NUMBER'
        )}
      </p>
      <div className="row">
        {telephoneNumberConfig.isVisible && (
          <>
            <div className="col-md-6">
              <Controller
                name="mobilePhone"
                control={control}
                rules={{
                  validate: {
                    validatePhoneNumber,
                    validateRequireAtLeastOnePhone: validateRequireAtLeastOnePhone(
                      watch('homePhone'),
                      watch('workPhone')
                    )
                  }
                }}
                render={({ field }) => (
                  <FormPhoneInput
                    {...field}
                    phoneConfig={mobilePhoneConfig}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE')}
                    countryCode={mobilePhone.countryCode}
                    errorMsg={t(
                      (errors.mobilePhone && errors.mobilePhone.message) || ''
                    )}
                    value={field.value.phoneNumber}
                    onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                      if (!phoneNumber && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                              FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS &&
                            cm.key !==
                              FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangePhoneNumber(field)(
                        phoneNumber,
                        regionCode,
                        isValid
                      );
                    }}
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <Controller
                name="homePhone"
                control={control}
                rules={{
                  validate: {
                    validatePhoneNumber
                  }
                }}
                render={({ field }) => (
                  <FormPhoneInput
                    {...field}
                    phoneConfig={homePhoneConfig}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE')}
                    countryCode={homePhone.countryCode}
                    errorMsg={t(
                      (errors.homePhone && errors.homePhone.message) || ''
                    )}
                    value={field.value.phoneNumber}
                    onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                      if (!phoneNumber && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangePhoneNumber(field)(
                        phoneNumber,
                        regionCode,
                        isValid
                      );
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        {telephoneNumberConfig.isVisible && (
          <div className="col-md-6">
            <Controller
              name="workPhone"
              control={control}
              rules={{
                validate: {
                  validatePhoneNumber
                }
              }}
              render={({ field }) => (
                <FormPhoneInput
                  {...field}
                  phoneConfig={workPhoneConfig}
                  id="workPhone"
                  className="pt-3"
                  inputClass="form-control "
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE')}
                  countryCode={workPhone.countryCode}
                  errorMsg={t(
                    (errors.workPhone && errors.workPhone.message) || ''
                  )}
                  value={field.value.phoneNumber}
                  onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                    if (!phoneNumber && contactedDetailRef.current) {
                      const preferredContactMethodWatch = contactedDetailRef.current.watch(
                        'preferredContactMethod'
                      );
                      const newPreferred = preferredContactMethodWatch.filter(
                        cm =>
                          cm.key !==
                          FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
                      );
                      contactedDetailRef.current.setValue(
                        'preferredContactMethod',
                        newPreferred
                      );
                    }
                    onChangePhoneNumber(field)(
                      phoneNumber,
                      regionCode,
                      isValid
                    );
                  }}
                />
              )}
            />
          </div>
        )}
        {emailConfig.isVisible && (
          <div className="col-md-6">
            <div className="form-group pt-3">
              <label>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL_ADDRESS')}</label>
              {emailConfig.isRequired && (
                <span className="text-primary bold">&nbsp;*</span>
              )}

              <Controller
                name="email"
                control={control}
                rules={{
                  required: required(emailConfig.isRequired),
                  validate: {
                    validateEmail: validateEmail(
                      emailCfgConman && emailCfgConman.value
                        ? emailCfgConman.value
                        : ''
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    maxlength={emailConfig.maxLength}
                    require={emailConfig.isRequired}
                    onBlur={checkDuplicateEmail}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!event.target.value && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangeInput(field)(event);
                    }}
                    errorMsg={t((errors.email && errors.email.message) || '')}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactDetails;
