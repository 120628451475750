import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAddonFee, IFee } from 'interfaces/Membership';
import { formatMoney, uniqueId } from 'util/ControlUtils';

interface ITooltipPaymentInfoTable {
  title: string;
  data: IFee[];
  addons: IAddonFee[];
}

const TooltipPaymentInfoTable: React.FC<ITooltipPaymentInfoTable> = ({
  title,
  data,
  addons
}) => {
  const dataMapped =
    (data && data.map((item) => ({ ...item, id: uniqueId() }))) || [];
  const { t } = useTranslation();
  return (
    <table className="tooltip-info-table">
      <thead>
        <tr>
          <th>
            {title}
            <br />
            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.DESCRIPTION')}
          </th>
          <th>{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.AMOUNT')}</th>
        </tr>
      </thead>
      <tbody>
        {dataMapped &&
          dataMapped.map(item => (
            <tr key={item.id}>
              <td>{item.typeName}</td>
              <td className='tooltip-amount'>{formatMoney(item.amount, 2)}</td>
            </tr>
          ))}
        {addons &&
          addons.map(addon => (
            <tr key={`${addon.addonId}_${uniqueId()}`}>
              <td>{addon.addonName}</td>
              <td className='tooltip-amount'>{formatMoney(addon.amount, 2)}</td>
            </tr>
          ))}
        {!dataMapped.length && (!addons || !addons.length)  ? (
          <tr>
            <td>{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NO_FEES')}</td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};

export default TooltipPaymentInfoTable;
