import React from 'react';

import { ITemplate } from './interface';
import MembershipConfiguration from 'models/MembershipConfiguration';
import {
  IAllPackagePlans,
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember
} from 'interfaces/Membership';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import { useDispatch } from 'react-redux';
import * as MembershipActions from 'reduxs/actions/NewCorporateMembership';
import * as NewMembershipActions from 'reduxs/actions/NewMembership';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import PaymentDetails from 'models/PaymentDetails';
import { useTypedSelector } from 'reduxs';
import { STORAGE } from 'constants/Constants';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import SelectPackagePlan from 'features/SelectPackagePlanAddCorporateMember';
import SelectPaymentDetails from 'features/SelectPaymentDetail';
import MemberSummary from 'features/MemberSummary';
import SelectProductExtras from 'features/SelectProductExtras';
import SelectPersonalDetails from 'features/SelectPersonalDetailsAddMember';
import {
  setAddMemberPackagePlanSearchFilter,
  setAddMemberPersonalDetails
} from 'reduxs/actions/NewMembership';

const NewMembership = ({
  handleCancel,
  handleCreateMember,
  configs,
  operatorId
}) => {
  const dispatch = useDispatch();
  const newCorporateMembership = useTypedSelector(
    state => state.newCorporateMembership
  );
  const payment = useTypedSelector(state => state.payment);
  const { membershipConfiguration } = newCorporateMembership;

  const setAllPackageAction = (allPackagePlans: IAllPackagePlans) => {
    dispatch(
      MembershipActions.setCorporateMemberAllPackagePlans(allPackagePlans)
    );
  };
  const setProductExtrasAction = (
    selectedPromotions: IPromotionCatergory[],
    selectedAddons: AddOn[]
  ) => {
    dispatch(
      MembershipActions.setCorporateMemberProductExtras(
        selectedPromotions,
        selectedAddons
      )
    );
  };

  const setPackagePlanSearchFilterAction = (
    membershipSearchFilter: MembershipSearchFilter
  ) => {
    dispatch(
      MembershipActions.setCorporateMemberPackagePlanSearchFilter(
        membershipSearchFilter
      )
    );
    dispatch(setAddMemberPackagePlanSearchFilter(membershipSearchFilter));
  };

  const setConfigurationAction = (
    membershipConfiguration: MembershipConfiguration
  ) => {
    dispatch(
      MembershipActions.setCorporateMemberConfiguration(membershipConfiguration)
    );
  };

  const setSelectedPackagePlanAction = (
    packagePlan: IPackagePlanItemAddCorporateMember | IPackagePlanItem
  ) => {
    dispatch(
      MembershipActions.setCorporateMemberSelectedPackagePlan(packagePlan)
    );
    dispatch(NewMembershipActions.setAddMemberSelectedPackagePlan(packagePlan));
  };

  const setPersonalDetailsAction = (personalDetails: PersonalDetailsModel) => {
    dispatch(
      MembershipActions.setCorporateMemberPersonalDetails(personalDetails)
    );
    dispatch(setAddMemberPersonalDetails(personalDetails));
  };

  const setPaymentDetailsAction = (paymentDetails: PaymentDetails) => {
    dispatch(
      MembershipActions.setCorporateMemberPaymentDetails(paymentDetails)
    );
  };

  const setQuestionConfigsAction = (questionConfigs: IQuestionConfigs) => {
    dispatch(
      MembershipActions.setAddNewCorporateMemberQuestionConfigs(questionConfigs)
    );
  };

  if (!membershipConfiguration || membershipConfiguration.stepIndex === 1) {
    return (
      <SelectPackagePlan
        onCancel={handleCancel}
        configs={configs}
        setAllPackageAction={setAllPackageAction}
        setSelectedPackagePlanAction={setSelectedPackagePlanAction}
        setConfigurationAction={setConfigurationAction}
        setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
        setProductExtrasAction={setProductExtrasAction}
      />
    );
  }
  const { stepIndex, stepLatest } = membershipConfiguration;
  switch (true) {
    case stepIndex === 2 && stepLatest > 1:
      return (
        <SelectProductExtras
          isCorporate
          configs={configs}
          membership={newCorporateMembership}
          onCancel={handleCancel}
          setSelectedPackagePlanAction={setSelectedPackagePlanAction}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setProductExtrasAction={setProductExtrasAction}
        />
      );
    case stepIndex === 3 && stepLatest > 2:
      return (
        <SelectPersonalDetails
          setQuestionConfigsAction={setQuestionConfigsAction}
          membership={newCorporateMembership}
          onCancel={handleCancel}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setPersonalDetailsAction={setPersonalDetailsAction}
        />
      );
    case stepIndex === 4 && stepLatest > 3:
      return (
        <SelectPaymentDetails
          configs={configs}
          membership={newCorporateMembership}
          payment={payment}
          onCancel={handleCancel}
          membershipStorage={STORAGE.NEW_CORPORATE_MEMBERSHIP}
          setPaymentDetailsAction={setPaymentDetailsAction}
          setConfigurationAction={setConfigurationAction}
          operatorId={operatorId}
        />
      );
    case stepIndex === 5 && stepLatest > 4:
      return (
        <MemberSummary
          membership={newCorporateMembership}
          onCancel={handleCancel}
          onFinish={handleCreateMember}
          setConfigurationAction={setConfigurationAction}
        />
      );
    default:
      return <></>;
  }
};

const Template: React.FC<ITemplate> = ({
  configs,
  handleCancel,
  handleCreateMember,
  operatorId
}) => {
  return (
    <div className="add-member-page">
      <NewMembership
        handleCancel={handleCancel}
        handleCreateMember={handleCreateMember}
        configs={configs}
        operatorId={operatorId}
      />
    </div>
  );
};

export default Template;
