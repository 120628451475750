import { API_GET_SYSTEM_CONFIGS } from 'constants/Constants';
import { baseApi, createHeader, getTenantId } from './common-service';

const getSystemConfigs = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  headers['x-tenant-id'] = getTenantId();
  return baseApi.get(API_GET_SYSTEM_CONFIGS, { headers }).then(res => res.data);
};

export { getSystemConfigs };
