// System Configs
export const ADDMEMBER_ADD_NEW_DATE_FORMAT = 'UpdatePersonalDetail.BirthDate';
export const ADDMEMBER_ADD_NEW_DOB_FORMAT = 'UpdatePersonalDetail.BirthDate';
export const ADDMEMBER_ACCOUNT_NUMBER_FORMAT =
  'Validation.RegularExpressions.Asia.AccountNumbers';
export const ADDMEMBER_CURRENCY_CODE = 'CurrencyCode';
export const ADDMEMBER_BIRTH_DATE_STEP_1 = 'UpdatePersonalDetail.BirthDate';
// step 3 config
export const ADDMEMBER_TITLE = 'addPersonalDetail.Title';
export const ADDMEMBER_GENDER = 'addPersonalDetail.Gender';
export const ADDMEMBER_FIRST_NAME = 'addPersonalDetail.FirstName';
export const ADDMEMBER_LAST_NAME = 'addPersonalDetail.LastName';
export const ADDMEMBER_MIDDLE_NAME = 'addPersonalDetail.MiddleName';
export const ADDMEMBER_BIRTH_DATE_STEP_3 =
  'addPersonalDetail.DateOfBirth.Step3';
export const ADDMEMBER_PARENTAL_CONSENT_PROVIDED =
  'addPersonalDetail.ParentalConsentProvided';
export const ADDMEMBER_NATIONAL = 'addPersonalDetail.NationalId';
export const ADDMEMBER_NATIONAL_ID = 'addPersonalDetail.PassportNumber';
export const ADDMEMBER_OTHER_NATIONAL_ID =
  'addPersonalDetail.NationalId.Alternativeid';
export const ADDMEMBER_OTHER_PASSPORT_NUMBER =
  'addPersonalDetail.PassportNumber';
export const ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES =
  'addPersonalDetail.MemberLanguagePreferences';
export const ADDMEMBER_TELEPHONE_NUMBER = 'addPersonalDetail.TelephoneNumber';
// use for validate mobile, home, work telephone in Esignature, sync data from MM
export const ADDMEMBER_CONTACT_DETAILS_MOBILE_TELEPHONE =
  'addPersonalDetail.TelephoneNumber.MobileTelephone';
export const ADDMEMBER_CONTACT_DETAILS_HOME_TELEPHONE =
  'addPersonalDetail.TelephoneNumber.HomeTelephone';
export const ADDMEMBER_CONTACT_DETAILS_WORK_TELEPHONE =
  'addPersonalDetail.TelephoneNumber.WorkTelephone';
export const ADDMEMBER_EMAIL_ADDRESS = 'addPersonalDetail.EmailAddress';
export const ADDMEMBER_NUMBER = 'addPersonalDetail.Number/Name';
export const ADDMEMBER_ADDRESS_LINE_1 = 'addPersonalDetail.Address.Line1';
export const ADDMEMBER_ADDRESS_LINE_2 = 'addPersonalDetail.Address.Line2';
export const ADDMEMBER_ADDRESS_CITY = 'addPersonalDetail.Address.City';
export const ADDMEMBER_ADDRESS_STATE = 'addPersonalDetail.Address.State';
export const ADDMEMBER_ADDRESS_POSTCODE = 'addPersonalDetail.Address.Postcode';
export const ADDMEMBER_ADDRESS_COUNTRY = 'addPersonalDetail.Address.Country';
export const ADDMEMBER_MEMBERSHIP_APPLICATION_REF =
  'addPersonalDetail.ApplicationRef';
export const ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED =
  'addPersonalDetail.MemberHappyToBeContact';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD =
  'addPersonalDetail.PreferredContactMethod';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_POST =
  'addPersonalDetail.PCM.Post';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE =
  'addPersonalDetail.PCM.HomeTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE =
  'addPersonalDetail.PCM.WorkTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE =
  'addPersonalDetail.PCM.MobileTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS =
  'addPersonalDetail.PCM.SMS';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL =
  'addPersonalDetail.PCM.Email';
export const ADDMEMBER_MARKETING_SOURCE = 'addPersonalDetail.Source';
export const ADDMEMBER_CHANNEL_CONFIG = 'addMember.channelConfig';
export const ADDMEMBER_EMERGENCY_CONTACT_NAME =
  'addPersonalDetail.EmergencyContactName';
export const ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER =
  'addPersonalDetail.EmergencyContactPhoneNumber';
export const ADDMEMBER_COMPANY_NAME = 'addPersonalDetail.CompanyName';
export const ADDMEMBER_OCCUPATION = 'addPersonalDetail.Occupation';
export const ADDMEMBER_REFERRED_BY_NAME = 'addPersonalDetail.ReferredByName';
export const ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER =
  'addPersonalDetail.ReferredByMembershipNumber';
export const ADDMEMBER_OTHER_COMMISSION_NOTE =
  'addPersonalDetail.OtherCommissionNote';
export const ADDMEMBER_GENERAL_NOTE = 'addPersonalDetail.GeneralNote';
export const ADDMEMBER_RECURRING_CARD_TYPE =
  'addPersonalDetail.RecurringCardType';
