import React from 'react';
import { IHealthQuestion } from '../interface';
import DescriptionContent from '../DescriptionContent';

const HealthQuestion: React.FC<IHealthQuestion> = ({ membership }) => {
  const { personalDetails } = membership;

  if (!personalDetails.healthQuestions) {
    return <></>;
  }

  return (
    <DescriptionContent
      data={[
        {
          fieldLabel: '',
          isVisible: true,
          data: personalDetails.healthQuestions
        }
      ]}
    />
  );
};

export default HealthQuestion;
