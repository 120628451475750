import React from 'react';

interface IStep {
  isActive: boolean;
  isReadyToGo: boolean;
  isActiveClick: boolean;
  label: string;
  stepNumber: string;
  onClick: () => void;
}
const Step: React.FC<IStep> = ({
  isActive,
  isReadyToGo,
  isActiveClick,
  stepNumber,
  label,
  onClick
}) => {
  return (
    <>
      <a
        className={`step 
          ${isActive ? 'active' : ''}
          ${isReadyToGo ? 'go-to-step' : ''}`}
        onClick={() => isActiveClick && onClick()}
      >
        <div className="number-step">
          <span>{stepNumber}</span>
        </div>
        <p className="text-step">{label}</p>
      </a>
    </>
  );
};

export default Step;
