import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { history, configureStore } from 'reduxs';

import Layout from 'pages/Layout';

import initI18n from './util/I18n';

const store = configureStore();
initI18n();

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={null}>
          <Switch>
            <Route path="/" render={() => <Layout />} />
          </Switch>
        </React.Suspense>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
