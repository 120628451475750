import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  ContactedDetailsModel,
  ContactedDetailsFieldsModel,
  LookupModel,
  ContactMethodModel
} from 'models/PersonalDetails';
import { FormSelect, FormInput } from 'components/Form';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { useTranslation } from 'react-i18next';
import {
  ACTIVE_STATUS_CODE,
  CHANNEL_CODE_QUESTION_CODE,
  CONTACT_METHOD_LIST,
  MARKETING_CAMPAIGN_QUESTION_CODE
} from 'constants/DefaultConstants';
import { ConmanFieldConfigModel } from 'models/ConmanConfig';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import {
  onChangeInput,
  required,
  validateEmptySelection,
  validateInputField
} from 'util/FormValidations';
import { isFieldRequired } from 'util/FieldConfiguration';
import { ContactDetailsFormInput } from './ContactDetails';
import { safeLookupModel } from 'util/ControlUtils';

const getLanguageKey = configKey => {
  switch (configKey) {
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.POST';
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE';
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE';
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE';
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL';
    case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:
      return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.SMS';
    default:
      return 'DEFAULT_FALLBACK';
  }
};

interface IContactedDetails {
  marketingSources: LookupModel[];
  channelCodes: LookupModel[];
  marketingCampaigns: LookupModel[];
  conmanConfig: ConmanFieldConfigModel[];
  contactedDetails: ContactedDetailsModel;
  contactedDetailsFieldConfigs: ContactedDetailsFieldsModel;
  contactDetailRef: React.MutableRefObject<UseFormReturn<
    ContactDetailsFormInput,
    any
  > | null>;
  contactedDetailRef: MutableRefObject<UseFormReturn<
    ContactedDetailsFormInput
  > | null>;
}

export type ContactedDetailsFormInput = {
  applicationRef: string;
  memberHappyToBeContact: boolean;
  marketingSource: LookupModel;
  channelCode: LookupModel;
  marketingCampaign: LookupModel;
  preferredContactMethod: ContactMethodModel[];
};
const ContactedDetails: React.FC<IContactedDetails> = ({
  marketingSources,
  marketingCampaigns,
  contactedDetails,
  contactedDetailsFieldConfigs,
  channelCodes,
  conmanConfig,
  contactedDetailRef,
  contactDetailRef
}) => {
  const {
    applicationRef,
    marketingSource,
    memberHappyToBeContact,
    preferredContactMethod,
    channelCode,
    marketingCampaign
  } = contactedDetails;

  const {
    applicationRefConfig,
    memberHappyToBeContactConfig,
    preferredContactMethodConfig,
    marketingSourceConfig
  } = contactedDetailsFieldConfigs;

  const { t } = useTranslation();
  const [channelConfig, setChannelConfig] = useState<ConmanFieldConfigModel>();
  const [marketingCampaignConfig, setMarketingCampaignConfig] = useState<
    ConmanFieldConfigModel
  >();

  const form = useForm<ContactedDetailsFormInput>({
    defaultValues: {
      applicationRef: applicationRef,
      memberHappyToBeContact: memberHappyToBeContact,
      marketingSource: safeLookupModel(
        marketingSources.find(item => item.value === marketingSource.value)
      ),
      channelCode: safeLookupModel(
        channelCodes.find(item => item.value === channelCode.value)
      ),
      marketingCampaign: safeLookupModel(
        marketingCampaigns.find(item => item.value === marketingCampaign.value)
      ),
      preferredContactMethod: preferredContactMethod
    }
  });

  const {
    control,
    register,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors }
  } = form;
  const preferredContactMethodWatch = watch('preferredContactMethod');

  useEffect(() => {
    if (conmanConfig && conmanConfig.length) {
      const channelCodeCfg = conmanConfig.find(
        (cmn: ConmanFieldConfigModel) =>
          cmn.questionCode === CHANNEL_CODE_QUESTION_CODE
      );
      setChannelConfig(channelCodeCfg);
      const marketingCampaignCfg = conmanConfig.find(
        (cmn: ConmanFieldConfigModel) =>
          cmn.questionCode === MARKETING_CAMPAIGN_QUESTION_CODE
      );
      setMarketingCampaignConfig(marketingCampaignCfg);
    }
  }, [conmanConfig]);

  useEffect(() => {
    if (contactedDetailRef) {
      contactedDetailRef.current = form;
    }
  }, []);

  const checkMethodValid = (
    key: string,
    form: UseFormReturn<ContactDetailsFormInput>
  ): [string, boolean] => {
    let isContactMethodProvided = false;
    let errorMessage = '';
    const email = form.watch('email');
    const homePhone = form.watch('homePhone');
    const workPhone = form.watch('workPhone');
    const mobilePhone = form.watch('mobilePhone');

    switch (key) {
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
        isContactMethodProvided = true;
        break;
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:
        isContactMethodProvided = !!email;
        if (!isContactMethodProvided)
          errorMessage = t(
            'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
            { contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL') }
          );
        break;
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:
        isContactMethodProvided =
          homePhone.isValid && homePhone.phoneNumber !== '';
        if (!isContactMethodProvided)
          errorMessage = t(
            'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
            {
              contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE')
            }
          );
        break;
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:
        isContactMethodProvided =
          workPhone.isValid && workPhone.phoneNumber !== '';
        if (!isContactMethodProvided)
          errorMessage = t(
            'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
            {
              contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE')
            }
          );
        break;
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:
      case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:
        isContactMethodProvided =
          mobilePhone.isValid && mobilePhone.phoneNumber !== '';
        if (!isContactMethodProvided)
          errorMessage = t(
            'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
            {
              contactMethod: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE'
              )
            }
          );
        break;
      default:
        break;
    }

    return [errorMessage, isContactMethodProvided];
  };

  const onCheckboxChange = (key: string, checked: boolean, value) => {
    let [errorMessage, isContactMethodProvided] = contactDetailRef.current
      ? checkMethodValid(key, contactDetailRef.current)
      : ['', false];

    if (isContactMethodProvided) {
      const prevContacts = watch('preferredContactMethod');
      const newContacts = checked
        ? prevContacts.concat(value)
        : prevContacts.filter(cm => cm.id !== value.id);
      errorMessage =
        isFieldRequired(preferredContactMethodConfig) &&
        newContacts.length === 0
          ? 'COMMON.FIELD_REQUIRED'
          : '';
      setValue('preferredContactMethod', newContacts);
    }
    if (errorMessage) {
      setError('preferredContactMethod', {
        type: 'isContactMethodProvided',
        message: errorMessage
      });
    } else {
      clearErrors('preferredContactMethod');
    }
  };

  const contactMethodItems = CONTACT_METHOD_LIST.map(cm => {
    const languageKey = getLanguageKey(cm.key);
    return (
      preferredContactMethodConfig.isVisible && (
        <div key={cm.id} className="col-lg-4 col-md-6">
          <div className="form-check">
            <input
              id={`cm-${cm.id}`}
              type="checkbox"
              checked={preferredContactMethodWatch.some(
                pref => pref.id === cm.id
              )}
              onChange={event =>
                onCheckboxChange(cm['key'], event.target.checked, cm)
              }
            />
            <label htmlFor={`cm-${cm.id}`} className="icon-checked-ico">
              <span>{t(languageKey, cm.name)} </span>
            </label>
          </div>
        </div>
      )
    );
  });

  //populate contact method to row with items/row
  const contactMethodRows = Array.from(
    { length: Math.ceil(contactMethodItems.length / 3) },
    (_, i) => (
      <div key={i} className="row pb-3 pl-3">
        {contactMethodItems.slice(i * 3, i * 3 + 3)}
      </div>
    )
  );

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {applicationRefConfig.isVisible && (
            <Controller
              name="applicationRef"
              control={control}
              rules={{
                required: required(applicationRefConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(applicationRefConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  inputClass="form-control"
                  label={t(
                    'PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBERSHIP_APPLICATION_REF'
                  )}
                  maxlength={applicationRefConfig.maxLength}
                  require={applicationRefConfig.isRequired}
                  errorMsg={t(
                    (errors.applicationRef && errors.applicationRef.message) ||
                      ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {memberHappyToBeContactConfig.isVisible && (
            <div className="form-check">
              <div>
                <input
                  type="checkbox"
                  id="memberHappyToBeContact"
                  className="myCheckedbox"
                  {...register('memberHappyToBeContact', {
                    required: required(memberHappyToBeContactConfig.isRequired)
                  })}
                />

                <label
                  className="icon-checked-ico"
                  htmlFor="memberHappyToBeContact"
                >
                  <span>
                    {t(
                      'PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBER_HAPPY_TO_BE_CONTACTED'
                    )}
                  </span>
                </label>
                {memberHappyToBeContactConfig.isRequired && (
                  <span className="text-primary bold">&nbsp;*</span>
                )}
              </div>
              {errors.memberHappyToBeContact && (
                <span className="text-danger input-error">
                  {t(errors.memberHappyToBeContact.message || '')}
                </span>
              )}
            </div>
          )}
          {preferredContactMethodConfig.isVisible && (
            <div className="box-nation active mt-3 mb-3">
              <p>
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD')}
                {preferredContactMethodConfig.isRequired && (
                  <span className="text-primary bold">&nbsp;*</span>
                )}
                <label className="text-danger input-error">
                  {t(
                    (errors.preferredContactMethod &&
                      errors.preferredContactMethod.message) ||
                      ''
                  )}
                </label>
              </p>

              {contactMethodRows}
            </div>
          )}
        </div>
      </div>

      <div className="row marketing-source">
        <div className="col-md-6">
          {marketingSourceConfig.isVisible && (
            <Controller
              name="marketingSource"
              control={control}
              rules={{
                required: required(marketingSourceConfig.isRequired),
                validate: {
                  validateEmptySelection: value =>
                    validateEmptySelection(
                      value,
                      marketingSourceConfig.isRequired
                    )
                }
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SOURCE')}
                  disabled={marketingSources.length === 0}
                  require={marketingSourceConfig.isRequired}
                  options={marketingSources}
                  errorMsg={t(
                    (errors.marketingSource &&
                      errors.marketingSource.message) ||
                      ''
                  )}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {channelConfig &&
            channelConfig.status === ACTIVE_STATUS_CODE &&
            channelCodes.length > 0 && (
              <Controller
                name="channelCode"
                control={control}
                rules={{
                  required: required(channelConfig.isRequired === 1),
                  validate: {
                    validateEmptySelection: value =>
                      validateEmptySelection(
                        value,
                        channelConfig.isRequired === 1
                      )
                  }
                }}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CHANNEL_CODE'
                    )}
                    disabled={channelCodes.length === 0}
                    require={channelConfig.isRequired === 1}
                    options={channelCodes}
                    errorMsg={t(
                      (errors.channelCode && errors.channelCode.message) || ''
                    )}
                  />
                )}
              />
            )}
        </div>
      </div>
      <div className="row marketing-source">
        <div className="col-md-6">
          {marketingCampaignConfig &&
            marketingCampaignConfig.status === ACTIVE_STATUS_CODE &&
            marketingCampaigns.length > 0 && (
              <Controller
                name="marketingCampaign"
                control={control}
                rules={{
                  required: required(marketingCampaignConfig.isRequired === 1),
                  validate: {
                    validateEmptySelection: value =>
                      validateEmptySelection(
                        value,
                        marketingCampaignConfig.isRequired === 1
                      )
                  }
                }}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.MARKETING_CAMPAIGN'
                    )}
                    disabled={marketingCampaigns.length === 0}
                    require={marketingCampaignConfig.isRequired === 1}
                    options={marketingCampaigns}
                    errorMsg={t(
                      (errors.marketingCampaign &&
                        errors.marketingCampaign.message) ||
                        ''
                    )}
                  />
                )}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default ContactedDetails;
