import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD_TYPES } from 'constants/Constants';
import { ITemplate } from './interface';
import NationalId from './NationalId';
import PrimaryPaymentMethod from './PrimaryPaymentMethod';
import { isLumpSumpPackage } from 'util/Common';
import { useTypedSelector } from 'reduxs';
import { canGoToSummary, isPaymentInfoExist } from 'selectors/member';

const Template: React.FC<ITemplate> = ({
  openDropdown,
  paymentDetails,
  primaryPaymentMethods,
  issuingBanks,
  membership,
  payment,
  errors,
  handleOpenDropdown,
  handleChange,
  handleInputChange,
  handlePrevious,
  handleNext,
  goToStep,
  onCancel,
  handleAddCardSignature,
  handleEditCardSignature
}) => {
  const { onlinePayment, state } = payment;
  const {
    membershipSearchFilter,
    membershipConfiguration,
    selectedPackagePlan,
    selectedAddons,
    selectedPromotions
  } = membership;
  const { t } = useTranslation();
  const isLumpSump = isLumpSumpPackage(selectedPackagePlan);
  const isDisabledEzPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );
  const paymentInfoExist = useTypedSelector(state =>
    isPaymentInfoExist(state, paymentDetails.primaryPaymentMethodId)
  );

  const canGoToNextStep = canGoToSummary({
    isPaymentInfoExist: paymentInfoExist,
    isDisabledEzPay,
    primaryPaymentMethodId: paymentDetails.primaryPaymentMethodId,
    onlinePayment,
    statePayment: state
  });

  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <div className="content select-add-on">
            <div className="main-payment select-add-on-main">
              <CollapseButton
                className="collapse-promotion noborder"
                title={t(
                  isLumpSump
                    ? 'PAGE.MEMBERSHIPS.CREATE.STEP_5.PAYMENT_METHOD'
                    : 'PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD'
                )}
                content={PrimaryPaymentMethod({
                  state,
                  paymentDetails,
                  primaryPaymentMethods,
                  issuingBanks,
                  errors,
                  onlinePayment,
                  selectedPackagePlan,
                  handleChange,
                  handleInputChange,
                  handleAddCardSignature,
                  handleEditCardSignature
                })}
                isOpen={openDropdown['primary']}
                maxHeight="1000px"
                openCollapsedContent={() => handleOpenDropdown('primary')}
              />
              <NationalId
                paymentDetails={paymentDetails}
                errors={errors}
                handleInputChange={handleInputChange}
              />
            </div>
            {selectedPackagePlan && (
              <ProductSummary
                productSummary={selectedPackagePlan}
                membershipSearchFilter={membershipSearchFilter}
                membershipConfiguration={membershipConfiguration}
                addons={selectedAddons}
                promotions={selectedPromotions}
                handlePrevious={() => handlePrevious()}
                handleNext={() => handleNext()}
                isDisabledNext={!canGoToNextStep}
                handleCancel={onCancel}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Template;
