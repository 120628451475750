import React from 'react';
import { FormInput } from 'components/Form';
import { useTranslation } from 'react-i18next';

const NationalId = ({ paymentDetails, errors, handleInputChange }) => {
  const { nationalIdNumber, otherNationalIdNumber } = paymentDetails;
  const { t } = useTranslation();

  return (
    <div className="national">
      <p className="national-title text-uppercase">
        {t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_DETAILS')}
      </p>
      <div className="row">
        <div className="col-xl-6">
          <FormInput
            id="national-id-number"
            name="national-id-number"
            inputClass="form-control"
            value={nationalIdNumber}
            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_NUMBER')}
            placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_NUMBER')}
            maxlength={40}
            onChange={event =>
              handleInputChange('nationalIdNumber', event.target.value)
            }
            errorMsg={errors.nationalIdNumber}
          />
        </div>
        <div className="col-xl-6">
          <FormInput
            id="other-national-id-number"
            name="other-national-id-number"
            inputClass="form-control"
            value={otherNationalIdNumber}
            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PASSPORT_NUMBER')}
            placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PASSPORT_NUMBER')}
            onChange={event =>
              handleInputChange('otherNationalIdNumber', event.target.value)
            }
            maxlength={15}
            errorMsg={errors.otherNationalIdNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default NationalId;
