export class DefaultModel {
  id = '';
  name = '';
  value = '';
  label = '';
}

export interface OpenDropdownModel {
  personalDetails: boolean;
  contactDetails: boolean;
  addressDetails: boolean;
  contactedDetails: boolean;
  emergencyContact: boolean;
  healthQuestion: boolean;
  otherFields: boolean;
}

export interface LoadingModel {
  personalDetailsFieldConfigs: boolean;
  contactDetailsFieldConfigs: boolean;
  addressDetailsFieldConfigs: boolean;
  emergencyContactFieldsConfigs: boolean;
  otherFieldsFieldsConfigs: boolean;
  contactedDetailsFieldConfigs: boolean;
  emergencyNameConfig: boolean;
  conmanConfigList: boolean;
  emergencyNumberConfig: boolean;
  emergencyContact: boolean;
  contactDetails: boolean;
  healthQuestions: boolean;
  contactedDetails: boolean;
  marketingSources: boolean;
  channelCodes: boolean;
  marketingCampaign: boolean;
  countries: boolean;
  questions: boolean;
  addressDetails: boolean;
  duplicateMembers: boolean;
  otherFields: boolean;
  recurringCardTypes: boolean;
  titles: boolean;
  genders: boolean;
  languages: boolean;
  personalDetails: boolean;
}

export class ContactMethodModel extends DefaultModel {
  key = '';
}

export class LookupModel extends DefaultModel {
  key = '';
  code = '';
}

export class PhoneNumberModel {
  isValid = false;
  countryCode = '';
  phoneNumber = '';
}

export class FieldConfig {
  key = '';
  name = '';
  isRequired = false;
  isVisible = true;
  regEx = '';
  maxLength = 255;
}

export class PersonalDetailsModel {
  title = new LookupModel();
  gender = new LookupModel();
  firstName = '';
  lastName = '';
  middleName = '';
  nationalIdNumber = '';
  passportNumber = '';
  dateOfBirth: Date | null = null;
  preferedLanguage = new LookupModel();
  isParentalConsentProvided = false;
  contactDetails = new ContactDetailsModel();
  addressDetails = new AddressDetailsModel();
  contactedDetails = new ContactedDetailsModel();
  emergencyContact = new EmergencyContactModel();
  healthQuestions: Question[] = [];
  otherFields = new OtherFieldsModel();
  age? = 0;
}

export class PersonalDetailFieldsModel {
  titleConfig = new FieldConfig();
  genderConfig = new FieldConfig();
  firstNameConfig = new FieldConfig();
  lastNameConfig = new FieldConfig();
  dateOfBirthConfig = new FieldConfig();
  nationalConfig = new FieldConfig();
  nationalIdConfig = new FieldConfig();
  preferedLanguageConfig = new FieldConfig();
  parentalConsentConfig = new FieldConfig();
  middleNameConfig = new FieldConfig();
  passportNumberConfig = new FieldConfig();
}

export class ContactDetailsModel {
  mobilePhone = new PhoneNumberModel();
  homePhone = new PhoneNumberModel();
  workPhone = new PhoneNumberModel();
  email = '';
  isEmailValid = true;
  mobilePhoneNoLongerUse? = false;
  homePhoneNoLongerUse? = false;
  workPhoneNoLongerUse? = false;
  emailNoLongerUse? = false;
}

export class ContactDetailsFieldsModel {
  telephoneNumberConfig = new FieldConfig();
  emailConfig = new FieldConfig();
  mobilePhoneConfig = new FieldConfig();
  homePhoneConfig = new FieldConfig();
  workPhoneConfig = new FieldConfig();
}

export class AddressDetailsModel {
  addressNumber = '';
  addressLine1 = '';
  addressLine2 = '';
  city = '';
  state = '';
  postCode = '';
  country = new LookupModel();
}

export class AddressDetailsFieldsModel {
  addressNumberConfig = new FieldConfig();
  addressLine1Config = new FieldConfig();
  addressLine2Config = new FieldConfig();
  cityConfig = new FieldConfig();
  stateConfig = new FieldConfig();
  countryConfig = new FieldConfig();
  postCodeConfig = new FieldConfig();
}

export class ContactedDetailsModel {
  applicationRef = '';
  memberHappyToBeContact = false;
  preferredContactMethod: ContactMethodModel[] = [];
  marketingSource = new LookupModel();
  channelCode = new LookupModel();
  marketingCampaign = new LookupModel();
}

export class ContactedDetailsFieldsModel {
  applicationRefConfig = new FieldConfig();
  memberHappyToBeContactConfig = new FieldConfig();
  preferredContactMethodConfig = new FieldConfig();
  marketingSourceConfig = new FieldConfig();
}

export interface Question {
  id: number;
  name: string;
  isRequired: boolean;
  active: boolean;
}

export class EmergencyContactModel {
  contactName = '';
  contactNumber = new PhoneNumberModel();
  relationship = '';
  guardianName = '';
  guardianPhoneNumber = '';
  guardianAddress = '';
  guardianRelationship = '';
  guardianIdentityNo = '';
  guardianIdentityTypeId = new LookupModel();
  guardianPass = '';
}
export class EmergencyContactFieldsModel {
  contactNameConfig = new FieldConfig();
  contactNumberConfig = new FieldConfig();
}

export class HealthQuestionModel {
  id = '';
  name = '';
  isLastQuestion = false;
}

export class OtherFieldsModel {
  companyName = '';
  occupation = '';
  referredByMemberName = '';
  referredByMembershipNumber = '';
  otherCommissionNote = '';
  generalNote = '';
  recurringCardType = new LookupModel();
}

export class OtherFieldsFieldsModel {
  companyNameConfig = new FieldConfig();
  occupationConfig = new FieldConfig();
  referredByMemberNameConfig = new FieldConfig();
  referredByMembershipNumberConfig = new FieldConfig();
  otherCommissionNoteConfig = new FieldConfig();
  generalNoteConfig = new FieldConfig();
  recurringCardTypeConfig = new FieldConfig();
}
