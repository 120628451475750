import React from 'react';
import { PaymentState } from 'reduxs/actions/Payment';
import OnlinePaymentCard from './OnlinePaymentCard';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';

const OnlinePayment = props => {
  const { state, cardType, onlinePayment } = props;

  const { t } = useTranslation();

  if (state === PaymentState.SUCCESS && cardType) {
    return <OnlinePaymentCard card={onlinePayment} />;
  }

  if (state === PaymentState.LOADING) {
    return <Loading />;
  }

  if (state === PaymentState.DECLINE) {
    return (
      <>
        <OnlinePaymentCard card={onlinePayment} />
        <br />
        <span className="text-danger">
          {t('PAGE.MEMBERSHIPS.CREATE.STEP_4.DECLINED_CARD')}
        </span>
      </>
    );
  }

  if (state === PaymentState.FAIL) {
    return (
      <span className="text-danger">
        {t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ONLINE_PAYMENT_LOADING_FAIL')}
      </span>
    );
  }

  return <></>;
};

export default OnlinePayment;
