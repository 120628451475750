import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Utils from 'util/ControlUtils';
import { DataDescription, IPersonalDetails } from '../interface';
import { getName } from 'util/Common';
import DescriptionContent from '../DescriptionContent';

const Summary: React.FC<IPersonalDetails> = ({ membership, fieldConfigs }) => {
  const {
    membershipSearchFilter,
    membershipConfiguration,
    selectedPackagePlan,
    personalDetails
  } = membership;

  const { t } = useTranslation();
  let dataField: DataDescription[] = [];

  if (selectedPackagePlan) {
    dataField = [
      {
        isVisible: fieldConfigs.titleConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.NAME'),
        data: Utils.getValueData(
          getName({
            firstName: personalDetails.firstName,
            middleName: personalDetails.middleName,
            lastName: personalDetails.lastName
          })
        )
      },
      {
        isVisible: fieldConfigs.genderConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENDER'),
        data: Utils.getValueData(personalDetails.gender.label)
      },
      {
        isVisible: fieldConfigs.nationalConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.NATIONAL_ID_NUMBER'),
        data: Utils.getValueData(personalDetails.nationalIdNumber)
      },
      {
        isVisible: fieldConfigs.nationalConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PASSPORT_NUMBER_SUMMARY'),
        data: Utils.getValueData(personalDetails.passportNumber)
      },
      {
        isVisible: fieldConfigs.dateOfBirthConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE_OF_BIRTH'),
        data: Utils.getValueData(
          Utils.DisplayDateFormat(
            personalDetails.dateOfBirth,
            membershipConfiguration.newMemberTimeFormat
          )
        )
      },
      {
        isVisible: fieldConfigs.preferedLanguageConfig.isVisible,
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PREFERRED_LANGUAGE'),
        data:
          personalDetails.preferedLanguage &&
          Utils.getValueData(personalDetails.preferedLanguage.label)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.CLUB'),
        data: Utils.getValueData(membershipSearchFilter.clubName)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_SALE_PERSON'),
        data: Utils.getValueData(membershipSearchFilter.primarySalePersonName)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SECONDARY_SALE_PERSON'),
        data: Utils.getValueData(membershipSearchFilter.secondarySalePersonName)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TIER'),
        data: Utils.getValueData(selectedPackagePlan.tier.name)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE'),
        data: Utils.getValueData(selectedPackagePlan.package.name)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
        data: Utils.getValueData(selectedPackagePlan.name)
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.START_DATE'),
        data: Utils.getValueData(
          Utils.DisplayDateFormat(
            membershipSearchFilter.startDate,
            membershipConfiguration.newMemberTimeFormat
          )
        )
      },
      {
        fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TOTAL_INITIAL_FEE'),
        data: Utils.getValueData(
          Utils.formatMoney(
            selectedPackagePlan.payment.payNow.value,
            2
          ).toString()
        )
      }
    ];
  }

  return (
    <DescriptionContent
      data={dataField.map(data => ({
        ...data,
        isVisible: !data.isVisible ? true : data.isVisible
      }))}
      title={t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP_AND_THEIR_MEMBERSHIP'
      )}
    />
  );
};

export default Summary;
