import { IConfig } from 'interfaces/SystemConfig';
import MembershipConfiguration from 'models/MembershipConfiguration';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import {
  GENERAL_APPLICATION_TYPE_DIGITAL_CONTRACT,
  GENERAL_DATABASE_DATE_FORMAT,
  GENERAL_DATE_FORMAT
} from 'constants/Constants';
import OptionModel from 'models/Option';
import * as Utils from 'util/ControlUtils';
import { INewMembership } from 'reduxs/reducers/NewMembership';
import { IValidPromotion } from 'models/Promotion';
import {
  checkAvailablePromotion,
  getApplicationTypes,
  getClubs,
  getCorporateNames,
  getFieldsConfiguration,
  getPackagePlans,
  getPackagePlansForCorporateMember,
  getSalePeople,
  getSystemDate
} from 'services/membership-service';
import { condition, getPackageAccessText, lockScrolling } from 'util/Common';
import MembershipSearchFilter, {
  PromotionSearchFilter
} from 'models/MembershipSearchFilter';
import { getFieldConfig } from 'util/FieldConfiguration';
import { FieldConfig } from 'models/PersonalDetails';
import {
  IAllPackagePlans,
  IAllPackagePlansAddCorporateMember,
  ICounter,
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember
} from 'interfaces/Membership';
import {
  INVALID_PROMO,
  PRIMARY_PAYMENT_METHOD,
  UNDEFINED_VALUE
} from 'constants/DefaultConstants';
import _ from 'lodash';
import { FieldError } from 'interfaces/common/FieldError';
import ICorporateOptionModel from 'models/ICorporateOptionModel';
import React from 'react';
import observer from 'util/Observer';
import { IGetCorporateNameResponse } from 'services/interfaces/responses/membership';
import { t } from 'util/I18nMessages';
import { Club } from 'models/Club';
import { ProductFilterType } from 'services/enums';

export const defaultOption = {
  label: 'COMMON.PLEASE_SELECT',
  value: '0'
};

export const getMembershipConfigurationFromDOB = (
  configs: IConfig[],
  membershipConfig: MembershipConfiguration
) => {
  const membershipConfigurationTemp = membershipConfig;
  const dobFormat = configs.find(
    e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DOB_FORMAT
  );
  if (dobFormat) {
    membershipConfigurationTemp.newMemberDOBFormat = dobFormat.value;
  } else {
    membershipConfigurationTemp.newMemberDOBFormat = GENERAL_DATE_FORMAT;
  }
  const dateFormat = configs.find(
    e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DATE_FORMAT
  );
  if (dateFormat) {
    membershipConfigurationTemp.newMemberTimeFormat = dateFormat.value;
  } else {
    membershipConfigurationTemp.newMemberTimeFormat = GENERAL_DATE_FORMAT;
  }
  return membershipConfig;
};

export const processMembershipSearchFilter = async (
  res,
  membershipSearchFilter: MembershipSearchFilter,
  newMembership: INewMembership
) => {
  let validPromotion: IValidPromotion | null = null;
  let showCheckIcon: boolean = false;
  let clubsData: Club[] = [];
  const membershipSearchFilterTemp = membershipSearchFilter;
  let startDate;
  if (!membershipSearchFilter.startDate) {
    const systemDate = await getSystemDate();
    startDate = Utils.DisplayDateFormat(
      systemDate,
      GENERAL_DATABASE_DATE_FORMAT
    );
  } else {
    startDate = Utils.DisplayDateFormat(
      membershipSearchFilter.startDate,
      GENERAL_DATABASE_DATE_FORMAT
    );
  }
  membershipSearchFilterTemp.startDate = startDate;
  let promotionId: number | undefined = undefined;
  const clubs: OptionModel[] = Utils.alphabetSort(
    res.data.map(e => ({
      value: e.id,
      label: e.name
    })),
    'label'
  );
  clubsData = res.data;
  if (clubs.length > 0 && clubs[0].value) {
    if (!membershipSearchFilterTemp.clubId) {
      membershipSearchFilterTemp.clubId = Number(clubs[0].value);
      membershipSearchFilterTemp.clubName = clubs[0].label;
      membershipSearchFilter.clubSelect = clubsData.find(
        club => club.id === Number(clubs[0].value)
      );
    }

    if (
      newMembership.membershipSearchFilter &&
      newMembership.membershipSearchFilter.promotion.id
    ) {
      promotionId = newMembership.membershipSearchFilter.promotion.id;
      const {
        code,
        id,
        categoryId,
        categoryName
      } = newMembership.membershipSearchFilter.promotion;
      validPromotion = {
        code,
        id,
        categoryId: condition(categoryId, categoryId, 0),
        categoryName
      };
      showCheckIcon = true;
    } else {
      membershipSearchFilter.promotion.code = '';
    }
  }
  return {
    membershipSearchFilterTemp,
    showCheckIcon,
    validPromotion,
    startDate,
    clubs,
    promotionId,
    clubsData
  };
};

export const handleSetData = async (
  res,
  membershipSearchFilter,
  newMembership: INewMembership
) => {
  let allPackagePlans: any = [];
  let salePeople: OptionModel[] = [];
  let corporateNameList: OptionModel[] = [];
  const {
    membershipSearchFilterTemp,
    showCheckIcon,
    validPromotion,
    startDate,
    clubs,
    promotionId,
    clubsData
  } = await processMembershipSearchFilter(
    res,
    membershipSearchFilter,
    newMembership
  );
  const data = {
    showCheckIcon,
    validPromotion,
    allPackagePlans,
    salePeople,
    membershipSearchFilterTemp,
    clubs,
    corporateNameList,
    clubsData
  };
  if (clubs.length > 0 && clubs[0].value) {
    const dob = membershipSearchFilterTemp.dateOfBirth
      ? Utils.DisplayDateFormat(
          membershipSearchFilterTemp.dateOfBirth,
          GENERAL_DATABASE_DATE_FORMAT
        )
      : '';
    try {
      const [res1, res2] = await Promise.all([
        getSalePeople({ clubId: membershipSearchFilterTemp.clubId }),
        getPackagePlans({
          clubId: membershipSearchFilterTemp.clubId,
          startDate,
          promoId: promotionId,
          dob
        })
      ]);
      salePeople = res1.data.map(e => ({
        value: e.id,
        label: e.name
      }));
      allPackagePlans = res2.data || [];
      allPackagePlans = allPackagePlans.map(obj => ({
        ...obj,
        package: {
          ...obj.package,
          timeGroup: {
            ...obj.package.timeGroup,
            name: getPackageAccessText(obj)
          }
        },
        minimumContractValue: `${obj.minimumContract.value} ${
          obj.minimumContract.unit.name
        }`
      }));

      if (
        !membershipSearchFilterTemp.primarySalePersonId &&
        salePeople.length > 0
      ) {
        membershipSearchFilterTemp.primarySalePersonId = Number(
          salePeople[0].value
        );
        membershipSearchFilterTemp.primarySalePersonName = salePeople[0].label;
      }
      return {
        ...data,
        validPromotion,
        showCheckIcon,
        allPackagePlans,
        salePeople,
        membershipSearchFilter
      };
    } catch (error) {
      return {
        ...data,
        validPromotion,
        showCheckIcon,
        allPackagePlans: [],
        salePeople,
        membershipSearchFilter
      };
    }
  }
  return data;
};

export const handleSetDataCorporateMember = async (
  res,
  membershipSearchFilter,
  newMembership: INewMembership
) => {
  let allPackagePlans: any = [];
  let salePeople: OptionModel[] = [];
  let corporateNameList: OptionModel[] = [];
  const {
    membershipSearchFilterTemp,
    showCheckIcon,
    validPromotion,
    startDate,
    clubs,
    promotionId,
    clubsData
  } = await processMembershipSearchFilter(
    res,
    membershipSearchFilter,
    newMembership
  );
  const data = {
    showCheckIcon,
    validPromotion,
    allPackagePlans,
    salePeople,
    membershipSearchFilterTemp,
    clubs,
    corporateNameList,
    clubsData
  };
  if (clubs.length > 0 && clubs[0].value) {
    try {
      const [res1, res3] = await Promise.all([
        getSalePeople({ clubId: membershipSearchFilterTemp.clubId }),
        getCorporateNames({ clubId: membershipSearchFilterTemp.clubId })
      ]);
      salePeople = Utils.transformOptions<OptionModel>(res1.data, 'name', 'id');
      const rawCorporateNames = Utils.alphabetSort(
        res3.filter(c => c.status === 'AC'),
        'name'
      );
      corporateNameList = Utils.transformOptions(
        rawCorporateNames,
        'name',
        'corporatePartyId'
      );

      if (
        !membershipSearchFilterTemp.primarySalePersonId &&
        salePeople.length > 0
      ) {
        membershipSearchFilterTemp.primarySalePersonId = Number(
          salePeople[0].value
        );
        membershipSearchFilterTemp.primarySalePersonName = salePeople[0].label;
      }

      if (Number(membershipSearchFilterTemp.corporateId)) {
        const res2 = await getPackagePlansForCorporateMember({
          clubId: Number(membershipSearchFilterTemp.clubId),
          startDate,
          promoId: promotionId,
          corporateId: membershipSearchFilterTemp.corporateId,
          productFilterType: ProductFilterType.CorporatePlansOnly,
          dob: Utils.getFormattedDateValue(
            membershipSearchFilterTemp.dateOfBirth
          )
        });
        allPackagePlans = res2.data || [];
      }
      allPackagePlans = allPackagePlans.map((obj, index: number) => ({
        ...obj,
        package: {
          ...obj.package,
          timeGroup: {
            ...obj.package.timeGroup,
            name: getPackageAccessText(obj)
          }
        },
        planArgreementId: index,
        minimumContractValue: `${obj.minimumContract.value} ${
          obj.minimumContract.unit.name
        }`
      }));
      return {
        ...data,
        corporateNameList,
        validPromotion,
        showCheckIcon,
        allPackagePlans,
        salePeople,
        membershipSearchFilter,
        clubsData
      };
    } catch (error) {
      return {
        ...data,
        corporateNameList: [],
        validPromotion,
        showCheckIcon,
        allPackagePlans: [],
        salePeople,
        membershipSearchFilter,
        clubsData
      };
    }
  }
  return data;
};
export const handleSetApplicationType = (
  searchFilter,
  res
): [MembershipSearchFilter, OptionModel[]] => {
  const applicationTypes: OptionModel[] = res.data
    .filter(
      a => a.name.toLowerCase() === GENERAL_APPLICATION_TYPE_DIGITAL_CONTRACT
    )
    .map(e => ({
      value: e.id,
      label: e.name
    }));
  const applicationType = applicationTypes.find(
    e => e.label.toLowerCase() === GENERAL_APPLICATION_TYPE_DIGITAL_CONTRACT
  );
  if (!searchFilter.applicationTypeId && applicationType) {
    searchFilter.applicationTypeId = applicationType.value;
  }
  return [searchFilter, applicationTypes];
};

export const getInitialData = async (
  membershipSearchFilter: MembershipSearchFilter,
  handleSetClub
) => {
  const result = {
    dateOfBirthConfig: new FieldConfig(),
    clubs: [],
    applicationTypes: [],
    searchFilterFinal: new MembershipSearchFilter()
  };
  try {
    const [res1, res2, res3] = await Promise.all([
      getClubs(),
      getApplicationTypes(),
      getFieldsConfiguration()
    ]);
    const [searchFilter, clubs] = await handleSetClub(
      res1,
      membershipSearchFilter
    );
    if (searchFilter) {
      const [searchFilterFinal, applicationTypes] = handleSetApplicationType(
        searchFilter,
        res2
      );
      const dateOfBirthConfig = getFieldConfig(
        res3,
        FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_1
      );
      return {
        ...result,
        dateOfBirthConfig,
        clubs,
        applicationTypes,
        searchFilterFinal
      };
    }
  } catch (err) {
    return result;
  }
  return result;
};

export const getTemporaryData = (
  membershipSearchFilter: MembershipSearchFilter,
  membershipConfiguration: MembershipConfiguration,
  newMembership: INewMembership,
  selectedPackagePlan: IPackagePlanItem | undefined
) => {
  let membershipSearchFilterTemp = membershipSearchFilter;
  if (newMembership && newMembership.membershipSearchFilter) {
    membershipSearchFilterTemp = newMembership.membershipSearchFilter;
  }
  let membershipConfigurationTemp = membershipConfiguration;
  let selectedPackagePlanTemp = selectedPackagePlan;

  if (newMembership && newMembership.selectedPackagePlan) {
    membershipSearchFilterTemp = newMembership.membershipSearchFilter;
    membershipConfigurationTemp = newMembership.membershipConfiguration;
    selectedPackagePlanTemp = newMembership.selectedPackagePlan;
  } else {
    membershipConfigurationTemp.newMemberDOBFormat = GENERAL_DATE_FORMAT;
    membershipConfigurationTemp.newMemberTimeFormat = GENERAL_DATE_FORMAT;
  }
  return {
    membershipSearchFilterTemp,
    membershipConfigurationTemp,
    selectedPackagePlanTemp
  };
};

export const searchPackagePlan = (
  membershipSearchFilter: MembershipSearchFilter,
  handleFilterPackagePlan: () => void
) => {
  const { selectedPackagePlanId } = membershipSearchFilter;
  if (selectedPackagePlanId) {
    handleFilterPackagePlan();
  }
};

export const fakeTimeGroup = (
  p: IPackagePlanItemAddCorporateMember | IAllPackagePlans
) => (p.package.timeGroup ? p.package.timeGroup.name : UNDEFINED_VALUE);

export const makeReciprocalAccesses = (
  packagePlans: IAllPackagePlans[]
): ICounter[] =>
  Object.values(
    packagePlans.reduce((r, e) => {
      const key = `${e.tier.name}`;
      if (!r[key]) r[key] = { name: e.tier.name, count: 1 };
      else r[key].count += 1;
      return r;
    }, {})
  );

export const makeSubsidyTypes = (
  packagePlans: IAllPackagePlansAddCorporateMember[]
): ICounter[] =>
  Object.values(
    packagePlans.reduce((r, e) => {
      const key = `${e.corporateSubsidy.name}`;
      if (!r[key]) r[key] = { name: e.corporateSubsidy.name, count: 1 };
      else r[key].count += 1;
      return r;
    }, {})
  );

export const makeMinimumContracts = (
  packagePlans: IAllPackagePlans[]
): ICounter[] =>
  Object.values(
    packagePlans.reduce((r, e) => {
      const key = `${e.minimumContractValue}`;
      if (!r[key])
        r[key] = {
          name: e.minimumContractValue,
          value: Number(/\d+/.exec(e.minimumContractValue)![0]),
          count: 1
        };
      else r[key].count += 1;
      return r;
    }, {})
  );

export const makeAccesses = (packagePlans: IAllPackagePlans[]): ICounter[] =>
  Object.values(
    packagePlans.reduce((r, e) => {
      const name = e.package.timeGroup ? e.package.timeGroup.name : 'Unknown';
      const key = name;
      if (!r[key]) r[key] = { name: name, count: 1 };
      else r[key].count += 1;
      return r;
    }, {})
  );

export const makePaymentMethods = (
  packagePlans: IAllPackagePlans[]
): ICounter[] => {
  const arr = [];
  packagePlans.forEach(plan => {
    plan.paymentMethodTypes.forEach(paymentMethod => {
      if (paymentMethod.planMethodCode === PRIMARY_PAYMENT_METHOD) {
        const key = `${paymentMethod.planPaymentMethodTypeName}`;
        if (!arr[key])
          arr[key] = {
            name: paymentMethod.planPaymentMethodTypeName,
            count: 1
          };
        else arr[key].count += 1;
      }
    });
  });
  return Object.values(arr);
};

export const onCountFilterData = (
  packagePlans: IAllPackagePlans[] | IAllPackagePlansAddCorporateMember[]
) => {
  const reciprocalAccesses = makeReciprocalAccesses(packagePlans);
  const minimumContracts = makeMinimumContracts(packagePlans);
  const accesses = makeAccesses(packagePlans);
  const paymentMethods = makePaymentMethods(packagePlans);
  // Sort minimumContracts by ascending

  const isValidMinimumContract = (contract: ICounter, params: string) => {
    if (typeof contract.name === 'undefined') {
      return false;
    }
    return contract.name.toLowerCase().indexOf(params) > -1;
  };

  const contractsByDay = minimumContracts.filter(el =>
    isValidMinimumContract(el, 'day')
  );
  const contractsByMonth = minimumContracts.filter(el =>
    isValidMinimumContract(el, 'month')
  );
  const contractsByYear = minimumContracts.filter(el =>
    isValidMinimumContract(el, 'year')
  );
  const sortByDay = _.sortBy(contractsByDay, 'value');
  const sortByMonth = _.sortBy(contractsByMonth, 'value');
  const sortByYear = _.sortBy(contractsByYear, 'value');
  const newContracts = [...sortByDay, ...sortByMonth, ...sortByYear];
  return {
    reciprocalAccesses,
    newContracts,
    accesses,
    paymentMethods
  };
};

export const getMembershipSearchFilterAfterClearFilter = (
  membershipSearchFilter: MembershipSearchFilter
) => {
  const membershipSearchFilterTemp = membershipSearchFilter;
  membershipSearchFilterTemp.reciprocalAccessName = '';
  membershipSearchFilterTemp.minimumContractName = '';
  membershipSearchFilterTemp.accessName = '';
  membershipSearchFilterTemp.paymentMethodName = '';
  return membershipSearchFilterTemp;
};

export const handleFilterPackagePlanData = (
  allPackagePlans: IAllPackagePlans[],
  membershipSearchFilter: MembershipSearchFilter
) => {
  const filterPackagePlans = packagePlan => {
    const {
      reciprocalAccessName,
      minimumContractName,
      accessName,
      paymentMethodName
    } = membershipSearchFilter;
    return (
      (!reciprocalAccessName ||
        packagePlan.tier.name === reciprocalAccessName) &&
      (!minimumContractName ||
        packagePlan.minimumContractValue === minimumContractName) &&
      (!accessName || fakeTimeGroup(packagePlan) === accessName) &&
      (!paymentMethodName ||
        packagePlan.paymentMethodTypes.some(
          paymentMethodType =>
            paymentMethodType.planPaymentMethodTypeName === paymentMethodName
        ))
    );
  };

  const result = allPackagePlans.filter(packagePlan =>
    filterPackagePlans(packagePlan)
  );
  return result;
};

export const handleFilterCorporatePackagePlanData = (
  allPackagePlans: IAllPackagePlansAddCorporateMember[],
  membershipSearchFilter: MembershipSearchFilter
) => {
  const result = allPackagePlans.filter(e => {
    const reciprocalAccessNameCondition =
      !membershipSearchFilter.reciprocalAccessName ||
      e.tier.name === membershipSearchFilter.reciprocalAccessName;
    const minimumContractNameCondition =
      !membershipSearchFilter.minimumContractName ||
      e.minimumContractValue === membershipSearchFilter.minimumContractName;
    const accessNameCondition =
      !membershipSearchFilter.accessName ||
      fakeTimeGroup(e) === membershipSearchFilter.accessName;
    const paymentMethodNameCondition =
      !membershipSearchFilter.paymentMethodName ||
      e.paymentMethod.name === membershipSearchFilter.paymentMethodName;
    const corporateSubsidyCondition =
      !membershipSearchFilter.subsidyTypeName ||
      e.corporateSubsidy.name === membershipSearchFilter.subsidyTypeName;
    return (
      reciprocalAccessNameCondition &&
      minimumContractNameCondition &&
      accessNameCondition &&
      paymentMethodNameCondition &&
      corporateSubsidyCondition
    );
  });
  return result;
};

export const storeDataToRedux = (
  newMembership: INewMembership,
  membershipSearchFilter: MembershipSearchFilter,
  setPackagePlanSearchFilterAction: (
    membershipSearchFilter: MembershipSearchFilter
  ) => void
) => {
  const { membershipSearchFilter: membershipSearchFilter_ } = newMembership;
  membershipSearchFilter_.primarySalePersonId =
    membershipSearchFilter.primarySalePersonId;
  membershipSearchFilter_.primarySalePersonName =
    membershipSearchFilter.primarySalePersonName;
  membershipSearchFilter_.secondarySalePersonId =
    membershipSearchFilter.secondarySalePersonId;
  membershipSearchFilter_.secondarySalePersonName =
    membershipSearchFilter.secondarySalePersonName;
  membershipSearchFilter_.applicationTypeId =
    membershipSearchFilter.applicationTypeId;
  membershipSearchFilter_.startDate = membershipSearchFilter.startDate;
  membershipSearchFilter_.dateOfBirth = membershipSearchFilter.dateOfBirth;
  setPackagePlanSearchFilterAction(membershipSearchFilter_);
};

export const hasChangeControlValue = (
  newMembership: INewMembership,
  membershipSearchFilter: MembershipSearchFilter
) => {
  const { membershipSearchFilter: propMembershipSearchFilter } = newMembership;
  const stateMembershipSearchFilter = membershipSearchFilter;
  if (
    stateMembershipSearchFilter.applicationTypeId !==
      propMembershipSearchFilter.applicationTypeId ||
    stateMembershipSearchFilter.dateOfBirth !==
      propMembershipSearchFilter.dateOfBirth ||
    stateMembershipSearchFilter.startDate !==
      propMembershipSearchFilter.startDate ||
    (stateMembershipSearchFilter.primarySalePersonName !==
      propMembershipSearchFilter.primarySalePersonName &&
      stateMembershipSearchFilter.clubId === propMembershipSearchFilter.clubId)
  ) {
    return true;
  }
  return false;
};

export const handleErrorToNextStep = (
  membershipSearchFilter: MembershipSearchFilter,
  membershipConfiguration: MembershipConfiguration,
  dateOfBirthConfig: FieldConfig
) => {
  let errors: FieldError = {};
  const {
    clubId,
    primarySalePersonId,
    applicationTypeId,
    dateOfBirth,
    startDate
  } = membershipSearchFilter;

  const { newMemberDOBFormat, newMemberTimeFormat } = membershipConfiguration;

  if (!clubId) {
    errors = {
      ...errors,
      clubId: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB_REQUIRED')
    };
  }

  if (!primarySalePersonId) {
    errors = {
      ...errors,
      primarySalePersonId: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_1.SALE_PERSON_REQUIRED'
      )
    };
  }

  if (!applicationTypeId) {
    errors = {
      ...errors,
      applicationTypeId: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_1.APPLICATION_TYPE_REQUIRED'
      )
    };
  }

  if (dateOfBirthConfig.isVisible) {
    if (dateOfBirthConfig.isRequired && !dateOfBirth) {
      errors = {
        ...errors,
        dateOfBirth: 'PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_REQUIRED'
      };
    } else if (
      dateOfBirth &&
      !Utils.IsValidDate(dateOfBirth, newMemberDOBFormat)
    ) {
      errors = {
        ...errors,
        dateOfBirth: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_INVALID')
      };
    } else if (dateOfBirth && !Utils.IsValidDateOfBirth(dateOfBirth)) {
      errors = {
        ...errors,
        dateOfBirth: t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_CAN_NOT_BE_FUTURE_DATE'
        )
      };
    }
  }

  if (!startDate) {
    errors.startDate = t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_REQUIRED');
  } else if (!Utils.IsValidDate(startDate, newMemberTimeFormat)) {
    errors.startDate = t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_INVALID');
  } else if (!Utils.IsValidStartDate(startDate)) {
    errors = {
      ...errors,
      startDate: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_CAN_NOT_BE_PAST_DATE'
      )
    };
  }
  return errors;
};

export const getPromotionId = (validPromotion: IValidPromotion | null) => {
  if (validPromotion && validPromotion.id) {
    return validPromotion.id;
  }
  return 0;
};

export const cloneMembershipSearchFilter = (
  membershipSearchFilterObj: MembershipSearchFilter
) => {
  return { ...membershipSearchFilterObj };
};

export const handleChangeClub = async (
  membershipSearchFilterTemp: MembershipSearchFilter,
  validPromotion: IValidPromotion | null,
  clubs: OptionModel[],
  secondarySalePeople: OptionModel[],
  newValue
) => {
  const startDate = Utils.DisplayDateFormat(
    membershipSearchFilterTemp.startDate,
    GENERAL_DATABASE_DATE_FORMAT
  );
  const dob = membershipSearchFilterTemp.dateOfBirth
    ? Utils.DisplayDateFormat(
        membershipSearchFilterTemp.dateOfBirth,
        GENERAL_DATABASE_DATE_FORMAT
      )
    : '';
  const [res1, res2] = await Promise.all([
    getSalePeople({ clubId: newValue }),
    getPackagePlans({
      clubId: newValue,
      startDate,
      promoId: getPromotionId(validPromotion),
      dob
    })
  ]);
  const salePersonList: OptionModel[] = res1.data.map(e => ({
    value: e.id,
    label: e.name
  }));
  const clubList = clubs.filter(cl => cl.value === newValue);
  membershipSearchFilterTemp.clubName = condition(
    clubList.length > 0,
    clubList[0].label,
    ''
  );
  if (salePersonList.length > 0) {
    membershipSearchFilterTemp.primarySalePersonId = condition(
      salePersonList[0],
      salePersonList[0].value,
      ''
    );
    membershipSearchFilterTemp.primarySalePersonName = condition(
      salePersonList[0],
      salePersonList[0].label,
      ''
    );
    membershipSearchFilterTemp.secondarySalePersonId = condition(
      secondarySalePeople[0],
      secondarySalePeople[0].value,
      ''
    );
    membershipSearchFilterTemp.secondarySalePersonName = condition(
      secondarySalePeople[0],
      secondarySalePeople[0].label,
      ''
    );
  }
  membershipSearchFilterTemp.reciprocalAccessName = '';
  membershipSearchFilterTemp.minimumContractName = '';
  membershipSearchFilterTemp.accessName = '';
  membershipSearchFilterTemp.paymentMethodName = '';

  let allPackagePlans = res2.data;
  allPackagePlans = allPackagePlans.map(obj => ({
    ...obj,
    package: {
      ...obj.package,
      timeGroup: {
        ...obj.package.timeGroup,
        name: getPackageAccessText(obj)
      }
    },
    minimumContractValue: `${obj.minimumContract.value} ${
      obj.minimumContract.unit.name
    }`
  }));
  return {
    allPackagePlans,
    salePersonList,
    membershipSearchFilterTmp: membershipSearchFilterTemp
  };
};

export const handleChangeClubCorporateMember = async (
  membershipSearchFilterTemp: MembershipSearchFilter,
  validPromotion: IValidPromotion | null,
  clubs: OptionModel[],
  secondarySalePeople: OptionModel[],
  newValue
) => {
  const startDate = Utils.DisplayDateFormat(
    membershipSearchFilterTemp.startDate,
    GENERAL_DATABASE_DATE_FORMAT
  );
  const [res1, res3] = await Promise.all([
    getSalePeople({ clubId: newValue }),
    getCorporateNames({ clubId: newValue })
  ]);
  const salePersonList = Utils.transformOptions<OptionModel>(
    res1.data,
    'name',
    'id'
  );
  const rawCorporateNames = Utils.alphabetSort(
    res3.filter(c => c.status === 'AC'),
    'name'
  );
  const corporateNameList = Utils.transformOptions<OptionModel>(
    rawCorporateNames,
    'name',
    'corporatePartyId'
  );
  const clubList = clubs.filter(cl => cl.value === newValue);
  membershipSearchFilterTemp.clubName = condition(
    clubList.length > 0,
    clubList[0].label,
    ''
  );

  if (salePersonList.length > 0) {
    membershipSearchFilterTemp.primarySalePersonId = condition(
      salePersonList[0],
      salePersonList[0].value,
      ''
    );
    membershipSearchFilterTemp.primarySalePersonName = condition(
      salePersonList[0],
      salePersonList[0].label,
      ''
    );
    membershipSearchFilterTemp.secondarySalePersonId = condition(
      secondarySalePeople[0],
      secondarySalePeople[0].value,
      ''
    );
    membershipSearchFilterTemp.secondarySalePersonName = condition(
      secondarySalePeople[0],
      secondarySalePeople[0].label,
      ''
    );
  }
  membershipSearchFilterTemp.reciprocalAccessName = '';
  membershipSearchFilterTemp.minimumContractName = '';
  membershipSearchFilterTemp.accessName = '';
  membershipSearchFilterTemp.paymentMethodName = '';
  let allPackagePlans: any = [];
  if (Number(membershipSearchFilterTemp.corporateId)) {
    const res2 = await getPackagePlansForCorporateMember({
      clubId: newValue,
      startDate,
      promoId: getPromotionId(validPromotion),
      corporateId: membershipSearchFilterTemp.corporateId,
      productFilterType: ProductFilterType.CorporatePlansOnly,
      dob: Utils.getFormattedDateValue(membershipSearchFilterTemp.dateOfBirth)
    });
    allPackagePlans = res2.data;
    allPackagePlans = allPackagePlans.map((obj, index: number) => ({
      ...obj,
      package: {
        ...obj.package,
        timeGroup: {
          ...obj.package.timeGroup,
          name: getPackageAccessText(obj)
        }
      },
      planArgreementId: index,
      minimumContractValue: `${obj.minimumContract.value} ${
        obj.minimumContract.unit.name
      }`
    }));
  }
  return {
    membershipSearchFilterTmp: membershipSearchFilterTemp,
    allPackagePlans,
    corporateNameList,
    salePersonList
  };
};

export const handleChangePrimarySalePerson = (
  membershipSearchFilterTemp: MembershipSearchFilter,
  salePeople: OptionModel[],
  newValue
) => {
  const primarySalePersonName = salePeople.filter(s => s.value === newValue);

  membershipSearchFilterTemp.primarySalePersonId = condition(
    primarySalePersonName.length > 0,
    primarySalePersonName[0].value,
    ''
  );
  membershipSearchFilterTemp.primarySalePersonName = condition(
    primarySalePersonName.length > 0,
    primarySalePersonName[0].label,
    ''
  );
  return membershipSearchFilterTemp;
};

export const handleChangeSecondarySalePeople = (
  membershipSearchFilterTemp: MembershipSearchFilter,
  secondarySalePeople: OptionModel[],
  newValue
) => {
  const secondarySalePersonName = secondarySalePeople.filter(
    s => s.value === newValue
  );
  membershipSearchFilterTemp.secondarySalePersonId = condition(
    secondarySalePersonName.length > 0,
    secondarySalePersonName[0].value,
    ''
  );
  membershipSearchFilterTemp.secondarySalePersonName = condition(
    secondarySalePersonName.length > 0,
    secondarySalePersonName[0].label,
    ''
  );
  return membershipSearchFilterTemp;
};

export const handleChangeApplicationTypeId = (
  membershipSearchFilterTemp: MembershipSearchFilter,
  applicationTypes: OptionModel[],
  newValue
) => {
  const applicationType = applicationTypes.filter(a => a.value === newValue);
  membershipSearchFilterTemp.applicationTypeId = condition(
    applicationType.length > 0,
    applicationType[0].label,
    ''
  );
  return membershipSearchFilterTemp;
};

export const handleChangeCorporateName = async (
  corporateNames: OptionModel[],
  membershipSearchFilterTemp: MembershipSearchFilter,
  validPromotion: IValidPromotion | null,
  newValue
) => {
  const corporateName = corporateNames.filter(c => c.value === newValue);
  membershipSearchFilterTemp.corporateId = condition(
    corporateName.length > 0,
    corporateName[0].value,
    ''
  );

  const startDate = Utils.DisplayDateFormat(
    membershipSearchFilterTemp.startDate,
    GENERAL_DATABASE_DATE_FORMAT
  );
  if (!Number(newValue)) {
    return {
      allPackagePlans: [],
      membershipSearchFilterTmp: membershipSearchFilterTemp
    };
  }
  let allPackagePlans: any = [];
  if (Number(membershipSearchFilterTemp.corporateId)) {
    const res2 = await getPackagePlansForCorporateMember({
      clubId: membershipSearchFilterTemp.clubId,
      startDate,
      promoId: getPromotionId(validPromotion),
      corporateId: newValue,
      productFilterType: ProductFilterType.CorporatePlansOnly,
      dob: Utils.getFormattedDateValue(membershipSearchFilterTemp.dateOfBirth)
    });
    allPackagePlans = res2.data;
    allPackagePlans = allPackagePlans.map((obj, index: number) => ({
      ...obj,
      package: {
        ...obj.package,
        timeGroup: {
          ...obj.package.timeGroup,
          name: getPackageAccessText(obj)
        }
      },
      planArgreementId: index,
      minimumContractValue: `${obj.minimumContract.value} ${
        obj.minimumContract.unit.name
      }`
    }));
  }
  return {
    allPackagePlans,
    membershipSearchFilterTmp: membershipSearchFilterTemp
  };
};

export const getErrorAfterHandle = async (
  error: any,
  startDate: string,
  getPackagePlanList,
  errors: FieldError
) => {
  const errorResponse = error.response ? error.response.data : error.response;
  if (error.response && error.response.status === 404) {
    await getPackagePlanList(startDate, 0);
    const err = {
      ...errors,
      promotionCode:
        errorResponse.message.toUpperCase() === INVALID_PROMO
          ? 'PAGE.MEMBERSHIPS.CREATE.STEP_1.PROMOTION_CODE_INVALID'
          : ''
    };
    return err;
  }
  return errors;
};

export const getListPackagePlansCorporateMember = async (
  membershipSearchFilter: MembershipSearchFilter,
  startDate: string,
  promoId: number,
  dob?: string,
  currentCorporate?: ICorporateOptionModel | undefined
) => {
  const corporateId = currentCorporate
    ? getCorporateID(currentCorporate)
    : membershipSearchFilter.corporateId;
  if (corporateId) {
    const res = await getPackagePlansForCorporateMember({
      clubId: membershipSearchFilter.clubId,
      startDate,
      promoId,
      corporateId,
      productFilterType: ProductFilterType.CorporatePlansOnly,
      dob
    });
    let allPackagePlans = res.data;
    allPackagePlans = allPackagePlans.map((obj, index: number) => ({
      ...obj,
      package: {
        ...obj.package,
        timeGroup: {
          ...obj.package.timeGroup,
          name: getPackageAccessText(obj)
        }
      },
      planArgreementId: index,
      minimumContractValue: `${obj.minimumContract.value} ${
        obj.minimumContract.unit.name
      }`
    }));
    return allPackagePlans;
  }
  return [];
};

export const getListPackagePlans = async (
  membershipSearchFilter: MembershipSearchFilter,
  startDate: string,
  promoId: number,
  dob: string
) => {
  const res = await getPackagePlans({
    clubId: membershipSearchFilter.clubId,
    startDate,
    promoId,
    dob
  });
  let allPackagePlans = res.data || [];
  allPackagePlans = allPackagePlans.map(obj => ({
    ...obj,
    package: {
      ...obj.package,
      timeGroup: {
        ...obj.package.timeGroup,
        name: getPackageAccessText(obj)
      }
    },
    minimumContractValue: `${obj.minimumContract.value} ${
      obj.minimumContract.unit.name
    }`
  }));
  return allPackagePlans;
};

export const handlePromoCode = async (
  isCorporate: boolean,
  promotionCode: string,
  membershipSearchFilter: MembershipSearchFilter,
  getPackagePlanList,
  handleError
) => {
  const startDate = Utils.DisplayDateFormat(
    membershipSearchFilter.startDate,
    GENERAL_DATABASE_DATE_FORMAT
  );
  const corporateId = membershipSearchFilter.corporateId.toString();
  if (promotionCode) {
    const validPromo = await checkAvailablePromotion({
      promotionCode,
      isOnline: 'false',
      startDate,
      handleError,
      corporateId
    });
    if (validPromo && validPromo.isCorporate === isCorporate) {
      await getPackagePlanList(startDate, validPromo.id);
      return {
        showCheckIcon: true,
        validPromotion: {
          id: validPromo.id,
          code: validPromo.code,
          categoryId: validPromo.category.id,
          categoryName: validPromo.category.name
        },
        membershipSearchFilterTemp: {
          ...membershipSearchFilter,
          promotion: {
            ...membershipSearchFilter.promotion,
            categoryId: Number(validPromo.category.id),
            categoryName: validPromo.category.name,
            id: validPromo.id
          }
        },
        error: ''
      };
    }
    return {
      validPromotion: null,
      showCheckIcon: false,
      membershipSearchFilterTemp: membershipSearchFilter,
      error: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PROMOTION_CODE_INVALID')
    };
  }
  await getPackagePlanList(startDate, 0);
  return {
    validPromotion: null,
    showCheckIcon: false,
    membershipSearchFilterTemp: {
      ...membershipSearchFilter,
      promotion: new PromotionSearchFilter()
    },
    error: ''
  };
};

export const handleChangeDate = async (
  date,
  name,
  membershipSearchFilter: MembershipSearchFilter
) => {
  const membershipSearchFilterTemp = cloneMembershipSearchFilter(
    membershipSearchFilter
  );
  membershipSearchFilterTemp[name] = date
    ? new Date(Utils.DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT))
    : null;
  return membershipSearchFilterTemp;
};

export const handleOnPackagePlanSelected = (
  packagePlanId: number,
  membershipSearchFilter: MembershipSearchFilter,
  membershipConfiguration: MembershipConfiguration,
  packagePlans: IPackagePlanItem[],
  validPromotion: IValidPromotion | null
) => {
  const membershipSearchFilterTemp = membershipSearchFilter;
  const membershipConfigurationTemp = membershipConfiguration;
  const packagePlan = packagePlans.find(
    pl => pl.originalPlanId === packagePlanId
  );
  if (validPromotion && validPromotion.id) {
    membershipSearchFilterTemp.promotion.id = validPromotion.id;
    membershipSearchFilterTemp.promotion.categoryId = validPromotion.categoryId;
    membershipSearchFilter.promotion.categoryName = validPromotion.categoryName;
  }
  if (
    membershipSearchFilter.secondarySalePersonId === Number(defaultOption.value)
  ) {
    membershipSearchFilter.secondarySalePersonId = 0;
    membershipSearchFilter.secondarySalePersonName = '';
  }
  if (packagePlan) {
    membershipSearchFilterTemp.selectedPackagePlanId = packagePlanId;
    if (membershipConfigurationTemp.stepLatest < 2) {
      membershipConfigurationTemp.stepLatest = 2;
    }
    membershipConfigurationTemp.stepIndex = 2;
  }
  return {
    membershipConfigurationTemp,
    membershipSearchFilterTemp,
    packagePlan
  };
};

export const onPackagePlanSelectedCorporateMember = (
  membershipSearchFilter: MembershipSearchFilter,
  membershipConfiguration: MembershipConfiguration,
  packagePlans: IPackagePlanItemAddCorporateMember[],
  packagePlanId: number,
  validPromotion: IValidPromotion | null,
  agreementPartyID: number,
  currentCorporate?: ICorporateOptionModel
) => {
  const membershipSearchFilterTemp = membershipSearchFilter;
  const membershipConfigurationTemp = membershipConfiguration;

  const packagePlan = packagePlans.find(
    pl =>
      pl.originalPlanId === packagePlanId &&
      pl.corporateAgreements.some(
        corporateAgreement =>
          corporateAgreement.agreementPartyID === agreementPartyID
      )
  );

  if (validPromotion && validPromotion.id) {
    membershipSearchFilterTemp.promotion.id = validPromotion.id;
    membershipSearchFilterTemp.promotion.categoryId = validPromotion.categoryId;
    membershipSearchFilter.promotion.categoryName = validPromotion.categoryName;
  }
  if (
    membershipSearchFilter.secondarySalePersonId === Number(defaultOption.value)
  ) {
    membershipSearchFilter.secondarySalePersonId = 0;
    membershipSearchFilter.secondarySalePersonName = '';
  }

  if (packagePlan) {
    const id = currentCorporate
      ? getCorporateID(currentCorporate)
      : membershipSearchFilterTemp.corporateId;

    const activeCorporateAgreement = packagePlan.corporateAgreements.find(
      ca => ca.organizationPartyID === id
    );
    membershipSearchFilterTemp.agreementPartyId = activeCorporateAgreement
      ? activeCorporateAgreement.agreementPartyID
      : 0;

    membershipSearchFilterTemp.selectedPackagePlanId = packagePlanId;

    if (membershipConfigurationTemp.stepLatest < 2) {
      membershipConfigurationTemp.stepLatest = 2;
    }
    membershipConfigurationTemp.stepIndex = 2;
  }
  return {
    membershipConfigurationTemp,
    membershipSearchFilterTemp,
    packagePlan
  };
};

export const findCurrentCorporate = (
  corporateList: ICorporateOptionModel[],
  value: string
) => {
  return corporateList.find(
    (item: ICorporateOptionModel) => item.value.toString() === value.toString()
  );
};

export const getCorporateID = (
  currentCorporate: ICorporateOptionModel | undefined
) => {
  return currentCorporate ? currentCorporate.id : 0;
};

export const getCorporateGuestID = (
  currentCorporate: ICorporateOptionModel | undefined
) => {
  return currentCorporate ? Number(currentCorporate.value) : 0;
};

export const handleOutSideClick = (
  e,
  filterRef: React.MutableRefObject<HTMLDivElement | undefined | null>,
  filterButtonRef: React.MutableRefObject<HTMLButtonElement | undefined | null>
) => {
  if (!filterButtonRef.current || !filterRef.current) {
    return false;
  }

  if (
    !filterRef.current.contains(e.target) &&
    !filterButtonRef.current.contains(e.target)
  ) {
    lockScrolling('member', false);
    return true;
  }
  return false;
};

export const goToNextStep = (
  stepIndex,
  newMembership: INewMembership,
  membershipSearchFilter: MembershipSearchFilter,
  isReadyToNextStep: () => boolean,
  handleSaveAndLeave: (stepIndex: number, isSave?: boolean) => void
) => {
  const {
    membershipConfiguration,
    membershipConfiguration: { stepLatest }
  } = newMembership;

  if (
    stepLatest > 1 &&
    hasChangeControlValue(newMembership, membershipSearchFilter)
  ) {
    if (!isReadyToNextStep()) return;
    const content = (
      <React.Fragment>
        <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page" />
        <p className="content-center add-member-page">
          {t('MSG.UNSAVED_CHANGES')}
        </p>
        <div className="modalSaas__row--btn">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => handleSaveAndLeave(stepIndex, true)}
          >
            {t('BUTTON.SAVE_AND_LEAVE')}
          </button>
          <button
            className="btn btn-dark text-uppercase"
            onClick={() => handleSaveAndLeave(stepIndex)}
          >
            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
          </button>
        </div>
      </React.Fragment>
    );
    observer.publish('openDialogEvent', {
      content: content
    });
  } else {
    membershipConfiguration.stepIndex = stepIndex;
  }
  return membershipConfiguration;
};

export const toggleShowFilter = (e, isShowFilter: boolean) => {
  e.preventDefault();
  lockScrolling('member', !isShowFilter);
  return !isShowFilter;
};

export const handleSetDataConvertGuestToCorporateMember = async (
  membershipSearchFilterTemp: MembershipSearchFilter,
  promotionId: number | undefined,
  startDate: string
) => {
  let data = {
    allPackagePlans: [],
    salePeople: [],
    corporateNameList: [],
    secondarySalePeople: [],
    membershipSearchFilterTmp: membershipSearchFilterTemp,
    currentCorporate: null
  };
  try {
    const [res1, res3] = await Promise.all([
      getSalePeople({ clubId: membershipSearchFilterTemp.clubId }),
      getCorporateNames({ clubId: membershipSearchFilterTemp.clubId })
    ]);
    const salePeople: OptionModel[] = Utils.transformOptions<OptionModel>(
      res1.data,
      'name',
      'id'
    );
    const rawCorporateNames = Utils.alphabetSort(
      res3.filter(c => c.status === 'AC'),
      'name'
    );
    const corporateNameList: ICorporateOptionModel[] = rawCorporateNames.map(
      (item: IGetCorporateNameResponse) => ({
        label: item.name,
        value: item.corporateIdentifier,
        id: item.corporatePartyId
      })
    );
    let currentCorporate = findCurrentCorporate(
      corporateNameList,
      membershipSearchFilterTemp.corporateId
        ? membershipSearchFilterTemp.corporateId.toString()
        : ''
    );
    if (
      !membershipSearchFilterTemp.primarySalePersonId &&
      salePeople.length > 0
    ) {
      membershipSearchFilterTemp.primarySalePersonId = Number(
        salePeople[0].value
      );
      membershipSearchFilterTemp.primarySalePersonName = salePeople[0].label;
    }
    let allPackagePlans = [];
    if (Number(membershipSearchFilterTemp.corporateId)) {
      const res2 = await getPackagePlansForCorporateMember({
        clubId: membershipSearchFilterTemp.clubId,
        startDate,
        promoId: promotionId,
        corporateId: currentCorporate
          ? currentCorporate.id
          : membershipSearchFilterTemp.corporateId,
        productFilterType: ProductFilterType.CorporatePlansOnly,
        dob: Utils.getFormattedDateValue(membershipSearchFilterTemp.dateOfBirth)
      });
      let packagePlans = res2.data || [];
      allPackagePlans = packagePlans.map((obj, index: number) => ({
        ...obj,
        planArgreementId: index,
        package: {
          ...obj.package,
          timeGroup: {
            ...obj.package.timeGroup,
            name: getPackageAccessText(obj)
          }
        },
        minimumContractValue: `${obj.minimumContract.value} ${
          obj.minimumContract.unit.name
        }`
      }));
    }
    return {
      ...data,
      allPackagePlans,
      salePeople,
      corporateNameList: [{ id: 0, ...defaultOption }, ...corporateNameList],
      secondarySalePeople: [defaultOption, ...salePeople],
      membershipSearchFilterTmp: membershipSearchFilterTemp,
      currentCorporate
    };
  } catch (error) {
    return data;
  }
};

export const getPackagePlanForConvert = (
  packagePlans: (IPackagePlanItem | IPackagePlanItemAddCorporateMember)[]
) => {
  return packagePlans.filter(plan => !plan.isRestricted);
};

export const getInitialValue = (
  membershipSearchFilter: MembershipSearchFilter
) => ({
  promotionCode: membershipSearchFilter.promotion.code,
  startDate: Utils.getFormattedDateValue(membershipSearchFilter.startDate),
  dateOfBirth: Utils.getFormattedDateValue(membershipSearchFilter.dateOfBirth)
});
