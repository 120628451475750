import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import PersonalDetails from 'components/Membership/PersonalDetails';
import ContactDetails from 'components/Membership/ContactDetails';
import AddressDetails from 'components/Membership/AddressDetails';
import ContactedDetails from 'components/Membership/ContactedDetails';
import EmergencyContact from 'components/Membership/EmergencyContact';
import HealthQuestion from 'components/Membership/HealthQuestion';
import OtherFields from 'components/Membership/OtherFields';
import DuplicateMembers from 'components/Membership/DuplicateMembers';
import { useTranslation } from 'react-i18next';
import { ITemplate } from './interface';
import Loading from 'components/Loading';

const Template: React.FC<ITemplate> = ({
  isLoading,
  isServiceError,
  openDropdown,
  personalSection,
  otherFields,
  contactSection,
  emergencyContact,
  contactDetails,
  contactedDetails,
  addressSection,
  contactedSection,
  emergencySection,
  healthQuestionSection,
  otherFieldsSection,
  duplicateMembers,
  membership,
  conmanConfig,
  emailCfgConman,
  contactedDetailRef,
  otherFieldsRef,
  personalDetails,
  addressDetails,
  healthQuestions,
  goToStep,
  onDuplicateMemberSectionClose,
  handleOpenDropdown,
  handleNext,
  onCancel,
  handlePrevious,
  personalRef,
  contactDetailRef,
  addressDetailRef,
  emergencyContactRef,
  healthQuestionRef,
  otherFieldsConfigList,
  setPersonalDetailsAction
}) => {
  const {
    membershipSearchFilter,
    membershipConfiguration,
    selectedPackagePlan,
    selectedAddons,
    selectedPromotions
  } = membership;

  const { t } = useTranslation();
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />

          <div className="content select-add-on personal">
            {isLoading ? (
              <Loading />
            ) : (
              <React.Fragment>
                {isServiceError && (
                  <span className="text-danger input-error">
                    {t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR')}
                  </span>
                )}

                {duplicateMembers && duplicateMembers.length !== 0 ? (
                  <DuplicateMembers
                    duplicateMembers={duplicateMembers}
                    onClose={onDuplicateMemberSectionClose}
                  />
                ) : (
                  <div className="select-add-on-main">
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.PERSONAL_DETAILS'
                      )}
                      content={
                        <PersonalDetails
                          titles={personalSection.titles}
                          genders={personalSection.genders}
                          languages={personalSection.languages}
                          personalDetails={personalDetails}
                          personalDetailsFieldConfigs={
                            personalSection.personalDetailsFieldConfigs
                          }
                          personalRef={personalRef}
                          membershipConfiguration={membershipConfiguration}
                          setPersonalDetailsAction={setPersonalDetailsAction}
                        />
                      }
                      isOpen={openDropdown['personalDetails']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('personalDetails')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.CONTACT_DETAILS'
                      )}
                      content={
                        <ContactDetails
                          overrideEmailOptions={
                            contactSection.overrideEmailOptions
                          }
                          contactedDetailRef={contactedDetailRef}
                          contactDetailRef={contactDetailRef}
                          contactDetails={contactDetails}
                          contactDetailsFieldConfigs={
                            contactSection.contactDetailsFieldConfigs
                          }
                          emailCfgConman={emailCfgConman}
                        />
                      }
                      isOpen={openDropdown['contactDetails']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('contactDetails')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.ADDRESS_DETAILS'
                      )}
                      content={
                        <AddressDetails
                          countries={addressSection.countries}
                          addressDetails={addressDetails}
                          addressDetailsFieldConfigs={
                            addressSection.addressDetailsFieldConfigs
                          }
                          addressDetailRef={addressDetailRef}
                        />
                      }
                      isOpen={openDropdown['addressDetails']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('addressDetails')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.CONTACTED_DETAILS'
                      )}
                      content={
                        <ContactedDetails
                          contactDetailRef={contactDetailRef}
                          marketingSources={contactedSection.marketingSources}
                          channelCodes={contactedSection.channelCodes}
                          marketingCampaigns={
                            contactedSection.marketingCampaigns
                          }
                          contactedDetails={contactedDetails}
                          contactedDetailsFieldConfigs={
                            contactedSection.contactedDetailsFieldConfigs
                          }
                          contactedDetailRef={contactedDetailRef}
                          conmanConfig={conmanConfig}
                        />
                      }
                      isOpen={openDropdown['contactedDetails']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('contactedDetails')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT'
                      )}
                      content={
                        <EmergencyContact
                          emergencyContactRef={emergencyContactRef}
                          emergencyContact={emergencyContact}
                          guardianIdentityTypeIdList={
                            emergencySection.guardianIdentityTypeIdList
                          }
                          conmanConfig={conmanConfig}
                        />
                      }
                      isOpen={openDropdown['emergencyContact']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('emergencyContact')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE'
                      )}
                      content={
                        <HealthQuestion
                          questions={healthQuestionSection.questions}
                          healthQuestions={healthQuestions}
                          healthQuestionRef={healthQuestionRef}
                        />
                      }
                      isOpen={openDropdown['healthQuestion']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('healthQuestion')
                      }
                    />
                    <CollapseButton
                      className="collapse-promotion"
                      title={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OTHER_FIELDS')}
                      content={
                        <OtherFields
                          recurringCardTypes={
                            otherFieldsSection.recurringCardTypes
                          }
                          otherFields={otherFields}
                          otherFieldsRef={otherFieldsRef}
                          otherFieldsConfigList={otherFieldsConfigList}
                        />
                      }
                      isOpen={openDropdown['otherFields']}
                      maxHeight="1200px"
                      openCollapsedContent={() =>
                        handleOpenDropdown('otherFields')
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )}

            {selectedPackagePlan ? (
              <ProductSummary
                productSummary={selectedPackagePlan}
                membershipSearchFilter={membershipSearchFilter}
                membershipConfiguration={membershipConfiguration}
                addons={selectedAddons}
                promotions={selectedPromotions}
                handlePrevious={() => handlePrevious()}
                handleNext={() => handleNext()}
                handleCancel={onCancel}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Template);
