import React from 'react';
import { DataDescription, IMembershipDetails } from '../interface';
import { useTranslation } from 'react-i18next';
import * as Utils from 'util/ControlUtils';
import DescriptionContent from '../DescriptionContent';

const MembershipDetails: React.FC<IMembershipDetails> = ({
  membership,
  minContractEndDate
}) => {
  const {
    membershipSearchFilter,
    membershipConfiguration,
    selectedPackagePlan,
    selectedAddons
  } = membership;
  const { t } = useTranslation();

  if (!selectedPackagePlan) {
    return <></>;
  }

  const data = [
    {
      isVisible: true,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE'),
      data: Utils.getValueData(selectedPackagePlan.package.name)
    },
    {
      isVisible: true,
      fieldLabel: t('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
      data: Utils.getValueData(selectedPackagePlan.name)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_SALE_PERSON'),
      data: Utils.getValueData(membershipSearchFilter.primarySalePersonName)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SECONDARY_SALE_PERSON'),
      data: Utils.getValueData(membershipSearchFilter.secondarySalePersonName)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.JOIN_DATE'),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(
          new Date(),
          membershipConfiguration.newMemberTimeFormat
        )
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.START_DATE'),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(
          membershipSearchFilter.startDate,
          membershipConfiguration.newMemberTimeFormat
        )
      )
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.MIN_CONTRACT_PERIOD_END_DATE'
      ),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(
          minContractEndDate,
          membershipConfiguration.newMemberTimeFormat
        )
      )
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE_ELIGIBILITY_REQUIRED'
      ),
      data: t('BUTTON.NO')
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ELIGIBILITY_PROVIDED'),
      data: t('BUTTON.NO')
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MEMBER_ADD-ONS'),
      data: Utils.getValueData(
        selectedAddons
          .reduce<string[]>((acc, cur) => [...acc, cur.name], [])
          .join(', ')
      )
    }
  ] as DataDescription[];

  return (
    <DescriptionContent
      title={t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP'
      )}
      data={data.map(data => ({
        ...data,
        isVisible: true
      }))}
    />
  );
};

export default MembershipDetails;
