import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Error404 from 'components/Error404';
import CustomModal from 'components/Modal';
import observer from 'util/Observer';
import HomePage from './HomePage';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { useTranslation } from 'react-i18next';
import RouteLoader from './RouteLoader';

interface Props {
  isFullConnected: boolean;
}

const AppRoute: React.FC<Props> = ({ isFullConnected }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [titleDialog, setTitleDialog] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [classHolder, setClassHolder] = useState('');
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [forceChangeRoute, setForceChangeRoute] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();
  const { location } = history;
  const { t } = useTranslation();

  useEffect(() => {
    observer.subscribe('flagUnsavedChangeEvent', data => {
      setSomethingChanged(data);
    });

    observer.subscribe('openDialogEvent', (data: Record<string, string>) => {
      if (data) {
        setMessageDialog(data.message);
        setOpenDialog(true);
        setTitleDialog(data.title);
        setModalContent(data.content);
        setClassHolder(data.classHolder);
      }
    });

    observer.subscribe('closeDialogEvent', (data: boolean) => {
      if (data) {
        setOpenDialog(false);
      }
    });
  }, []);

  useEffect(() => {
    history.block(e => {
      const currentLocale = location.pathname;
      const currentState = location.state;
      const nextLocale = e.pathname;

      if (localStorage.isRequiredChangePassword === '1') {
        // A notification appears in case user accidently navigates out of change password page
        Swal.fire({
          ...DefaultConstants.SWAL_COMMON_STYLE,
          text: t(
            'PAGE.CHANGE_FORGOT_PASSWORD.FIRST_TIME_LOGIN_CHANGE_PASSWORD'
          ),
          type: 'warning',
          confirmButtonText: t('COMMON.OK')
        });
        return false;
      }
      if (
        currentLocale !== nextLocale &&
        somethingChanged &&
        !forceChangeRoute
      ) {
        Swal.fire({
          ...DefaultConstants.SWAL_COMMON_STYLE,
          text: t('MSG.UNSAVED_CHANGES'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: t('BUTTON.STAY_ON_THIS_PAGE'),
          cancelButtonText: t('BUTTON.LEAVE_THIS_PAGE')
        }).then(result => {
          if (
            result.hasOwnProperty('dismiss') &&
            result.dismiss === Swal.DismissReason.cancel
          ) {
            setForceChangeRoute(true);
            history.push(nextLocale, currentState);
          }
        });
        return false;
      } else if (currentLocale !== nextLocale) {
        setForceChangeRoute(false);
        setSomethingChanged(false);
      }
    });

    window.onbeforeunload = () => {
      if (somethingChanged) {
        return t('COMMON.CHANGE_NOT_BE_SAVED');
      } else {
        return null;
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      observer.unsubscribe('flagUnsavedChangeEvent');
      observer.unsubscribe('openDialogEvent');
      observer.unsubscribe('closeDialogEvent');
    };
  }, []);

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <div className="page-container">
        <main className="main-content">
          <Switch>
            <Route path={`${match.url}`} exact render={() => <HomePage />} />
            {RouteLoader.map(({ url, Component }) => {
              return (
                <Route
                  key={url}
                  path={`${match.url}${url}`}
                  exact={true}
                  render={props => <Component />}
                />
              );
            })}
            {isFullConnected && <Route component={Error404} />}
          </Switch>
          <CustomModal
            classHolder={classHolder}
            isOpen={openDialog}
            title={titleDialog}
            closeModal={closeDialog}
            content={
              <React.Fragment>
                <p>{messageDialog}</p>
                {modalContent}
              </React.Fragment>
            }
          />
        </main>
      </div>
    </React.Fragment>
  );
};

export default AppRoute;
