import { combineReducers, Reducer } from 'redux';
import {
  connectRouter,
  LocationChangeAction,
  RouterState
} from 'connected-react-router';
import NewMembership, { INewMembership } from './NewMembership';
import Payment, { IPayment } from './Payment';
import Conman, { IConman } from './Conman';
import NewCorporateMembership, {
  INewCorporateMembership
} from './NewCorporateMembership';
import ConvertGuestToMember, {
  IConvertGuestToMember
} from './ConvertGuestToMember';
import ConvertGuestToGuestPass, {
  IConvertGuestToGuestPass
} from './ConvertGuestToGuestPass';
import ConvertGuestToCorporateMember, {
  IConvertGuestToCorporateMember
} from './ConvertGuestToCorporateMember';

export interface RootState {
  router: Reducer<RouterState, LocationChangeAction>;
  conman: IConman;
  payment: IPayment;
  newMembership: INewMembership;
  newCorporateMembership: INewCorporateMembership;
  convertGuestToMember: IConvertGuestToMember;
  convertGuestToGuestPass: IConvertGuestToGuestPass;
  convertGuestToCorporateMember: IConvertGuestToCorporateMember;
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    conman: Conman,
    payment: Payment,
    newMembership: NewMembership,
    newCorporateMembership: NewCorporateMembership,
    convertGuestToMember: ConvertGuestToMember,
    convertGuestToGuestPass: ConvertGuestToGuestPass,
    convertGuestToCorporateMember: ConvertGuestToCorporateMember
  });
