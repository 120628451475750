import React from 'react';

interface ILabel {
  label?: string;
  labelClass?: string;
  isRequire?: boolean;
}
const Label: React.FC<ILabel> = ({ label, labelClass, isRequire }) => {
  return (
    (label && (
      <label className={labelClass}>
        {label}
        {isRequire && <span className="text-primary bold">&nbsp;*</span>}
      </label>
    )) || <></>
  );
};

export default Label;
