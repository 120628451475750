import { UNITS } from 'constants/Constants';
import { FULL_ACCESS } from 'constants/DefaultConstants';
import { IPackagePlanItem } from 'interfaces/Membership';

const condition = (valueCompared: any, value1: any, value2: any = null) => {
  return valueCompared ? value1 : value2;
};

const delay = (fn: () => void, ms: number) =>
  new Promise(resolve => setTimeout(() => resolve(fn()), ms));

const retry = async (
  fn: () => Promise<any>,
  maxAttempts: number = 3,
  delayInSeconds: number = 3000,
  failCallback?: () => void
) => {
  const execute = async attempt => {
    try {
      return await fn();
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        console.error(`Retrying after ${delayInSeconds} seconds due to:`, err);
        return delay(() => execute(nextAttempt), delayInSeconds);
      } else {
        if (failCallback) {
          failCallback();
        }
      }
    }
  };
  return execute(1);
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const formatAmount = (amount: number) => {
  let formattedAmount = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
  let decimalSeparator = formattedAmount.charAt(formattedAmount.length - 3);
  return formattedAmount.split(decimalSeparator);
};

const printUnits = (value: number, unit: string) => {
  return value > 1 ? UNITS[unit].PLURAL : UNITS[unit].SINGULAR;
};

const getName = ({
  title,
  lastName,
  middleName,
  firstName
}: {
  [key: string]: string;
}) => {
  let name = '';

  name += title ? `${title} ` : '';
  name += lastName ? `${lastName} ` : '';
  name += middleName ? `${middleName} ` : '';
  name += firstName ? `${firstName}` : '';

  return name.trim();
};

const getGender = (gender: string) => {
  return gender === 'FE' ? 'GENDER.FEMALE' : 'GENDER.MALE';
};

const getAddress = ({
  number,
  line,
  city,
  state,
  postCode,
  country
}: {
  [key: string]: string;
}) => {
  let fullAddress = '';

  fullAddress += number ? `${number}<br/>` : '';
  fullAddress += line ? `${line}<br/>` : '';
  fullAddress += city ? `${city}<br/>` : '';
  fullAddress += state ? `${state}<br/>` : '';
  fullAddress += country ? `${country}<br/>` : '';
  fullAddress += postCode ? `${postCode}<br/>` : '';
  return fullAddress.substring(0, fullAddress.length - 5);
};

const lockScrolling = (id: string, isLock: boolean) => {
  const memberSection = document.getElementById(id);
  if (memberSection) {
    memberSection.style.position = isLock ? 'fixed' : 'static';
  }
};

const getPackageAccessText = (packagePlan: IPackagePlanItem) => {
  if (packagePlan.package.timeGroup && packagePlan.package.timeGroup.id) {
    return packagePlan.package.timeGroup.name;
  }
  return FULL_ACCESS;
};

const isLumpSumpPackage = (packagePlan: IPackagePlanItem | null) => {
  if(!packagePlan) {
    return false;
  }
  return packagePlan.isLumpSum;
}

export {
  condition,
  retry,
  scrollToTop,
  formatAmount,
  printUnits,
  getName,
  getAddress,
  getGender,
  lockScrolling,
  getPackageAccessText,
  isLumpSumpPackage
};
