import React, { MutableRefObject, useEffect, useState } from 'react';
import { LookupModel, OtherFieldsModel } from 'models/PersonalDetails';
import { FormSelect, FormInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { ConmanFieldConfigModel } from 'src/models/ConmanConfig';
import {
  ACTIVE_STATUS_CODE,
  COMPANY_NAME_TEXT,
  GENERAL_NOTE_TEXT,
  OCCUPATION_TEXT,
  OTHER_COMMISSION_NOTE_TEXT,
  REFERRED_BY_MEMBERSHIP_NUMBER_TEXT,
  REFERRED_BY_NAME_TEXT
} from 'constants/DefaultConstants';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import {
  onChangeInput,
  required,
  validateInputField
} from 'util/FormValidations';
import { safeLookupModel } from 'util/ControlUtils';

interface IOtherFields {
  recurringCardTypes: LookupModel[];
  otherFields: OtherFieldsModel;
  otherFieldsConfigList: ConmanFieldConfigModel[];
  otherFieldsRef: MutableRefObject<UseFormReturn<OtherFieldsFormInput> | null>;
}

export type OtherFieldsFormInput = {
  companyName: string;
  occupation: string;
  referredByMemberName: string;
  referredByMembershipNumber: string;
  otherCommissionNote: string;
  generalNote: string;
  recurringCardType: LookupModel;
};

const OtherFields: React.FC<IOtherFields> = ({
  recurringCardTypes,
  otherFields,
  otherFieldsConfigList,
  otherFieldsRef
}) => {
  const {
    companyName,
    occupation,
    referredByMemberName,
    referredByMembershipNumber,
    otherCommissionNote,
    generalNote,
    recurringCardType
  } = otherFields;

  const form = useForm<OtherFieldsFormInput>({
    mode: 'onChange',
    defaultValues: {
      companyName: companyName,
      occupation: occupation,
      referredByMemberName: referredByMemberName,
      referredByMembershipNumber: referredByMembershipNumber,
      otherCommissionNote: otherCommissionNote,
      generalNote: generalNote,
      recurringCardType: safeLookupModel(recurringCardType)
    }
  });
  const {
    control,
    formState: { errors }
  } = form;

  const [companyNameConfig, setCompanyNameConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [occupationConfig, setOccupationConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [referredByMemberNameConfig, setReferredByMemberNameConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [
    referredByMembershipNumberConfig,
    setReferredByMembershipNumberConfig
  ] = useState<ConmanFieldConfigModel>();
  const [commissionNoteConfig, setCommissionNoteConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [generalNoteConfig, setGeneralNoteConfig] = useState<
    ConmanFieldConfigModel
  >();
  const { t } = useTranslation();

  useEffect(() => {
    if (otherFieldsConfigList && otherFieldsConfigList.length) {
      const companyNameCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === COMPANY_NAME_TEXT
      );
      setCompanyNameConfig(companyNameCfg);
      const occupationCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === OCCUPATION_TEXT
      );
      setOccupationConfig(occupationCfg);
      const referredByMemberNameCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === REFERRED_BY_NAME_TEXT
      );
      setReferredByMemberNameConfig(referredByMemberNameCfg);
      const referredByMembershipNumberCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === REFERRED_BY_MEMBERSHIP_NUMBER_TEXT
      );
      setReferredByMembershipNumberConfig(referredByMembershipNumberCfg);
      const otherCommissionNoteCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === OTHER_COMMISSION_NOTE_TEXT
      );
      setCommissionNoteConfig(otherCommissionNoteCfg);
      const generalNoteCfg = otherFieldsConfigList.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GENERAL_NOTE_TEXT
      );
      setGeneralNoteConfig(generalNoteCfg);
    }
  }, [otherFieldsConfigList]);

  const isValidConfig = (config: ConmanFieldConfigModel) => {
    return config && config.status === ACTIVE_STATUS_CODE;
  };

  const isRequiredConmanField = (conmanConfig: ConmanFieldConfigModel) => {
    return (
      conmanConfig &&
      conmanConfig.isRequired === 1 &&
      conmanConfig.status === ACTIVE_STATUS_CODE
    );
  };

  useEffect(() => {
    if (otherFieldsRef) {
      otherFieldsRef.current = form;
    }
  }, []);

  return (
    <div className="personal-group">
      <div className="row">
        <div className="col-md-6">
          {companyNameConfig && isValidConfig(companyNameConfig) && (
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: required(isRequiredConmanField(companyNameConfig)),
                validate: {
                  validateInputField: validateInputField(
                    companyNameConfig.regEx || ''
                  )
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.COMPANY_NAME')}
                  require={isRequiredConmanField(companyNameConfig)}
                  errorMsg={t(
                    (errors.companyName && errors.companyName.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {occupationConfig && isValidConfig(occupationConfig) && (
            <Controller
              name="occupation"
              control={control}
              rules={{
                required: required(isRequiredConmanField(occupationConfig)),
                validate: {
                  validateInputField: validateInputField(
                    occupationConfig.regEx || ''
                  )
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OCCUPATION')}
                  require={isRequiredConmanField(occupationConfig)}
                  errorMsg={t(
                    (errors.occupation && errors.occupation.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {referredByMemberNameConfig &&
            isValidConfig(referredByMemberNameConfig) && (
              <Controller
                name="referredByMemberName"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(referredByMemberNameConfig)
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      referredByMemberNameConfig.regEx || ''
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.REFERRED_BY_NAME')}
                    require={isRequiredConmanField(referredByMemberNameConfig)}
                    errorMsg={t(
                      (errors.referredByMemberName &&
                        errors.referredByMemberName.message) ||
                        ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {referredByMembershipNumberConfig &&
            isValidConfig(referredByMembershipNumberConfig) && (
              <Controller
                name="referredByMembershipNumber"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(referredByMembershipNumberConfig)
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      referredByMembershipNumberConfig.regEx || ''
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.REFERRED_BY_MEMBERSHIP_NUMBER')}
                    require={isRequiredConmanField(
                      referredByMembershipNumberConfig
                    )}
                    errorMsg={t(
                      (errors.referredByMembershipNumber &&
                        errors.referredByMembershipNumber.message) ||
                        ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {commissionNoteConfig && isValidConfig(commissionNoteConfig) && (
            <Controller
              name="otherCommissionNote"
              control={control}
              rules={{
                required: required(isRequiredConmanField(commissionNoteConfig)),
                validate: {
                  validateInputField: validateInputField(
                    commissionNoteConfig.regEx || ''
                  )
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OTHER_COMMISSION_NOTE')}
                  require={isRequiredConmanField(commissionNoteConfig)}
                  errorMsg={t(
                    (errors.otherCommissionNote &&
                      errors.otherCommissionNote.message) ||
                      ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {generalNoteConfig && isValidConfig(generalNoteConfig) && (
            <Controller
              name="generalNote"
              control={control}
              rules={{
                required: required(isRequiredConmanField(generalNoteConfig)),
                validate: {
                  validateInputField: validateInputField(
                    generalNoteConfig.regEx || ''
                  )
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.GENERAL_NOTE')}
                  require={isRequiredConmanField(generalNoteConfig)}
                  errorMsg={t(
                    (errors.generalNote && errors.generalNote.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Controller
            name="recurringCardType"
            control={control}
            render={({ field }) => (
              <FormSelect
                {...field}
                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.RECURRING_CARD_TYPE')}
                disabled={recurringCardTypes.length === 0}
                options={recurringCardTypes}
                errorMsg={t(
                  (errors.recurringCardType &&
                    errors.recurringCardType.message) ||
                    ''
                )}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherFields;
