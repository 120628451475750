import React from 'react';
import Loading from 'components/Loading';
import { IPackagePlanItem } from 'interfaces/Membership';
import ConvertGuestToGuestPassPackagePlanItem from './ConvertGuestToGuestPassPackagePlanItem';

interface IConvertGuestToGuestPassPackagePlanList {
  isLoading: boolean;
  packagePlans: IPackagePlanItem[];
  selectedPackagePlanId: number;
  onPackagePlanSelected: (packagePlanId: number) => void;
}

const ConvertGuestToGuestPassPackagePlanList: React.FunctionComponent<
  IConvertGuestToGuestPassPackagePlanList
> = ({
  isLoading,
  packagePlans,
  selectedPackagePlanId,
  onPackagePlanSelected
}) => {
  return (
    <div className="content select-package-plan">
      {isLoading ? (
        <Loading />
      ) : (
        packagePlans &&
        packagePlans.map(packagePlanItem => (
          <ConvertGuestToGuestPassPackagePlanItem
            key={packagePlanItem.originalPlanId}
            packagePlanItem={packagePlanItem}
            selectedPackagePlanId={selectedPackagePlanId}
            onSelected={onPackagePlanSelected}
          />
        ))
      )}
    </div>
  );
};

export default ConvertGuestToGuestPassPackagePlanList;
