import React from 'react';
import * as Utils from 'util/ControlUtils';
import FormButton from 'components/Membership/FormButton';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import AddOn from 'models/AddOn';
import IPromotionCatergory from 'interfaces/Promotion';
import { PROMOTION_TYPE } from 'constants/Constants';
import { IPackagePlanItem } from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { condition } from 'util/Common';
import TooltipPaymentInfoTable from '../../TooltipPaymentInfoTable';
import EzyPayFee from './EzyPayFee';
import { useTypedSelector } from 'reduxs';

interface ProductSummaryProps {
  productSummary: IPackagePlanItem;
  membershipSearchFilter: MembershipSearchFilter;
  membershipConfiguration: MembershipConfiguration;
  promotions?: IPromotionCatergory[];
  addons: AddOn[];
  isDisabledNext?: boolean;
  handlePrevious?: () => void;
  handleNext?: () => void;
  handleCancel?: () => void;
  handleFinish?: () => void;
}

const ProductSummary: React.FunctionComponent<ProductSummaryProps> = ({
  productSummary,
  membershipSearchFilter,
  membershipConfiguration,
  addons,
  promotions,
  isDisabledNext,
  handlePrevious,
  handleNext,
  handleCancel,
  handleFinish
}) => {
  const { t } = useTranslation();
  const isDisableEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );
  const {
    payment: { payNow, firstBilling, nextBilling },
    isLumpSum
  } = productSummary;

  const payNowFormatted = Utils.formatMoney(payNow.value, 2).toString();
  const payNows =
    payNowFormatted.indexOf('.') > 0
      ? payNowFormatted.split('.')
      : [payNowFormatted, '00'];
  const payNowsTooltip = (
    <TooltipPaymentInfoTable
      title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PAY_FEE_BREAKDOWN')}
      data={payNow.fees}
      addons={payNow.addonFees}
    />
  );

  const promotionCategory =
    promotions &&
    promotions.find(category => category.name === PROMOTION_TYPE.PROMOTION);
  const specialPromotionCategory =
    promotions &&
    promotions.find(
      category => category.name === PROMOTION_TYPE.SPECIAL_PROMOTION
    );
  const collectPromotions = [
    ...((specialPromotionCategory && specialPromotionCategory.promotions) ||
      []),
    ...((promotionCategory && promotionCategory.promotions) || [])
  ];

  const firstBillingFormatted = firstBilling
    ? firstBilling.amount.toString()
    : '0';

  const firstBillings = condition(
    firstBillingFormatted.indexOf('.') > 0,
    firstBillingFormatted.split('.'),
    [firstBillingFormatted, '00']
  );

  const firstBillingsTooltip = (
    <TooltipPaymentInfoTable
      title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_FEE_BREAKDOWN')}
      data={firstBilling.fees}
      addons={firstBilling.addonFees}
    />
  );

  const nextBillingFormatted = nextBilling
    ? nextBilling.amount.toString()
    : '0';

  const nextBillings = condition(
    nextBillingFormatted.indexOf('.') > 0,
    nextBillingFormatted.split('.'),
    [nextBillingFormatted, '00']
  );

  const finalStep = 5;

  const nextBillingsTooltip = (
    <TooltipPaymentInfoTable
      title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_FEE_BREAKDOWN')}
      data={nextBilling.fees}
      addons={nextBilling.addonFees}
    />
  );

  const startDate =
    payNow && payNow.fees && payNow.fees.length && payNow.fees[0].startDate
      ? payNow.fees[0].startDate
      : membershipSearchFilter.startDate;

  const renderAddonSection = () => (
    <>
      <div className="section-add-ons">
        <p className="title-content">
          {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.ADDONS')}
        </p>
        {addons.map(addon => {
          const price = condition(
            addon.amount.payNow,
            addon.amount.payNow,
            addon.amount.recurring
          ).toString();
          const priceSplitted =
            price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
          return (
            <div key={addon.id} className="row-span">
              <span className="first-span">{addon.name}</span>
              <span className="dots" />
              <span className="last-span">
                <b>
                  {priceSplitted[0]}.{priceSplitted[1].padEnd(2, '0')}
                </b>
              </span>
            </div>
          );
        })}
        {addons.length === 0 && (
          <div className="row-span">
            <span>
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NO_ADDONS_SELECTED')}
            </span>
          </div>
        )}
      </div>
    </>
  );
  return (
    <div className="aside-summary">
      <div className="product-summary">
        <p className="title-summary">
          {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PRODUCT_SUMMARY')}
        </p>
        <div className="pay-now">
          <p className="title-pay">
            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PAY_NOW')}
          </p>
          <p className="number-pay-now">
            {payNows[0]}.
            <sup>
              {payNows[1].padEnd(2, '0')}
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={payNowsTooltip}
                placement="top-start"
                className="btn-tooltip"
              >
                <IconButton aria-label="info">
                  <InfoIcon
                    fontSize="small"
                    className="infoIcon icon-detail-no-boder"
                  />
                </IconButton>
              </Tooltip>
            </sup>
          </p>
        </div>
        {!isLumpSum && (
          <div className="section-billing">
            <div className="row-span">
              <span className="first-span">
                {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_BILLING')}:
              </span>
              <span className="dots" />
              <span className="first-billing last-span">
                <b>
                  {firstBillings[0]}.{firstBillings[1]}
                </b>
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={firstBillingsTooltip}
                  placement="top-start"
                  className="btn-tooltip"
                >
                  <IconButton aria-label="info">
                    <InfoIcon
                      fontSize="small"
                      className="infoIcon icon-detail-no-boder"
                    />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
            <div className="row-span">
              <span className="first-span">
                {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_BILLING')}:
              </span>
              <span className="dots" />
              <span className="next-billing last-span">
                <b>
                  {nextBillings[0]}.{nextBillings[1]}
                </b>
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={nextBillingsTooltip}
                  placement="top-start"
                  className="btn-tooltip"
                >
                  <IconButton aria-label="info">
                    <InfoIcon
                      fontSize="small"
                      className="infoIcon icon-detail-no-boder"
                    />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          </div>
        )}
        <div className="section-info">
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.START')}
            </span>
            <span className="dots" />
            <span className="last-span">
              {Utils.DisplayDateFormat(
                startDate,
                membershipConfiguration.newMemberTimeFormat
              )}
            </span>
          </div>
          {!isLumpSum &&
            productSummary.payment.firstBilling &&
            productSummary.payment.firstBilling.startDate && (
              <div className="row-span">
                <span className="first-span">
                  {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_BILLING')}
                </span>
                <span className="dots" />
                <span className="last-span">
                  {Utils.DisplayDateFormat(
                    productSummary.payment.firstBilling.startDate,
                    membershipConfiguration.newMemberTimeFormat
                  )}
                </span>
              </div>
            )}
          {!isLumpSum &&
            productSummary.payment.nextBilling &&
            productSummary.payment.nextBilling.startDate && (
              <div className="row-span">
                <span className="first-span">
                  {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_BILLING')}
                </span>
                <span className="dots" />
                <span className="last-span">
                  {Utils.DisplayDateFormat(
                    productSummary.payment.nextBilling.startDate,
                    membershipConfiguration.newMemberTimeFormat
                  )}
                </span>
              </div>
            )}
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.TIER')}
            </span>
            <span className="dots" />
            <span className="last-span">{productSummary.tier.name}</span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.MCP')}
            </span>
            <span className="dots" />
            <span>
              {productSummary.minimumContract.value}{' '}
              {productSummary.minimumContract.unit.name}
            </span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.ACCESS')}
            </span>
            <span className="dots" />
            <span className="last-span">
              {productSummary.package.timeGroup
                ? productSummary.package.timeGroup.name
                : 'Unknown'}
            </span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PLAN')}
            </span>
            <span className="dots" />
            <span className="last-span">{productSummary.name}</span>
          </div>
        </div>
        {renderAddonSection()}
        <div className="section-promotions">
          <p className="title-content">
            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PROMOTIONS')}
          </p>
          {collectPromotions.map(promotion => {
            return (
              <div key={promotion.id} className="row-span">
                <span className="first-span">{promotion.name}</span>
                <span className="dots" />
              </div>
            );
          })}
          {collectPromotions.length === 0 && (
            <div className="row-span">
              <span>
                {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NO_PROMOTIONS_SELECTED')}
              </span>
            </div>
          )}
        </div>
        {!isDisableEzyPay && <EzyPayFee />}
      </div>
      <div className="sticky-aside">
        <FormButton
          isFinalStep={membershipConfiguration.stepIndex === finalStep}
          isOnProductSummary={true}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          isDisabledNext={isDisabledNext}
          handleCancel={handleCancel}
          handleFinish={handleFinish}
        />
      </div>
    </div>
  );
};

export default ProductSummary;
