import React from 'react';
import { PAYMENT_METHOD_TYPES } from 'constants/Constants';
import { IInputCardButton } from '../../interface';
import { useTranslation } from 'react-i18next';
import './input-card-button.scss';

const InputCardButton: React.FC<IInputCardButton> = ({
  primaryPaymentMethodId,
  isExistCard,
  paymentToken,
  handleAddCardSignature,
  handleEditCardSignature
}) => {
  const { t } = useTranslation();

  if (primaryPaymentMethodId !== PAYMENT_METHOD_TYPES.CREDIT_CARD) {
    return <></>;
  }

  return (
    <div className="input-container">
      {isExistCard ? (
        <button
          className="btn btn-primary"
          onClick={() => {
            paymentToken
              ? handleEditCardSignature(paymentToken)
              : handleAddCardSignature();
          }}
        >
          {t('BUTTON.MODIFY_PAYMENT_DETAIL')}
        </button>
      ) : (
        <button className="btn btn-primary" onClick={handleAddCardSignature}>
          {t('BUTTON.INPUT_CARD')}
        </button>
      )}
      <div className="input-container__logo">
        <img
          src={window.location.origin + '/images/visa.png'}
          alt="id check"
          width={50}
          height={50}
        />
        <img
          src={window.location.origin + '/images/id-check.png'}
          alt="visa"
          width={75}
          height={50}
        />
      </div>
    </div>
  );
};

export default InputCardButton;
