import * as types from 'constants/ActionTypes';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import PaymentDetails from 'models/PaymentDetails';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import { IQuestionConfigs } from 'src/models/IQuestionConfigs';
import { IConvertGuestToCorporateMember } from '../reducers/ConvertGuestToCorporateMember';

export function resetConvertGuestToCorporateMemberState() {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_RESET_STATE
  };
}

export function setConvertGuestToCorporateMemberPackagePlanSearchFilter(
  membershipSearchFilter: MembershipSearchFilter
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SEARCH_FILTER,
    membershipSearchFilter
  };
}

export function setConvertGuestToCorporateMemberConfiguration(
  membershipConfiguration: MembershipConfiguration
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_CONFIGURATION,
    membershipConfiguration
  };
}

export function setConvertGuestToCorporateMemberProductExtras(
  selectedPromotions: IPromotionCatergory[],
  selectedAddons: AddOn[]
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS,
    selectedPromotions,
    selectedAddons
  };
}

export function setConvertGuestToCorporateMemberSelectedPackagePlan(
  packagePlan: IPackagePlanItem
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN,
    packagePlan
  };
}

export function setConvertGuestToCorporateMemberPersonalDetails(
  personalDetails: PersonalDetailsModel
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PERSONAL_DETAILS,
    data: personalDetails
  };
}

export function setConvertGuestToCorporateMemberAllPackagePlans(
  packagePlans: IAllPackagePlans
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN,
    packagePlans
  };
}

export function setConvertGuestToCorporateMemberSummary(memberSummary) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_MEMBER_SUMMARY,
    memberSummary
  };
}

export function setConvertGuestToCorporateMemberQuestionConfigs(questionConfigs: IQuestionConfigs) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_QUESTION_CONFIGS,
    questionConfigs
  };
}

export function setConvertGuestToCorporateMemberPaymentDetails(
  paymentDetails: PaymentDetails
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PAYMENT_DETAILS,
    paymentDetails
  };
}
export function replaceConvertGuestToCorporateMemberData(data: IConvertGuestToCorporateMember) {
  return { type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_REPLACE_DATA, data };
}

export function setConvertGuestToCorporateMemberGuestCorporateId(
  guestCorporateId: number
) {
  return {
    type: types.CONVERT_GUEST_TO_CORPORATE_MEMBER_GUEST_CORPORATE_ID,
    guestCorporateId
  };
}
