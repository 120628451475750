import React, { useCallback } from 'react';

import Loading from 'components/Loading';
import { getSubTenantId } from 'services/common-service';
import { ITemplate } from './interface';
import { useDispatch } from 'react-redux';
import * as MembershipActions from 'reduxs/actions/ConvertGuestToMember';
import * as NewMembershipActions from 'reduxs/actions/NewMembership';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import PaymentDetails from 'models/PaymentDetails';
import { IQuestionConfigs } from 'models/IQuestionConfigs';
import { useTypedSelector } from 'reduxs';
import SelectPackagePlan from 'features/SelectPackagePlanConvertGuestToMember';
import SelectPaymentDetails from 'features/SelectPaymentDetail';
import MemberSummary from 'features/MemberSummary';
import SelectProductExtras from 'features/SelectProductExtras';
import SelectPersonalDetails from 'features/SelectPersonalDetailsConvertGuestToMember';
import { STORAGE } from 'constants/Constants';
import {
  setAddMemberPackagePlanSearchFilter,
  setAddMemberPersonalDetails
} from 'reduxs/actions/NewMembership';

const NewMembership = ({
  handleCancel,
  handleCreateMember,
  configs,
  newMembership,
  externalId,
  operatorId
}) => {
  const { membershipConfiguration } = newMembership;
  const { stepIndex, stepLatest } = membershipConfiguration || {};
  const dispatch = useDispatch();

  const guestToMember = useTypedSelector(state => state.convertGuestToMember);
  const payment = useTypedSelector(state => state.payment);

  const setAllPackageAction = useCallback(
    (allPackagePlans: IAllPackagePlans) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberAllPackagePlans(
          allPackagePlans
        )
      );
    },
    []
  );

  const setProductExtrasAction = useCallback(
    (selectedPromotions: IPromotionCatergory[], selectedAddons: AddOn[]) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberProductExtras(
          selectedPromotions,
          selectedAddons
        )
      );
    },
    []
  );

  const setPackagePlanSearchFilterAction = useCallback(
    (membershipSearchFilter: MembershipSearchFilter) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberPackagePlanSearchFilter(
          membershipSearchFilter
        )
      );
      dispatch(setAddMemberPackagePlanSearchFilter(membershipSearchFilter));
    },
    []
  );

  const setConfigurationAction = useCallback(
    (membershipConfiguration: MembershipConfiguration) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberConfiguration(
          membershipConfiguration
        )
      );
    },
    []
  );

  const setSelectedPackagePlanAction = useCallback(
    (packagePlan: IPackagePlanItem) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberSelectedPackagePlan(
          packagePlan
        )
      );
      dispatch(
        NewMembershipActions.setAddMemberSelectedPackagePlan(packagePlan)
      );
    },
    []
  );

  const setPersonalDetailsAction = useCallback(
    (personalDetails: PersonalDetailsModel) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberPersonalDetails(
          personalDetails
        )
      );
      dispatch(setAddMemberPersonalDetails(personalDetails));
    },
    []
  );

  const setPaymentDetailsAction = useCallback(
    (paymentDetails: PaymentDetails) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberPaymentDetails(paymentDetails)
      );
    },
    []
  );

  const setQuestionConfigsAction = useCallback(
    (questionConfigs: IQuestionConfigs) => {
      dispatch(
        MembershipActions.setConvertGuestToMemberQuestionConfigs(
          questionConfigs
        )
      );
    },
    []
  );

  if (!membershipConfiguration || membershipConfiguration.stepIndex === 1) {
    return (
      <SelectPackagePlan
        configs={configs}
        setAllPackageAction={setAllPackageAction}
        setSelectedPackagePlanAction={setSelectedPackagePlanAction}
        setConfigurationAction={setConfigurationAction}
        setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
        setProductExtrasAction={setProductExtrasAction}
        onCancel={handleCancel}
      />
    );
  }
  switch (true) {
    case stepIndex === 2 && stepLatest > 1:
      return (
        <SelectProductExtras
          isCorporate={false}
          membership={guestToMember}
          configs={configs}
          onCancel={handleCancel}
          setSelectedPackagePlanAction={setSelectedPackagePlanAction}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setProductExtrasAction={setProductExtrasAction}
        />
      );
    case stepIndex === 3 && stepLatest > 2:
      return (
        <SelectPersonalDetails
          newMembership={guestToMember}
          onCancel={handleCancel}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setPersonalDetailsAction={setPersonalDetailsAction}
          setQuestionConfigsAction={setQuestionConfigsAction}
        />
      );
    case stepIndex === 4 && stepLatest > 3:
      return (
        <SelectPaymentDetails
          configs={configs}
          membership={guestToMember}
          payment={payment}
          externalId={externalId}
          onCancel={handleCancel}
          setPaymentDetailsAction={setPaymentDetailsAction}
          setConfigurationAction={setConfigurationAction}
          operatorId={operatorId}
          membershipStorage={STORAGE.CONVERT_GUEST_TO_MEMBER}
        />
      );
    case stepIndex === 5 && stepLatest > 4:
      return (
        <MemberSummary
          membership={newMembership}
          onCancel={handleCancel}
          onFinish={handleCreateMember}
          setConfigurationAction={setConfigurationAction}
        />
      );
    default:
      return <></>;
  }
};

const Template: React.FC<ITemplate> = ({
  newMembership,
  externalId,
  isLoading,
  configs,
  handleCancel,
  handleCreateMember,
  operatorId
}) => {
  return !isLoading ? (
    <div className="add-member-page">
      {getSubTenantId() ? (
        <NewMembership
          handleCancel={handleCancel}
          handleCreateMember={handleCreateMember}
          newMembership={newMembership}
          configs={configs}
          externalId={externalId}
          operatorId={operatorId}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <Loading />
  );
};

export default Template;
