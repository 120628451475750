import React from 'react';
import { useTranslation } from 'react-i18next';

import { IMemberPaymentDetails, DataDescription } from '../interface';

import DescriptionContent from '../DescriptionContent';

import { isLumpSumpPackage } from 'util/Common';

import { useTypedSelector } from 'reduxs';
import { selectPaymentInfo } from 'selectors/member';

import { usePaymentCyberSource } from './usePaymentCyberSource';
import { usePaymentEzyPay } from './usePaymentEzyPay';

const PaymentDetail: React.FC<IMemberPaymentDetails> = ({ membership }) => {
  const { paymentDetails, selectedPackagePlan } = membership;
  const isLumpSum = isLumpSumpPackage(selectedPackagePlan);

  const isDisabledEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );
  const ezyPaymentInfo = useTypedSelector(state => selectPaymentInfo(state));

  const { t } = useTranslation();

  const useDataPayment = () => {
    if (isDisabledEzyPay) {
      return usePaymentCyberSource(paymentDetails, t, isLumpSum);
    }

    if (!isDisabledEzyPay) {
      return usePaymentEzyPay(ezyPaymentInfo!, paymentDetails, t);
    }

    return [];
  };

  const data: DataDescription[] = useDataPayment();

  return (
    <DescriptionContent
      title={t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP'
      )}
      data={data.map(data => ({
        ...data,
        isVisible: true
      }))}
    />
  );
};

export default PaymentDetail;
