import PaymentDetails from 'models/PaymentDetails';

import { DataDescription } from '../interface';
import { PAYMENT_METHOD_TYPES } from 'constants/Constants';

import * as Utils from 'util/ControlUtils';

export const usePaymentCyberSource = (
  paymentDetails: PaymentDetails,
  t,
  isLumpSum
) => {
  let cyberSourceData: DataDescription[] = [];
  const primaryPayment = paymentDetails.primaryPaymentMethod.find(
    payment => payment.value === paymentDetails.primaryPaymentMethodId
  );

  if (
    paymentDetails &&
    paymentDetails.primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD
  ) {
    cyberSourceData = [];
  } else {
    cyberSourceData = [
      {
        fieldLabel: t(
          isLumpSum
            ? 'PAGE.MEMBERSHIPS.CREATE.STEP_5.PAYMENT_METHOD'
            : 'PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_PAYMENT_METHOD'
        ),
        data: primaryPayment && Utils.getValueData(primaryPayment.label)
      }
    ];
  }

  cyberSourceData = cyberSourceData.concat([
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_NUMBER'),
      data: Utils.getValueData(paymentDetails.nationalIdNumber)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PASSPORT_NUMBER'),
      data: Utils.getValueData(paymentDetails.otherNationalIdNumber)
    }
  ]);

  return cyberSourceData;
};
