import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import { useTranslation } from 'react-i18next';
import { getParamFromWindowSearch } from 'util/ControlUtils';
import { postMember } from 'services/membership-service';
import { resetOnlinePayment } from 'reduxs/actions/Payment';
import Popup from 'util/Popup';
import { getEsignatureUrl, getSubTenantId } from 'services/common-service';
import IAddNewCorporateMemberPage from './interface';
import Template from './template';
import { useTypedSelector } from 'reduxs';
import {
  AddressDetailsModel,
  ContactDetailsModel,
  ContactMethodModel,
  ContactedDetailsModel,
  PersonalDetailsModel
} from 'src/models/PersonalDetails';
import {
  resetConvertGuestToCorporateMemberState,
  setConvertGuestToCorporateMemberGuestCorporateId,
  setConvertGuestToCorporateMemberPackagePlanSearchFilter,
  setConvertGuestToCorporateMemberPersonalDetails,
  setConvertGuestToCorporateMemberSummary
} from 'reduxs/actions/ConvertGuestToCorporateMember';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { generateHandleSaveDataPayload } from 'util/Payload';
import { IExternalGuest } from 'models/ExternalGuest';
import { getInitData, getReferrerId } from 'common/ConvertGuest';
import { GO_TO_HOME_PAGE_MESSAGE } from 'constants/Iframe';
import { selectPaymentInfo } from 'selectors/member';

const ConvertGuestToCorporateMemberPage: React.FC<
  IAddNewCorporateMemberPage
> = () => {
  const corporateMembership = useTypedSelector(
    state => state.convertGuestToCorporateMember
  );
  const conman = useTypedSelector(state => state.conman);
  const externalId = getParamFromWindowSearch('guestId');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const operatorId = Number(getParamFromWindowSearch('operator_id'));
  const isRedirected = getParamFromWindowSearch('isRedirected');

  const paymentInfo = useTypedSelector(state => selectPaymentInfo(state));
  const isDisableEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );

  const handleCancel = () => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('COMMON.CANCEL_NOTIFICATION'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        dispatch(resetOnlinePayment());
        dispatch(resetConvertGuestToCorporateMemberState());
        window.parent.postMessage(GO_TO_HOME_PAGE_MESSAGE, getEsignatureUrl());
      }
    });
  };

  useEffect(() => {
    if (externalId && !isRedirected) {
      getInitData(
        externalId,
        populateMemberPersonalDetails,
        populateMemberSearchFilter,
        setConvertGuestToCorporateMemberGuestCorporateId,
        setConvertGuestToCorporateMemberSummary,
        dispatch,
        setIsLoading
      );
    }
  }, [externalId]);

  const checkValidPhone = (phoneNumber: string | undefined) => {
    return phoneNumber ? true : false;
  };

  const populateMemberPersonalDetails = (guestInfo: IExternalGuest) => {
    const preferredContactMethod: ContactMethodModel[] = [];

    if (!guestInfo.smsOptedOut) {
      preferredContactMethod.push(DefaultConstants.CONTACT_METHOD_LIST[5]);
    }
    if (!guestInfo.emailOptedOut) {
      preferredContactMethod.push(DefaultConstants.CONTACT_METHOD_LIST[4]);
    }
    const addressDetails: AddressDetailsModel = {
      addressNumber: '',
      addressLine1: guestInfo.address,
      addressLine2: '',
      city: guestInfo.city,
      country: {
        id: '',
        name: '',
        value: '',
        label: '',
        key: '',
        code: ''
      },
      postCode: guestInfo.zip,
      state: guestInfo.state
    };
    const contactedDetails: ContactedDetailsModel = {
      applicationRef: '',
      memberHappyToBeContact: false,
      preferredContactMethod: preferredContactMethod,
      marketingSource: {
        id: '',
        name: '',
        value: guestInfo.leadSource,
        label: '',
        key: '',
        code: ''
      },
      channelCode: {
        id: '',
        name: '',
        value: guestInfo.channelCode,
        label: '',
        key: '',
        code: ''
      },
      marketingCampaign: {
        id: '',
        name: '',
        value: guestInfo.marketingCampaign,
        label: '',
        key: '',
        code: ''
      }
    };
    const contactDetails: ContactDetailsModel = {
      mobilePhone: {
        isValid: checkValidPhone(guestInfo.mobilePhone),
        countryCode: '',
        phoneNumber: guestInfo.mobilePhone || ''
      },
      homePhone: {
        isValid: checkValidPhone(guestInfo.homePhone),
        countryCode: '',
        phoneNumber: guestInfo.homePhone || ''
      },
      workPhone: {
        isValid: checkValidPhone(guestInfo.workPhone),
        countryCode: '',
        phoneNumber: guestInfo.workPhone || ''
      },
      email: guestInfo.email,
      isEmailValid: true
    };
    const personalDetails: PersonalDetailsModel = {
      ...corporateMembership.personalDetails,
      title: {
        id: '',
        name: '',
        value: '',
        label: '',
        key: '',
        code: ''
      },
      gender: {
        id: '',
        name: '',
        value: guestInfo.gender === 'UN' ? '' : guestInfo.gender,
        label: '',
        key: '',
        code: ''
      },
      firstName: guestInfo.firstName,
      lastName: guestInfo.lastName,
      middleName: guestInfo.middleName,
      nationalIdNumber: '',
      passportNumber: '',
      dateOfBirth: guestInfo.dateOfBirth
        ? new Date(guestInfo.dateOfBirth)
        : null,
      preferedLanguage: {
        id: '',
        name: '',
        value: guestInfo.preferredLanguage.substring(
          guestInfo.preferredLanguage.length - 2
        ),
        label: '',
        key: '',
        code: ''
      },
      isParentalConsentProvided: false,
      addressDetails,
      contactDetails,
      contactedDetails
    };
    dispatch(setConvertGuestToCorporateMemberPersonalDetails(personalDetails));
  };

  const populateMemberSearchFilter = (
    guestInfo: IExternalGuest,
    minDate: Date
  ) => {
    const membershipSearchTmp: MembershipSearchFilter = {
      ...corporateMembership.membershipSearchFilter,
      clubId: guestInfo.clubId,
      clubName: guestInfo.clubName,
      dateOfBirth: guestInfo.dateOfBirth
        ? new Date(guestInfo.dateOfBirth)
        : null,
      primarySalePersonId: guestInfo.salePersonId,
      secondarySalePersonId: guestInfo.secondarySalePersonId
        ? guestInfo.secondarySalePersonId
        : 0,
      secondarySalePersonName: guestInfo.secondarySalePersonName,
      startDate: minDate,
      minStartDate: minDate,
      corporateId: Number(guestInfo.corporateId)
    };
    dispatch(
      setConvertGuestToCorporateMemberPackagePlanSearchFilter(
        membershipSearchTmp
      )
    );
  };

  const handleCreateMember = () => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('MSG.ARE_YOU_SURE_TO_FINISH'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        handleSave();
      }
    });
  };

  const handleSave = () => {
    Popup.showProcessing();
    const data = {
      ...generateHandleSaveDataPayload(
        corporateMembership,
        operatorId,
        conman,
        isDisableEzyPay!,
        paymentInfo!
      ),
      guestId: externalId,
      guestCorporateId: corporateMembership.guestCorporateId,
      agreementPartyId:
        corporateMembership.membershipSearchFilter.agreementPartyId || '',
      referrerMemberId: getReferrerId(corporateMembership)
    };
    postMember({ data }).then(res => {
      observer.publish('flagUnsavedChangeEvent', false);
      Swal.close();
      Popup.showMessageAddMemberSuccess(
        () =>
        (window.parent.location.href = `${getEsignatureUrl()}/digitalcontract/signdocument?memberid=${res.id
          }&subTenantId=${getSubTenantId()}`)
      );
    });
  };

  return (
    <Template
      configs={conman.configs}
      isLoading={isLoading}
      handleCancel={handleCancel}
      handleCreateMember={handleCreateMember}
      operatorId={operatorId}
      externalId={externalId}
    />
  );
};

export default ConvertGuestToCorporateMemberPage;
