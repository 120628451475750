import React from 'react';
import { FormSelect, FormDatePicker } from 'components/Form';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import OptionModel from 'models/Option';
import ICorporateOptionModel from 'models/ICorporateOptionModel';
import { MAX_DATE_SYSTEM, MIN_DATE_SYSTEM } from 'constants/Constants';
import { focusInput } from 'util/ControlUtils';

interface AddCorporateMemberFormInfoProps {
  dateOfBirthConfig;
  membershipSearchFilter;
  membershipConfiguration;
  errors;
  clubs;
  applicationTypes;
  corporateNames;
  salePeople;
  toggleFilter;
  filterButtonRef;
  handleChange;
  handleInputChange;
  handleDateChange;
  applyPromotionCode: (event) => void;
  showCheckIcon: boolean;
  secondarySalePeople: OptionModel[];
  isAllowEditClub?: boolean;
  corporateId?: string;
  isLoading: boolean;
  loadPackagePlansAfterChangeDate: (name: string) => void;
}

const AddCorporateMemberFormInfo: React.FC<AddCorporateMemberFormInfoProps> = ({
  dateOfBirthConfig,
  membershipSearchFilter,
  membershipConfiguration,
  errors,
  clubs,
  applicationTypes,
  corporateNames,
  salePeople,
  secondarySalePeople,
  toggleFilter,
  filterButtonRef,
  handleChange,
  handleInputChange,
  handleDateChange,
  applyPromotionCode,
  showCheckIcon,
  isAllowEditClub,
  corporateId,
  isLoading,
  loadPackagePlansAfterChangeDate
}) => {
  function memberValue(membershipSearchFilter, key, arrList) {
    return membershipSearchFilter[key]
      ? arrList.find(
          e => e.value.toString() === membershipSearchFilter[key].toString()
        )
      : arrList[0];
  }
  const findCurrentCorporateValue = (arr: ICorporateOptionModel[], corporateId) => {
    return arr.find((item: ICorporateOptionModel) => item.value.toString() === corporateId.toString());
  }
 
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="list-page">
        <p className="title-content room-name text-uppercase select-add-on-title--custom">
          {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.SELECT_PACKAGE_PLAN')}
        </p>
        <div className="list-page-content ml-auto d-flex">
          <div ref={filterButtonRef} className="box-btn-filter d-flex">
            <button
              className="btn btn-primary btn-height-sm btn-filter not-after"
              onClick={event => toggleFilter(event)}
            >
              <i className="icon icon-filter" />
              <span>{t('PAGE.MEMBERSHIPS.CREATE.FILTER')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="section-select">
        <FormSelect
          id="club"
          name="club"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB')}
          disabled={clubs.length === 0 || isAllowEditClub === false || isLoading}
          require
          onChange={event => handleChange('clubId', event)}
          value={memberValue(membershipSearchFilter, 'clubId', clubs)}
          options={clubs}
          errorMsg={t(errors.clubId)}
        />
        <FormSelect
          id="primarySalePersonId"
          name="primarySalePersonId"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_SALE_PERSON')}
          disabled={salePeople.length === 0 || isLoading}
          require
          onChange={event => handleChange('primarySalePersonId', event)}
          value={memberValue(
            membershipSearchFilter,
            'primarySalePersonId',
            salePeople
          )}
          options={salePeople}
          errorMsg={errors.primarySalePersonId}
        />
        <FormSelect
          id="secondarySalePersonId"
          name="secondarySalePersonId"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SECONDARY_SALE_PERSON')}
          disabled={secondarySalePeople.length === 0 || isLoading}
          onChange={event => handleChange('secondarySalePersonId', event)}
          value={memberValue(
            membershipSearchFilter,
            'secondarySalePersonId',
            secondarySalePeople
          )}
          options={secondarySalePeople}
          errorMsg={errors.secondarySalePersonId}
        />
        <FormSelect
          id="applicationType"
          name="applicationType"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.APPLICATION_TYPE')}
          disabled={applicationTypes.length === 0 || isLoading}
          require
          onChange={event => handleChange('applicationTypeId', event)}
          value={memberValue(
            membershipSearchFilter,
            'applicationTypeId',
            applicationTypes
          )}
          options={applicationTypes}
          errorMsg={errors.applicationTypeId}
        />
        <FormSelect
          id="corporateName"
          name="corporateName"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CORPORATE_NAME')}
          disabled={corporateNames.length === 0 || isLoading}
          require
          onChange={event => handleChange('corporateName', event)}
          value={corporateId ? findCurrentCorporateValue(corporateNames, corporateId) : memberValue(
            membershipSearchFilter,
            'corporateId',
            corporateNames
          )}
          options={corporateNames}
          errorMsg={errors.applicationTypeId}
        />

        {dateOfBirthConfig.isVisible && (
          <FormDatePicker
            id="date-of-birth"
            name="date-of-birth"
            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH')}
            selectKey="dateOfBirth"
            className="datepicker-wrapper"
            maxDate={new Date()}
            minDate={new Date(MIN_DATE_SYSTEM)}
            disabled={isLoading}
            required={dateOfBirthConfig.isRequired}
            dateFormat={membershipConfiguration.newMemberDOBFormat}
            selectedDate={membershipSearchFilter.dateOfBirth}
            onChange={value => handleDateChange(value, 'dateOfBirth')}
            errorMsg={t(errors.dateOfBirth)}
            onSelect={()  => focusInput("date-of-birth")}
            onBlur={() => loadPackagePlansAfterChangeDate('dateOfBirth')}
            onKeyDown={event => {
              if(event.key === 'Backspace' || event.key === 'Delete') {
                event.preventDefault();
              }
              if(event.key === 'Enter') {
                loadPackagePlansAfterChangeDate('dateOfBirth');
              }
            }}
          />
        )}
        <div className="form-group">
          <label>{t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PROMOTION_CODE')}</label>
          <div className="input-group promo-input">
            <input
              id="promotion-input"
              className="form-control"
              type="text"
              name="code"
              disabled={isLoading}
              autoComplete="off"
              onChange={event => handleInputChange('promotionCode', event)}
              value={membershipSearchFilter.promotion.code || ''}
              onBlur={event => applyPromotionCode(event)}
              onKeyUp={event => {
                event.which = event.which || event.keyCode;
                if (event.which === 13) {
                  applyPromotionCode(event);
                }
              }}
            />
            {showCheckIcon && <CheckIcon />}
          </div>
          <span className="text-danger input-error">
            {t(errors.promotionCode)}
          </span>
        </div>
        <FormDatePicker
          id="start-date"
          name="start-date"
          label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE')}
          selectKey="startDate"
          disabled={isLoading}
          className="datepicker-wrapper"
          minDate={
            membershipSearchFilter.minStartDate
              ? membershipSearchFilter.minStartDate
              : new Date()
          }
          maxDate={new Date(MAX_DATE_SYSTEM)}
          required
          dateFormat={membershipConfiguration.newMemberTimeFormat}
          selectedDate={membershipSearchFilter.startDate}
          onChange={value => handleDateChange(value, 'startDate')}
          errorMsg={errors.startDate}
          onSelect={()  => focusInput("start-date")}
          onBlur={() => loadPackagePlansAfterChangeDate('startDate')}
          onKeyDown={event => {
            if(event.key === 'Backspace' || event.key === 'Delete') {
              event.preventDefault();
            }
            if(event.key === 'Enter') {
              loadPackagePlansAfterChangeDate('startDate');
            }
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default AddCorporateMemberFormInfo;
