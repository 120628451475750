import React from 'react';
import { DataDescription } from '../interface';
import classNames from 'classnames';
import SubContent from './SubContent';

interface DescriptionContentProps {
  data: DataDescription[];
  title?: string;
}

const DescriptionContent = (props: DescriptionContentProps) => {
  const { data, title } = props;

  if (data.length === 0) {
    return <></>;
  }

  return (
    <div className="select-group open">
      {title && <p>{title}</p>}
      {data.map(
        field =>
          field.isVisible && (
            <div key={field.fieldLabel} className="row pb-2">
              {field.fieldLabel && (
                <div className="col-md-5 col-lg-5">{field.fieldLabel}</div>
              )}
              <div
                className={classNames({
                  'col-md-7 col-lg-7': field.fieldLabel,
                  'col-md-12 col-lg-12': !field.fieldLabel
                })}
              >
                {Array.isArray(field.data) ? (
                  <SubContent data={field.data} />
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: field.data }} />
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default DescriptionContent;
