import moment from 'moment';
import { PAYMENT_METHOD_TYPES } from 'constants/Constants';
import { BankUtil } from 'models/EzyPay/Bank';
import { CardUtil } from 'models/EzyPay/Card';
import { PayToUtil } from 'models/EzyPay/PayTo';

const currentTime = () => {
  return moment().format('YYYY-MM-DD') + 'T00:00:00';
};

const getCommonFields = ezyPaymentInfo => {
  return {
    paymentMethodCode: 'PR',
    status: 'AC',
    recurringDetailReference: ezyPaymentInfo.paymentMethodToken,
    paymentProvider: 'EZ',
    isRecurringDetailReferenceActive: false
  };
};

const getCreditCardPaymentData = card => {
  return {
    paymentTokenIdentifierId: '',
    paymentMethodTypeCode: 'CC',
    paymentMethodTypeName: 'Credit Card',
    accountName: card.accountHolderName,
    accountNumber: CardUtil.getCardNumber(card),
    issueNumber: 0,
    nationalID: '',
    cardType: card.type,
    expiration: CardUtil.getExpireDate(card).replace('/', ''),
    tenderName: card.type,
    tenderTypeCode: card.type,
    paymentMethod: 'CC'
  };
};

const getDirectDebitPaymentData = (bank, payTo, paymentDetails) => {
  if (bank) {
    return {
      creationDate: currentTime(),
      nationalID: paymentDetails.nationalIdNumber,
      paymentMethodTypeCode: 'DD',
      paymentMethodTypeName: 'Direct Debit',
      accountName: bank.accountHolderName,
      accountNumber: BankUtil.getDomestic(bank),
      IssuerCode: 212200,
      IssueNumber: 0,
      cardType: 'BANK',
      tenderName: 'Direct Debit',
      tenderTypeCode: 'DDEB',
      paymentMethod: 'DD',
      expiration: ''
    };
  }
  if (payTo) {
    const accountType = PayToUtil.getBankAccountType(payTo);
    if (accountType === 'Bank Account') {
      return {
        paymentTokenIdentifierId: '',
        creationDate: currentTime(),
        paymentMethodTypeCode: 'DD',
        paymentMethodTypeName: 'Direct Debit',
        accountName: PayToUtil.getAccountName(payTo),
        accountNumber: PayToUtil.getDomesticNumber(payTo),
        IssuerCode: 212200,
        IssueNumber: 0,
        nationalID: '',
        cardSubType: accountType,
        cardType: 'PAYTO',
        tenderName: 'PayTo',
        tenderTypeCode: 'PAYT',
        paymentMethod: 'DD'
      };
    } else if (accountType === 'Email') {
      return {
        paymentTokenIdentifierId: '',
        creationDate: currentTime(),
        paymentMethodTypeCode: 'DD',
        paymentMethodTypeName: 'Direct Debit',
        accountName: PayToUtil.getAccountName(payTo),
        accountNumber: '',
        IssuerCode: '',
        IssueNumber: 0,
        nationalID: '',
        cardType: 'PAYTO',
        cardSubType: accountType,
        PayIdViaEmail: PayToUtil.getPayIdEmail(payTo),
        PayToMandateStatus: PayToUtil.getMandateReason(payTo),
        tenderName: 'PayTo',
        tenderTypeCode: 'PAYT',
        paymentMethod: 'DD'
      };
    } else if (accountType === 'Phone Number') {
      return {
        paymentTokenIdentifierId: '',
        creationDate: currentTime(),
        paymentMethodTypeCode: 'DD',
        paymentMethodTypeName: 'Direct Debit',
        accountName: PayToUtil.getAccountName(payTo),
        accountNumber: '',
        IssuerCode: '',
        IssueNumber: 0,
        nationalID: '',
        cardType: 'PAYTO',
        cardSubType: accountType,
        PayIdViaPhoneNumber: PayToUtil.getPayIdPhoneNumber(payTo),
        PayToMandateStatus: PayToUtil.getMandateReason(payTo),
        tenderName: 'PayTo',
        tenderTypeCode: 'PAYT',
        paymentMethod: 'DD'
      };
    }
  }
};

export const getEzyPaymentData = (
  currentPayment,
  paymentDetails,
  ezyPaymentInfo
) => {
  const cashPayload = {
    paymentMethodCode: currentPayment.planMethodCode,
    paymentMethodTypeCode: currentPayment.paymentMethodTypeCode,
    paymentMethodTypeName: currentPayment.planPaymentMethodTypeName,
    tenderName: 'Cash',
    tenderTypeCode: 'CASH',
    status: 'AC'
  };

  switch (paymentDetails.primaryPaymentMethodId) {
    case PAYMENT_METHOD_TYPES.CREDIT_CARD:
      const { card } = ezyPaymentInfo;
      return {
        ...getCommonFields(ezyPaymentInfo),
        ...getCreditCardPaymentData(card)
      };

    case PAYMENT_METHOD_TYPES.DIRECT_DEBIT:
      const { bank, payTo } = ezyPaymentInfo;
      return {
        ...getCommonFields(ezyPaymentInfo),
        ...getDirectDebitPaymentData(bank, payTo, paymentDetails)
      };

    case PAYMENT_METHOD_TYPES.CASH:
    default:
      return {
        ...cashPayload
      };
  }
};
