import {
  ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_POST,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
  ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
} from 'util/FieldConfiguration/Keys';

export const ACTIVE_STATUS_CODE = 'AC';
export const CHANNEL_CODE_QUESTION_CODE = 'C1';
export const MARKETING_CAMPAIGN_QUESTION_CODE = 'C2';
export const RELATIONSHIP_TEXT = 'Relationship';
export const GUARDIAN_NAME_TEXT = 'Guardian Name';
export const GUARDIAN_PHONE_NUMBER_TEXT = 'Guardian Phone';
export const GUARDIAN_ADDRESS_TEXT = 'Guardian Address';
export const GUARDIAN_RELATIONSHIP_TEXT = 'Guardian Relationship';
export const GUARDIAN_IDENTITY_NO_TEXT = 'Guardian Identity No.';
export const GUARDIAN_IDENTITY_TYPE_ID_TEXT = 'Guardian Identity Type Id';
export const GUARDIAN_PASS_TEXT = 'Guardian Pass';
export const EMERGENCY_CONTACT_NAME_TEXT = 'Emergency Contact Name';
export const EMERGENCY_CONTACT_PHONE_NUMBER_TEXT =
  'Emergency Contact Phone Number';
export const MARKETING_SOURCE_TEXT = 'Source';
export const MARKETING_CAMPAIGN_TEXT = 'Marketing Campaign';
export const CHANNEL_CODE_TEXT = 'Channel Code';
export const COMPANY_NAME_TEXT = 'Company Name';
export const OCCUPATION_TEXT = 'Occupation';
export const REFERRED_BY_NAME_TEXT = 'Referred by (name)';
export const REFERRED_BY_MEMBERSHIP_NUMBER_TEXT =
  'Referred by (Membership number)';
export const OTHER_COMMISSION_NOTE_TEXT = 'Other Commission Note';
export const GENERAL_NOTE_TEXT = 'General Note';
export const RECURRING_CARD_TYPE_TEXT = 'Company Name';
export const EMAIL_SYSTEM_CONFIG_KEY =
  'Validation.RegularExpressions.Generic.Email';
export const OTHER_FIELDS_QUESTION_TYPE_CODE = 'LY';
export const FULL_ACCESS = 'Full Access';
export const SWAL_COMMON_STYLE = {
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-dark'
  },
  buttonsStyling: false,
  focusConfirm: false
};
export const UNDEFINED_VALUE = 'Undefined';
export const INVALID_PROMO = 'PROMOTION_IS_INVALID';
export const CLUB_KEY_CONMAN = 'GymSales.AllowSellingCrossClub';
export const FUTURE_START_PERIOD_CODE = 'FJ';
export const GUEST_PASS_STATUS = 'Guest Pass';
export const PRIMARY_PAYMENT_METHOD = 'PR';

export const CONTACT_METHOD_LIST = [
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_POST,
    name: 'Post',
    id: '1',
    value: 'post',
    label: 'Post'
  },
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
    name: 'Home Telephone',
    id: '2',
    value: 'homeTelephone',
    label: 'Home Telephone'
  },
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
    name: 'Mobile Telephone',
    id: '3',
    value: 'mobileTelephone',
    label: 'Mobile Telephone'
  },
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE,
    name: 'Work Telephone',
    id: '4',
    value: 'mobileTelephone',
    label: 'Work Telephone'
  },
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
    name: 'Email',
    id: '5',
    value: 'email',
    label: 'Email'
  },
  {
    key: ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
    name: 'SMS',
    id: '6',
    value: 'sms',
    label: 'SMS'
  }
];

export const DEFAULT_CONTACT_DATA = {
  id: '',
  name: '',
  value: '',
  label: '',
  key: '',
  code: ''
};

export const LANGUAGE_MAPPER_CONFIG =
  'MemberPreferences.PreferredLanguage.AnswerLangMapper';
export const ENGLISH_PREFERRED_LANGUAGE = 'en-GB';
export const HK_TRANSLATION_FILE_NAME = 'en-HK';
export const SG_TRANSLATION_FILE_NAME = 'en-SG';
export const HK_DOMAIN = 'hk';
export const SG_DOMAIN = 'sg';
export const NEW_MEMBERSHIP_SCENARIO = 'NewMembership';
export const AU_DOMAIN = 'au';
