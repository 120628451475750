import React from 'react';
import { ITemplate } from './interface';
import MembershipConfiguration from 'models/MembershipConfiguration';
import {
  IAllPackagePlans,
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember
} from 'interfaces/Membership';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import { useDispatch } from 'react-redux';
import * as MembershipActions from 'reduxs/actions/ConvertGuestToCorporateMember';
import * as NewMembershipActions from 'reduxs/actions/NewMembership';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import PaymentDetails from 'models/PaymentDetails';
import { useTypedSelector } from 'reduxs';
import { STORAGE } from 'constants/Constants';

import { IQuestionConfigs } from 'models/IQuestionConfigs';
import SelectPackagePlan from 'features/SelectPackagePlanConvertGuestToCorporateMember';
import SelectPaymentDetails from 'features/SelectPaymentDetail';
import MemberSummary from 'features/MemberSummary';
import SelectProductExtras from 'features/SelectProductExtras';
import SelectPersonalDetails from 'features/SelectPersonalDetailsConvertGuestToMember';
import {
  setAddMemberPackagePlanSearchFilter,
  setAddMemberPersonalDetails
} from 'reduxs/actions/NewMembership';

const NewMembership = ({
  handleCancel,
  handleCreateMember,
  configs,
  operatorId,
  externalId
}) => {
  const dispatch = useDispatch();
  const convertGuestToCorporateMember = useTypedSelector(
    state => state.convertGuestToCorporateMember
  );
  const payment = useTypedSelector(state => state.payment);
  const { membershipConfiguration } = convertGuestToCorporateMember;

  const setAllPackageAction = (allPackagePlans: IAllPackagePlans) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberAllPackagePlans(
        allPackagePlans
      )
    );
  };
  const setProductExtrasAction = (
    selectedPromotions: IPromotionCatergory[],
    selectedAddons: AddOn[]
  ) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberProductExtras(
        selectedPromotions,
        selectedAddons
      )
    );
  };

  const setPackagePlanSearchFilterAction = (
    membershipSearchFilter: MembershipSearchFilter
  ) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberPackagePlanSearchFilter(
        membershipSearchFilter
      )
    );
    dispatch(setAddMemberPackagePlanSearchFilter(membershipSearchFilter));
  };

  const setConfigurationAction = (
    membershipConfiguration: MembershipConfiguration
  ) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberConfiguration(
        membershipConfiguration
      )
    );
  };

  const setSelectedPackagePlanAction = (
    packagePlan: IPackagePlanItemAddCorporateMember | IPackagePlanItem
  ) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberSelectedPackagePlan(
        packagePlan
      )
    );
    dispatch(NewMembershipActions.setAddMemberSelectedPackagePlan(packagePlan));
  };

  const setPersonalDetailsAction = (personalDetails: PersonalDetailsModel) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberPersonalDetails(
        personalDetails
      )
    );
    dispatch(setAddMemberPersonalDetails(personalDetails));
  };

  const setQuestionConfigsAction = (questionConfigs: IQuestionConfigs) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberQuestionConfigs(
        questionConfigs
      )
    );
  };

  const setPaymentDetailsAction = (paymentDetails: PaymentDetails) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberPaymentDetails(
        paymentDetails
      )
    );
  };

  const setGuestCorporateIdAction = (corporateId: number) => {
    dispatch(
      MembershipActions.setConvertGuestToCorporateMemberGuestCorporateId(
        corporateId
      )
    );
  };

  if (!membershipConfiguration || membershipConfiguration.stepIndex === 1) {
    return (
      <SelectPackagePlan
        onCancel={handleCancel}
        configs={configs}
        setAllPackageAction={setAllPackageAction}
        setSelectedPackagePlanAction={setSelectedPackagePlanAction}
        setConfigurationAction={setConfigurationAction}
        setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
        setProductExtrasAction={setProductExtrasAction}
        setGuestCorporateIdAction={setGuestCorporateIdAction}
      />
    );
  }
  const { stepIndex, stepLatest } = membershipConfiguration;
  switch (true) {
    case stepIndex === 2 && stepLatest > 1:
      return (
        <SelectProductExtras
          isCorporate
          configs={configs}
          membership={convertGuestToCorporateMember}
          onCancel={handleCancel}
          setSelectedPackagePlanAction={setSelectedPackagePlanAction}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setProductExtrasAction={setProductExtrasAction}
        />
      );
    case stepIndex === 3 && stepLatest > 2:
      return (
        <SelectPersonalDetails
          newMembership={convertGuestToCorporateMember}
          onCancel={handleCancel}
          setConfigurationAction={setConfigurationAction}
          setPackagePlanSearchFilterAction={setPackagePlanSearchFilterAction}
          setPersonalDetailsAction={setPersonalDetailsAction}
          setQuestionConfigsAction={setQuestionConfigsAction}
        />
      );
    case stepIndex === 4 && stepLatest > 3:
      return (
        <SelectPaymentDetails
          configs={configs}
          membership={convertGuestToCorporateMember}
          payment={payment}
          onCancel={handleCancel}
          membershipStorage={STORAGE.CONVERT_GUEST_TO_CORPORATE_MEMBER}
          setPaymentDetailsAction={setPaymentDetailsAction}
          setConfigurationAction={setConfigurationAction}
          operatorId={operatorId}
          externalId={externalId}
        />
      );
    case stepIndex === 5 && stepLatest > 4:
      return (
        <MemberSummary
          membership={convertGuestToCorporateMember}
          onCancel={handleCancel}
          onFinish={handleCreateMember}
          setConfigurationAction={setConfigurationAction}
        />
      );
    default:
      return <></>;
  }
};

const Template: React.FC<ITemplate> = ({
  configs,
  handleCancel,
  handleCreateMember,
  isLoading,
  operatorId,
  externalId
}) => {
  return !isLoading ? (
    <div className="add-member-page">
      <NewMembership
        handleCancel={handleCancel}
        handleCreateMember={handleCreateMember}
        configs={configs}
        operatorId={operatorId}
        externalId={externalId}
      />
    </div>
  ) : (
    <></>
  );
};

export default Template;
