import React, { useEffect, useState } from 'react';
import { IMemberSummary } from './interface';
import {
  getCorporateNames,
  getPackageEndDate
} from 'services/membership-service';
import Template from './template';
import { scrollToTop } from 'util/Common';
import { DisplayDateFormat, transformOptions } from 'util/ControlUtils';
import OptionModel from 'models/Option';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';

const MemberSummary: React.FC<IMemberSummary> = ({
  guest,
  guestId,
  answerMappingData,
  onCancel,
  onFinish,
  setConfigurationAction
}) => {
  const { searchFilter, selectedPackagePlan } = guest;
  const [corporateNames, setCorporateNames] = useState<OptionModel[]>([]);
  const [packageEndDate, setPackageEndDate] = useState('');
  useEffect(() => {
    scrollToTop();
    if (selectedPackagePlan) {
      Promise.all([
        getCorporateNames({ clubId: searchFilter.clubId }),
        getPackageEndDate({
          clubId: Number(searchFilter.clubId),
          startDate: DisplayDateFormat(
            searchFilter.startDate,
            GENERAL_DATABASE_DATE_FORMAT
          ),
          planId: selectedPackagePlan.originalPlanId
        })
      ]).then(([res1, res2]) => {
        setCorporateNames(
          transformOptions(res1, 'name', 'corporateIdentifier')
        );
        setPackageEndDate(res2);
      });
    }
  }, []);

  const [openDropdown, setOpenDropdown] = useState({
    personalDetails: true,
    guestPassDetails: true,
    referrerDetails: true,
  });

  const handlePrevious = () => {
    const { configuration } = guest;
    configuration.stepIndex = 1;
    setConfigurationAction(configuration);
  };

  const handleOpenDropdown = key => {
    setOpenDropdown({
      ...openDropdown,
      [key]: !openDropdown[key]
    });
  };

  const handleEdit = index => {
    const { configuration } = guest;
    configuration.stepIndex = index;
    setConfigurationAction(configuration);
  };

  const goToStep = stepIndex => {
    const { configuration } = guest;
    configuration.stepIndex = stepIndex;
    configuration.stepLatest = stepIndex;

    setConfigurationAction(configuration);
  };

  return (
    <Template
      guest={guest}
      answerMappingData={answerMappingData}
      corporateNames={corporateNames}
      openDropdown={openDropdown}
      packageEndDate={packageEndDate}
      onCancel={onCancel}
      onFinish={onFinish}
      handleOpenDropdown={handleOpenDropdown}
      handlePrevious={handlePrevious}
      handleEdit={handleEdit}
      goToStep={goToStep}
    />
  );
};

export default React.memo(MemberSummary);
