import { Club } from './Club';

export default class MembershipSearchFilter {
  primarySalePersonId = 0;
  secondarySalePersonId = 0;
  primarySalePersonName = '';
  secondarySalePersonName = '';
  applicationTypeId = 0;
  corporateId = 0;
  reciprocalAccessName = '';
  accessName = '';
  minimumContractName = '';
  paymentMethodName = '';
  subsidyTypeName = '';
  agreementPartyId = 0;
  clubId = 0;
  clubName = '';
  operator = '';
  startDate: Date | null = null;
  dateOfBirth: Date | null = null;
  selectedPackagePlanId = 0;
  promotion = new PromotionSearchFilter();
  minStartDate? = new Date();
  clubSelect?: Club | null = null;
}

export class PromotionSearchFilter {
  code: string = '';
  id: number | null = null;
  categoryId: number | null = null;
  categoryName: string = '';
}
