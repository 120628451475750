import React, { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Question } from 'models/PersonalDetails';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { required } from 'util/FormValidations';

interface IHealthQuestion {
  questions: Question[];
  healthQuestions: Question[];
  healthQuestionRef: MutableRefObject<UseFormReturn<
    HealthQuestionFormInput
  > | null>;
}

export type HealthQuestionFormInput = {
  healthQuestions: Question[];
  questions: (Question & { isChecked: boolean })[];
};

const HealthQuestion: React.FC<IHealthQuestion> = ({
  questions,
  healthQuestions,
  healthQuestionRef
}) => {
  const { t } = useTranslation();

  const form = useForm<HealthQuestionFormInput>({
    defaultValues: {
      healthQuestions: healthQuestions,
      questions: questions.filter(q => q.active)
    }
  });

  const {
    control,
    setValue,
    watch,
    register,
    getValues,
    formState: { errors }
  } = form;

  const { fields } = useFieldArray({
    control,
    name: 'questions'
  });

  const lastQuestion = fields[fields.length - 1];
  const healthQuestionsWatch = watch('healthQuestions');
  
  const isQuestionChecked = (id: number) => {
    return healthQuestionsWatch.some((question) => question.id === id);
  }

  let questionRows;

  const onCheckboxChange = (
    checked: boolean,
    question: Question,
    id: number
  ) => {
    const healthQuestion: Question = {
      ...question,
      id
    };

    const newAnsweredQuestions = checked
      ? healthQuestionsWatch.concat(healthQuestion)
      : healthQuestionsWatch.filter(aq => aq.id !== healthQuestion.id);

    setValue('healthQuestions', newAnsweredQuestions);
  };

  useEffect(() => {
    if (healthQuestionRef) {
      healthQuestionRef.current = form;
    }
  }, []);

  if (questions.length > 1) {
    let questionItems = fields.slice(0, -1).map((question, index) => {
      const id = getValues(`questions.${index}.id`);
      return (
        <div key={question.id} className="col-md-6">
          <div className="form-group">
            <div className="form-check">
              <input
                id={`q-${question.id}`}
                type="checkbox"
                checked={isQuestionChecked(id)}
                {...register(`questions.${index}.isChecked`, {
                  onChange: event => {
                    onCheckboxChange(event.target.checked, question, id);
                  },
                  required: required(question.isRequired)
                })}
              />
              <label htmlFor={`q-${question.id}`} className="icon-checked-ico">
                <span>{t(question.name)}</span>
                {question.isRequired && (
                  <span className="text-primary bold">&nbsp;*</span>
                )}
              </label>
            </div>
            {errors.questions && (
              <span className="text-danger input-error">
                {t(
                  ((errors.questions[index] || {}).isChecked || {}).message ||
                    ''
                )}
              </span>
            )}
          </div>
        </div>
      );
    });

    //populate question to row with 2 items/row
    questionRows = Array.from(
      { length: Math.ceil(questionItems.length / 2) },
      (_, i) => (
        <div key={i} className="row">
          {questionItems.slice(i * 2, i * 2 + 2)}
        </div>
      )
    );
  }

  return (
    <>
      {questionRows}
      {!!lastQuestion.id && (
        <div className="row">
          <div className="col">
            <div className="form-group pt-3 bd-t-gray2">
              <div className="form-check">
                <input
                  id="q-all"
                  type="checkbox"
                  checked={isQuestionChecked(fields.length - 1)}
                  {...register(`questions.${fields.length - 1}.isChecked`, {
                    onChange: event => {
                      const id = getValues(`questions.${fields.length - 1}.id`);
                      onCheckboxChange(event.target.checked, lastQuestion, id);
                    },
                    required: required(lastQuestion.isRequired)
                  })}
                />
                <label htmlFor="q-all" className="icon-checked-ico">
                  <span>{t(lastQuestion.name)}</span>
                </label>
                {lastQuestion.isRequired && (
                  <span className="text-primary bold">&nbsp;*</span>
                )}
              </div>
              {errors.questions && (
                <span className="text-danger input-error">
                  {t(
                    (
                      (errors.questions[fields.length - 1] || {}).isChecked ||
                      {}
                    ).message || ''
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HealthQuestion;
