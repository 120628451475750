import * as Redux from 'redux';
import { createBrowserHistory } from 'history';

import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers, { RootState } from './reducers/index';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
let middlewares = [thunk, routeMiddleware];
const isDisabledLogger = true;
if (process.env.NODE_ENV !== 'production' && !isDisabledLogger) {
  middlewares = [...middlewares, logger];
}

const store = Redux.createStore(
  reducers(history),
  {},
  Redux.compose(Redux.applyMiddleware(...middlewares))
);

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export function configureStore() {
  return store;
}

export { history };
