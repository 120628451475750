import React from 'react';
import { useDispatch } from 'react-redux';
import { resetAddMemberState } from 'reduxs/actions/NewMembership';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import { useTranslation } from 'react-i18next';
import { getParamFromWindowSearch } from 'util/ControlUtils';
import { postMember } from 'services/membership-service';
import { resetOnlinePayment } from 'reduxs/actions/Payment';
import Popup from 'util/Popup';
import { getEsignatureUrl, getSubTenantId } from 'services/common-service';
import IAddNewCorporateMemberPage from './interface';
import Template from './template';
import { useTypedSelector } from 'reduxs';
import { generateHandleSaveDataPayload } from 'util/Payload';
import { GO_TO_HOME_PAGE_MESSAGE } from 'constants/Iframe';
import { selectPaymentInfo } from 'selectors/member';

const AddNewCorporateMemberPage: React.FC<IAddNewCorporateMemberPage> = () => {
  const newCorporateMembership = useTypedSelector(
    state => state.newCorporateMembership
  );
  const conman = useTypedSelector(state => state.conman);
  const operatorId = Number(getParamFromWindowSearch('operator_id'));

  const paymentInfo = useTypedSelector(state => selectPaymentInfo(state));
  const isDisableEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCancel = () => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('COMMON.CANCEL_NOTIFICATION'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        dispatch(resetOnlinePayment());
        dispatch(resetAddMemberState());
        window.parent.postMessage(GO_TO_HOME_PAGE_MESSAGE, getEsignatureUrl());
      }
    });
  };

  const handleCreateMember = () => {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      text: t('MSG.ARE_YOU_SURE_TO_FINISH'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        handleSave();
      }
    });
  };

  const handleSave = () => {
    Popup.showProcessing();
    const data = {
      ...generateHandleSaveDataPayload(
        newCorporateMembership,
        operatorId,
        conman,
        isDisableEzyPay!,
        paymentInfo!
      ),
      agreementPartyId:
        newCorporateMembership.membershipSearchFilter.agreementPartyId || ''
    };
    postMember({ data })
      .then(res => {
        observer.publish('flagUnsavedChangeEvent', false);
        Swal.close();
        Popup.showMessageAddMemberSuccess(
          () =>
          (window.parent.location.href = `${getEsignatureUrl()}/digitalcontract/signdocument?memberid=${res.id
            }&subTenantId=${getSubTenantId()}`)
        );
      })
      .catch((reason: any) => {
        Swal.close();
        Popup.showMessageCustom({
          text: reason.response.data.message,
          type: 'error'
        });
      });
  };

  return (
    <Template
      configs={conman.configs}
      handleCancel={handleCancel}
      handleCreateMember={handleCreateMember}
      operatorId={operatorId}
    />
  );
};

export default AddNewCorporateMemberPage;
