import { PAYMENT_METHOD_TYPES } from 'constants/Constants';
import OptionModel from './Option';
import { EzyPayInfo } from './EzyPay/EzyPayInfo';

export default class PaymentDetails {
  primaryPaymentMethod: OptionModel[] = [{ label: '', value: '' }];
  primaryPaymentMethodId = '';
  secondaryPaymentMethod = '';
  paymentTokenInstrumentIdentifierId = '';
  paymentInfo: EzyPayInfo | null = null;
  nationalIdNumber = '';
  otherNationalIdNumber = '';
  // Online payment
  cardType = '';
  cardHolderName = '';
  cardExpiryDate = '';
  cardNumber = '';
  paymentToken = '';
  expirationDate = '';
  // Direct debit
  issuingBankId = '';
  issuingBank: any;
  accountName = '';
  accountNumber = '';
  isLoadingEzyPay: boolean = false;
}

export const updatePrimaryPaymentMethod = (
  paymentDetail,
  selectedPackagePlan
) => {
  paymentDetail.primaryPaymentMethod = selectedPackagePlan.paymentMethodTypes
    .filter(
      paymentMethodType =>
        paymentMethodType.paymentMethodTypeCode !== PAYMENT_METHOD_TYPES.NONE
    )
    .map(paymentMethodType => {
      return {
        value: paymentMethodType.paymentMethodTypeCode,
        label: paymentMethodType.planPaymentMethodTypeName
      };
    });

  return paymentDetail;
};

export const updatePaymentWithDataPayment = (
  paymentDetail,
  dataPaymentDetail
) => {
  if (dataPaymentDetail && dataPaymentDetail.primaryPaymentMethodId) {
    return {
      ...dataPaymentDetail
    };
  }
  return {
    ...paymentDetail,
    secondaryPaymentMethod: 'cash'
  };
};
