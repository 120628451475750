import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from 'components/Icon/Icon';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { IPromotion } from 'interfaces/Promotion';
import moment from 'moment';
import { GENERAL_DATE_FORMAT, PROMOTION_TYPE } from 'constants/Constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { IConfig } from 'interfaces/SystemConfig';
import {
  IAddons,
  IAppliedPromotion,
  IPromotions,
  ITemplate,
  PromotionProps
} from './interface';
import { condition } from 'util/Common';

const TooltipInfoTable = ({
  promotion,
  currencyCode
}: {
  promotion: IPromotion;
  currencyCode?: IConfig;
}) => {
  const { t } = useTranslation();
  return (
    <table className="tooltip-info-table">
      <tbody>
        {promotion.payments.map(e => (
          <tr key={e.id}>
            <td>
              {t(
                `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_TYPE.${e.type.code}`,
                {
                  value: e.promotionValue,
                  currency: currencyCode && currencyCode.value,
                  paymentSubType: t(
                    `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_SUBTYPE.${
                      e.typeCode
                    }`
                  )
                }
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Promotion: React.FC<PromotionProps> = ({
  promotion,
  isActive,
  type,
  currencyCode,
  handleUpdatePromotions
}) => {
  const { t } = useTranslation();

  return (
    <div className="form-check">
      <label className="custom-radio">
        <div className="card-gr">
          <div className="card-gr__content">
            <h4>{promotion.name}</h4>
            <ul>
              <li>
                {t(
                  `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_TYPE.${
                    promotion.payments[0].type.code
                  }`,
                  {
                    value: promotion.payments[0].promotionValue,
                    currency: currencyCode && currencyCode.value,
                    paymentSubType: t(
                      `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_SUBTYPE.${
                        promotion.payments[0].typeCode
                      }`
                    )
                  }
                )}
              </li>
              <li>
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}:{' '}
                {moment(promotion.expirationDate).format(GENERAL_DATE_FORMAT)}
              </li>
            </ul>
          </div>
          <div className="card-gr__tooltip">
            {promotion.payments && promotion.payments.length > 1 ? (
              <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{
                  tooltip: 'promotion-tooltip'
                }}
                title={
                  <TooltipInfoTable
                    promotion={promotion}
                    currencyCode={currencyCode}
                  />
                }
                placement="top-start"
                className="btn-tooltip"
              >
                <InfoIcon
                  fontSize="small"
                  className="infoIcon icon-detail-no-boder"
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </div>
        <input
          type="radio"
          name={type}
          checked={isActive}
          onClick={() => {
            handleUpdatePromotions(type, promotion);
          }}
          onChange={() => {}}
        />
        <span className="checkmark-radio" />
        {promotion.isNoPaymentsAvailable && (
          <span className="payment-label">
            {t('PAGE.MEMBERSHIPS.CREATE.STEP_2.NO_PAYMENT_PROMOTION')}
          </span>
        )}
      </label>
    </div>
  );
};

const AppliedPromotion: React.FC<IAppliedPromotion> = ({
  type,
  promotion,
  currencyCode,
  confirmRemove
}) => {
  const { t } = useTranslation();
  return (
    <div className="form-check form-check__promotion-applied">
      <label className="custom-radio custom-radio--disabled">
        <div className="card-gr">
          <div className="card-gr__content">
            <h4>{promotion.name}</h4>
            <ul>
              <li>
                {t(
                  `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_TYPE.${
                    promotion.payments[0].type.code
                  }`,
                  {
                    value: promotion.payments[0].promotionValue,
                    currency: currencyCode && currencyCode.value,
                    paymentSubType: t(
                      `PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_SUBTYPE.${
                        promotion.payments[0].typeCode
                      }`
                    )
                  }
                )}
              </li>
              <li>
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}:{' '}
                {moment(promotion.expirationDate).format(GENERAL_DATE_FORMAT)}
              </li>
            </ul>
          </div>
          <div className="card-gr__tooltip">
            {promotion.payments && promotion.payments.length > 1 ? (
              <Tooltip
                disableFocusListener
                disableTouchListener
                classes={{
                  tooltip: 'promotion-tooltip'
                }}
                title={
                  <TooltipInfoTable
                    promotion={promotion}
                    currencyCode={currencyCode}
                  />
                }
                placement="top-start"
                className="btn-tooltip"
              >
                <InfoIcon
                  fontSize="small"
                  className="infoIcon icon-detail-no-boder"
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </div>
        <input type="radio" name={type} checked disabled />
        <span className="checkmark-radio" />
        {promotion.isNoPaymentsAvailable && (
          <span className="payment-label">
            {t('PAGE.MEMBERSHIPS.CREATE.STEP_2.NO_PAYMENT_PROMOTION')}
          </span>
        )}
      </label>
      <button className="remove" onClick={() => confirmRemove(type)}>
        <TrashIcon />
      </button>
    </div>
  );
};

const Promotions: React.FC<IPromotions> = ({
  promotionCategory,
  isExisted,
  activePromotions,
  currencyCode,
  confirmRemove,
  handleUpdatePromotions
}) => {
  const { t } = useTranslation();
  const renderPromotions = () =>
    promotionCategory.length > 0 ? (
      promotionCategory.map(promotion => (
        <Promotion
          type={PROMOTION_TYPE.PROMOTION}
          promotion={promotion}
          isActive={activePromotions.includes(promotion.id)}
          key={promotion.id}
          currencyCode={currencyCode}
          handleUpdatePromotions={handleUpdatePromotions}
        />
      ))
    ) : (
      <>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.NO_PROMOTIONS_AVAILABLE')}</>
    );

  return (
    <>
      <div className="option-group select-group open">
        {isExisted && promotionCategory.length > 0 ? (
          <AppliedPromotion
            currencyCode={currencyCode}
            type={PROMOTION_TYPE.PROMOTION}
            promotion={promotionCategory[0]}
            confirmRemove={confirmRemove}
          />
        ) : (
          renderPromotions()
        )}
      </div>
    </>
  );
};

const SpecialPromotions: React.FC<IPromotions> = ({
  promotionCategory,
  isExisted,
  currencyCode,
  activePromotions,
  confirmRemove,
  handleUpdatePromotions
}) => {
  const { t } = useTranslation();
  const renderPromotions = () =>
    promotionCategory.length > 0 ? (
      promotionCategory.map(promotion => (
        <Promotion
          type={PROMOTION_TYPE.SPECIAL_PROMOTION}
          promotion={promotion}
          key={promotion.id}
          currencyCode={currencyCode}
          isActive={activePromotions.includes(promotion.id)}
          handleUpdatePromotions={handleUpdatePromotions}
        />
      ))
    ) : (
      <>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.NO_PROMOTIONS_AVAILABLE')}</>
    );

  return (
    <>
      <div className="option-group select-group open">
        {isExisted && promotionCategory.length > 0 ? (
          <AppliedPromotion
            currencyCode={currencyCode}
            type={PROMOTION_TYPE.SPECIAL_PROMOTION}
            promotion={promotionCategory[0]}
            confirmRemove={confirmRemove}
          />
        ) : (
          renderPromotions()
        )}
      </div>
    </>
  );
};

const AddOns: React.FC<IAddons> = ({
  isLoading,
  addOns,
  selectedAddons,
  handleChangeAddOn
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading.addOns && <Loading />}
      {!isLoading.addOns && (
        <form className="option-group check-gr">
          {addOns.map(e => {
            const price = condition(
              e.amount.payNow,
              e.amount.payNow,
              e.amount.recurring
            ).toString();
            const prices =
              price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
            return (
              <div key={e.id} className="form-check">
                <input
                  onChange={event => handleChangeAddOn(e, event)}
                  checked={selectedAddons.filter(f => f.id === e.id).length > 0}
                  id={`addOn${e.id}`}
                  type="checkbox"
                />
                <label className="icon-checked-ico" htmlFor={`addOn${e.id}`}>
                  <div className="card-gr">
                    <h4 className="name-add-on">{e.name}</h4>
                  </div>
                </label>
                <div className="info-add-on">
                  <p className="monthly">
                    {t(
                      condition(
                        e.amount.recurring,
                        `PAGE.MEMBERSHIPS.CREATE.STEP_2.MONTHLY_COST`,
                        `PAGE.MEMBERSHIPS.CREATE.STEP_2.ONE_OFF_COST`
                      )
                    )}
                    <span className="add-on-number">
                      {prices[0]}.<sup>{prices[1].padEnd(2, '0')}</sup>
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </form>
      )}
    </>
  );
};

const Template: React.FC<ITemplate> = ({
  openDropdown,
  isLoading,
  addOns,
  selectedAddons,
  selectedPromotions,
  selectedPackagePlanNew,
  promotionCategory,
  specialPromotionCategory,
  activePromotions,
  currencyCode,
  membership,
  handlePrevious,
  handleOpenDropdown,
  handleNext,
  onCancel,
  goToStep,
  handleUpdatePromotions,
  handleRemovePromotion,
  handleChangeAddOn
}) => {
  const { t } = useTranslation();
  const { membershipSearchFilter, membershipConfiguration } = membership;
  const confirmRemove = (type: string) => {
    const htmlContent = `
        <p>
            ${t('PAGE.MEMBERSHIPS.CREATE.STEP_2.CONFIRM_MESSAGE')}
        </p>`;
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      padding: '2em',
      html: htmlContent,
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: false,
      confirmButtonText: t('BUTTON.YES'),
      cancelButtonText: t('BUTTON.NO')
    }).then(res => {
      if (res.value) {
        handleRemovePromotion(type);
      }
    });
  };

  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <div className="content select-add-on product-extra">
            <div className="select-add-on-main">
              <p className="select-add-on-title text-uppercase select-add-on-title--custom">
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_2.SELECT_PRODUCT_EXTRAS')}
              </p>
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION')}
                content={
                  <Promotions
                    promotionCategory={promotionCategory}
                    isExisted={
                      membershipSearchFilter.promotion.categoryName ===
                      PROMOTION_TYPE.PROMOTION
                    }
                    currencyCode={currencyCode}
                    activePromotions={activePromotions}
                    confirmRemove={confirmRemove}
                    handleUpdatePromotions={handleUpdatePromotions}
                  />
                }
                isOpen={openDropdown['promotion']}
                maxHeight="2000px"
                openCollapsedContent={() => handleOpenDropdown('promotion')}
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.SPECIAL_PROMOTION')}
                content={
                  <SpecialPromotions
                    promotionCategory={specialPromotionCategory}
                    isExisted={
                      membershipSearchFilter.promotion.categoryName ===
                      PROMOTION_TYPE.SPECIAL_PROMOTION
                    }
                    currencyCode={currencyCode}
                    activePromotions={activePromotions}
                    confirmRemove={confirmRemove}
                    handleUpdatePromotions={handleUpdatePromotions}
                  />
                }
                isOpen={openDropdown['specialPromotion']}
                maxHeight="2000px"
                openCollapsedContent={() =>
                  handleOpenDropdown('specialPromotion')
                }
              />
              <CollapseButton
                className="collapse-promotion"
                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.ADDONS')}
                content={
                  <AddOns
                    isLoading={isLoading}
                    addOns={addOns}
                    selectedAddons={selectedAddons}
                    handleChangeAddOn={handleChangeAddOn}
                  />
                }
                isOpen={openDropdown['addOns']}
                maxHeight="2000px"
                openCollapsedContent={() => handleOpenDropdown('addOns')}
              />
            </div>
            {selectedPackagePlanNew ? (
              <ProductSummary
                productSummary={selectedPackagePlanNew}
                membershipSearchFilter={membershipSearchFilter}
                membershipConfiguration={membershipConfiguration}
                addons={selectedAddons}
                promotions={selectedPromotions}
                handlePrevious={() => handlePrevious()}
                handleNext={() => handleNext()}
                handleCancel={onCancel}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Template;
