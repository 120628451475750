import * as types from 'constants/ActionTypes';
import PaymentDetails from 'models/PaymentDetails';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import {
  IAllPackagePlans,
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember
} from 'interfaces/Membership';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';

export function resetCorporateMemberState() {
  return {
    type: types.ADD_CORPORATE_MEMBER_RESET_STATE
  };
}

export function setCorporateMemberPackagePlanSearchFilter(
  membershipSearchFilter: MembershipSearchFilter
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_SEARCH_FILTER,
    membershipSearchFilter
  };
}

export function setCorporateMemberConfiguration(
  membershipConfiguration: MembershipConfiguration
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_CONFIGURATION,
    membershipConfiguration
  };
}

export function setCorporateMemberProductExtras(
  selectedPromotions: IPromotionCatergory[],
  selectedAddons: AddOn[]
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS,
    selectedPromotions,
    selectedAddons
  };
}

export function setCorporateMemberSelectedPackagePlan(
  packagePlan: IPackagePlanItemAddCorporateMember | IPackagePlanItem
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN,
    packagePlan
  };
}

export function setAddNewCorporateMemberQuestionConfigs(questionConfigs) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_QUESTION_CONFIGS,
    questionConfigs
  };
}

export function setCorporateMemberPersonalDetails(
  personalDetails: PersonalDetailsModel
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_PERSONAL_DETAILS,
    data: personalDetails
  };
}

export function setCorporateMemberAllPackagePlans(
  packagePlans: IAllPackagePlans
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN,
    packagePlans
  };
}

export function setCorporateMemberSummary(memberSummary) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_MEMBER_SUMMARY,
    memberSummary
  };
}

export function setCorporateMemberPaymentDetails(
  paymentDetails: PaymentDetails
) {
  return {
    type: types.ADD_CORPORATE_MEMBER_SET_PAYMENT_DETAILS,
    paymentDetails
  };
}

export function replaceCorporateMembership(data: any) {
  return { type: types.ADD_CORPORATE_MEMBER_REPLACE_MEMBERSHIP, data };
}
