import React, { Suspense, useEffect, useState } from 'react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import store from 'reduxs';
import * as actions from 'reduxs/actions/Conman';

import observer from 'util/Observer';
import { getParamFromWindowSearch } from 'util/ControlUtils';

import Operator from 'models/Operator';

import { getTokenByOneTimeToken } from 'services/idp-service';
import { setAccessToken, setDefaultUserInfo } from 'services/common-service';
import { getSystemConfigs } from 'services/conman-service';

import AppRoute from './Route';
import RestrictedRoute from './RestrictedRoute';

import Loading from 'components/Loading';

import 'assets/vendors/style';

declare const document: any;

interface LayoutProps {
  isLoadingUser?: boolean;
  conman?: {
    operator: Operator;
    logoUri: string;
  };
}

const Layout: React.FC<LayoutProps> = ({ isLoadingUser }) => {
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const token = getParamFromWindowSearch('ref_token');
  const history = useHistory();
  const match = useRouteMatch();

  const loadConfiguration = () => {
    getSystemConfigs()
      .then(res => {
        store.dispatch(actions.setConfigs(res.data));
      })
      .catch(() => {
        handleTokenExpired();
      })
      .finally(() => {
        setIsDataUpdated(true);
      });
  };

  const formatDataAccountFromApi = data => {
    const { accessToken, domain, exp } = data;
    const accountInfo = {
      'x-tenant-id': data.TenantId || data.tenantId,
      'x-subtenant-id': data.SubTenantId || data.subTenantId,
      domain: domain,
      language: data.Language || data.language,
      expiredTime: exp
    };
    return {
      accessToken,
      accountInfo,
      domain
    };
  };

  const loadConfigWithToken = async (token: string) => {
    const res: any = await getTokenByOneTimeToken({ ref_token: token });
    if (res.isSuccess) {
      const { accessToken, accountInfo } = formatDataAccountFromApi(
        JSON.parse(res.message)
      );
      updateDataWithLoadConfiguration(accessToken, accountInfo);
    } else {
      handleWithLocalStorage();
    }
  };

  const handleWithLocalStorage = () => {
    const accessToken = localStorage.accessToken;
    const accountInfo = JSON.parse(localStorage.accountInfo || '""');
    if (accessToken && accountInfo) {
      updateDataWithLoadConfiguration(accessToken, accountInfo);
    } else {
      handleTokenExpired();
    }
  };

  const handleTokenExpired = () => {
    history.push('/forbidden');
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (token) {
        loadConfigWithToken(token);
      } else {
        handleWithLocalStorage();
      }
    })();
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      function() {
        observer.publish('windowResizeEvent', window.innerWidth);
      },
      false
    );
  }, []);

  const updateDataWithLoadConfiguration = (accessToken, accountInfo) => {
    setAccessToken(accessToken);
    setDefaultUserInfo(accountInfo);
    loadConfiguration();
  };

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Suspense fallback={null}>
        <Switch>
          {isDataUpdated && (
            <RestrictedRoute path={`${match.url}`} component={AppRoute} />
          )}
        </Switch>
      </Suspense>
    </MuiPickersUtilsProvider>
  );
};

export default Layout;
