import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import FormButton from 'components/Membership/FormButton';
import { ITemplate } from './interface';
import AddCorporateMemberFormFilter from 'components/Membership/AddCorporateMemberFormFilter';
import AddCorporateMemberFormInfo from 'components/Membership/AddCorporateMemberFormInfo';
import AddCorporateMemberPackagePlanList from 'components/Membership/AddCorporateMemberPackagePlanList';

const Template: React.FC<ITemplate> = ({
  dateOfBirthConfig,
  membershipSearchFilter,
  membershipConfiguration,
  errors,
  showCheckIcon,
  secondarySalePeople,
  clubs,
  applicationTypes,
  salePeople,
  corporateNames,
  reciprocalAccesses,
  minimumContracts,
  accesses,
  subsidyTypes,
  paymentMethods,
  packagePlans,
  isShowFilter,
  isLoading,
  filterRef,
  filterButtonRef,
  toggleFilter,
  handleChange,
  handleInputFilterChange,
  handleDateChange,
  onPackagePlanSelected,
  handleFilterPackagePlan,
  handleClearFilter,
  goToStep,
  onCancel,
  applyPromotionCode,
  isAllowEditClub,
  corporateId,
  loadPackagePlansAfterChangeDate
}) => {
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <AddCorporateMemberFormInfo
            loadPackagePlansAfterChangeDate={loadPackagePlansAfterChangeDate}
            isLoading={isLoading}
            dateOfBirthConfig={dateOfBirthConfig}
            membershipSearchFilter={membershipSearchFilter}
            membershipConfiguration={membershipConfiguration}
            errors={errors}
            clubs={clubs}
            applicationTypes={applicationTypes}
            corporateNames={corporateNames}
            salePeople={salePeople}
            toggleFilter={toggleFilter}
            filterButtonRef={filterButtonRef}
            handleChange={handleChange}
            handleInputChange={handleInputFilterChange}
            handleDateChange={handleDateChange}
            applyPromotionCode={applyPromotionCode}
            showCheckIcon={showCheckIcon}
            secondarySalePeople={secondarySalePeople}
            isAllowEditClub={isAllowEditClub}
            corporateId={corporateId}
          />
          <AddCorporateMemberPackagePlanList
            isLoading={isLoading}
            packagePlans={packagePlans}
            membershipSearchFilter={membershipSearchFilter}
            membershipConfiguration={membershipConfiguration}
            onPackagePlanSelected={onPackagePlanSelected}
          />
          <AddCorporateMemberFormFilter
            isShowFilter={isShowFilter}
            membershipSearchFilter={membershipSearchFilter}
            reciprocalAccesses={reciprocalAccesses}
            minimumContracts={minimumContracts}
            accesses={accesses}
            subsidyTypes={subsidyTypes}
            paymentMethods={paymentMethods}
            toggleFilter={toggleFilter}
            filterRef={filterRef}
            handleFilterChange={handleInputFilterChange}
            handleFilterPackagePlan={handleFilterPackagePlan}
            handleClearFilter={handleClearFilter}
          />
          <FormButton handleCancel={onCancel} />
        </div>
      </div>
    </section>
  );
};

export default Template;
