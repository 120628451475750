import Swal, { SweetAlertType } from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from './I18nMessages';
import _ from 'lodash';

export interface Message {
  text: string;
  type?: SweetAlertType;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  allowOutsideClick?: boolean;
  showCloseButton?: boolean;
  padding?: string;
  focusCancel?: boolean;
}

const Popup = {
  showProcessing() {
    this.showMessageCustom({
      text: `PROCESSING<br/>
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      showCloseButton: false
    });
  },

  showMessageCustom(message: Message, callBack?: () => void) {
    const {
      text,
      type,
      allowOutsideClick,
      cancelButtonText,
      showCancelButton,
      confirmButtonText,
      showConfirmButton,
      showCloseButton,
      padding
    } = message;

    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      html: text,
      type: !_.isUndefined(type) ? type : 'warning',
      padding: padding,
      showCancelButton: !_.isUndefined(showCancelButton)
        ? showCancelButton
        : true,
      confirmButtonText: !_.isUndefined(confirmButtonText)
        ? confirmButtonText
        : t('BUTTON.YES'),
      cancelButtonText: !_.isUndefined(cancelButtonText)
        ? cancelButtonText
        : t('BUTTON.NO'),
      showConfirmButton: !_.isUndefined(showConfirmButton)
        ? showConfirmButton
        : true,
      showCloseButton: !_.isUndefined(showCloseButton)
        ? showCloseButton
        : false,
      allowOutsideClick: !_.isUndefined(allowOutsideClick)
        ? allowOutsideClick
        : true
    }).then(res => {
      if (res.value && callBack) {
        callBack();
      }
    });

  },

  showMessageAddMemberSuccess(callBack: () => void) {
    const delay = milisecond => {
      return new Promise(resolve => {
        setTimeout(resolve, milisecond);
      });
    };

    return new Promise(_ => {
      this.showMessageCustom({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        type: 'success',
        text: ` ${t('MSG.MEMBERSHIPS.CREATE.SAVE_SUCCESS')} <br/>
                  <small><i>${t('REDIRECT.TO.SINGING.PAGE')}</i></small>
                  `,
        showConfirmButton: false,
        allowOutsideClick: false,
        showCloseButton: false,
        showCancelButton: false
      });
      delay(3000).finally(() => callBack());
    });
  }
};

export default Popup;
