import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import {
  PersonalDetailFieldsModel,
  ContactDetailsFieldsModel,
  AddressDetailsFieldsModel,
  ContactedDetailsFieldsModel,
  EmergencyContactFieldsModel,
  OtherFieldsFieldsModel,
  OpenDropdownModel
} from 'models/PersonalDetails';
import {
  getCountries,
  getFieldsConfiguration,
  getMarketingSources,
  getMemberGenders,
  getMemberLanguagePreferences,
  getMemberTitles,
  getRecurringCardTypes,
  getChannelCodes,
  getMarketingCampaign,
  getConmanConfig,
  getGuardianIdentityTypeId,
  getQuestionList,
  getOtherFieldsConfig
} from 'services/membership-service';
import Template from './template';
import { ConmanFieldConfigModel } from 'models/ConmanConfig';
import { EMAIL_SYSTEM_CONFIG_KEY } from 'constants/DefaultConstants';
import ISelectPersonalDetails, {
  IAddressSection,
  IContactedSection,
  IContactSection,
  IEmergencySection,
  IHealthQuestionSection,
  IOtherFieldsSection,
  IPersonalSection
} from './interface';
import { useTypedSelector } from 'reduxs';
import { IGetConmanConifgResponse } from 'services/interfaces/responses/membership';
import { IDuplicateMember } from 'components/Membership/DuplicateMembers';
import { UseFormReturn } from 'react-hook-form';
import { AddressDetailsFormInput } from 'components/Membership/AddressDetails';
import { ContactedDetailsFormInput } from 'components/Membership/ContactedDetails';
import { EmergencyContactFormInput } from 'components/Membership/EmergencyContact';
import { HealthQuestionFormInput } from 'components/Membership/HealthQuestion';
import { OtherFieldsFormInput } from 'components/Membership/OtherFields';
import { PersonalDetailsFormInput } from 'components/Membership/PersonalDetails';
import { ContactDetailsFormInput } from 'components/Membership/ContactDetails';
import { IConfig } from 'interfaces/SystemConfig';
import {
  findDuplicateMembersData,
  getAddressFieldConfiguration,
  getContactFieldConfiguration,
  getContactedFieldConfiguration,
  getCustomFieldsConfig,
  getEmergencyContactFieldConfiguration,
  getFieldEmergencyConfig,
  getMemberConfigNextStep,
  getOtherFieldsFieldConfiguration,
  getPersonalFieldConfiguration,
  handleNextStep,
  handleSaveAndLeaveAction,
  handleSaveData,
  handleSavePersonalDetailsData,
  loadConfiguration,
  loadCountriesAndQuestionList,
  loadGuardianIdentityType,
  loadMarketingsAndChannelCodes,
  loadMember,
  loadOtherFieldsConfig,
  populateAddressDetailsFromReduxStore,
  populateContactDetailsFromReduxStore,
  populateContactedDetailsFromReduxStore,
  populateEmergencyContactFromReduxStore,
  populateHealthQuestionsFromReduxStore,
  populateOtherFieldsFromReduxStore,
  populatePersonalDetailsFromReduxStore
} from 'common/SelectPersonalDetails';

const SelectPersonalDetails: React.FC<ISelectPersonalDetails> = ({
  newMembership,
  onCancel,
  setPersonalDetailsAction,
  setConfigurationAction,
  setPackagePlanSearchFilterAction,
  setQuestionConfigsAction
}) => {
  const [isServiceError, setIsServiceError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openDropdown, setOpenDropdown] = useState<OpenDropdownModel>({
    personalDetails: true,
    contactDetails: false,
    addressDetails: false,
    contactedDetails: false,
    emergencyContact: false,
    healthQuestion: false,
    otherFields: false
  });

  const personalSection = useRef<IPersonalSection>({
    titles: [],
    genders: [],
    languages: [],
    personalDetailsFieldConfigs: new PersonalDetailFieldsModel()
  });
  const contactSection = useRef<IContactSection>({
    overrideEmailOptions: [],
    contactDetailsFieldConfigs: new ContactDetailsFieldsModel()
  });

  const addressSection = useRef<IAddressSection>({
    countries: [],
    addressDetailsFieldConfigs: new AddressDetailsFieldsModel()
  });

  const contactedSection = useRef<IContactedSection>({
    marketingSources: [],
    channelCodes: [],
    marketingCampaigns: [],
    contactedDetailsFieldConfigs: new ContactedDetailsFieldsModel()
  });

  const emergencySection = useRef<IEmergencySection>({
    emergencyNameConfig: [],
    emergencyNumberConfig: [],
    guardianIdentityTypeIdList: [],
    emergencyContactFieldsConfigs: new EmergencyContactFieldsModel()
  });

  const healthQuestionSection = useRef<IHealthQuestionSection>({
    questions: []
  });

  const otherFieldsSection = useRef<IOtherFieldsSection>({
    recurringCardTypes: [],
    otherFieldsFieldsConfigs: new OtherFieldsFieldsModel()
  });

  const conmanConfigList = useRef<ConmanFieldConfigModel[]>([]);
  const duplicateMembers = useRef<IDuplicateMember[]>([]);
  const duplicateMemberProcessed = useRef<boolean>(false);
  const relationshipConfig = useRef<ConmanFieldConfigModel>();
  const guardianNameConfig = useRef<ConmanFieldConfigModel>();
  const guardianPhoneNumberConfig = useRef<ConmanFieldConfigModel>();
  const guardianAddressConfig = useRef<ConmanFieldConfigModel>();
  const guardianRelationshipConfig = useRef<ConmanFieldConfigModel>();
  const guardianIdentityNoConfig = useRef<ConmanFieldConfigModel>();
  const guardianIdentityTypeIdConfig = useRef<ConmanFieldConfigModel>();
  const guardianPassConfig = useRef<ConmanFieldConfigModel>();
  const emergencyContactNameConfig = useRef<ConmanFieldConfigModel>();
  const emergencyContactPhoneNumConfig = useRef<ConmanFieldConfigModel>();
  const channelCodeConfig = useRef<ConmanFieldConfigModel>();
  const marketingCampaignConfig = useRef<ConmanFieldConfigModel>();
  const marketingSourceConfig = useRef<ConmanFieldConfigModel>();
  //other field config
  const otherFieldsConfigList = useRef<ConmanFieldConfigModel[]>([]);
  const companyNameConfig = useRef<ConmanFieldConfigModel>();
  const occupationConfig = useRef<ConmanFieldConfigModel>();
  const referredByNameConfig = useRef<ConmanFieldConfigModel>();
  const referredByMembershipNumberConfig = useRef<ConmanFieldConfigModel>();
  const otherCommissionNoteConfig = useRef<ConmanFieldConfigModel>();
  const generalNoteConfig = useRef<ConmanFieldConfigModel>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const conman = useTypedSelector(state => state.conman);

  const emailCfgConman = conman.configs.find(
    (cfg: IConfig) => cfg.key === EMAIL_SYSTEM_CONFIG_KEY
  );

  const personalRef = useRef<UseFormReturn<PersonalDetailsFormInput>>(null);
  const contactDetailRef = useRef<UseFormReturn<ContactDetailsFormInput>>(null);
  const addressDetailRef = useRef<UseFormReturn<AddressDetailsFormInput>>(null);
  const contactedDetailRef = useRef<UseFormReturn<ContactedDetailsFormInput>>(
    null
  );
  const emergencyContactRef = useRef<UseFormReturn<EmergencyContactFormInput>>(
    null
  );
  const healthQuestionRef = useRef<UseFormReturn<HealthQuestionFormInput>>(
    null
  );
  const otherFieldsRef = useRef<UseFormReturn<OtherFieldsFormInput>>(null);

  const loadDataConfiguration = (
    fieldResponse,
    localConfigs: {
      personalDetailsFieldLocalConfigs_: PersonalDetailFieldsModel;
      contactDetailsFieldLocalConfigs_: ContactDetailsFieldsModel;
      addressDetailsFieldLocalConfigs_: AddressDetailsFieldsModel;
      emergencyContactFieldsLocalConfigs_: EmergencyContactFieldsModel;
      otherFieldsFieldsLocalConfigs_: OtherFieldsFieldsModel;
      contactedDetailsFieldLocalConfigs_: ContactedDetailsFieldsModel;
    }
  ) => {
    const {
      personalDetailsFieldLocalConfigs_,
      contactDetailsFieldLocalConfigs_,
      addressDetailsFieldLocalConfigs_,
      emergencyContactFieldsLocalConfigs_,
      otherFieldsFieldsLocalConfigs_,
      contactedDetailsFieldLocalConfigs_
    } = localConfigs;
    const {
      personalDetailsFieldConfigs_,
      contactDetailsFieldConfigs_,
      addressDetailsFieldConfigs_,
      contactedDetailsFieldConfigs_,
      emergencyContactFieldsConfigs_,
      otherFieldsFieldsConfigs_
    } = loadConfiguration(fieldResponse);
    personalSection.current.personalDetailsFieldConfigs = {
      ...personalDetailsFieldLocalConfigs_,
      ...personalDetailsFieldConfigs_
    };
    contactSection.current.contactDetailsFieldConfigs = {
      ...contactDetailsFieldLocalConfigs_,
      ...contactDetailsFieldConfigs_
    };

    addressSection.current.addressDetailsFieldConfigs = {
      ...addressDetailsFieldLocalConfigs_,
      ...addressDetailsFieldConfigs_
    };

    emergencySection.current.emergencyContactFieldsConfigs = {
      ...emergencyContactFieldsLocalConfigs_,
      ...emergencyContactFieldsConfigs_
    };

    otherFieldsSection.current.otherFieldsFieldsConfigs = {
      ...otherFieldsFieldsLocalConfigs_,
      ...otherFieldsFieldsConfigs_
    };

    contactedSection.current.contactedDetailsFieldConfigs = {
      ...contactedDetailsFieldLocalConfigs_,
      ...contactedDetailsFieldConfigs_
    };
  };

  const loadEmergencyAndConman = conmanConfigResponse => {
    conmanConfigList.current = conmanConfigResponse || [];
  };

  const loadOtherFieldsDataConfig = (
    otherFieldsResponse: IGetConmanConifgResponse[],
    recurringCardTypeResponse
  ) => {
    const recurringCardTypeList = loadOtherFieldsConfig(
      otherFields_,
      recurringCardTypeResponse
    );

    otherFieldsSection.current.recurringCardTypes = recurringCardTypeList || [];
    otherFieldsConfigList.current = otherFieldsResponse || [];
  };

  const loadGuardianIdentityTypeData = guardianIdentityTypeIdResponse => {
    const guardianIdentityTypeIds = loadGuardianIdentityType(
      guardianIdentityTypeIdResponse,
      emergencyContact_
    );
    emergencySection.current.guardianIdentityTypeIdList = guardianIdentityTypeIds;
  };

  const loadMarketingsAndChannelCodesData = (
    marketingSourceResponse,
    channelCodeResponse,
    marketingCampaignResponse
  ) => {
    const {
      marketingSourcesData,
      channelCodesData,
      marketingCampaignData
    } = loadMarketingsAndChannelCodes(
      marketingSourceResponse,
      channelCodeResponse,
      marketingCampaignResponse,
      contactedDetails_
    );
    contactedSection.current.marketingSources = marketingSourcesData;
    contactedSection.current.channelCodes = channelCodesData;
    contactedSection.current.marketingCampaigns = marketingCampaignData;
  };

  const loadCountriesAndQuestionListData = (
    countryResponse,
    questionResponse
  ) => {
    const { countriesData, healthQuestionsData } = loadCountriesAndQuestionList(
      countryResponse,
      questionResponse,
      addressDetails_
    );
    addressSection.current.countries = countriesData;
    healthQuestionSection.current.questions = healthQuestionsData;
  };
  const loadMemberData = (titleResponse, genderResponse, languageResponse) => {
    const { titles_, genders_, languages_ } = loadMember(
      titleResponse,
      genderResponse,
      languageResponse,
      personalDetails_
    );
    personalSection.current = {
      ...personalSection.current,
      titles: titles_,
      genders: genders_,
      languages: languages_
    };
  };
  const loadConfigurationFallback = (localConfigs: {
    personalDetailsFieldLocalConfigs_: PersonalDetailFieldsModel;
    contactDetailsFieldLocalConfigs_: ContactDetailsFieldsModel;
    addressDetailsFieldLocalConfigs_: AddressDetailsFieldsModel;
    emergencyContactFieldsLocalConfigs_: EmergencyContactFieldsModel;
    otherFieldsFieldsLocalConfigs_: OtherFieldsFieldsModel;
    contactedDetailsFieldLocalConfigs_: ContactedDetailsFieldsModel;
  }) => {
    const {
      personalDetailsFieldLocalConfigs_,
      contactDetailsFieldLocalConfigs_,
      addressDetailsFieldLocalConfigs_,
      emergencyContactFieldsLocalConfigs_,
      otherFieldsFieldsLocalConfigs_,
      contactedDetailsFieldLocalConfigs_
    } = localConfigs;

    personalSection.current.personalDetailsFieldConfigs = {
      ...personalDetailsFieldLocalConfigs_
    };

    contactSection.current.contactDetailsFieldConfigs = {
      ...contactDetailsFieldLocalConfigs_
    };

    addressSection.current.addressDetailsFieldConfigs = {
      ...addressDetailsFieldLocalConfigs_
    };

    emergencySection.current.emergencyContactFieldsConfigs = {
      ...emergencyContactFieldsLocalConfigs_
    };

    otherFieldsSection.current.otherFieldsFieldsConfigs = {
      ...otherFieldsFieldsLocalConfigs_
    };

    contactedSection.current.contactedDetailsFieldConfigs = {
      ...contactedDetailsFieldLocalConfigs_
    };
  };

  const emergencyContact_ = useMemo(
    () => populateEmergencyContactFromReduxStore(newMembership),
    []
  );
  const contactDetails_ = useMemo(
    () => populateContactDetailsFromReduxStore(newMembership),
    []
  );
  const healthQuestions_ = useMemo(
    () => populateHealthQuestionsFromReduxStore(newMembership),
    []
  );
  const contactedDetails_ = useMemo(
    () => populateContactedDetailsFromReduxStore(newMembership),
    []
  );
  const addressDetails_ = useMemo(
    () => populateAddressDetailsFromReduxStore(newMembership),
    []
  );
  const otherFields_ = useMemo(
    () => populateOtherFieldsFromReduxStore(newMembership),
    []
  );
  const personalDetails_ = useMemo(
    () => populatePersonalDetailsFromReduxStore(newMembership),
    []
  );

  useEffect(() => {
    const personalDetailsFieldLocalConfigs_ = getPersonalFieldConfiguration(
      InitialFieldConfigs.data
    );
    const contactDetailsFieldLocalConfigs_ = getContactFieldConfiguration(
      InitialFieldConfigs.data
    );
    const addressDetailsFieldLocalConfigs_ = getAddressFieldConfiguration(
      InitialFieldConfigs.data
    );
    const contactedDetailsFieldLocalConfigs_ = getContactedFieldConfiguration(
      InitialFieldConfigs.data
    );
    const emergencyContactFieldsLocalConfigs_ = getEmergencyContactFieldConfiguration(
      InitialFieldConfigs.data
    );
    const otherFieldsFieldsLocalConfigs_ = getOtherFieldsFieldConfiguration(
      InitialFieldConfigs.data
    );

    Promise.all([
      getFieldsConfiguration(),
      getConmanConfig(),
      getGuardianIdentityTypeId(),
      getMarketingSources(),
      getChannelCodes(),
      getMarketingCampaign(),
      getCountries(),
      getQuestionList(),
      getRecurringCardTypes(),
      getMemberTitles(),
      getMemberGenders(),
      getMemberLanguagePreferences(),
      getOtherFieldsConfig()
    ])
      .then(
        ([
          fieldResponse,
          conmanConfigResponse,
          guardianIdentityTypeIdResponse,
          marketingSourceResponse,
          channelCodeResponse,
          marketingCampaignResponse,
          countryResponse,
          questionResponse,
          recurringCardTypeResponse,
          titleResponse,
          genderResponse,
          languageResponse,
          otherFieldsResponse
        ]) => {
          loadDataConfiguration(fieldResponse, {
            personalDetailsFieldLocalConfigs_,
            contactDetailsFieldLocalConfigs_,
            addressDetailsFieldLocalConfigs_,
            contactedDetailsFieldLocalConfigs_,
            emergencyContactFieldsLocalConfigs_,
            otherFieldsFieldsLocalConfigs_
          });
          loadEmergencyAndConman(conmanConfigResponse);
          loadOtherFieldsDataConfig(
            otherFieldsResponse,
            recurringCardTypeResponse
          );
          loadGuardianIdentityTypeData(guardianIdentityTypeIdResponse);
          loadMarketingsAndChannelCodesData(
            marketingSourceResponse,
            channelCodeResponse,
            marketingCampaignResponse
          );
          loadCountriesAndQuestionListData(
            countryResponse.data,
            questionResponse
          );
          loadMemberData(titleResponse, genderResponse, languageResponse);

          setIsServiceError(false);
          setIsLoading(false);
        }
      )
      .catch(() => {
        loadConfigurationFallback({
          personalDetailsFieldLocalConfigs_,
          contactDetailsFieldLocalConfigs_,
          addressDetailsFieldLocalConfigs_,
          contactedDetailsFieldLocalConfigs_,
          emergencyContactFieldsLocalConfigs_,
          otherFieldsFieldsLocalConfigs_
        });
        setIsServiceError(true);
        setIsLoading(false);
      });
  }, []);

  const handlePrevious = () => {
    const { membershipConfiguration } = newMembership;
    membershipConfiguration.stepIndex = 2;
    membershipConfiguration.stepLatest = 2;
    setConfigurationAction(membershipConfiguration);
    const refList = {
      personalRef: personalRef.current,
      contactDetailRef: contactDetailRef.current,
      addressDetailRef: addressDetailRef.current,
      contactedDetailRef: contactedDetailRef.current,
      emergencyContactRef: emergencyContactRef.current,
      healthQuestionRef: healthQuestionRef.current,
      otherFieldsRef: otherFieldsRef.current
    };
    handleSavePersonalDetailsData(
      newMembership,
      refList,
      setPersonalDetailsAction,
      setPackagePlanSearchFilterAction,
      emailCfgConman
    );
  };

  const handleOpenDropdown = key => {
    setOpenDropdown({
      ...openDropdown,
      [key]: !openDropdown[key]
    });
  };

  const onDuplicateMemberSectionClose = () => {
    duplicateMembers.current = [];
    duplicateMemberProcessed.current = false;
    setOpenDropdown({ ...openDropdown, personalDetails: true });
  };

  const findDuplicateMembers = async (
    lastName: string,
    birthDate: Date,
    postCode: string,
    saveCallback: () => void
  ) => {
    const result = await findDuplicateMembersData(
      lastName,
      birthDate,
      postCode
    );
    if (result) {
      duplicateMembers.current = result;
      duplicateMemberProcessed.current = true;
    } else {
      goToNextStep(saveCallback);
    }
  };

  const goToStep = stepIndex => {
    const membershipConfiguration = getMemberConfigNextStep(
      stepIndex,
      newMembership,
      hasChanged,
      handleSaveAndLeave
    );
    setConfigurationAction(membershipConfiguration);
    const refList = {
      personalRef: personalRef.current,
      contactDetailRef: contactDetailRef.current,
      addressDetailRef: addressDetailRef.current,
      contactedDetailRef: contactedDetailRef.current,
      emergencyContactRef: emergencyContactRef.current,
      healthQuestionRef: healthQuestionRef.current,
      otherFieldsRef: otherFieldsRef.current
    };
    handleSavePersonalDetailsData(
      newMembership,
      refList,
      setPersonalDetailsAction,
      setPackagePlanSearchFilterAction,
      emailCfgConman
    );
  };

  const hasChangeControlValue = () => {
    const {
      membershipConfiguration: { stepLatest }
    } = newMembership;
    setHasChanged(!hasChanged);
    if (stepLatest < 4) return;
  };
  const handleSaveAndLeave = stepIndex => {
    const membershipConfiguration = handleSaveAndLeaveAction(
      newMembership,
      stepIndex
    );
    setConfigurationAction(membershipConfiguration);
  };

  const goToNextStep = (saveCallBack: () => void) => {
    const {
      membershipSearchFilterRes,
      membershipConfigurationRes
    } = handleNextStep(
      duplicateMembers,
      newMembership,
      personalRef,
      saveCallBack
    );
    setPackagePlanSearchFilterAction(membershipSearchFilterRes);
    setConfigurationAction(membershipConfigurationRes);
  };

  useEffect(() => {
    if (conmanConfigList.current && conmanConfigList.current.length) {
      const res = getFieldEmergencyConfig(conmanConfigList.current);
      if (res) {
        relationshipConfig.current = res.relationshipConfig;
        guardianNameConfig.current = res.guardianNameConfig;
        guardianPhoneNumberConfig.current = res.guardianPhoneNumberConfig;
        guardianAddressConfig.current = res.guardianAddressConfig;
        guardianRelationshipConfig.current = res.guardianRelationshipConfig;
        guardianIdentityNoConfig.current = res.guardianIdentityNoConfig;
        guardianIdentityTypeIdConfig.current = res.guardianIdentityTypeIdConfig;
        guardianPassConfig.current = res.guardianPassConfig;
        emergencyContactNameConfig.current = res.emergencyNameConfig;
        emergencyContactPhoneNumConfig.current = res.emergencyPhoneNumberConfig;
        channelCodeConfig.current = res.channelCodeConfig;
        marketingCampaignConfig.current = res.marketingCampaignConfig;
        marketingSourceConfig.current = res.marketingSourceConfig;
      }
    }
    //other fields config
    if (otherFieldsConfigList && otherFieldsConfigList.current.length) {
      const otherFieldsRes = getCustomFieldsConfig(
        otherFieldsConfigList.current
      );
      if (otherFieldsRes) {
        companyNameConfig.current = otherFieldsRes.companyNameConfig;
        occupationConfig.current = otherFieldsRes.occupationConfig;
        referredByNameConfig.current =
          otherFieldsRes.referredMemberByNameConfig;
        referredByMembershipNumberConfig.current =
          otherFieldsRes.referredByMembershipNumberConfig;
        otherCommissionNoteConfig.current =
          otherFieldsRes.otherCommissionNoteConfig;
        generalNoteConfig.current = otherFieldsRes.generalNoteConfig;
      }
    }
  }, [conmanConfigList.current, otherFieldsConfigList.current]);

  const handleNext = async () => {
    if (isServiceError) return;
    if (
      personalRef.current &&
      contactDetailRef.current &&
      addressDetailRef.current &&
      contactedDetailRef.current &&
      emergencyContactRef.current &&
      healthQuestionRef.current &&
      otherFieldsRef.current
    ) {
      const refList = {
        personalRef: personalRef.current,
        contactDetailRef: contactDetailRef.current,
        addressDetailRef: addressDetailRef.current,
        contactedDetailRef: contactedDetailRef.current,
        emergencyContactRef: emergencyContactRef.current,
        healthQuestionRef: healthQuestionRef.current,
        otherFieldsRef: otherFieldsRef.current
      };
      const configList = {
        guardianNameConfig: guardianNameConfig.current,
        guardianIdentityNoConfig: guardianIdentityNoConfig.current,
        guardianRelationshipConfig: guardianRelationshipConfig.current,
        guardianAddressConfig: guardianAddressConfig.current,
        guardianPhoneNumberConfig: guardianPhoneNumberConfig.current,
        guardianPassConfig: guardianPassConfig.current,
        emergencyContactNameConfig: emergencyContactNameConfig.current,
        relationshipConfig: relationshipConfig.current,
        emergencyContactPhoneNumConfig: emergencyContactPhoneNumConfig.current,
        guardianIdentityTypeIdConfig: guardianIdentityTypeIdConfig.current,
        marketingSourceConfig: marketingSourceConfig.current,
        marketingCampaignConfig: marketingCampaignConfig.current,
        channelCodeConfig: channelCodeConfig.current,
        companyNameConfig: companyNameConfig.current,
        occupationConfig: occupationConfig.current,
        referredByNameConfig: referredByNameConfig.current,
        referredByMembershipNumberConfig:
          referredByMembershipNumberConfig.current,
        otherCommissionNoteConfig: otherCommissionNoteConfig.current,
        generalNoteConfig: generalNoteConfig.current
      };
      const {
        isNeedToCheckDuplicate,
        openDropdown,
        fieldValues,
        questionConfigs,
        isFormValidated
      } = await handleSaveData(
        refList,
        configList,
        emailCfgConman,
        duplicateMemberProcessed.current
      );
      const {
        lastName,
        dateOfBirth,
        addressDetails: { postCode }
      } = fieldValues;

      if (isNeedToCheckDuplicate && dateOfBirth) {
        await findDuplicateMembers(lastName, dateOfBirth, postCode, () => {
          setPersonalDetailsAction(fieldValues);
          setQuestionConfigsAction(questionConfigs);
        });
      } else if (isFormValidated && duplicateMemberProcessed) {
        goToNextStep(() => {
          setPersonalDetailsAction(fieldValues);
          setQuestionConfigsAction(questionConfigs);
        });
      }
      setOpenDropdown(openDropdown);
      hasChangeControlValue();
    }
  };

  return (
    <Template
      newMembership={newMembership}
      isLoading={isLoading}
      isServiceError={isServiceError}
      openDropdown={openDropdown}
      personalSection={personalSection.current}
      contactSection={contactSection.current}
      addressSection={addressSection.current}
      contactedSection={contactedSection.current}
      emergencySection={emergencySection.current}
      conmanConfig={conmanConfigList.current}
      healthQuestionSection={healthQuestionSection.current}
      otherFieldsSection={otherFieldsSection.current}
      otherFieldsConfigList={otherFieldsConfigList.current}
      duplicateMembers={duplicateMembers.current}
      addressDetailRef={addressDetailRef}
      contactedDetailRef={contactedDetailRef}
      emergencyContactRef={emergencyContactRef}
      healthQuestionRef={healthQuestionRef}
      otherFieldsRef={otherFieldsRef}
      personalRef={personalRef}
      contactDetailRef={contactDetailRef}
      otherFields={otherFields_}
      contactedDetails={contactedDetails_}
      emergencyContact={emergencyContact_}
      healthQuestions={healthQuestions_}
      contactDetails={contactDetails_}
      personalDetails={personalDetails_}
      addressDetails={addressDetails_}
      goToStep={goToStep}
      onDuplicateMemberSectionClose={onDuplicateMemberSectionClose}
      handleOpenDropdown={handleOpenDropdown}
      handleNext={handleNext}
      onCancel={onCancel}
      handlePrevious={handlePrevious}
      setPersonalDetailsAction={setPersonalDetailsAction}
    />
  );
};

export default SelectPersonalDetails;
