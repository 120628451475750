import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormCheckbox, FormSelect } from 'components/Form';
import { PAYMENT_METHODS, PAYMENT_METHOD_TYPES } from 'constants/Constants';
import * as Utils from 'util/ControlUtils';
import { isLumpSumpPackage } from 'util/Common';
import EzyPay from './EzyPay';
import InputCardButton from './InputCardButton';
import DirectDebitCard from './DirectDebitCard';
import OnlinePayment from './OnlinePayment';
import EzPayEditButton from './EzyPay/EzyPayEditButton';
import { useTypedSelector } from 'reduxs';
import { isPaymentInfoExist } from 'selectors/member';

const PrimaryPaymentMethod = ({
  state,
  paymentDetails,
  primaryPaymentMethods,
  errors,
  selectedPackagePlan,
  handleChange,
  onlinePayment,
  handleInputChange,
  handleAddCardSignature,
  handleEditCardSignature,
  issuingBanks
}) => {
  const { primaryPaymentMethodId } = paymentDetails;
  const { paymentMethod } = selectedPackagePlan;
  const { cardType, paymentToken } = onlinePayment;
  const { t } = useTranslation();
  const paymentInfoExist = useTypedSelector(state =>
    isPaymentInfoExist(state, primaryPaymentMethodId)
  );
  const isLoadingEzyPay = useTypedSelector(
    state => state.newMembership.paymentDetails.isLoadingEzyPay
  );
  const isDisabledEzPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );

  if (paymentMethod.name === PAYMENT_METHODS.CASH) {
    return (
      <FormCheckbox
        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CASH')}
        checked
        disabled
      />
    );
  }

  const renderEditButton = () => {
    if (primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CASH) {
      return null;
    }

    if (isDisabledEzPay)
      return (
        <InputCardButton
          isExistCard={cardType}
          primaryPaymentMethodId={primaryPaymentMethodId}
          handleAddCardSignature={handleAddCardSignature}
          handleEditCardSignature={handleEditCardSignature}
          paymentToken={paymentToken}
        />
      );

    return <EzPayEditButton paymentInfoExist={paymentInfoExist} />;
  };

  return (
    <>
      <div className="primary-payment-method">
        <FormSelect
          id="primary-payment-method"
          name="primary-payment-method"
          label={t(
            isLumpSumpPackage(selectedPackagePlan)
              ? 'PAGE.MEMBERSHIPS.CREATE.STEP_5.PAYMENT_METHOD'
              : 'PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD'
          )}
          disabled={primaryPaymentMethods.length === 0 || isLoadingEzyPay}
          require
          onChange={event => handleChange('primaryPaymentMethodId', event)}
          value={Utils.getProperty(
            paymentDetails,
            'primaryPaymentMethodId',
            primaryPaymentMethods
          )}
          options={primaryPaymentMethods}
          errorMsg={errors.primaryPaymentMethodId}
        />
        {renderEditButton()}
      </div>
      {!isDisabledEzPay &&
      primaryPaymentMethodId !== PAYMENT_METHOD_TYPES.CASH ? (
        <EzyPay paymentDetails={paymentDetails} />
      ) : (
        <>
          {primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT && (
            <DirectDebitCard
              isCredit={cardType}
              paymentDetails={paymentDetails}
              issuingBanks={issuingBanks}
              errors={errors}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
            />
          )}
          {primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD && (
            <OnlinePayment
              cardType={cardType}
              onlinePayment={onlinePayment}
              state={state}
            />
          )}
        </>
      )}
    </>
  );
};
export default PrimaryPaymentMethod;
