import React, { useEffect, useMemo, useState } from 'react';
import ISelectProductExtras from './interface';
import AddOn, { AddOnClass } from 'models/AddOn';
import Template from './template';
import {
  getAddonsForAddMembership,
  getArrearsPreview,
  getPromotionCategories
} from 'services/membership-service';
import { IPromotion } from 'interfaces/Promotion';
import {
  GENERAL_DATABASE_DATE_FORMAT,
  PROMOTION_TYPE
} from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import {
  IPackagePlanItem,
  IPackagePlanItemAddCorporateMember,
  IPromo
} from 'interfaces/Membership';
import { condition, scrollToTop } from 'util/Common';
import { IGetPromotionCategoriesResponse } from 'services/interfaces/responses/membership';
import Popup from 'util/Popup';
import { t } from 'util/I18nMessages';
import { NEW_MEMBERSHIP_SCENARIO } from 'constants/DefaultConstants';
import { PromotionSearchFilter } from 'models/MembershipSearchFilter';
import { useDispatch } from 'react-redux';
import { loadDisabledEzyPay } from 'reduxs/actions/Payment';
import { useTypedSelector } from 'reduxs';

const SelectProductExtras: React.FC<ISelectProductExtras> = ({
  isCorporate,
  configs,
  membership,
  onCancel,
  setSelectedPackagePlanAction,
  setConfigurationAction,
  setPackagePlanSearchFilterAction,
  setProductExtrasAction
}) => {
  const { membershipSearchFilter, selectedPackagePlan } = membership;

  const [openDropdown, setOpenDropdown] = useState({
    promotion: true,
    specialPromotion: true,
    addOns: true
  });
  const [isLoading, setIsLoading] = useState({
    promotion: false,
    specialPromotion: false,
    addOns: false
  });
  const [addOns, setAddOns] = useState<AddOn[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<AddOn[]>([]);
  const [selectedPromotions, setSelectedPromotions] = useState<any[]>([]);
  const [reloadArrear, setReloadArrear] = useState(false);
  const [selectedPackagePlanNew, setSelectedPackagePlanNew] = useState<
    IPackagePlanItem | IPackagePlanItemAddCorporateMember | null
  >(selectedPackagePlan);

  const [promotionCategory, setPromotionCategory] = useState<IPromotion[]>([]);
  const [specialPromotionCategory, setSpecialPromotionCategory] = useState<
    IPromotion[]
  >([]);
  const isDisableEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof isDisableEzyPay !== 'boolean') {
      dispatch(loadDisabledEzyPay());
    }
  }, [isDisableEzyPay]);

  const activePromotions = useMemo(
    () =>
      selectedPromotions.reduce<number[]>(
        (pre, cur) => [...pre, ...cur.promotions.map(pro => pro.id)],
        []
      ),
    [reloadArrear, selectedPromotions.length]
  );

  const clubId = membershipSearchFilter.clubId;

  const currencyCode = useMemo(
    () => configs.find(e => e.key === FieldKeys.ADDMEMBER_CURRENCY_CODE),
    [configs]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    getAddonsForAddMembership({ clubId }).then(res => {
      setIsLoading({ ...isLoading, addOns: false });
      setAddOns(getAddOnsFromAddOnClasses(res));
      setSelectedAddons(membership.selectedAddons);
    });
  }, []);

  const { promotion } = membershipSearchFilter;

  const initSelectedPromotion = (data: IGetPromotionCategoriesResponse[]) => {
    // select auto applied promotion
    if (
      selectedPackagePlan &&
      selectedPackagePlan.promotions &&
      selectedPackagePlan.promotions.length &&
      selectedPackagePlan.isPromotionApplied
    ) {
      if (promotion.id) {
        const initCategoryIndex = data.findIndex(
          category => category.name === promotion.categoryName
        );
        const initCategory = { ...data[initCategoryIndex] };
        if (initCategory && Object.keys(initCategory).length > 0) {
          const initPromotion = initCategory.promotions.findIndex(
            p => p.id === promotion.id
          );
          initCategory.promotions = [initCategory.promotions[initPromotion]];
          initCategory.id = 1;
          setSelectedPromotions([...selectedPromotions, initCategory]);
        }
        setReloadArrear(true);
      }
    }

    if (membership.selectedPromotions.length) {
      setSelectedPromotions(membership.selectedPromotions);
    }
  };
  const handleNormalPromotionCategory = (
    data: IGetPromotionCategoriesResponse[]
  ) => {
    const promotionCategory = data.find(
      category => category.name === PROMOTION_TYPE.PROMOTION
    );
    const promotionCategoryPromotions: IPromotion[] =
      (promotionCategory && promotionCategory.promotions) || [];

    setPromotionCategory(
      promotionCategoryPromotions.filter(
        pro =>
          !pro.code && pro.isAvailableOffline && pro.isCorporate === isCorporate
      )
    );
    if (promotion.id && promotion.categoryName === PROMOTION_TYPE.PROMOTION) {
      const modifiedPromotionCategoryPromotions = promotionCategoryPromotions.filter(
        pro => pro.id === promotion.id
      );
      setPromotionCategory(modifiedPromotionCategoryPromotions);
    }
  };

  const handleSpecialPromotionCategory = (
    data: IGetPromotionCategoriesResponse[]
  ) => {
    const specialPromotionCategory = data.find(
      category => category.name === PROMOTION_TYPE.SPECIAL_PROMOTION
    );
    const specialPromotionCategoryPromotions: IPromotion[] =
      (specialPromotionCategory && specialPromotionCategory.promotions) || [];

    setSpecialPromotionCategory(
      specialPromotionCategoryPromotions.filter(
        pro =>
          !pro.code && pro.isAvailableOffline && pro.isCorporate === isCorporate
      )
    );
    if (
      promotion.id &&
      promotion.categoryName === PROMOTION_TYPE.SPECIAL_PROMOTION
    ) {
      const modifiedSpecialPromotionCategoryPromotions = specialPromotionCategoryPromotions.filter(
        pro => pro.id === promotion.id
      );
      setSpecialPromotionCategory(modifiedSpecialPromotionCategoryPromotions);
    }
  };

  const getAutoAppliedPromotion = (data: IGetPromotionCategoriesResponse[]) => {
    if (
      selectedPackagePlan &&
      selectedPackagePlan.promotions &&
      selectedPackagePlan.promotions.length
    ) {
      const autoAppliedPromotions = selectedPackagePlan.promotions.map(
        (promo: IPromo) => {
          const category = data.find(item => item.name === promo.category.name);
          if (category && category.promotions) {
            const promotion = category.promotions.find(
              promotion => promotion.id === promo.id
            );
            if (promotion) {
              return {
                id: promo.category.id,
                name: promo.category.name,
                promotions: [
                  {
                    id: promo.id,
                    name: promo.name,
                    isCorporate: promotion.isCorporate,
                    code: promotion.code,
                    expirationDate: promotion.expirationDate,
                    isAvailableOffline: promotion.isAvailableOffline,
                    payments: promotion.payments
                  }
                ]
              };
            }
          }
        }
      );
      setSelectedPromotions(
        autoAppliedPromotions.filter((item: any) => !item.promotions[0].code)
      );
    }
  };

  useEffect(() => {
    const planId = selectedPackagePlan
      ? selectedPackagePlan.originalPlanId.toString()
      : '';
    let agreementPartyId;
    if (
      selectedPackagePlan &&
      'corporateAgreements' in selectedPackagePlan &&
      selectedPackagePlan.corporateAgreements &&
      selectedPackagePlan.corporateAgreements.length
    ) {
      agreementPartyId =
        selectedPackagePlan.corporateAgreements[0].agreementPartyID;
    }
    const currentMembershipSearchFilter = membership.membershipSearchFilter;
    const clubId = currentMembershipSearchFilter.clubId.toString();
    const dob = currentMembershipSearchFilter.dateOfBirth
      ? DisplayDateFormat(
          currentMembershipSearchFilter.dateOfBirth,
          GENERAL_DATABASE_DATE_FORMAT
        )
      : '';
    getPromotionCategories(planId, clubId, agreementPartyId, dob)
      .then(data => {
        getAutoAppliedPromotion(data);
        if (promotion) {
          initSelectedPromotion(data);
        }
        handleNormalPromotionCategory(data);
        handleSpecialPromotionCategory(data);
      })
      .catch(() => {
        Popup.showMessageCustom({
          text: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR'),
          type: 'error'
        });
      });
  }, [promotion.id]);

  useEffect(() => {
    const { startDate, clubId, selectedPackagePlanId } = membershipSearchFilter;
    const dob = condition(
      membershipSearchFilter.dateOfBirth,
      DisplayDateFormat(
        membershipSearchFilter.dateOfBirth,
        GENERAL_DATABASE_DATE_FORMAT
      ),
      ''
    );
    if (reloadArrear) {
      const body = {
        clubId: clubId,
        startedDate: DisplayDateFormat(startDate, GENERAL_DATABASE_DATE_FORMAT),
        planId: selectedPackagePlanId,
        promotionIds: activePromotions,
        addonIds: selectedAddons.map(addon => addon.id),
        dateOfBirth: dob,
        scenario: NEW_MEMBERSHIP_SCENARIO
      };
      getArrearsPreview({ body }).then(data => {
        if (selectedPackagePlanNew) {
          const { firstBilling, nextBilling, paynow } = data;
          const packagePlan = {
            ...selectedPackagePlanNew,
            payment: {
              ...selectedPackagePlanNew.payment,
              payNow: {
                value: paynow.amount,
                fees: paynow.packageFees,
                addonFees: paynow.addonFees,
                currencyCode: selectedPackagePlanNew.payment.payNow.currencyCode
              },
              firstBilling: {
                amount: firstBilling.amount,
                fees: firstBilling.packageFees,
                startDate: firstBilling.startDate,
                addonFees: firstBilling.addonFees
              },
              nextBilling: {
                amount: nextBilling.amount,
                fees: nextBilling.packageFees,
                startDate: nextBilling.startDate,
                addonFees: nextBilling.addonFees
              }
            }
          };
          setSelectedPackagePlanNew(packagePlan);
        }
      });
      setReloadArrear(false);
    }
  }, [reloadArrear]);

  const getAddOnsFromAddOnClasses = (addOnClasses: AddOnClass[]) => {
    return addOnClasses
      .reduce<AddOn[]>((acc, cur) => [...acc, ...cur.addons], [])
      .filter((elem, pos, arr) => arr.findIndex(i => i.id === elem.id) === pos);
  };

  const handleChangeAddOn = (addOn: AddOn, event) => {
    if (event.target.checked) {
      setSelectedAddons([...selectedAddons, addOn]);
    } else {
      const saveSelectedAddOns = selectedAddons.filter(e => e.id !== addOn.id);
      setSelectedAddons(saveSelectedAddOns);
    }
    setReloadArrear(true);
  };

  const handleUpdatePromotions = (type: string, promotion: IPromotion) => {
    let currentSelectedPromotions = selectedPromotions;
    const categoryIndex = currentSelectedPromotions.findIndex(
      category => category.name === type
    );
    if (categoryIndex > -1) {
      const isExistIndex = currentSelectedPromotions[
        categoryIndex
      ].promotions.findIndex(pro => pro.id === promotion.id);
      if (isExistIndex > -1) {
        currentSelectedPromotions[categoryIndex].promotions = [];
      } else {
        currentSelectedPromotions[categoryIndex].promotions = [promotion];
      }
    } else {
      currentSelectedPromotions = [
        ...currentSelectedPromotions,
        {
          id: currentSelectedPromotions.length + 1,
          name: type,
          promotions: [promotion]
        }
      ];
    }
    setSelectedPromotions(currentSelectedPromotions);
    setReloadArrear(true);
  };

  const handleRemovePromotion = (type: string) => {
    const currentSelectedPromotions = selectedPromotions;
    const categoryIndex = currentSelectedPromotions.findIndex(
      category => category.name === type
    );
    if (categoryIndex > -1) {
      currentSelectedPromotions[categoryIndex].promotions = [];
    }
    setSelectedPromotions(currentSelectedPromotions);
    // load list all promotion after untick for selected promotion which has code
    const membershipSearchFilterTemp = membershipSearchFilter;
    membershipSearchFilterTemp.promotion = new PromotionSearchFilter();
    setPackagePlanSearchFilterAction(membershipSearchFilterTemp);
    setReloadArrear(true);
  };

  const handlePrevious = () => {
    const { membershipConfiguration } = membership;
    membershipConfiguration.stepIndex = 1;
    setConfigurationAction(membershipConfiguration);
    setProductExtrasAction(selectedPromotions, selectedAddons);
    if (selectedPackagePlanNew) {
      setSelectedPackagePlanAction(selectedPackagePlanNew);
    }
  };

  const handleNext = () => {
    const { membershipConfiguration } = membership;
    if (membershipConfiguration.stepLatest < 3) {
      membershipConfiguration.stepLatest = 3;
    }
    membershipConfiguration.stepIndex = 3;
    setConfigurationAction(membershipConfiguration);
    setProductExtrasAction(selectedPromotions, selectedAddons);
    if (selectedPackagePlanNew) {
      setSelectedPackagePlanAction(selectedPackagePlanNew);
    }
  };

  const handleOpenDropdown = (key: string) => {
    setOpenDropdown({
      ...openDropdown,
      [key]: !openDropdown[key]
    });
  };

  const goToStep = stepIndex => {
    const { membershipConfiguration } = membership;
    membershipConfiguration.stepIndex = stepIndex;
    setConfigurationAction(membershipConfiguration);
  };

  return (
    <Template
      membership={membership}
      openDropdown={openDropdown}
      isLoading={isLoading}
      addOns={addOns}
      promotionCategory={promotionCategory}
      specialPromotionCategory={specialPromotionCategory}
      selectedAddons={selectedAddons}
      selectedPromotions={selectedPromotions}
      selectedPackagePlanNew={selectedPackagePlanNew}
      activePromotions={activePromotions}
      currencyCode={currencyCode}
      handlePrevious={handlePrevious}
      handleOpenDropdown={handleOpenDropdown}
      handleNext={handleNext}
      goToStep={goToStep}
      handleUpdatePromotions={handleUpdatePromotions}
      handleRemovePromotion={handleRemovePromotion}
      handleChangeAddOn={handleChangeAddOn}
      onCancel={onCancel}
    />
  );
};

export default SelectProductExtras;
