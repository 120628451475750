import React from 'react';
import { useTranslation } from 'react-i18next';
import { IReferrerDetails } from '../interface';
import DescriptionContent from 'features/MemberSummary/DescriptionContent';

const ReferrerDetails: React.FC<IReferrerDetails> = ({ guest }) => {
  const { t } = useTranslation();
  const memberReferrerCode = guest.guestDetails.memberReferrerCodeInfo;

  const getReferrerName = () => {
    if (!memberReferrerCode.isSuccess || !memberReferrerCode.referrerCode) {
      return 'N/A';
    }
    return `${memberReferrerCode.referrerFirstName ||
      ''} ${memberReferrerCode.referrerMiddleName ||
      ''} ${memberReferrerCode.referrerLastName || ''}`;
  };
  const data = [
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.REFERRER_CODE'),
      data: memberReferrerCode.referrerCode
        ? memberReferrerCode.referrerCode
        : 'N/A'
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.GUEST_PASS.REFERRER_NAME'
      ),
      data: getReferrerName()
    }
  ];

  return (
    <DescriptionContent
      data={data.map(data => ({
        ...data,
        isVisible: true
      }))}
    />
  );
};

export default ReferrerDetails;
