import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import FormFilter from 'components/Membership/FormFilter';
import FormButton from 'components/Membership/FormButton';
import PackagePlanList from 'components/Membership/PackagePlanList';
import { ITemplate } from './interface';
import ConvertGuestToMemberFormInfo from 'components/Membership/ConvertGuestToMemberFormInfo';

const Template: React.FC<ITemplate> = ({
  dateOfBirthConfig,
  membershipConfiguration,
  membershipSearchFilter,
  errors,
  showCheckIcon,
  secondarySalePeople,
  clubs,
  applicationTypes,
  salePeople,
  reciprocalAccesses,
  minimumContracts,
  accesses,
  paymentMethods,
  packagePlans,
  isShowFilter,
  isLoading,
  filterRef,
  filterButtonRef,
  isAllowEditClub,
  toggleFilter,
  handleChange,
  handleInputFilterChange,
  handleDateChange,
  onPackagePlanSelected,
  handleFilterPackagePlan,
  handleClearFilter,
  goToStep,
  onCancel,
  applyPromotionCode,
  loadPackagePlansAfterChangeDate,
}) => {
  return (
    <section id="member">
      <div className="member member-paging">
        <div className="member-main">
          <Breadcrumb
            membershipConfiguration={membershipConfiguration}
            goToStep={stepIndex => goToStep(stepIndex)}
          />
          <ConvertGuestToMemberFormInfo
            isLoading={isLoading}
            loadPackagePlansAfterChangeDate={loadPackagePlansAfterChangeDate}
            dateOfBirthConfig={dateOfBirthConfig}
            membershipSearchFilter={membershipSearchFilter}
            membershipConfiguration={membershipConfiguration}
            errors={errors}
            clubs={clubs}
            applicationTypes={applicationTypes}
            salePeople={salePeople}
            toggleFilter={toggleFilter}
            filterButtonRef={filterButtonRef}
            handleChange={handleChange}
            handleInputChange={handleInputFilterChange}
            handleDateChange={handleDateChange}
            applyPromotionCode={applyPromotionCode}
            showCheckIcon={showCheckIcon}
            secondarySalePeople={secondarySalePeople}
            isAllowEditClub={isAllowEditClub}

          />
          <PackagePlanList
            isLoading={isLoading}
            packagePlans={packagePlans}
            selectedPackagePlanId={membershipSearchFilter.selectedPackagePlanId}
            membershipConfiguration={membershipConfiguration}
            onPackagePlanSelected={onPackagePlanSelected}
          />
          <FormFilter
            isShowFilter={isShowFilter}
            membershipSearchFilter={membershipSearchFilter}
            reciprocalAccesses={reciprocalAccesses}
            minimumContracts={minimumContracts}
            accesses={accesses}
            paymentMethods={paymentMethods}
            toggleFilter={toggleFilter}
            filterRef={filterRef}
            handleFilterChange={handleInputFilterChange}
            handleFilterPackagePlan={handleFilterPackagePlan}
            handleClearFilter={handleClearFilter}
          />
          <FormButton handleCancel={onCancel} />
        </div>
      </div>
    </section>
  );
};

export default Template;
