import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import { useTypedSelector } from 'reduxs';
import { useDispatch } from 'react-redux';

import { loadFeeEzyPay } from 'reduxs/actions/Payment';

import { EzyPayFeeUtils } from 'models/EzyPay/EzyPayFee';

import './ezypay-fee.scss';

import Loading from 'components/Loading';

const EzyPayFee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ezyPayFees, failLoadEzyPay } = useTypedSelector(
    state => state.payment
  );
  const plaSelected = useTypedSelector(
    state => state.newMembership.selectedPackagePlan
  );

  useEffect(() => {
    if (!Array.isArray(ezyPayFees) && plaSelected) {
      dispatch(loadFeeEzyPay());
    }
  }, [ezyPayFees, plaSelected]);

  const showTooltip = fee =>
    EzyPayFeeUtils.isPercentage(fee) || EzyPayFeeUtils.isBoth(fee);

  if (!ezyPayFees) {
    return <Loading />;
  }

  return (
    <div className="ezy-pay-fees">
      <p className="ezy-pay-fees__title">{t('EZYPAY.FEE')}</p>
      <ul className="ezy-pay-fees__content">
        {failLoadEzyPay ? (
          <span className="ezy-pay-fees__error">{t('FAIL_LOAD_EZYPAY')}</span>
        ) : (
          ezyPayFees.length === 0 && <span>{t('NO_FEE_EZYPAY')}</span>
        )}
        {EzyPayFeeUtils.sortByAppliesTo(ezyPayFees).map(fee => (
          <li key={fee.description}>
            <span className="ezy-pay-fees__content-title">
              {fee.description}
            </span>
            <span className="ezy-pay-fees__content-amount">
              {EzyPayFeeUtils.getValue(fee)}
              {showTooltip(fee) && (
                <Tooltip
                  title={
                    <span className="ezy-pay-fees__content-tooltip">
                      {t('INVOICE_AMOUNT')}
                    </span>
                  }
                  placement="bottom-end"
                >
                  <InfoIcon
                    fontSize="small"
                    className="infoIcon icon-detail-no-boder"
                  />
                </Tooltip>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EzyPayFee;
