import React from 'react';
import { useTranslation } from 'react-i18next';
import Dots from '../Dots';
import MembershipConfiguration from 'models/MembershipConfiguration';
import Step from './Step';

interface BreadcrumbProps {
  membershipConfiguration: MembershipConfiguration;
  goToStep: (stepIndex: number) => void;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  membershipConfiguration,
  goToStep
}) => {
  const { t } = useTranslation();
  const { stepIndex, stepLatest } = membershipConfiguration;

  if (!membershipConfiguration) {
    return <></>;
  }

  return (
    <div className="sticky-breadcrum">
      <div className="paging-steps d-flex">
        <Step
          stepNumber="01"
          label={t('PAGE.MEMBERSHIPS.BREADCRUMB.PACKAGE_PLAN')}
          isActive={true}
          isReadyToGo={stepLatest > 0 && stepIndex > 1}
          isActiveClick={stepLatest > 0 && stepIndex !== 1}
          onClick={() => goToStep(1)}
        />

        <div
          className={`section-dots ${
            stepLatest > 1 || stepIndex === 2 ? 'dots-primary' : ''
          }`}
        >
          <Dots />
        </div>
        <Step
          stepNumber="02"
          label={t('PAGE.MEMBERSHIPS.BREADCRUMB.PROMOTION_ADD_ON')}
          isActive={stepLatest > 1 || stepIndex === 2}
          isReadyToGo={stepIndex !== 2}
          isActiveClick={stepLatest > 1 && stepIndex !== 2}
          onClick={() => goToStep(2)}
        />

        <div
          className={`section-dots ${
            stepLatest > 2 || stepIndex === 3 ? 'dots-primary' : ''
          }`}
        >
          <Dots />
        </div>

        <Step
          stepNumber="03"
          label={t('PAGE.MEMBERSHIPS.BREADCRUMB.PERSONAL_DETAILS')}
          isActive={stepLatest > 2 || stepIndex === 3}
          isReadyToGo={stepIndex !== 3}
          isActiveClick={stepLatest > 2 && stepIndex !== 3}
          onClick={() => goToStep(3)}
        />

        <div
          className={`section-dots ${
            stepLatest > 3 || stepIndex === 4 ? 'dots-primary' : ''
          }`}
        >
          <Dots />
        </div>

        <Step
          stepNumber="04"
          label={t('PAGE.MEMBERSHIPS.BREADCRUMB.PAYMENT_DETAILS')}
          isActive={stepLatest > 3 || stepIndex === 4}
          isReadyToGo={stepIndex !== 4}
          isActiveClick={stepLatest > 3 && stepIndex !== 4}
          onClick={() => goToStep(4)}
        />

        <div
          className={`section-dots ${
            stepLatest > 4 || stepIndex === 5 ? 'dots-primary' : ''
          }`}
        >
          <Dots />
        </div>

        <Step
          stepNumber="05"
          label={t('PAGE.MEMBERSHIPS.BREADCRUMB.MEMBERS_SUMMARY')}
          isActive={stepLatest > 4 || stepIndex === 5}
          isReadyToGo={stepIndex !== 5}
          isActiveClick={stepLatest > 4 && stepIndex !== 1}
          onClick={() => goToStep(5)}
        />
      </div>
    </div>
  );
};

export default Breadcrumb;
