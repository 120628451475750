import * as types from 'constants/ActionTypes';
import { IPackagePlanItem } from 'interfaces/Membership';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import { GuestDetailsModel } from 'models/GuestDetails';

export interface IConvertGuestToGuestPass {
  searchFilter: ConvertGuestPassSearchFilter;
  configuration: ConvertGuestPassConfiguration;
  selectedPackagePlan: IPackagePlanItem | null;
  guestDetails: GuestDetailsModel;
  packagePlans: never[];
}

const convertGuestToGuestPass: IConvertGuestToGuestPass = {
  searchFilter: new ConvertGuestPassSearchFilter(),
  configuration: new ConvertGuestPassConfiguration(),
  selectedPackagePlan: null,
  guestDetails: new GuestDetailsModel(),
  packagePlans: []
};

const convertGuestToGuestPassReducer = (
  state = convertGuestToGuestPass,
  action
) => {
  switch (action.type) {
    case types.CONVERT_GUEST_TO_GUESTPASS_SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter
      };
    case types.CONVERT_GUEST_TO_GUEST_PASS_SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration
      };
    case types.CONVERT_GUEST_TO_GUESTPASS_SET_SELECTED_PACKAGE_PLAN:
      return {
        ...state,
        selectedPackagePlan: action.packagePlan
      };
    case types.CONVERT_GUEST_TO_GUESTPASS_SET_ALL_PACKAGE_PLAN:
      return {
        ...state,
        packagePlans: action.packagePlans
      };
    case types.CONVERT_GUEST_TO_GUESTPASS_SET_GUEST_DETAILS:
      return {
        ...state,
        guestDetails: action.guestDetails
      };
    default:
      return state;
  }
};

export default convertGuestToGuestPassReducer;
