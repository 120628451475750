export default class ConvertGuestPassSearchFilter {
  primarySalePersonId = 0;
  secondarySalePersonId = 0;
  primarySalePersonName = '';
  secondarySalePersonName = '';
  clubId = 0;
  clubName = '';
  startDate = new Date();
  selectedPackagePlanId = 0;
}
