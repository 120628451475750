import Swal from 'sweetalert2';
import axios, { AxiosRequestConfig } from 'axios';
import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from 'util/I18nMessages';
import { handleSessionExpired } from 'util/ControlUtils';
import {
  BASE_API_URL_DEVELOPMENT,
  IDENTITY_SERVER_ENDPOINT_URL,
  ESIGNATURE_URL
} from 'constants/Constants';
import { condition } from 'util/Common';

class Headers {
  subTenantId?: string = '';
  hasCache?: boolean = true;
}

const idpApi = axios.create({
  baseURL: IDENTITY_SERVER_ENDPOINT_URL
});
const baseApi = axios.create({
  baseURL: BASE_API_URL_DEVELOPMENT
});

baseApi.interceptors.request.use((config: AxiosRequestConfig) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${getAccessToken()}`
  }
}));

interface CreateHeader {
  hasCache?: boolean;
  subTenant?: {
    useSubTenant: boolean;
    subTenantId?: string;
  };
  tenant?: {
    useTenant: boolean;
    tenantId?: string;
  };
}

const createHeader = (
  config: CreateHeader = {
    hasCache: true,
    tenant: { useTenant: true, tenantId: '' },
    subTenant: { useSubTenant: true, subTenantId: '' }
  }
) => {
  const { hasCache, tenant, subTenant } = config;
  let xTenantId = {};
  let xSubTenantId = {};
  if (tenant && tenant.useTenant) {
    if (tenant.tenantId) {
      xTenantId = { 'x-tenant-id': tenant.tenantId };
    } else {
      xTenantId = condition(
        getTenantId(),
        { 'x-tenant-id': getTenantId() },
        {}
      );
    }
  }

  if (subTenant && subTenant.useSubTenant) {
    if (subTenant.subTenantId) {
      xSubTenantId = { 'x-subtenant-id': subTenant.subTenantId };
    } else {
      xSubTenantId = condition(
        getSubTenantId(),
        { 'x-subtenant-id': getSubTenantId() },
        {}
      );
    }
  }
  const cacheControl = condition(
    !hasCache,
    { 'cache-control': 'no-cache' },
    {}
  );
  return {
    ...xTenantId,
    ...xSubTenantId,
    ...cacheControl
  };
};

const getHeader = (hasCache: boolean = true) => {
  const xTenantId = getTenantId() ? { 'x-tenant-id': getTenantId() } : {};
  const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    accept: 'application/json',
    ...xTenantId,
    Authorization: `Bearer ${getAccessToken()}`,
    ...cacheControl
  };
};

const getHeaderWithoutSubTenant = (hasCache: boolean = true) => {
  const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    accept: 'application/json',
    'x-tenant-id': getTenantId(),
    Authorization: `Bearer ${getAccessToken()}`,
    ...cacheControl
  };
};

const getHeaderWithSubtenant = (subTenantId: string) => {
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    accept: 'application/json',
    'x-tenant-id': getTenantId(),
    'x-subtenant-id': subTenantId,
    Authorization: `Bearer ${getAccessToken()}`,
    'cache-control': 'no-cache'
  };
};

const getHeaderWithSubtenantAndRightName = (
  subTenantId: string = '',
  hasCache: boolean = true
) => {
  const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
  return {
    'Content-Type': 'application/json;charset=UTF-8',
    accept: 'application/json',
    'x-tenant-id': getTenantId(),
    'x-subtenant-id': subTenantId ? subTenantId : getSubTenantId(),
    'x-right-name': '',
    Authorization: `Bearer ${getAccessToken()}`,
    ...cacheControl
  };
};

const commonHeader = (headers: Headers) => {
  const { subTenantId, hasCache } = headers;
  const getHeaders = subTenantId
    ? getHeaderWithSubtenantAndRightName(subTenantId, hasCache)
    : getHeader(hasCache);
  return getHeaders;
};

const handleErrDate = err => {
  const errorResponse = err.response ? err.response.data : err.response;
  Swal.fire({
    ...DefaultConstants.SWAL_COMMON_STYLE,
    text: t('START_DATE_BILLING_ERROR'),
    type: 'error',
    confirmButtonText: t('BUTTON.CLOSE')
  });
  throw errorResponse;
};

const handleErrorHttp = err => {
  const errorResponse = err.response ? err.response.data : err.response;
  const title = errorResponse.title ? t(errorResponse.title) : '';
  const message = errorResponse.message
    ? t(errorResponse.message)
    : t('MSG.NETWORK_ERROR');

  if (errorResponse) {
    Swal.fire({
      ...DefaultConstants.SWAL_COMMON_STYLE,
      title: title,
      text: message,
      type: 'error',
      confirmButtonText: t('BUTTON.CLOSE')
    });
  }

  if (!errorResponse && err) {
    if (err.response && err.response.status === 401) {
      handleSessionExpired();
    } else {
      Swal.fire({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        text: t('MSG.NETWORK_ERROR'),
        type: 'warning',
        confirmButtonText: t('BUTTON.CLOSE')
      });
    }
  }

  throw errorResponse;
};

export interface DefaultInfo {
  'x-tenant-id': string;
  'x-subtenant-id': string;
  domain: string;
  language: string;
  expiredTime: string;
}

let accessToken = '';
let accountInfo: DefaultInfo;

/**
 * Listen for changes from other tabs
 */
window.addEventListener('storage', event => {
  if (event.key === 'accessToken') {
    accessToken = event.newValue || '';
  }
  if (event.key === 'accountInfo') {
    accountInfo = JSON.parse(event.newValue || '""');
  }
});

const getAccessToken = (): string | null => {
  if (accessToken) {
    return accessToken;
  }

  if (localStorage.accessToken) {
    return localStorage.accessToken;
  }

  return null;
};

const setAccessToken = (token: string): void => {
  accessToken = token;
  localStorage.accessToken = token;
};

const removeAccessToken = (): void => {
  accessToken = '';
};

const getDefaultUserInfo = (): DefaultInfo => {
  const accountInfo = JSON.parse(localStorage.accountInfo);
  return accountInfo;
};

const getTimeExpired = (): string => {
  const accountInfo = getDefaultUserInfo();
  return accountInfo.expiredTime;
};

const setDefaultUserInfo = (info: DefaultInfo): void => {
  accountInfo = info;
  localStorage.accountInfo = JSON.stringify(info);
};

const getTenantId = () => {
  const accountInfo = getDefaultUserInfo();
  return accountInfo['x-tenant-id'];
};

const getSubTenantId = () => {
  const accountInfo = getDefaultUserInfo();
  return accountInfo['x-subtenant-id'];
};

export const getEsignatureUrl = () => {
  return `${ESIGNATURE_URL}`
};

export {
  baseApi,
  idpApi,
  getHeader,
  getHeaderWithoutSubTenant,
  getHeaderWithSubtenant,
  getHeaderWithSubtenantAndRightName,
  commonHeader,
  handleErrorHttp,
  createHeader,
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getDefaultUserInfo,
  setDefaultUserInfo,
  getSubTenantId,
  getTenantId,
  handleErrDate,
  getTimeExpired
};
