import React, { useState, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import * as Interface from 'interfaces';
import { useTranslation } from 'react-i18next';

interface OptionModel {
  label: string;
  value: React.ReactNode;
}

interface SelectProps extends Interface.InputInterface {
  options: OptionModel[];
  isDisabled?: boolean;
  defaultValue?: string | number;
  isClearable: boolean;
  searchIcon?: boolean;
  isTyping?: boolean;
  onInputChange?: (value: string) => void;
  isLoading?: boolean;
}

const primaryColor = '#00b7bc';

const customStyles = (searchIcon = false) => {
  const padding = searchIcon
    ? {
        paddingLeft: 24
      }
    : {
        padding: 0
      };

  return {
    valueContainer: (provided: Record<string, {}>) => ({
      ...provided,
      ...padding
    }),
    option: (
      provided: Record<string, {}>,
      { isSelected }: { isSelected?: boolean }
    ) => {
      return {
        ...provided,
        backgroundColor: isSelected ? primaryColor : provided.backgroundColor
      };
    },
    control: (
      provided: Record<string, {}>,
      { isFocused }: { isFocused?: boolean }
    ) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: isFocused ? primaryColor : provided.borderColor,
      padding: '2px 10px',
      minHeight: '34px',
      '&:hover': { borderColor: 'primaryColor' }
    })
  };
};

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <SearchIcon className="search-icon" />}
        {children}
      </components.ValueContainer>
    )
  );
};

const Select = React.forwardRef<unknown, SelectProps>((props, ref) => {
  const {
    value,
    isDisabled,
    inputClass,
    searchIcon,
    isLoading,
    errorMsg,
    onInputChange,
    onChange
  } = props;
  const [menuIsOpen, setMenuOpen] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setMenuOpen(
      isDisabled
        ? {
            menuIsOpen: false
          }
        : {}
    );
  }, [isDisabled, value, errorMsg]);

  const handleChangeValue = (value: OptionModel) => {
    onChange!(value);
  };

  const handleInputChange = (value: string) => {
    onInputChange!(value);
  };

  const classNameError = errorMsg ? 'error' : '';
  const inputClassName = inputClass ? inputClass : '';

  return (
    <>
      <ReactSelect
        styles={customStyles(searchIcon)}
        classNamePrefix="select"
        components={searchIcon ? { ValueContainer } : {}}
        className={`${classNameError} ${inputClassName}`}
        onChange={handleChangeValue}
        isLoading={isLoading}
        noOptionsMessage={() => t('COMMON.NO_OPTIONS')}
        ref={ref}
        {...menuIsOpen}
        {...(onInputChange !== null
          ? { onInputChange: handleInputChange }
          : {})}
        {...props}
      />
      {errorMsg && <span className="text-danger input-error">{errorMsg}</span>}
    </>
  );
});

Select.displayName = 'Select';

export default Select;
