import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDisableShowIframe } from 'reduxs/actions/NewMembership';

interface EzPayEditButtonProps {
  paymentInfoExist: boolean;
}

const EzPayEditButton = (props: EzPayEditButtonProps) => {
  const { t } = useTranslation();
  const { paymentInfoExist } = props;
  const dispatch = useDispatch();

  const handleEditEzyPay = () => {
    dispatch(updateDisableShowIframe(false));
  };

  if (paymentInfoExist) {
    return (
      <button className="btn btn-primary" onClick={handleEditEzyPay}>
        {t('BUTTON.MODIFY_PAYMENT_DETAIL')}
      </button>
    );
  }

  return null;
};

export default EzPayEditButton;
