import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import * as Interface from 'interfaces';
import Label from './Label';

interface FormInputProps extends Interface.InputInterface {
  maxlength?: number;
  minlength?: number;
  toolTipMsg?: string;
  min?: string;
  max?: string;
  classLongErrorMsg?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (props, ref) => {
    const {
      label,
      id,
      name,
      type = 'text',
      placeholder,
      maxlength,
      minlength,
      value,
      onChange = () => {},
      className = '',
      require,
      errorMsg,
      classLongErrorMsg = '',
      toolTipMsg = '',
      inputClass = '',
      labelClass = '',
      disabled,
      onClick,
      min,
      max,
      onBlur
    } = props;

    function handleChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
      onChange(e);
    }

    function handleEnterInput(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        return false;
      }
    }

    return (
      <form
        className={`${className} position-relative form-group`}
        onKeyDown={handleEnterInput}
      >
        <Label label={label} labelClass={labelClass} isRequire={require} />

        <Tooltip disableFocusListener disableTouchListener title={toolTipMsg}>
          <input
            type={type}
            name={name}
            id={id}
            min={min}
            max={max}
            value={value}
            placeholder={placeholder}
            maxLength={maxlength}
            minLength={minlength}
            onChange={handleChangeValue}
            onClick={onClick}
            className={`${inputClass} form-control`}
            disabled={disabled}
            autoComplete="off"
            ref={ref}
            onBlur={onBlur}
          />
        </Tooltip>
        <span className={`text-danger input-error ${classLongErrorMsg}`}>
          {errorMsg ? errorMsg : ''}
        </span>
      </form>
    );
  }
);

export default FormInput;
