import React from 'react';
import OnlinePayment from 'models/OnlinePayment';
import { useTranslation } from 'react-i18next';

const OnlinePaymentCard: React.FC<{ card: OnlinePayment }> = ({ card }) => {
  const { cardType, cardHolderName, cardExpiryDate, cardNumber } = card;
  const { t } = useTranslation();
  return (
    <div className="card-information">
      <div className="card-information__field">
        <span>
          {t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_TYPE')}
        </span>
        <span>{cardType}</span>
      </div>
      <div className="card-information__field">
        <span>
          {t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_NUMBER')}
        </span>
        <span>{cardNumber}</span>
      </div>
      <div className="card-information__field">
        <span>
          {t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_HOLDER_NAME')}
        </span>
        <span>{cardHolderName}</span>
      </div>
      <div className="card-information__field">
        <span>
          {t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.EXPIRY_DATE')}
        </span>
        <span>
          {cardExpiryDate.slice(0, 2)}/{cardExpiryDate.slice(-2)}
        </span>
      </div>
    </div>
  );
};

export default OnlinePaymentCard;
