import {
  getGuestDetails,
  getGuestPassDetail,
  getSystemDate
} from 'services/membership-service';
import * as DefaultConstants from 'constants/DefaultConstants';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getEsignatureUrl, getSubTenantId } from 'services/common-service';
import Popup from 'util/Popup';
import { IConvertGuestToMember } from 'reduxs/reducers/ConvertGuestToMember';
import { IConvertGuestToCorporateMember } from 'reduxs/reducers/ConvertGuestToCorporateMember';
import { IGuestDetailsResponse } from 'services/interfaces/responses/membership';
import { t } from 'util/I18nMessages';

export const getInitData = async (
  externalId: string,
  populateMemberPersonalDetails,
  populateMemberSearchFilter,
  setConvertGuestGuestCorporateId,
  setConvertGuestMemberSummary,
  dispatch,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const res = await getGuestDetails({ externalId: externalId });
    const systemDate = await getSystemDate();
    if (res && res.data.length) {
      const guestInfo = res.data[0];
      const memberReferrerCodeInfo = guestInfo.memberReferrerCodeInfo;
      let minDate = new Date(systemDate.toString());
      if (guestInfo.status === DefaultConstants.GUEST_PASS_STATUS) {
        const result = await getGuestPassDetail(guestInfo.id.toString());
        const today = new Date(
          moment(systemDate.toString())
            .startOf('day')
            .toISOString()
        );
        const startDate = new Date(result.endDate);
        if (today <= startDate) {
          startDate.setDate(startDate.getDate() + 1);
          minDate = startDate;
        }
      }
      if (!memberReferrerCodeInfo.isSuccess) {
        Swal.fire({
          ...DefaultConstants.SWAL_COMMON_STYLE,
          type: 'error',
          padding: 'left',
          html: ` 
            <div class="invalid-popup">
            <p> Invalid Referrer code!</p>
            <p>(${memberReferrerCodeInfo.referrerCode})</p>
            <p>Tips:</p>
            <ul >
              <li>&bull; Please update guest's referrer code before convert.</li>
              <li>&bull; Or you may try to convert guest from Membership Management system instead.</li>
            </ul> 
            </div>
            `,
          allowOutsideClick: false,
          confirmButtonText: t('BUTTON.GOT_IT'),
          width: '50%'
        }).then(res => {
          window.parent.location.href = `${getEsignatureUrl()}/Guest?ClubId=0&OperatorId=0&Query=&Status=All&subTenantId=${getSubTenantId()}`;
        });
        setIsLoading(false);
        return;
      }
      populateMemberPersonalDetails(guestInfo);
      populateMemberSearchFilter(guestInfo, minDate);

      dispatch(setConvertGuestGuestCorporateId(Number(guestInfo.corporateId)));
    }
    dispatch(setConvertGuestMemberSummary(res));
  } catch (e) {
    Popup.showMessageCustom({
      text: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR'),
      type: 'error'
    });
  } finally {
    setIsLoading(false);
  }
};

const checkMemberReferrerInfo = (memberSummary: IGuestDetailsResponse) => {
  if (!memberSummary.data.length) return false;
  return true;
};

export const getReferrerId = (
  newMemberShip: IConvertGuestToMember | IConvertGuestToCorporateMember
) => {
  if (!newMemberShip) return 0;
  if (!newMemberShip.memberSummary) return 0;
  if (checkMemberReferrerInfo(newMemberShip.memberSummary)) {
    return newMemberShip.memberSummary.data[0].memberReferrerCodeInfo
      .referrerMemberId;
  }
  return 0;
};
