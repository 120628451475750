import React, { useEffect, useRef, useState } from 'react';
import Template from './template';
import ISelectePackagePlan from './interface';
import * as Utils from 'util/ControlUtils';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import MembershipConfiguration from 'models/MembershipConfiguration';
import OptionModel from 'models/Option';
import { useTranslation } from 'react-i18next';
import { FieldConfig } from 'models/PersonalDetails';
import observer from 'util/Observer';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import {
  getPackagePlansForCorporateMember,
  getSalePeople,
  getCorporateNames
} from 'services/membership-service';
import { condition, getPackageAccessText } from 'util/Common';
import {
  IPackagePlanItemAddCorporateMember,
  ICounter,
  IPackagePlanItem,
  IAllPackagePlansAddCorporateMember
} from 'interfaces/Membership';
import { useTypedSelector } from 'reduxs';
import { IValidPromotion } from 'models/Promotion';
import { FieldError } from 'interfaces/common/FieldError';
import { IGetCorporateNameResponse } from 'services/interfaces/responses/membership';
import ICorporateOptionModel from 'models/ICorporateOptionModel';
import {
  cloneMembershipSearchFilter,
  defaultOption,
  findCurrentCorporate,
  getCorporateGuestID,
  getCorporateID,
  getErrorAfterHandle,
  getInitialData,
  getInitialValue,
  getListPackagePlansCorporateMember,
  getMembershipConfigurationFromDOB,
  getMembershipSearchFilterAfterClearFilter,
  getPackagePlanForConvert,
  getPromotionId,
  getTemporaryData,
  goToNextStep,
  handleChangeApplicationTypeId,
  handleChangeDate,
  handleChangePrimarySalePerson,
  handleChangeSecondarySalePeople,
  handleErrorToNextStep,
  handleFilterCorporatePackagePlanData,
  handleOutSideClick,
  handlePromoCode,
  handleSetDataConvertGuestToCorporateMember,
  makeSubsidyTypes,
  onCountFilterData,
  onPackagePlanSelectedCorporateMember,
  processMembershipSearchFilter,
  searchPackagePlan,
  storeDataToRedux,
  toggleShowFilter
} from 'common/SelectPackagePlan';
import { CLUB_KEY_CONMAN } from 'constants/DefaultConstants';
import { IConfig } from 'interfaces/SystemConfig';
import { Club } from 'models/Club';
import { InitialProps } from '../SelectPackagePlanAddMember/interface';
import { ProductFilterType } from 'services/enums';
import { updateFeeEzyPay } from 'reduxs/actions/Payment';
import { useDispatch } from 'react-redux';

const SelectPackagePlan: React.FC<ISelectePackagePlan> = ({
  configs,
  onCancel,
  setSelectedPackagePlanAction,
  setConfigurationAction,
  setPackagePlanSearchFilterAction,
  setProductExtrasAction,
  setAllPackageAction,
  setGuestCorporateIdAction
}) => {
  const dispatch = useDispatch();
  const filterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const newMembership = useTypedSelector(
    state => state.convertGuestToCorporateMember
  );
  const conman = useTypedSelector(state => state.conman);
  const [membershipSearchFilter, setMembershipSearchFilter] = useState(
    new MembershipSearchFilter()
  );
  const [membershipConfiguration, setMembershipConfiguration] = useState(
    new MembershipConfiguration()
  );
  const [selectedPackagePlan, setSelectedPackagePlan] = useState<
    IPackagePlanItem
  >();
  const [dateOfBirthConfig, setDateOfBirthConfig] = useState(new FieldConfig());

  const [packagePlans, setPackagePlans] = useState<
    IPackagePlanItemAddCorporateMember[]
  >([]);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clubs, setClubs] = useState<OptionModel[]>([]);
  const [allPackagePlans, setAllPackagePlans] = useState<
    IAllPackagePlansAddCorporateMember[]
  >([]);
  const [salePeople, setSalePeople] = useState<OptionModel[]>([]);
  const [corporateNames, setCorporateNames] = useState<ICorporateOptionModel[]>(
    []
  );
  const [secondarySalePeople, setSecondarySalePeople] = useState<OptionModel[]>(
    []
  );
  const [applicationTypes, setApplicationTypes] = useState<OptionModel[]>([]);
  const [reciprocalAccesses, setReciprocalAccesses] = useState<ICounter[]>([]);
  const [minimumContracts, setMinimumContracts] = useState<ICounter[]>([]);
  const [accesses, setAccesses] = useState<ICounter[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<ICounter[]>([]);
  const [subsidyTypes, setSubsidyTypes] = useState<ICounter[]>([]);
  const [errors, setErrors] = useState<FieldError>({});
  const [showCheckIcon, setShowCheckIcon] = useState<boolean>(false);
  const [validPromotion, setValidPromotion] = useState<IValidPromotion | null>(
    null
  );
  const [isEditClub, setIsEditClub] = useState<boolean>(false);
  const [currentCorporate, setCurrentCorporate] = useState<
    ICorporateOptionModel
  >();
  const [allClubs, setAllClubs] = useState<Club[]>([]);
  const [initialValues, setInitialValues] = useState<InitialProps>(
    getInitialValue(membershipSearchFilter)
  );

  const handleClickOutSide = e => {
    const isFilterShown = handleOutSideClick(e, filterRef, filterButtonRef);
    if (isFilterShown) {
      setIsShowFilter(false);
    }
  };

  const setDOBFormat = () => {
    const membershipConfig = getMembershipConfigurationFromDOB(
      configs,
      membershipConfiguration
    );
    if (configs) {
      setMembershipConfiguration(membershipConfig);
      return;
    }
    setMembershipConfiguration(new MembershipConfiguration());
  };

  const handleSetClub = async (
    res,
    membershipSearchFilter: MembershipSearchFilter
  ): Promise<[MembershipSearchFilter | undefined, OptionModel[]]> => {
    setIsLoading(true);
    const {
      membershipSearchFilterTemp,
      showCheckIcon,
      validPromotion,
      startDate,
      clubs,
      promotionId,
      clubsData
    } = await processMembershipSearchFilter(
      res,
      membershipSearchFilter,
      newMembership
    );
    if (clubs.length > 0 && clubs[0].value) {
      membershipSearchFilterTemp.clubSelect = clubsData.find(
        club => club.id === membershipSearchFilterTemp.clubId
      );
      if (!membershipSearchFilterTemp.startDate) {
        setIsLoading(false);
        setPackagePlans([]);
        setAllClubs(clubsData);
        return [membershipSearchFilterTemp, clubs];
      }
      setValidPromotion(validPromotion);
      setShowCheckIcon(showCheckIcon);
      setIsLoading(true);
      setAllClubs(clubsData);
      const {
        allPackagePlans,
        salePeople,
        corporateNameList,
        secondarySalePeople,
        membershipSearchFilterTmp,
        currentCorporate
      } = await handleSetDataConvertGuestToCorporateMember(
        membershipSearchFilterTemp,
        promotionId,
        startDate
      );

      if (currentCorporate) {
        membershipSearchFilterTemp.corporateId = currentCorporate.id;
        setCurrentCorporate(currentCorporate);
      } else {
        setCurrentCorporate(corporateNameList[0]);
      }
      setSalePeople(salePeople);
      setCorporateNames(corporateNameList);
      setSecondarySalePeople(secondarySalePeople);
      setMembershipSearchFilter(membershipSearchFilterTmp);
      setAllPackageAction(allPackagePlans);
      setAllPackagePlans(allPackagePlans);
      setPackagePlans(allPackagePlans);
      const err = {
        ...errors,
        clubId: condition(
          !allPackagePlans.length &&
            Number(membershipSearchFilterTemp.corporateId),
          'PAGE.MEMBERSHIPS.CREATE.STEP_1.NO_CONTRACT',
          ''
        )
      };
      setErrors(err);
      setIsLoading(false);
    }
    return [membershipSearchFilterTemp, clubs];
  };

  useEffect(() => {
    dispatch(updateFeeEzyPay(null));
    if (newMembership.selectedPackagePlan) {
      if (!currentCorporate || !currentCorporate.id) {
        const corporate = corporateNames.find(
          corporate =>
            corporate.id.toString() ==
            membershipSearchFilter.corporateId.toString()
        );
        setCurrentCorporate(corporate);
      }

      setInitialValues(getInitialValue(membershipSearchFilter));
      searchPackagePlan(membershipSearchFilter, handleFilterPackagePlan);
    } else {
      onCountFilter(allPackagePlans);
    }
  }, [newMembership.selectedPackagePlan, isLoading]);

  const getInitData = async (
    membershipSearchFilter: MembershipSearchFilter
  ) => {
    setIsLoading(true);
    const {
      dateOfBirthConfig,
      clubs,
      applicationTypes,
      searchFilterFinal
    } = await getInitialData(membershipSearchFilter, handleSetClub);
    setDateOfBirthConfig(dateOfBirthConfig);
    setClubs(clubs);
    setApplicationTypes(applicationTypes);
    setInitialValues(getInitialValue(searchFilterFinal));
    setMembershipSearchFilter(searchFilterFinal);
    setIsLoading(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    observer.publish('flagUnsavedChangeEvent', true);
    window.scrollTo(0, 0);
    const clubCfg = conman.configs.find(
      (cfg: IConfig) => cfg.key === CLUB_KEY_CONMAN && cfg.value
    );
    const {
      membershipSearchFilterTemp,
      membershipConfigurationTemp,
      selectedPackagePlanTemp
    } = getTemporaryData(
      membershipSearchFilter,
      membershipConfiguration,
      newMembership,
      selectedPackagePlan
    );
    if (clubCfg) {
      setIsEditClub(true);
    }
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setMembershipConfiguration(membershipConfigurationTemp);
    setSelectedPackagePlan(selectedPackagePlanTemp);
    setDOBFormat();
    getInitData(membershipSearchFilterTemp);
    setProductExtrasAction([], []);
  }, []);

  const handleFilterPackagePlan = () => {
    const result = handleFilterCorporatePackagePlanData(
      allPackagePlans,
      membershipSearchFilter
    );
    setPackagePlans(result);
    if (result.length > 0) {
      onCountFilter(allPackagePlans);
    }
  };

  const onCountFilter = (
    packagePlans: IAllPackagePlansAddCorporateMember[]
  ) => {
    const subsidyTypes = makeSubsidyTypes(packagePlans);
    const {
      reciprocalAccesses,
      newContracts,
      accesses,
      paymentMethods
    } = onCountFilterData(getPackagePlanForConvert(
      packagePlans
    ) as IAllPackagePlansAddCorporateMember[]);
    setReciprocalAccesses(reciprocalAccesses);
    setMinimumContracts(newContracts);
    setAccesses(accesses);
    setPaymentMethods(paymentMethods);
    setSubsidyTypes(subsidyTypes);
  };

  const handleClearFilter = () => {
    const membershipSearchFilterTemp = getMembershipSearchFilterAfterClearFilter(
      membershipSearchFilter
    );
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setPackagePlans(allPackagePlans);
    onCountFilter(allPackagePlans);
  };

  const toggleFilter = e => {
    const filterToggle = toggleShowFilter(e, isShowFilter);
    setIsShowFilter(filterToggle);
  };

  const handleSaveAndLeave = (stepIndex, isSave = false) => {
    const { membershipConfiguration } = newMembership;
    if (isSave) {
      storeDataToRedux(
        newMembership,
        membershipSearchFilter,
        setPackagePlanSearchFilterAction
      );
    }
    membershipConfiguration.stepIndex = stepIndex;
    setConfigurationAction(membershipConfiguration);
    observer.publish('closeDialogEvent', true);
  };

  const goToStep = stepIndex => {
    const membershipConfiguration = goToNextStep(
      stepIndex,
      newMembership,
      membershipSearchFilter,
      isReadyToNextStep,
      handleSaveAndLeave
    );
    if (membershipConfiguration) {
      setConfigurationAction(membershipConfiguration);
    }
  };

  const isReadyToNextStep = () => {
    let errors = handleErrorToNextStep(
      membershipSearchFilter,
      membershipConfiguration,
      dateOfBirthConfig
    );
    if (
      !Utils.isValidStartDateForGuestPass(
        newMembership.membershipSearchFilter.startDate,
        newMembership.membershipSearchFilter.minStartDate
      )
    ) {
      errors = {
        ...errors,
        startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_NOT_VALID')
      };
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const findCorporate = (key: string, event) => {
    let corporateName;
    if (key === 'corporateName') {
      corporateName = findCurrentCorporate(corporateNames, event.value);
    } else if (currentCorporate) {
      corporateName = findCurrentCorporate(
        corporateNames,
        currentCorporate.value
      );
    }
    return corporateName;
  };

  const handleChange = async (key, event) => {
    if (!event.value) return;
    let membershipSearchFilterTemp = cloneMembershipSearchFilter(
      membershipSearchFilter
    );
    const newValue = event.value;
    const corporate = findCorporate(key, event);
    membershipSearchFilterTemp.corporateId = corporate.id;
    if (key.includes('clubId')) {
      setIsLoading(true);
      membershipSearchFilterTemp[key] = newValue;
      membershipSearchFilterTemp.clubSelect =
        allClubs.find(club => club.id === newValue) || null;
      const startDate = DisplayDateFormat(
        membershipSearchFilterTemp.startDate,
        GENERAL_DATABASE_DATE_FORMAT
      );
      Promise.all([
        getSalePeople({ clubId: newValue }),
        getCorporateNames({ clubId: newValue })
      ]).then(([res1, res3]) => {
        const salePersonList = Utils.transformOptions<OptionModel>(
          res1.data,
          'name',
          'id'
        );
        const rawCorporateNames = Utils.alphabetSort(
          res3.filter(c => c.status === 'AC'),
          'name'
        );
        const corporateNameList: ICorporateOptionModel[] = rawCorporateNames.map(
          (item: IGetCorporateNameResponse) => ({
            label: item.name,
            value: item.corporateIdentifier,
            id: item.corporatePartyId
          })
        );
        const clubList = clubs.filter(cl => cl.value === newValue);
        membershipSearchFilterTemp.clubName = condition(
          clubList.length > 0,
          clubList[0].label,
          ''
        );

        if (salePersonList.length > 0) {
          membershipSearchFilterTemp.primarySalePersonId = condition(
            salePersonList[0],
            salePersonList[0].value,
            ''
          );
          membershipSearchFilterTemp.primarySalePersonName = condition(
            salePersonList[0],
            salePersonList[0].label,
            ''
          );
          membershipSearchFilterTemp.secondarySalePersonId = condition(
            secondarySalePeople[0],
            secondarySalePeople[0].value,
            ''
          );
          membershipSearchFilterTemp.secondarySalePersonName = condition(
            secondarySalePeople[0],
            secondarySalePeople[0].label,
            ''
          );
        }
        membershipSearchFilterTemp.reciprocalAccessName = '';
        membershipSearchFilterTemp.minimumContractName = '';
        membershipSearchFilterTemp.accessName = '';
        membershipSearchFilterTemp.paymentMethodName = '';

        setSalePeople(salePersonList);
        setCorporateNames(corporateNameList);
        setSecondarySalePeople([defaultOption, ...salePersonList]);
        setMembershipSearchFilter(membershipSearchFilterTemp);
        setCurrentCorporate(corporate);
        getPackagePlansForCorporateMember({
          clubId: newValue,
          startDate,
          promoId: getPromotionId(validPromotion),
          corporateId: getCorporateID(corporate),
          productFilterType: ProductFilterType.CorporatePlansOnly,
          dob: Utils.getFormattedDateValue(
            membershipSearchFilterTemp.dateOfBirth
          )
        }).then(res2 => {
          let allPackagePlans = res2.data;
          allPackagePlans = allPackagePlans.map((obj, index: number) => ({
            ...obj,
            package: {
              ...obj.package,
              timeGroup: {
                ...obj.package.timeGroup,
                name: getPackageAccessText(obj)
              }
            },
            planArgreementId: index,
            minimumContractValue: `${obj.minimumContract.value} ${
              obj.minimumContract.unit.name
            }`
          }));

          setAllPackageAction(allPackagePlans);
          setAllPackagePlans(allPackagePlans);
          setPackagePlans(allPackagePlans);
          onCountFilter(allPackagePlans);
          setIsLoading(false);

          const err = {
            ...errors,
            clubId: condition(
              !allPackagePlans.length &&
                Number(membershipSearchFilterTemp.corporateId),
              'PAGE.MEMBERSHIPS.CREATE.STEP_1.NO_CONTRACT',
              ''
            )
          };
          setErrors(err);
        });
      });
    }
    if (key.includes('primarySalePersonId')) {
      membershipSearchFilterTemp = handleChangePrimarySalePerson(
        membershipSearchFilterTemp,
        salePeople,
        newValue
      );
    }
    if (key.includes('secondarySalePersonId')) {
      membershipSearchFilterTemp = handleChangeSecondarySalePeople(
        membershipSearchFilterTemp,
        secondarySalePeople,
        newValue
      );
    }
    if (key.includes('applicationTypeId')) {
      membershipSearchFilterTemp = handleChangeApplicationTypeId(
        membershipSearchFilterTemp,
        applicationTypes,
        newValue
      );
    }

    if (key.includes('corporateName')) {
      let allPackagePlans = [];
      setIsLoading(true);
      const corporateName = corporateNames.find(c => c.value === newValue);
      setCurrentCorporate(corporateName);
      if (Number(newValue)) {
        const startDate = DisplayDateFormat(
          membershipSearchFilterTemp.startDate,
          GENERAL_DATABASE_DATE_FORMAT
        );
        const res2 = await getPackagePlansForCorporateMember({
          clubId: membershipSearchFilterTemp.clubId,
          startDate,
          promoId: getPromotionId(validPromotion),
          corporateId: getCorporateID(corporateName),
          productFilterType: ProductFilterType.CorporatePlansOnly,
          dob: Utils.getFormattedDateValue(
            membershipSearchFilterTemp.dateOfBirth
          )
        });
        allPackagePlans = res2.data.map((obj, index: number) => ({
          ...obj,
          package: {
            ...obj.package,
            timeGroup: {
              ...obj.package.timeGroup,
              name: getPackageAccessText(obj)
            }
          },
          planArgreementId: index,
          minimumContractValue: `${obj.minimumContract.value} ${
            obj.minimumContract.unit.name
          }`
        }));
      }
      if (corporateName) {
        membershipSearchFilterTemp.corporateId = Number(corporateName.id);
      }
      setAllPackageAction(allPackagePlans);
      setAllPackagePlans(allPackagePlans);
      setPackagePlans(allPackagePlans);
      onCountFilter(allPackagePlans);
      setIsLoading(false);
      const err = {
        ...errors,
        clubId: condition(
          !allPackagePlans.length &&
            Number(membershipSearchFilterTemp.corporateId),
          'PAGE.MEMBERSHIPS.CREATE.STEP_1.NO_CONTRACT',
          ''
        )
      };
      setErrors(err);
    }
    membershipSearchFilterTemp[key] = newValue;
    setMembershipSearchFilter(membershipSearchFilterTemp);
  };

  const handleError = async (error: any, startDate: string) => {
    setShowCheckIcon(false);
    setValidPromotion(null);
    const err = await getErrorAfterHandle(
      error,
      startDate,
      getPackagePlanList,
      errors
    );
    setErrors(err);
  };

  const getPackagePlanList = async (
    startDate: string,
    promoId: number,
    dob: string
  ) => {
    setIsLoading(true);
    const allPackagePlans = await getListPackagePlansCorporateMember(
      membershipSearchFilter,
      startDate,
      promoId,
      dob,
      currentCorporate
    );
    setPackagePlans(allPackagePlans);
    setAllPackagePlans(allPackagePlans);
    setIsLoading(false);
  };

  const handlePromotionCode = async promotionCode => {
    if (initialValues.promotionCode == promotionCode) {
      return;
    }
    setIsLoading(true);
    const {
      showCheckIcon,
      validPromotion,
      membershipSearchFilterTemp,
      error
    } = await handlePromoCode(
      true,
      promotionCode,
      membershipSearchFilter,
      getPackagePlanList,
      handleError
    );
    setErrors({ ...errors, promotionCode: error });
    setShowCheckIcon(showCheckIcon);
    setValidPromotion(validPromotion);
    setMembershipSearchFilter(membershipSearchFilterTemp);
    setInitialValues({ ...initialValues, promotionCode });
    setIsLoading(false);
  };

  const applyPromotionCode = async event => {
    const promotionCode = event.target.value;
    if (event.nativeEvent.key && event.nativeEvent.key === 'Enter') {
      const inputElement = document.getElementById('promotion-input');
      if (inputElement) {
        inputElement.blur();
      }
      return;
    }
    await handlePromotionCode(promotionCode);
  };

  const onPackagePlanSelected = (
    packagePlanId: number,
    agreementPartyID: number
  ) => {
    if (isReadyToNextStep()) {
      const {
        membershipConfigurationTemp,
        membershipSearchFilterTemp,
        packagePlan
      } = onPackagePlanSelectedCorporateMember(
        membershipSearchFilter,
        membershipConfiguration,
        packagePlans,
        packagePlanId,
        validPromotion,
        agreementPartyID,
        currentCorporate
      );
      if (currentCorporate) {
        membershipSearchFilterTemp.corporateId = Number(currentCorporate.id);
      }
      if (packagePlan) {
        setSelectedPackagePlanAction(packagePlan);
      }
      setConfigurationAction(membershipConfigurationTemp);
      setPackagePlanSearchFilterAction(membershipSearchFilterTemp);
      setGuestCorporateIdAction(getCorporateGuestID(currentCorporate));
    }
  };

  const handleInputFilterChange = (key, event) => {
    const membershipSearchFilterTemp = cloneMembershipSearchFilter(
      membershipSearchFilter
    );
    if (key === 'promotionCode') {
      membershipSearchFilterTemp.promotion.code = event.target.value;
      setValidPromotion(null);
      const error = { ...errors, promotionCode: '' };
      setErrors(error);
    } else {
      membershipSearchFilterTemp[key] = event.target.value;
    }
    setMembershipSearchFilter(membershipSearchFilterTemp);
  };

  const handleDateChange = async (date, name) => {
    if (name === 'dateOfBirth' && Utils.isBeforeMinDateSystem(date)) {
      return;
    }
    const membershipSearchFilterTemp = await handleChangeDate(
      date,
      name,
      membershipSearchFilter
    );
    setDOBFormat();
    setMembershipSearchFilter(membershipSearchFilterTemp);
    let startDate, dob;
    if (name === 'startDate') {
      setErrors({ ...errors, startDate: '' });
    }
    if (name === 'dateOfBirth') {
      setErrors({ ...errors, dateOfBirth: '' });
    }
  };

  const loadPackagePlansAfterChangeDate = async (name: string) => {
    const startDate = Utils.getFormattedDateValue(
      membershipSearchFilter.startDate
    );
    const dob = Utils.getFormattedDateValue(membershipSearchFilter.dateOfBirth);
    if (
      (name === 'startDate' && initialValues.startDate !== startDate) ||
      (name === 'dateOfBirth' && initialValues.dateOfBirth !== dob)
    ) {
      await getPackagePlanList(startDate, getPromotionId(validPromotion), dob);
      setInitialValues({ ...initialValues, startDate, dateOfBirth: dob });
    }
  };

  return (
    <Template
      dateOfBirthConfig={dateOfBirthConfig}
      membershipConfiguration={membershipConfiguration}
      membershipSearchFilter={membershipSearchFilter}
      errors={errors}
      clubs={clubs}
      secondarySalePeople={secondarySalePeople}
      applicationTypes={applicationTypes}
      salePeople={salePeople}
      corporateNames={corporateNames}
      reciprocalAccesses={reciprocalAccesses}
      minimumContracts={minimumContracts}
      accesses={accesses}
      subsidyTypes={subsidyTypes}
      paymentMethods={paymentMethods}
      packagePlans={getPackagePlanForConvert(packagePlans) as []}
      isShowFilter={isShowFilter}
      isLoading={isLoading}
      filterRef={filterRef}
      filterButtonRef={filterButtonRef}
      showCheckIcon={showCheckIcon}
      toggleFilter={toggleFilter}
      handleChange={handleChange}
      handleInputFilterChange={handleInputFilterChange}
      handleDateChange={handleDateChange}
      onPackagePlanSelected={onPackagePlanSelected}
      handleFilterPackagePlan={handleFilterPackagePlan}
      handleClearFilter={handleClearFilter}
      goToStep={goToStep}
      onCancel={onCancel}
      applyPromotionCode={applyPromotionCode}
      loadPackagePlansAfterChangeDate={loadPackagePlansAfterChangeDate}
      isAllowEditClub={isEditClub}
      corporateId={currentCorporate ? currentCorporate.value : ''}
    />
  );
};

export default SelectPackagePlan;
