import moment from 'moment';
import React from 'react';
import { formatAmount } from 'util/Common';
import * as Utils from 'util/ControlUtils';
import { METHOD_CLUB } from 'constants/Payment';
import { IMemberPayments } from '../interface';
import { useTranslation } from 'react-i18next';
import CustomTable from 'components/Table';

const MemberPayments: React.FC<IMemberPayments> = ({ membership, fees }) => {
  const { membershipConfiguration } = membership;
  const { t } = useTranslation();

  if (!fees || !fees.length || fees.length === 0) {
    return <></>;
  }
  const { newMemberTimeFormat } = membershipConfiguration;

  const feeFormatted = fees.map(item => {
    const amount = formatAmount(item ? item.amount : 0);
    return {
      ...item,
      startDate: moment(item.startDate).format(
        newMemberTimeFormat.toUpperCase()
      ),
      amount
    };
  });

  return (
    <>
      <p>
        {t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_5.PAYMENTS_PROJECT_FOR_6_MONTHS_UNTIL_VALUE',
          {
            value: moment(fees[0].startDate)
              .add(5, 'months')
              .startOf('month')
              .format(membershipConfiguration.newMemberTimeFormat.toUpperCase())
          }
        )}
      </p>
      <div className="decentralize">
        <div className="decentralize-main">
          <div className="table-responsive">
            <CustomTable
              headers={[
                {
                  key: 1,
                  title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE'
                },
                {
                  key: 2,
                  title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.TYPE'
                },
                {
                  key: 3,
                  title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.METHOD'
                },
                {
                  key: 4,
                  title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.STATUS'
                },
                {
                  key: 5,
                  title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.AMOUNT'
                }
              ]}
              tableContent={feeFormatted.map(item => {
                return (
                  <tr key={Utils.uniqueId()}>
                    <td className="td-left">{item.startDate}</td>
                    <td className="td-left">{item.typeName}</td>
                    <td className="td-left">
                      {item.collectionMethodCode === METHOD_CLUB
                        ? 'Club'
                        : 'Request'}
                    </td>
                    <td className="td-left">Projected</td>
                    <td className="td-left">
                      <p className="amount">
                        {item.amount[0]}.<sup>{item.amount[1]}</sup>
                      </p>
                    </td>
                  </tr>
                );
              })}
              renderingCondition
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberPayments;
