import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPersonalDetails } from '../interface';
import * as Utils from 'util/ControlUtils';
import { getAddress, getGender, getName } from 'util/Common';
import DescriptionContent from 'features/MemberSummary/DescriptionContent';

const GuestDetails: React.FC<IPersonalDetails> = ({
  guest,
  answerMappingData,
  corporateNames
}) => {
  const { configuration, guestDetails } = guest;
  const { marketingSource, channelCode, marketingCampaign } = answerMappingData;
  const { t } = useTranslation();
  const channelMapped = channelCode.find(
    channel => channel.value === guestDetails.channelCode
  );
  const marketingCampaignMapped = marketingCampaign.find(
    campaign => campaign.value === guestDetails.marketingCampaign
  );

  const marketingSourceMapped = marketingSource.find(
    campaign => campaign.value === guestDetails.leadSource
  );

  const corporateMapped = corporateNames.find(
    cor => cor.value === guestDetails.corporateId
  );

  const data = [
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.NAME'),
      data: Utils.getValueData(
        getName({
          lastName: guestDetails.lastName,
          middleName: guestDetails.middleName,
          firstName: guestDetails.firstName
        })
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENDER'),
      data: Utils.getValueData(t(getGender(guestDetails.gender)))
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE_OF_BIRTH'),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(
          guestDetails.dateOfBirth,
          configuration.newMemberTimeFormat
        )
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_ADDRESS'),
      data: Utils.getValueData(
        getAddress({
          number: guestDetails.address,
          city: guestDetails.city,
          state: guestDetails.state,
          postCode: guestDetails.zip
        })
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MOBILE_TELEPHONE'),
      data: Utils.getValueData(guestDetails.mobilePhone)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_TELEPHONE'),
      data: Utils.getValueData(guestDetails.homePhone)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.WORK_TELEPHONE'),
      data: Utils.getValueData(guestDetails.workPhone)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EMAIL'),
      data: Utils.getValueData(guestDetails.email)
    },

    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.PRIMARY_SALES_PERSON'
      ),
      data: Utils.getValueData(guestDetails.salePersonName)
    },

    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.SECONDARY_SALES_PERSON'
      ),
      data: Utils.getValueData(guestDetails.secondarySalePersonName)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.SOURCE'),
      data:
        marketingSourceMapped && Utils.getValueData(marketingSourceMapped.label)
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.MARKETING_CAMPAIGN'
      ),
      data:
        marketingCampaignMapped &&
        Utils.getValueData(marketingCampaignMapped.label)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.CHANNEL_CODE'),
      data: channelMapped && Utils.getValueData(channelMapped.label)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.GUEST_CORPORATE'),
      data: corporateMapped && Utils.getValueData(corporateMapped.label)
    }
  ];

  return (
    <DescriptionContent
      data={data.map(dataItem => ({
        ...dataItem,
        isVisible: true
      }))}
    />
  );
};

export default GuestDetails;
