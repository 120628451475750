import axios from 'axios';
import {
  API_ARREARS_PREVIEW,
  API_GET_ADDON,
  API_GET_ISSUING_BANKS,
  API_GET_PROMOTION_CATEGORIES,
  API_GET_RAW_RESPONSE,
  API_MBS_GET_AIA_VITALITY_CATEGORY,
  API_MBS_GET_APPLICATION_TYPES,
  API_MBS_GET_CLUBS,
  API_MBS_GET_CONMAN_CONFIG,
  API_MBS_GET_CONTACT_METHODS,
  API_MBS_GET_COUNTRIES,
  API_MBS_GET_DUPLICATE_MEMBERS,
  API_MBS_GET_DUPLICATE_MEMBERS_EMAIL,
  API_MBS_GET_EMERGENCY_CONFIG,
  API_MBS_GET_FIELDS_ADD_MEMBER,
  API_MBS_GET_GUARDIAN_IDENTITY_TYPE_ID,
  API_MBS_GET_LOOK_UP,
  API_MBS_GET_MEMBER_BANKS,
  API_MBS_GET_MEMBER_CARD_TYPES,
  API_MBS_GET_MEMBER_GENDERS,
  API_MBS_GET_MEMBER_LANGUAGE_PREFERENCES,
  API_MBS_GET_MEMBER_TITLES,
  API_MBS_GET_PACKAGE_PLANS,
  API_MBS_GET_RECURRING_CARD_TYPE,
  API_MBS_GET_SALE_PEOPLE,
  API_MBS_POST_MEMBER,
  INVALID_DATE,
  CHECK_AVAILABLE_PROMOTION,
  API_MBS_GET_CORPORATE_NAMES,
  API_GET_GUEST_PASS_DETAIL,
  API_MBS_GET_GUEST_DETAILS,
  API_MBS_POST_GUEST_TO_GUESTPASS,
  API_MBS_PATCH_GUEST_TO_MSCRM,
  API_MBS_GET_END_DATE,
  API_GET_SYSTEM_DATE
} from 'constants/Constants';
import { IGuestPassDetail } from 'models/IGuestPassDetail';
import {
  NEW_MEMBERSHIP_SCENARIO,
  OTHER_FIELDS_QUESTION_TYPE_CODE
} from 'constants/DefaultConstants';
import {
  baseApi,
  createHeader,
  handleErrDate,
  handleErrorHttp
} from './common-service';
import {
  ICheckAvailablePromotionRequest,
  IConvertGuestToGuestPassRequest,
  IGetAddonsForAddMembershipRequest,
  IGetArrearsPreviewRequest,
  IGetCorporateNameRequest,
  IGetDuplicateEmailRequest,
  IGetDuplicateMembersRequest,
  IGetEmergencyContactConfigRequest,
  IGetGuestPassDetailsRequest,
  IGetPackageEndDateRequest,
  IGetPackagePlansForAddGuestPassRequest,
  IGetPackagePlansForCorporateMemberRequest,
  IGetPackagePlansRequest,
  IGetRawResponseRequest,
  IGetSalePeopleRequest,
  IPatchGuestToMSCRMRequest,
  IPostMemberRequest
} from './interfaces/requests/membership';
import {
  ICheckAvailablePromotionResponse,
  IGetArrearsPreviewResponse,
  IGetConmanConifgResponse,
  IGetCorporateNameResponse,
  IGetEmergencyNameConfigResponse,
  IGetEmergencyNumberConfigResponse,
  IGetPromotionCategoriesResponse,
  IGetQuestionListResponse,
  IGuestDetailsResponse
} from './interfaces/responses/membership';

const getAddonsForAddMembership = ({
  clubId
}: IGetAddonsForAddMembershipRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(API_GET_ADDON.replace('{clubId}', clubId.toString()), {
      headers
    })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getClubs = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_CLUBS.replace('{0}', `${1000}`), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getApplicationTypes = () => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_APPLICATION_TYPES.replace('{0}', `${1000}`), {
      headers
    })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getSystemDate = () => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_GET_SYSTEM_DATE, {
      headers
    })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getSalePeople = ({ clubId }: IGetSalePeopleRequest) => {
  const headers = {
    ...createHeader({
      hasCache: false,
      subTenant: { useSubTenant: true }
    }),
    'x-clubid': clubId
  };
  return baseApi
    .get(
      API_MBS_GET_SALE_PEOPLE.replace('{0}', `${clubId}`).replace(
        '{1}',
        'id,firstName,lastName,salePersonClubs'
      ),
      { headers }
    )
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const checkAvailablePromotion = ({
  promotionCode,
  isOnline,
  startDate,
  handleError,
  corporateId
}: ICheckAvailablePromotionRequest): Promise<
  ICheckAvailablePromotionResponse
> => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  let params = new URLSearchParams();
  params.set('isOnlyAvailableOnline', isOnline);
  params.set('promotionCode', promotionCode);
  if (corporateId) {
    params.set('corporateId', corporateId);
  }
  const url = CHECK_AVAILABLE_PROMOTION + '?' + params.toString();
  return baseApi
    .get(url, { headers })
    .then(res => res.data)
    .catch(error => handleError(error, startDate));
};

const getPackagePlans = ({
  clubId,
  startDate,
  promoId,
  dob
}: IGetPackagePlansRequest) => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  let params = new URLSearchParams();
  if (startDate && startDate !== INVALID_DATE) {
    params.set('startDate', startDate);
  }
  if (promoId) {
    params.set('promotionIds', promoId.toString());
  }
  if (dob && dob !== INVALID_DATE) {
    params.set('dateOfBirth', dob);
  }
  params.set('planCategoryCode', 'PM');
  params.set('scenario', NEW_MEMBERSHIP_SCENARIO);
  const url = API_MBS_GET_PACKAGE_PLANS + '?' + params.toString();
  return baseApi
    .get(url.replace('{clubId}', clubId.toString()), { headers })
    .then(res => res.data)
    .catch(err => {
      if (!err.isSuccess && err.response.status === 400) {
        handleErrDate(err);
      } else {
        handleErrorHttp(err);
      }
    });
};

const getPackagePlansForCorporateMember = ({
  clubId,
  startDate,
  promoId,
  corporateId,
  productFilterType,
  dob
}: IGetPackagePlansForCorporateMemberRequest) => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  const params = new URLSearchParams();
  if (startDate && startDate !== INVALID_DATE) {
    params.set('startDate', startDate);
  }
  params.set('corporateId', corporateId.toString());
  if (productFilterType) {
    params.set('productFilterType', productFilterType.toString());
  }
  if (promoId) {
    params.set('promotionIds', promoId.toString());
  }
  if (dob && dob != INVALID_DATE) {
    params.set('dateOfBirth', dob);
  }
  params.set('planCategoryCode', 'PM');
  params.set('scenario', NEW_MEMBERSHIP_SCENARIO);
  const url = API_MBS_GET_PACKAGE_PLANS + '?' + params.toString();
  return baseApi
    .get(url.replace('{clubId}', clubId.toString()), { headers })
    .then(res => res.data)
    .catch(err => {
      if (!err.isSuccess && err.response.status === 400) {
        handleErrDate(err);
      } else {
        handleErrorHttp(err);
      }
    });
};

const getPackagePlansForAddGuestPass = ({
  clubId,
  startDate,
  planCategoryCode = 'GP'
}: IGetPackagePlansForAddGuestPassRequest) => {
  const headers = createHeader({
    hasCache: false,
    tenant: { useTenant: true },
    subTenant: { useSubTenant: true }
  });
  let params = new URLSearchParams();
  if (startDate !== INVALID_DATE) {
    params.set('startDate', startDate);
  }
  if (planCategoryCode) {
    params.set('planCategoryCode', planCategoryCode);
  }
  const url = API_MBS_GET_PACKAGE_PLANS + '?' + params.toString();
  return baseApi
    .get(url.replace('{clubId}', clubId), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getFieldsConfiguration = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_FIELDS_ADD_MEMBER.replace('{0}', `${1000}`), { headers })
    .then(res => {
      return res.data;
    })
    .catch(err => handleErrorHttp(err));
};

const getMemberTitles = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_MEMBER_TITLES, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getMemberGenders = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_MEMBER_GENDERS, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getEmergencyContactConfig = <T extends unknown>({
  questionTypeCode,
  questionCode
}: IGetEmergencyContactConfigRequest): Promise<T> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(
      API_MBS_GET_EMERGENCY_CONFIG.replace(
        '{typeCode}',
        questionTypeCode
      ).replace('{code}', questionCode),
      { headers }
    )
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getEmergencyNameConfig = ({
  questionTypeCode,
  questionCode
}: IGetEmergencyContactConfigRequest) =>
  getEmergencyContactConfig<IGetEmergencyNameConfigResponse[]>({
    questionTypeCode,
    questionCode
  });

const getEmergencyNumberConfig = ({
  questionTypeCode,
  questionCode
}: IGetEmergencyContactConfigRequest) =>
  getEmergencyContactConfig<IGetEmergencyNumberConfigResponse[]>({
    questionTypeCode,
    questionCode
  });

const getConmanConfig = (): Promise<IGetConmanConifgResponse[]> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_CONMAN_CONFIG.replace('{typeCode}', 'M1'), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getOtherFieldsConfig = (): Promise<IGetConmanConifgResponse[]> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(
      API_MBS_GET_CONMAN_CONFIG.replace(
        '{typeCode}',
        OTHER_FIELDS_QUESTION_TYPE_CODE
      ),
      { headers }
    )
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getQuestionList = (): Promise<IGetQuestionListResponse[]> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_CONMAN_CONFIG.replace('{typeCode}', 'MD'), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getMemberLanguagePreferences = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_MEMBER_LANGUAGE_PREFERENCES, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getCountries = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_COUNTRIES, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getContactMethods = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_CONTACT_METHODS, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getMarketingSources = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_LOOK_UP.replace('{0}', 'MS'), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};
const getChannelCodes = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_LOOK_UP.replace('{0}', 'C1'), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};
const getMarketingCampaign = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_LOOK_UP.replace('{0}', 'C2'), { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getAIAVitalityCategories = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_AIA_VITALITY_CATEGORY, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getRecurringCardTypes = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_RECURRING_CARD_TYPE, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};
const getGuardianIdentityTypeId = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_GUARDIAN_IDENTITY_TYPE_ID, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getDuplicateMembers = ({
  lastName,
  birthDate,
  postCode
}: IGetDuplicateMembersRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });

  const params = new URLSearchParams();
  if (lastName) {
    params.set('lastName', lastName);
  }
  if (postCode) {
    params.set('postCode', postCode);
  }
  if (birthDate) {
    params.set('dateOfBirth', birthDate);
  }
  const url = API_MBS_GET_DUPLICATE_MEMBERS + '?' + params.toString();

  return baseApi
    .get(url, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getDuplicateEmail = ({ email }: IGetDuplicateEmailRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi.get(
    API_MBS_GET_DUPLICATE_MEMBERS_EMAIL.replace('{0}', email),
    { headers }
  );
};

const getRawResponse = ({ transactionUUID }: IGetRawResponseRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .post(
      API_GET_RAW_RESPONSE,
      {
        TransactionUUID: transactionUUID
      },
      { headers }
    )
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getBanks = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return axios
    .get(API_MBS_GET_MEMBER_BANKS, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getCardTypes = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_MEMBER_CARD_TYPES, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const postMember = ({ data }: IPostMemberRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .post(API_MBS_POST_MEMBER, data, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getIssuingBanks = () => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(API_GET_ISSUING_BANKS, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getPromotionCategories = (
  planId: string,
  clubId: string,
  agreementPartyId: string,
  dob?: string
): Promise<IGetPromotionCategoriesResponse[]> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  let params = new URLSearchParams();
  if (planId) {
    params.set('planId', planId);
  }
  if (clubId) {
    params.set('clubId', clubId);
  }
  if (dob) {
    params.set('dateOfBirth', dob);
  }
  if (agreementPartyId) {
    params.set('agreementPartyId', agreementPartyId);
  }
  params.set('scenario', NEW_MEMBERSHIP_SCENARIO);
  const url = API_GET_PROMOTION_CATEGORIES + '?' + params.toString();
  return baseApi
    .get(url, { headers })
    .then(res => res.data.promotionCategories)
    .catch(err => handleErrorHttp(err));
};

const getArrearsPreview = ({
  body
}: IGetArrearsPreviewRequest): Promise<IGetArrearsPreviewResponse> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .post(API_ARREARS_PREVIEW, body, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getGuestDetails = ({
  externalId
}: IGetGuestPassDetailsRequest): Promise<IGuestDetailsResponse> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });

  const params = new URLSearchParams();
  if (externalId) {
    params.set('externalId', externalId);
  }

  const url = API_MBS_GET_GUEST_DETAILS + '?' + params.toString();
  return baseApi
    .get(url, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getCorporateNames = ({
  clubId
}: IGetCorporateNameRequest): Promise<IGetCorporateNameResponse[]> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get(API_MBS_GET_CORPORATE_NAMES.replace('{clubId}', clubId.toString()), {
      headers
    })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getGuestPassDetail = (id: string) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .get<IGuestPassDetail>(API_GET_GUEST_PASS_DETAIL.replace('{id}', id), {
      headers
    })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const patchGuestToMSCRM = ({ externalId }: IPatchGuestToMSCRMRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  const body = {
    status: 'Guest Pass'
  };
  return baseApi
    .patch(
      API_MBS_PATCH_GUEST_TO_MSCRM.replace('{externalId}', externalId),
      body,
      { headers }
    )
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const convertGuestToGuestPass = (body: IConvertGuestToGuestPassRequest) => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .post(API_MBS_POST_GUEST_TO_GUESTPASS, body, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

const getPackageEndDate = (
  body: IGetPackageEndDateRequest
): Promise<string> => {
  const headers = createHeader({
    hasCache: false,
    subTenant: { useSubTenant: true },
    tenant: { useTenant: true }
  });
  return baseApi
    .post(API_MBS_GET_END_DATE, body, { headers })
    .then(res => res.data)
    .catch(err => handleErrorHttp(err));
};

export {
  getAddonsForAddMembership,
  getClubs,
  getApplicationTypes,
  getSalePeople,
  getPackagePlans,
  getFieldsConfiguration,
  getMemberTitles,
  getMemberGenders,
  getMemberLanguagePreferences,
  getCountries,
  getContactMethods,
  getMarketingSources,
  getAIAVitalityCategories,
  getRecurringCardTypes,
  getDuplicateMembers,
  getBanks,
  getCardTypes,
  getGuardianIdentityTypeId,
  postMember,
  getEmergencyContactConfig,
  getDuplicateEmail,
  getRawResponse,
  getIssuingBanks,
  getChannelCodes,
  getMarketingCampaign,
  getConmanConfig,
  getQuestionList,
  checkAvailablePromotion,
  getPromotionCategories,
  getArrearsPreview,
  getEmergencyNameConfig,
  getEmergencyNumberConfig,
  getCorporateNames,
  getPackagePlansForCorporateMember,
  getGuestDetails,
  getGuestPassDetail,
  getPackagePlansForAddGuestPass,
  convertGuestToGuestPass,
  patchGuestToMSCRM,
  getPackageEndDate,
  getOtherFieldsConfig,
  getSystemDate
};
