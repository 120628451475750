import React, { useEffect, useState } from 'react';
import OptionModel from 'models/Option';
import PaymentDetails, {
  updatePaymentWithDataPayment,
  updatePrimaryPaymentMethod
} from 'models/PaymentDetails';
import * as Utils from 'util/ControlUtils';
import observer from 'util/Observer';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import {
  getFieldConfig,
  filterValuableFieldsOnly
} from 'util/FieldConfiguration/';
import { useTranslation } from 'react-i18next';
import {
  getFieldsConfiguration,
  getIssuingBanks
} from 'services/membership-service';
import Template from './template';
import { saveToLocal } from 'util/SaveToLocal';
import {
  PAYMENT_METHOD_TYPES,
  STORAGE,
  PAYMENT_METHODS
} from 'constants/Constants';
import { scrollToTop } from 'util/Common';
import ISelectPaymentDetails from './interface';
import { FieldError } from 'interfaces/common/FieldError';
import { sortedBanks } from 'models/IssuingBankOption';
import { getEsignatureUrl } from 'services/common-service';
import { useDispatch } from 'react-redux';
import { updateDisableShowIframe } from 'reduxs/actions/NewMembership';
import { useTypedSelector } from 'reduxs';
import { selectPaymentInfo } from 'selectors/member';

declare const window: Record<string, Record<string, any>>;

const SelectPaymentDetails: React.FC<ISelectPaymentDetails> = ({
  configs,
  membership,
  payment,
  membershipStorage,
  onCancel,
  setConfigurationAction,
  setPaymentDetailsAction,
  externalId,
  operatorId
}) => {
  const [openDropdown, setOpenDropdown] = useState({
    primary: true,
    secondary: false
  });
  const dispatch = useDispatch();

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>(
    new PaymentDetails()
  );
  const paymentInfo = useTypedSelector(state => selectPaymentInfo(state));
  const [issuingBanks, setIssuingBanks] = useState<OptionModel[]>([]);
  const [errors, setErrors] = useState<FieldError>({});
  const [hasChanged, setHasChanged] = useState(false);

  const [listAccountNumberFormat, setListAccountNumberFormat] = useState('');
  const [accountNumberFormat, setAccountNumberFormat] = useState('');
  const isDisableEzyPay = useTypedSelector(
    state => state.payment.isDisabledEzyPay
  );
  const { t } = useTranslation();
  const { onlinePayment } = payment;

  const initAccountNumberFormat = (
    value: string,
    accountNumberFormats: string
  ) => {
    const bankWithFormat = accountNumberFormats
      .split(';')
      .find(item => item.indexOf(`${value},`) > -1);
    const format =
      (bankWithFormat && bankWithFormat.replace(`${value},`, '')) || '';
    setAccountNumberFormat(format);
  };

  const getFieldConfiguration = (fieldConfiguration: any) => {
    const nationalConfig = getFieldConfig(
      fieldConfiguration,
      FieldKeys.ADDMEMBER_NATIONAL
    );
    const nationalIdConfig = getFieldConfig(
      fieldConfiguration,
      FieldKeys.ADDMEMBER_NATIONAL_ID
    );

    return {
      nationalConfig,
      nationalIdConfig
    };
  };

  const [fieldConfigs, setFieldConfigs] = useState(
    getFieldConfiguration(InitialFieldConfigs.data)
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (listAccountNumberFormat) {
      initAccountNumberFormat(
        paymentDetails.issuingBankId,
        listAccountNumberFormat
      );
    }
  }, [paymentDetails.issuingBankId, listAccountNumberFormat]);

  useEffect(() => {
    if (issuingBanks.length > 0 && !paymentDetails.issuingBankId) {
      setPaymentDetails({
        ...(membership.paymentDetails.primaryPaymentMethodId
          ? membership.paymentDetails
          : paymentDetails),
        issuingBankId: paymentDetails.issuingBankId || issuingBanks[0].value
      });
    }
  }, [issuingBanks, paymentDetails.issuingBankId]);

  useEffect(() => {
    let newPaymentDetail = new PaymentDetails();
    newPaymentDetail.primaryPaymentMethodId = PAYMENT_METHOD_TYPES.CREDIT_CARD;

    newPaymentDetail = updatePaymentWithDataPayment(
      newPaymentDetail,
      membership.paymentDetails
    );

    if (
      membership.selectedPackagePlan &&
      membership.selectedPackagePlan.paymentMethodTypes
    ) {
      newPaymentDetail = updatePrimaryPaymentMethod(
        newPaymentDetail,
        membership.selectedPackagePlan
      );
    }

    if (configs) {
      const accountNumberConfigFormats = configs.find(
        e => e.key === FieldKeys.ADDMEMBER_ACCOUNT_NUMBER_FORMAT
      );
      if (accountNumberConfigFormats) {
        setListAccountNumberFormat(accountNumberConfigFormats.value);
      }
    }

    Promise.all([getIssuingBanks(), getFieldsConfiguration()])
      .then(([issuingBankResponse, fieldsConfiguration]) => {
        if (issuingBankResponse.values) {
          setIssuingBanks(sortedBanks(issuingBankResponse.values));
          setPaymentDetails({
            ...membership.paymentDetails,
            ...newPaymentDetail,
            primaryPaymentMethodId: getPrimaryPaymentMethodId(newPaymentDetail)
          });
        }
        if (fieldsConfiguration) {
          const fieldConfigsModified = filterValuableFieldsOnly(
            getFieldConfiguration(fieldsConfiguration)
          );
          setFieldConfigs({ ...fieldConfigs, ...fieldConfigsModified });
        }
      })
      .catch(() => {
        setFieldConfigs(getFieldConfiguration(InitialFieldConfigs.data));
      });
  }, []);

  const getPrimaryPaymentMethodId = newPaymentDetail => {
    const { selectedPackagePlan } = membership;
    if (
      selectedPackagePlan &&
      selectedPackagePlan.paymentMethod.name === PAYMENT_METHODS.CASH
    ) {
      return PAYMENT_METHOD_TYPES.CASH;
    }

    if (
      selectedPackagePlan &&
      newPaymentDetail.primaryPaymentMethodId &&
      selectedPackagePlan.paymentMethodTypes.some(
        paymentMethodType =>
          paymentMethodType.paymentMethodTypeCode ===
          newPaymentDetail.primaryPaymentMethodId
      )
    ) {
      return newPaymentDetail.primaryPaymentMethodId;
    }

    return PAYMENT_METHOD_TYPES.CREDIT_CARD;
  };

  const handleChange = (key, event) => {
    const { value } = event;

    const newErrors = errors;
    switch (key) {
      case 'primaryPaymentMethodId':
        newErrors[key] = value
          ? getRequiredErrorMessage(
            value,
            t(
              'PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD_REQUIRED'
            )
          )
          : '';
        break;
      case 'issuingBankId':
        newErrors[key] = value
          ? getRequiredErrorMessage(
            value,
            t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK_REQUIRED')
          )
          : '';
        initAccountNumberFormat(value, listAccountNumberFormat);

        break;

      default:
        break;
    }

    setPaymentDetails({ ...paymentDetails, [key]: event.value });

    const isCreditCard = value === PAYMENT_METHOD_TYPES.CREDIT_CARD;
    const isDirectDebit = value === PAYMENT_METHOD_TYPES.DIRECT_DEBIT;
    if (!isDisableEzyPay) {
      if (
        paymentInfo &&
        ((!paymentInfo.card && isCreditCard) ||
          (isDirectDebit && !paymentInfo.bank && !paymentInfo.payTo))
      ) {
        dispatch(updateDisableShowIframe(false));
      } else {
        dispatch(updateDisableShowIframe(true));
      }
    }
    setErrors(newErrors);
    hasChangeControlValue();
  };

  const getRequiredErrorMessage = (value, requiredMessage) => {
    return value ? '' : requiredMessage;
  };

  const hasChangeControlValue = () => {
    const {
      membershipConfiguration: { stepLatest }
    } = membership;
    const CURRENT_STEP = 5;
    if (stepLatest < CURRENT_STEP) return;
    setHasChanged(true);
  };

  const getInvalidErrorMessage = (
    key,
    value,
    invalidMessage,
    requiredMessage = ''
  ) => {
    if (key && value) {
      return Utils[`IsValid${key}`](value) ? '' : invalidMessage;
    }
    return getRequiredErrorMessage(value, requiredMessage);
  };

  const handleInputChange = (key, value) => {
    let formatValue = value;
    const newErrors = { ...errors };

    const updateError = (regEx, errorMessage) => {
      if (value) {
        newErrors[key] = new RegExp(regEx).test(value) ? '' : errorMessage;
      } else {
        newErrors[key] = '';
      }
    };

    switch (key) {
      case 'accountName':
        formatValue = value.replace(/[^a-zA-Z ]/g, '');
        newErrors[key] = getInvalidErrorMessage(
          'AccountName',
          formatValue,
          t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_INVALID'),
          t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_REQUIRED')
        );
        break;
      case 'accountNumber':
        const regex = new RegExp(accountNumberFormat);
        newErrors[key] =
          value && regex.test(value)
            ? ''
            : t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NUMBER_INVALID');

        const isNotFilled = getRequiredErrorMessage(
          value,
          t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NUMBER_REQUIRED')
        );
        if (isNotFilled) {
          newErrors[key] = isNotFilled;
        }
        break;
      case 'nationalIdNumber':
        updateError(
          fieldConfigs.nationalConfig.regEx,
          t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_INVALID')
        );
        break;
      case 'otherNationalIdNumber':
        updateError(
          fieldConfigs.nationalIdConfig.regEx,
          t('PAGE.MEMBERSHIPS.CREATE.STEP_4.OTHER_NATIONAL_ID_INVALID')
        );
        break;
      default:
        break;
    }
    setPaymentDetails({ ...paymentDetails, [key]: formatValue });
    setErrors(newErrors);
    hasChangeControlValue();
  };

  const handlePrevious = () => {
    const CURRENT_STEP = 4;

    const {
      membershipConfiguration,
      membershipConfiguration: { stepLatest }
    } = membership;
    if (stepLatest > CURRENT_STEP && !isValidationSuccess()) {
      return;
    }
    storeDataToRedux();
    membershipConfiguration.stepIndex = CURRENT_STEP - 1;
    membershipConfiguration.stepLatest = CURRENT_STEP - 1;
    setConfigurationAction(membershipConfiguration);
  };

  const storeDataToRedux = () => {
    setPaymentDetailsAction({
      ...paymentDetails,
      ...onlinePayment,
      paymentInfo: membership.paymentDetails.paymentInfo
    });
  };

  const isValidationSuccess = () => {
    const {
      primaryPaymentMethodId,
      issuingBankId,
      accountName,
      accountNumber,
      nationalIdNumber,
      otherNationalIdNumber
    } = paymentDetails;

    let newErrors: FieldError = {};
    if (
      primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT &&
      isDisableEzyPay
    ) {
      if (!issuingBankId) {
        newErrors = {
          ...newErrors,
          issuingBankId: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK_REQUIRED'
        };
      }

      if (!accountName) {
        newErrors = {
          ...newErrors,
          accountName: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_REQUIRED'
        };
      } else if (!Utils.IsValidAccountName(accountName)) {
        newErrors = {
          ...newErrors,
          accountName: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_INVALID'
        };
      }

      const regex = new RegExp(accountNumberFormat);
      if (!accountNumber) {
        newErrors = {
          ...newErrors,
          accountNumber:
            'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NUMBER_REQUIRED'
        };
      } else if (!regex.test(accountNumber)) {
        newErrors = {
          ...newErrors,
          accountNumber: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NUMBER_INVALID'
        };
      }
    }

    if (
      nationalIdNumber &&
      !new RegExp(fieldConfigs.nationalConfig.regEx).test(nationalIdNumber)
    ) {
      newErrors = {
        ...newErrors,
        nationalIdNumber: t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_INVALID'
        )
      };
    }

    if (
      otherNationalIdNumber &&
      !new RegExp(fieldConfigs.nationalIdConfig.regEx).test(
        otherNationalIdNumber
      )
    ) {
      newErrors = {
        ...newErrors,
        otherNationalIdNumber: t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_4.OTHER_NATIONAL_ID_INVALID'
        )
      };
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    const { issuingBankId } = paymentDetails;
    const newPaymentDetails = paymentDetails;

    const CURRENT_STEP = 5;

    if (isValidationSuccess()) {
      newPaymentDetails.issuingBank =
        issuingBankId && issuingBanks.find(e => e.value === issuingBankId);
      const { membershipConfiguration } = membership;
      if (membershipConfiguration.stepLatest < CURRENT_STEP) {
        membershipConfiguration.stepLatest = CURRENT_STEP;
      }
      membershipConfiguration.stepIndex = CURRENT_STEP;
      setConfigurationAction(membershipConfiguration);
      storeDataToRedux();
    }
    setPaymentDetails(newPaymentDetails);
  };

  const handleSaveAndLeave = (stepIndex, isSave = false) => {
    const { membershipConfiguration } = membership;
    if (isSave) {
      storeDataToRedux();
    }
    membershipConfiguration.stepIndex = stepIndex;
    setConfigurationAction(membershipConfiguration);
    observer.publish('closeDialogEvent', true);
  };

  const handleOpenDropdown = key => {
    setOpenDropdown({ ...openDropdown, [key]: !openDropdown[key] });
  };

  const goToStep = stepIndex => {
    const {
      membershipConfiguration,
      membershipConfiguration: { stepLatest }
    } = membership;
    const CURRENT_STEP = 4;
    if (hasChanged && stepLatest > CURRENT_STEP) {
      if (!isValidationSuccess()) return;
      const content = (
        <React.Fragment>
          <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page" />
          <p className="content-center add-member-page">
            {t('MSG.UNSAVED_CHANGES')}
          </p>
          <div className="modalSaas__row--btn">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => handleSaveAndLeave(stepIndex, true)}
            >
              {t('BUTTON.SAVE_AND_LEAVE')}
            </button>
            <button
              className="btn btn-dark text-uppercase"
              onClick={() => handleSaveAndLeave(stepIndex)}
            >
              {t('BUTTON.LEAVE_AND_NOT_SAVE')}
            </button>
          </div>
        </React.Fragment>
      );
      observer.publish('openDialogEvent', {
        content: content
      });
    } else {
      storeDataToRedux();
      membershipConfiguration.stepIndex = stepIndex;
      membershipConfiguration.stepLatest = stepIndex;
      setConfigurationAction(membershipConfiguration);
    }
  };

  const handleAddCardSignature = () => {
    if (membershipStorage) {
      saveToLocal(membershipStorage, {
        ...membership,
        paymentDetails: { ...paymentDetails, ...onlinePayment }
      });
    }
    saveToLocal(STORAGE.OPERATOR_ID, operatorId);
    if (externalId) {
      saveToLocal(STORAGE.EXTERNAL_ID, externalId);
    }
    const data = {
      action: 'addCard'
    };
    window.parent.postMessage(data, getEsignatureUrl());
  };

  const handleEditCardSignature = (paymentToken: string) => {
    if (membershipStorage) {
      saveToLocal(membershipStorage, {
        ...membership,
        paymentDetails: { ...paymentDetails, ...onlinePayment }
      });
    }
    saveToLocal(STORAGE.OPERATOR_ID, operatorId);
    if (externalId) {
      saveToLocal(STORAGE.EXTERNAL_ID, externalId);
    }
    const data = {
      action: 'editCard',
      data: {
        paymentToken
      }
    };
    window.parent.postMessage(data, getEsignatureUrl());
  };

  return (
    <Template
      openDropdown={openDropdown}
      paymentDetails={paymentDetails}
      primaryPaymentMethods={paymentDetails.primaryPaymentMethod}
      issuingBanks={issuingBanks}
      membership={membership}
      payment={payment}
      errors={errors}
      handleOpenDropdown={handleOpenDropdown}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      goToStep={goToStep}
      onCancel={onCancel}
      handleAddCardSignature={handleAddCardSignature}
      handleEditCardSignature={handleEditCardSignature}
    />
  );
};

export default SelectPaymentDetails;
