import React, { MutableRefObject, useEffect } from 'react';
import moment from 'moment';
import { FormSelect, FormInput, FormDatePicker } from 'components/Form';
import {
  LookupModel,
  PersonalDetailsModel,
  PersonalDetailFieldsModel
} from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import {
  onChangeDatePicker,
  onChangeInput,
  required,
  validateEmptySelection,
  validateFutureDate,
  validateInputField,
  validateParentalConsent,
  validateRequireNationalIDOrPassport
} from 'util/FormValidations';
import { safeLookupModel } from 'util/ControlUtils';
import { MIN_DATE_SYSTEM } from 'constants/Constants';
import { useTypedSelector } from 'reduxs';

interface IPersonalDetails {
  personalDetailsFieldConfigs: PersonalDetailFieldsModel;
  personalDetails: PersonalDetailsModel;
  titles: LookupModel[];
  genders: LookupModel[];
  languages: LookupModel[];
  personalRef: MutableRefObject<UseFormReturn<PersonalDetailsFormInput> | null>;
  membershipConfiguration: any;
  setPersonalDetailsAction: (personalDetails: PersonalDetailsModel) => void;
}

export type PersonalDetailsFormInput = {
  title: LookupModel;
  firstName: string;
  middleName: string;
  lastName: string;
  nationalIdNumber: string;
  passportNumber: string;
  gender: LookupModel;
  preferedLanguage: LookupModel;
  dateOfBirth: Date | null;
  isParentalConsentProvided: boolean;
};

const PersonalDetails: React.FC<IPersonalDetails> = ({
  titles,
  genders,
  languages,
  personalDetails,
  personalDetailsFieldConfigs,
  membershipConfiguration,
  personalRef,
  setPersonalDetailsAction
}) => {
  const {
    title,
    gender,
    firstName,
    lastName,
    nationalIdNumber,
    dateOfBirth,
    isParentalConsentProvided,
    preferedLanguage,
    middleName,
    passportNumber
  } = personalDetails;

  const {
    titleConfig,
    genderConfig,
    firstNameConfig,
    lastNameConfig,
    dateOfBirthConfig,
    nationalConfig,
    passportNumberConfig,
    preferedLanguageConfig,
    parentalConsentConfig,
    middleNameConfig
  } = personalDetailsFieldConfigs;
  const { t } = useTranslation();

  const form = useForm<PersonalDetailsFormInput>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      title: safeLookupModel(titles.find(item => item.value === title.value)),
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      nationalIdNumber: nationalIdNumber,
      passportNumber: passportNumber,
      gender: safeLookupModel(gender),
      preferedLanguage: safeLookupModel(
        languages.find(item => item.value === preferedLanguage.value)
      ),
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
      isParentalConsentProvided: isParentalConsentProvided
    }
  });

  const newMembership = useTypedSelector(state => state.newMembership);
  const { membershipSearchFilter } = newMembership;
  const { clubSelect } = membershipSearchFilter;

  const {
    control,
    register,
    watch,
    formState: { errors }
  } = form;

  const dateOfBirthWatch = watch('dateOfBirth');
  const memberAge =
    (dateOfBirthWatch &&
      moment(dateOfBirthWatch).isValid() &&
      moment().diff(dateOfBirthWatch, 'years')) ||
    0;

  const isParentalConsentRequired =
    dateOfBirthWatch && memberAge < (clubSelect ? clubSelect.ageOfConsent : 18);

  useEffect(() => {
    if (personalRef && personalRef.current) {
      setPersonalDetailsAction({
        ...personalDetails,
        ...personalRef.current.getValues(),
        age: memberAge
      });
    }
  }, [dateOfBirthWatch, watch('isParentalConsentProvided')]);

  useEffect(() => {
    if (personalRef) {
      personalRef.current = form;
    }
  }, []);

  return (
    <div className="personal-group open">
      <div className="row">
        <div className="col-md-6">
          {titleConfig.isVisible && (
            <Controller
              name="title"
              control={control}
              rules={{
                required: required(titleConfig.isRequired),
                validate: {
                  validateEmptySelection: value =>
                    validateEmptySelection(value, titleConfig.isRequired)
                }
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  require={titleConfig.isRequired}
                  disabled={titles.length === 0}
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.TITLE')}
                  options={titles}
                  errorMsg={t((errors.title && errors.title.message) || '')}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6 form-group">
          {firstNameConfig.isVisible && (
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: required(firstNameConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(firstNameConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  require={firstNameConfig.isRequired}
                  maxlength={firstNameConfig.maxLength}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.FIRST_NAME')}
                  errorMsg={t(
                    (errors.firstName && errors.firstName.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {middleNameConfig.isVisible && (
            <Controller
              name="middleName"
              control={control}
              rules={{
                required: required(middleNameConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(middleNameConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  require={middleNameConfig.isRequired}
                  maxlength={middleNameConfig.maxLength}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MIDDLE_NAME')}
                  errorMsg={t(
                    (errors.middleName && errors.middleName.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {lastNameConfig.isVisible && (
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: required(lastNameConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(lastNameConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  require={lastNameConfig.isRequired}
                  maxlength={lastNameConfig.maxLength}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LAST_NAME')}
                  errorMsg={t(
                    (errors.lastName && errors.lastName.message) || ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {nationalConfig.isVisible && (
            <Controller
              name="nationalIdNumber"
              control={control}
              rules={{
                required: required(nationalConfig.isRequired),
                validate: {
                  validateRequireNationalIDOrPassport: validateRequireNationalIDOrPassport(
                    watch('passportNumber')
                  ),
                  validateInputField: validateInputField(nationalConfig.regEx)
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  require={nationalConfig.isRequired}
                  maxlength={nationalConfig.maxLength}
                  inputClass="form-control"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NATIONAL_ID_NUMBER')}
                  errorMsg={t(
                    (errors.nationalIdNumber &&
                      errors.nationalIdNumber.message) ||
                      ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {passportNumberConfig.isVisible && (
            <Controller
              name="passportNumber"
              control={control}
              rules={{
                required: required(passportNumberConfig.isRequired),
                validate: {
                  validateInputField: validateInputField(
                    passportNumberConfig.regEx
                  )
                }
              }}
              render={({ field }) => (
                <FormInput
                  {...field}
                  require={passportNumberConfig.isRequired}
                  maxlength={passportNumberConfig.maxLength}
                  inputClass="form-control"
                  label={t(
                    'PAGE.MEMBERSHIPS.CREATE.STEP_3.OLD_OTHER_NATIONAL_ID'
                  )}
                  errorMsg={t(
                    (errors.passportNumber && errors.passportNumber.message) ||
                      ''
                  )}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeInput(field)(event)
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-6">
          {dateOfBirthConfig.isVisible && (
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: required(dateOfBirthConfig.isRequired),
                validate: {
                  validateFutureDate
                }
              }}
              render={({ field }) => (
                <FormDatePicker
                  {...field}
                  id="dob"
                  className="datepicker-wrapper"
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.DATE_OF_BIRTH')}
                  required={dateOfBirthConfig.isRequired}
                  dateFormat={membershipConfiguration.newMemberDOBFormat}
                  maxDate={new Date()}
                  minDate={new Date(MIN_DATE_SYSTEM)}
                  selectedDate={field.value}
                  onChange={onChangeDatePicker(field)}
                  errorMsg={t(
                    (errors.dateOfBirth && errors.dateOfBirth.message) || ''
                  )}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6 align-self-end">
          {parentalConsentConfig.isVisible && isParentalConsentRequired && (
            <div className="form-group">
              <div className="form-check">
                <input
                  id="pcp"
                  type="checkbox"
                  {...register('isParentalConsentProvided', {
                    validate: {
                      validateParentalConsent: value =>
                        validateParentalConsent(
                          value,
                          isParentalConsentRequired
                        )
                    }
                  })}
                />
                <label className="icon-checked-ico" htmlFor="pcp">
                  <span>
                    {t(
                      'PAGE.MEMBERSHIPS.CREATE.STEP_3.PARENTAL_CONSENT_PROVIDED'
                    )}
                  </span>
                </label>
              </div>
              {errors.isParentalConsentProvided && (
                <span className="text-danger input-error">
                  {t(
                    (errors.isParentalConsentProvided &&
                      errors.isParentalConsentProvided.message) ||
                      ''
                  )}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {genderConfig.isVisible && (
            <Controller
              name="gender"
              control={control}
              rules={{
                required: required(genderConfig.isRequired),
                validate: {
                  validateEmptySelection: value =>
                    validateEmptySelection(value, genderConfig.isRequired)
                }
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  require={genderConfig.isRequired}
                  disabled={genders.length === 0}
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.GENDER')}
                  options={genders}
                  errorMsg={t((errors.gender && errors.gender.message) || '')}
                />
              )}
            />
          )}
        </div>
        <div className="col-md-6">
          {preferedLanguageConfig.isVisible && (
            <Controller
              name="preferedLanguage"
              control={control}
              rules={{
                required: required(preferedLanguageConfig.isRequired),
                validate: {
                  validateEmptySelection: value =>
                    validateEmptySelection(
                      value,
                      preferedLanguageConfig.isRequired
                    )
                }
              }}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  require={preferedLanguageConfig.isRequired}
                  disabled={languages.length === 0}
                  label={t(
                    'PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBER_PREFFERED_LANGUAGE'
                  )}
                  options={languages}
                  errorMsg={t(
                    (errors.preferedLanguage &&
                      errors.preferedLanguage.message) ||
                      ''
                  )}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PersonalDetails);
