import PaymentProcess from 'components/PaymentProcess';
import AddGuestPassPage from './MembershipManagement/ConvertGuestToGuestPassPage';
import AddNewCorporateMemberPage from './MembershipManagement/AddNewCorporateMemberPage';
import AddNewMemberPage from './MembershipManagement/AddNewMemberPage';
import ConvertGuestToMemberPage from './MembershipManagement/ConvertGuestToMemberPage';
import ConvertGuestToCorporateMemberPage from './MembershipManagement/ConvertGuestToCorporateMember';

const PaymentProcessRoute = {
  url: 'paymentprocess',
  Component: PaymentProcess
};

const AddNewMemberRoute = {
  url: 'memberships/create',
  Component: AddNewMemberPage
};

const AddCorporateMemberRoute = {
  url: 'memberships/createCorporate',
  Component: AddNewCorporateMemberPage
};

const ConvertGuestToMemberRoute = {
  url: `memberships/convertGuestToMember`,
  Component: ConvertGuestToMemberPage
};

const ConvertGuestToGuestPassRoute = {
  url: 'memberships/convertGuestToGuestPass',
  Component: AddGuestPassPage
};

const ConvertGuestToCorporateMemberRoute = {
  url: 'memberships/convertGuestToCorporateMember',
  Component: ConvertGuestToCorporateMemberPage
};

const RouteLoader = [
  AddNewMemberRoute,
  AddCorporateMemberRoute,
  PaymentProcessRoute,
  ConvertGuestToMemberRoute,
  ConvertGuestToGuestPassRoute,
  ConvertGuestToCorporateMemberRoute
];

export default RouteLoader;
