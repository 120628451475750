import { createSelector } from 'reselect';
import { EzyPayInfo } from 'models/EzyPay/EzyPayInfo';

import { PAYMENT_METHOD_TYPES } from 'constants/Constants';

import { PaymentState } from 'reduxs/actions/Payment';

export const selectNewMembership = state => state.newMembership;

export const selectPaymentInfo = createSelector(
  selectNewMembership,
  newMembership => {
    if (!newMembership.paymentDetails.paymentInfo) {
      return null;
    }

    return newMembership.paymentDetails.paymentInfo as EzyPayInfo;
  }
);

export const isPaymentInfoExist = createSelector(
  [
    selectPaymentInfo,
    (paymentInfo, primaryPaymentMethodId) => primaryPaymentMethodId
  ],
  (paymentInfo, primaryPaymentMethodId) => {
    const disableShowIframe = paymentInfo && paymentInfo.disableShowIframe;
    if (primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT) {
      return Boolean(
        disableShowIframe &&
          paymentInfo &&
          (paymentInfo.bank || paymentInfo.payTo)
      );
    }
    return Boolean(disableShowIframe && paymentInfo && paymentInfo.card);
  }
);

export const canGoToSummary = ({
  isPaymentInfoExist,
  isDisabledEzPay,
  primaryPaymentMethodId,
  onlinePayment,
  statePayment
}) => {
  const isPrimaryCreditCard =
    primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CREDIT_CARD;
  const isPrimaryDirectDebit =
    primaryPaymentMethodId === PAYMENT_METHOD_TYPES.DIRECT_DEBIT;
  const isCashPayment = primaryPaymentMethodId === PAYMENT_METHOD_TYPES.CASH;

  if (isDisabledEzPay) {
    return (
      (isPrimaryCreditCard && !onlinePayment.cardType) ||
      (isPrimaryCreditCard && statePayment === PaymentState.DECLINE) ||
      isCashPayment
    );
  }

  const isCreditCardOrDirectDebit = isPrimaryCreditCard || isPrimaryDirectDebit;

  return (isCreditCardOrDirectDebit && isPaymentInfoExist) || isCashPayment;
};
