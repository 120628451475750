import React from 'react';
import { IPersonalDetails } from '../interface';
import { useTranslation } from 'react-i18next';
import * as Utils from 'util/ControlUtils';
import { getAddress, getName } from 'util/Common';
import DescriptionContent from '../DescriptionContent';

const PersonalDetails: React.FC<IPersonalDetails> = ({
  membership,
  fieldConfigs
}) => {
  const { membershipConfiguration, personalDetails } = membership;
  const {
    nationalIdNumber,
    passportNumber,
    contactDetails,
    dateOfBirth,
    gender,
    addressDetails,
    contactedDetails,
    emergencyContact,
    otherFields,
    lastName,
    middleName,
    firstName
  } = personalDetails;
  const { t } = useTranslation();

  const email = contactDetails && contactDetails.email;

  const data = [
    {
      isVisible: fieldConfigs.titleConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.NAME'),
      data: Utils.getValueData(
        getName({
          lastName: lastName,
          middleName: middleName,
          firstName: firstName
        })
      )
    },
    {
      isVisible: fieldConfigs.nationalConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HKID_NO'),
      data: Utils.getValueData(nationalIdNumber)
    },
    {
      isVisible: fieldConfigs.nationalConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PASSPORT_NUMBER_SUMMARY'),
      data: Utils.getValueData(passportNumber)
    },
    {
      isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_TELEPHONE'),
      data: Utils.getValueData(contactDetails.homePhone.phoneNumber)
    },
    {
      isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MOBILE_TELEPHONE'),
      data: Utils.getValueData(contactDetails.mobilePhone.phoneNumber)
    },
    {
      isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.WORK_TELEPHONE'),
      data: Utils.getValueData(contactDetails.workPhone.phoneNumber)
    },
    {
      isVisible: fieldConfigs.dateOfBirthConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE_OF_BIRTH'),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(
          dateOfBirth,
          membershipConfiguration.newMemberTimeFormat
        )
      )
    },
    {
      isVisible: fieldConfigs.genderConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENDER'),
      data: Utils.getValueData(gender.label)
    },
    {
      isVisible: fieldConfigs.emailConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EMAIL'),
      data: Utils.getValueData(email)
    },
    {
      isVisible: fieldConfigs.line1Config.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_ADDRESS'),
      data: Utils.getValueData(
        getAddress({
          number: addressDetails.addressNumber,
          line1: addressDetails.addressLine1,
          line2: addressDetails.addressLine2,
          city: addressDetails.city,
          state: addressDetails.state,
          postCode: addressDetails.postCode,
          country: addressDetails.country.label
        })
      )
    },
    {
      isVisible: fieldConfigs.applicationRefConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.MEMBERSHIP_APPLICATION_REF'
      ),
      data: Utils.getValueData(contactedDetails.applicationRef)
    },
    {
      isVisible: fieldConfigs.emergencyNameConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EMERGENCY_CONTACT_NAME'),
      data: Utils.getValueData(emergencyContact.contactName)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.EMERGENCY_CONTACT_PHONE_NUMBER'
      ),
      data: Utils.getValueData(emergencyContact.contactNumber.phoneNumber)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.EMERGENCY_CONTACT_RELATIONSHIP'
      ),
      data: Utils.getValueData(emergencyContact.relationship)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_NAME'
      ),
      data: Utils.getValueData(emergencyContact.guardianName)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_PHONE_NUMBER'
      ),
      data: Utils.getValueData(emergencyContact.guardianPhoneNumber)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_ADDRESS'
      ),
      data: Utils.getValueData(emergencyContact.guardianAddress)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_RELATIONSHIP'
      ),
      data: Utils.getValueData(emergencyContact.guardianRelationship)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_IDENTITY_NO'
      ),
      data: Utils.getValueData(emergencyContact.guardianIdentityNo)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_TYPE_ID'
      ),
      data: Utils.getValueData(emergencyContact.guardianIdentityTypeId.label)
    },
    {
      isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_PASS'
      ),
      data: Utils.getValueData(emergencyContact.guardianPass)
    },
    {
      isVisible: fieldConfigs.preferredContactMethodsConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PREFERRED_CONTACT_METHODS'),
      data: Utils.getValueData(contactedDetails.preferredContactMethod)
    },
    {
      isVisible: fieldConfigs.sourceConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SOURCE'),
      data: Utils.getValueData(contactedDetails.marketingSource.label)
    },
    {
      isVisible: fieldConfigs.marketingConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MARKETING_CONSENT'),
      data: Utils.getValueData(
        contactedDetails.memberHappyToBeContact
          ? t('BUTTON.YES')
          : t('BUTTON.NO')
      )
    },
    {
      isVisible: fieldConfigs.companyNameConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.COMPANY_NAME'),
      data: Utils.getValueData(otherFields.companyName)
    },
    {
      isVisible: fieldConfigs.occupationConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.OCCUPATION'),
      data: Utils.getValueData(otherFields.occupation)
    },
    {
      isVisible: fieldConfigs.referredByNameConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.REFERRED_BY_NAME'),
      data: Utils.getValueData(otherFields.referredByMemberName)
    },
    {
      isVisible: fieldConfigs.referredByMembershipConfig.isVisible,
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.REFERRED_BY_MEMBERSHIP_NUMBER'
      ),
      data: Utils.getValueData(otherFields.referredByMembershipNumber)
    },
    {
      isVisible: fieldConfigs.otherCommissonNoteConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.OTHER_COMMISSION_NOTE'),
      data: Utils.getValueData(otherFields.otherCommissionNote)
    },
    {
      isVisible: fieldConfigs.generalNoteConfig.isVisible,
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENERAL_NOTE'),
      data: Utils.getValueData(otherFields.generalNote)
    }
  ];

  return (
    <DescriptionContent
      title={t(
        'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP_AND_THEIR_MEMBERSHIP'
      )}
      data={data}
    />
  );
};

export default PersonalDetails;
