import React from 'react';
import { useTranslation } from 'react-i18next';
import { IGuestPassDetails } from '../interface';
import * as Utils from 'util/ControlUtils';
import DescriptionContent from 'features/MemberSummary/DescriptionContent';

const GuestPassDetail: React.FC<IGuestPassDetails> = ({
  guest,
  packageEndDate
}) => {
  const { t } = useTranslation();
  const { configuration, searchFilter, selectedPackagePlan } = guest;
  const data = [
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.GUEST_ID'),
      data: Utils.getValueData(Utils.getParamFromWindowSearch('externalId'))
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.PACKAGE'),
      data: selectedPackagePlan && Utils.getValueData(selectedPackagePlan.name)
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.PRIMARY_SALES_PERSON'
      ),
      data: Utils.getValueData(searchFilter.primarySalePersonName)
    },
    {
      fieldLabel: t(
        'PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.SECONDARY_SALES_PERSON'
      ),
      data: Utils.getValueData(searchFilter.secondarySalePersonName)
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.JOIN_DATE'),
      data: Utils.getValueData(
        Utils.DisplayDateFormat(new Date(), configuration.newMemberTimeFormat)
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.START_DATE'),
      data: Utils.DisplayDateFormat(
        searchFilter.startDate,
        configuration.newMemberTimeFormat
      )
    },
    {
      fieldLabel: t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.STEP_2.END_DATE'),
      data:
        packageEndDate &&
        Utils.DisplayDateFormat(
          packageEndDate,
          configuration.newMemberTimeFormat
        )
    }
  ];

  return (
    <DescriptionContent
      data={data.map(data => ({
        ...data,
        isVisible: true
      }))}
    />
  );
};

export default GuestPassDetail;
