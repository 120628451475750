import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { condition } from 'util/Common';
import Tooltip from '@material-ui/core/Tooltip';
export interface IDuplicateMember {
  clubId: number;
  clubName: string;
  clubCode: string;
  membershipNumber: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  addressNameNumber: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  postalCode: string;
  memberStatus: string;
  paymentStatus: string;
  paymentStatusDescription: string;
  paymentStatusDescriptorId: number;
  id: number;
}
interface IMemberRecord {
  membershipNumber: string;
  clubName: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  memberStatus: string;
  paymentStatus: string;
  paymentStatusDescription: string;

}
const MemberRecord: React.FC<IMemberRecord> = ({
  membershipNumber,
  clubName,
  firstName,
  lastName,
  address,
  postalCode,
  memberStatus,
  paymentStatus,
  paymentStatusDescription
}) => {
  return (
    <tr key={`dup_${membershipNumber}`}>
      <td>{clubName}</td>
      <td className="text-center">{membershipNumber}</td>
      <td className="text-center">{`${firstName} ${lastName}`}</td>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={address}
        className="tooltipText"
      >
        <td className="text-center display-3-rows">{address}</td>
      </Tooltip>
      <td className="text-center">{postalCode}</td>
      <td className="text-center">{memberStatus}</td>
      <td className="text-center">{paymentStatusDescription}</td>
    </tr>
  );
};

interface IMemberTable {
  memberRecords: JSX.Element[];
}
const MemberTable: React.FC<IMemberTable> = ({ memberRecords }) => {
  const { t } = useTranslation();
  return (
    <div className="table-responsive-lg table-responsive-auto">
      <table className="table table-base duplicate-members-list">
        <thead>
          <tr>
            <th className="rounded-top-left">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.CLUB')}
            </th>
            <th className="text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.MEMBER_NO')}
            </th>
            <th className="text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.NAME')}
            </th>
            <th className="text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.ADDRESS')}
            </th>
            <th className="text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.POSTCODE')}
            </th>
            <th className="text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.STATUS')}
            </th>
            <th className="rounded-top-right text-center">
              {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.PAYMENT_STATUS')}
            </th>
          </tr>
        </thead>
        <tbody>{memberRecords}</tbody>
      </table>
    </div>
  );
};

interface IDuplicateMembers {
  duplicateMembers: IDuplicateMember[];
  onClose: () => void;
}

const DuplicateMembers: React.FC<IDuplicateMembers> = ({
  duplicateMembers,
  onClose
}) => {
  const { t } = useTranslation();

  const memberRecords = useMemo<JSX.Element[]>(
    () =>
      duplicateMembers.map(member => {
        const {
          clubName = '',
          membershipNumber = '',
          firstName = '',
          lastName = '',
          postalCode = '',
          memberStatus = '',
          paymentStatus = '',
          paymentStatusDescription = '',
          id
        } = member;

        let {
          addressNameNumber = '',
          addressLine1 = '',
          addressLine2 = '',
          addressLine3 = '',
          addressLine4 = ''
        } = member;

        addressNameNumber = condition(
          addressNameNumber,
          `${addressNameNumber},`,
          ''
        );
        addressLine1 = condition(addressLine1, `${addressLine1},`, '');
        addressLine2 = condition(addressLine2, `${addressLine2},`, '');
        addressLine3 = condition(addressLine3, `${addressLine3},`, '');
        addressLine4 = condition(addressLine4, `${addressLine4},`, '');

        let address = `${addressNameNumber} ${addressLine1} ${addressLine2} ${addressLine3} ${addressLine4}`.trim();
        if (!address) address = t('COMMON.NA');

        return (
          <MemberRecord
            key={id}
            membershipNumber={membershipNumber}
            clubName={clubName}
            firstName={firstName}
            lastName={lastName}
            address={address}
            postalCode={postalCode}
            memberStatus={memberStatus}
            paymentStatus={paymentStatus}
            paymentStatusDescription={paymentStatusDescription}
          />
        );
      }),
    [duplicateMembers.length]
  );
  return (
    <div className="select-add-on-main">
      <div className="main-table mt-2">
        <div className="d-flex justify-content-between align-items-center title-dupplicate">
          <span className="text-uppercase">
            {t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.TITLE')}
          </span>
          <i className="icon icon-close-ico" onClick={() => onClose()} />
        </div>

        <p>{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.SUB_TITLE')}</p>

        <p className="title-search">
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.SEARCH_FOUND',
                {
                  numOfMember: `<span class="total-member">${
                    duplicateMembers.length
                  }</span>`
                }
              )
            }}
          />
        </p>
        <MemberTable memberRecords={memberRecords} />
      </div>
    </div>
  );
};

export default DuplicateMembers;
