import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPackagePlanItem } from 'interfaces/Membership';
import { formatAmount, printUnits } from 'util/Common';

interface IConvertGuestToGuestPassPackagePlanItem {
  packagePlanItem: IPackagePlanItem;
  selectedPackagePlanId: number;
  onSelected: (packagePlanId: number) => void;
}

const ConvertGuestToGuestPassPackagePlanItem: React.FunctionComponent<
  IConvertGuestToGuestPassPackagePlanItem
> = ({ packagePlanItem, selectedPackagePlanId, onSelected }) => {
  let {
    name: packageName,
    payment: { payNow, regular },
    minimumContract: {
      value: contractDuration,
      unit: { id: contractId }
    },
    planPeriods,
    package: { timeGroup },
    originalPlanId: planId,
    name: planName
  } = packagePlanItem;
  packageName = packagePlanItem.package
    ? packagePlanItem.package.name
    : packageName;
  const paynowAmount = formatAmount(payNow ? payNow.value : 0);
  const regularAmount = formatAmount(regular ? regular.value : 0);
  const className =
    selectedPackagePlanId == planId
      ? 'row-content d-flex active'
      : 'row-content d-flex';
  const { t } = useTranslation();
  const planPeriod = planPeriods.find(period => period.code === contractId);

  return (
    <div className={className}>
      <div className="col-content">
        <p className="title">{packageName}</p>
        <ul>
          <li>{packagePlanItem.tier.name}</li>
          <li>
            {timeGroup && timeGroup.name ? timeGroup.name : t('FULL.ACCESS')}{' '}
          </li>
        </ul>
      </div>
      <div className="col-content">
        <p className="title">{planName}</p>
        <ul>
          <li>
            {contractDuration +
              ' ' +
              printUnits(
                contractDuration,
                planPeriod ? planPeriod.unit.code : ''
              )}
          </li>
        </ul>
      </div>
      <div className="col-content pay">
        <ul>
          <li className="pay-content">
            <div className="pay-now">
              <p>{t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PAY_NOW')}</p>
              <p className="number-pay-now">
                {paynowAmount[0]}.<sup>{paynowAmount[1]}</sup>
              </p>
            </div>
          </li>
          <li>
            <div className="regular">
              <p>
                {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.REGULAR')}
                <span className="number-regular">
                  {regularAmount[0]}.<sup>{regularAmount[1]}</sup>
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div className="col-content select">
        <button
          className="btn btn-primary text-uppercase"
          onClick={() => onSelected(planId)}
        >
          {t('BUTTON.SELECT')}
        </button>
      </div>
    </div>
  );
};

export default ConvertGuestToGuestPassPackagePlanItem;
