import React from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from 'util/I18nMessages';
import OptionModel from 'models/Option';
import { LookupModel } from 'models/PersonalDetails';
import {
  GENERAL_DATABASE_DATE_FORMAT,
  MIN_DATE_SYSTEM
} from 'constants/Constants';
import { ConmanFieldConfigModel } from 'models/ConmanConfig';

export function handleSessionExpired() {
  observer.publish('flagUnsavedChangeEvent', false);
  Swal.fire({
    ...DefaultConstants.SWAL_COMMON_STYLE,
    text: t('COMMON.EXPIRED_SESSION'),
    type: 'warning',
    confirmButtonText: t('BUTTON.CLOSE')
  }).then(() => {});
}

export function transformOptions<T extends OptionModel>(
  data: any[],
  labelKey: string,
  valueKey: string,
  rest = {}
): T[] {
  return data.map<T>(item => {
    return {
      value: item[valueKey],
      label: item[labelKey],
      ...rest
    } as T;
  });
}

export function alphabetSort<T>(arr: T[], field: string) {
  return arr.sort((a: T, b: T) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
}

export function IsValidEmail(emailAddress, emailConfig) {
  return emailConfig.test(emailAddress);
}

export function isValidInput(input: string, regex) {
  return regex.test(input);
}

// Eg: XXX-XXX-XXXX
// Eg: XXX.XXX.XXXX
// Eg: XXX XXX XXXX
export function IsValidPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.split(' ').join('');
  return /^\d{10}$/.test(phoneNumber);
}

export function IsValidPhoneNumberWithCountryCode(phoneNumber) {
  return /^\+?\d*$/.test(phoneNumber);
}

export function removeDuplicatedObj(arr) {
  return arr.reduce((unique, o) => {
    if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);
}

// Eg. +XX-XXXX-XXXX
// Eg. +XX.XXXX.XXXX
// Eg. +XX XXXX XXXX
export function IsValidSignPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.split(' ').join('');
  return /^\+?(\d{2})\)?[-. ]?(\d{4})[-. ]?(\d{4})$/.test(phoneNumber);
}

// Eg. 38980
// Eg. 83900-8789
export function IsValidPostCode(postCode) {
  return /^\d{5}$|^\d{5}-\d{4}$/.test(postCode);
}

// Eg. 2345643.00 will return 2,345,643.00
export function CommaFormatted(amount) {
  return amount ? amount.toLocaleString() : 0;
}

export function ConvertDateFormatForDatePicker(dateFormat) {
  switch (dateFormat) {
    case 'dd/mm/yyyy':
      return 'dd/MM/yyyy';
    case 'mm/dd/yyyy':
      return 'MM/dd/yyyy';
    default:
      return dateFormat;
  }
}

export function ConvertDateForSelectedDatePicker(date) {
  return moment(date).toDate();
}

export function DisplayDateFormat(date, dateFormat) {
  return moment(date).format(dateFormat.toUpperCase());
}

export function IsValidDateOfBirth(dateOfBirth) {
  return moment(dateOfBirth).isSameOrBefore(new Date(), 'day');
}

export function isBeforeMinDateSystem(dateOfBirth) {
  return moment(dateOfBirth).isBefore(new Date(MIN_DATE_SYSTEM), 'day');
}

export function IsValidStartDate(startDate) {
  return moment(startDate).isSameOrAfter(new Date(), 'day');
}

export const isValidStartDateForGuestPass = (startDate, minDate) => {
  return moment(startDate).isSameOrAfter(new Date(minDate), 'days');
};

export function ConvertDateFormat(date) {
  return moment(date)
    .utc()
    .toDate()
    .toISOString();
}

export function IsValidFormatDate(dateString, dateFormat) {
  if (dateString.length !== dateFormat.length) {
    return false;
  }
  return DisplayDateFormat(dateString, dateFormat).length === dateFormat.length;
}

export function IsValidDate(date, dateFormat) {
  const dateString = DisplayDateFormat(date, dateFormat);
  return dateString.length === dateFormat.length;
}

export function DisplayNationalIdNumber(nationalId) {
  return nationalId !== ''
    ? `${nationalId.substring(0, 4)}******${nationalId.substring(
        nationalId.length - 3,
        nationalId.length
      )}`
    : '';
}

export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPager(
  totalItems: number,
  currentPage: number = 1,
  itemPerPage: number = 10
) {
  const totalPages = Math.ceil(totalItems / itemPerPage);
  let startPage: number, endPage: number;
  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 3;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }
  const pages = [...Array(endPage + 1 - startPage).keys()].map(
    i => startPage + i
  );

  return {
    currentPage,
    startPage,
    endPage,
    pages,
    totalPages
  };
}

// Contain at least 8 characters
export function isMinimumCharacterCount(password) {
  return password.length > 7;
}

// Contain at least 1 uppercase character (A-Z)
export function IsMinimumUpperCaseLetter(password) {
  return /[A-Z]/.test(password);
}

// Contain at least 1 lowercase character (a-z)
export function IsMinimumLowerCaseLetter(password) {
  return /[a-z]/.test(password);
}

// Contain at least 1 digit character
export function IsMinimumDigitCharacterCount(password) {
  return /\d/.test(password);
}

// Contain at least one special character
export function IsMinimumSpecialCharacter(password) {
  return /[.!@#$%^&*]/.test(password);
}

export function isValidNumber(phone: string, phoneConfig: string) {
  const regex = new RegExp(phoneConfig);
  return regex.test(phone);
}

export function checkInvalidEmailField(email: string, emailConfig) {
  if (email && emailConfig) {
    return IsValidEmail(email, emailConfig);
  }
  return true;
}

export function checkInvalidInputField(value: string, regex) {
  if (value) {
    return isValidInput(value, regex);
  }
  return true;
}

export function isGroupNameValid(groupName: string) {
  if (groupName) {
    return /^[A-Za-z\s-]+$/.test(groupName);
  }
  return false;
}

export function isInputMatchRegex(regex: string, inputText: string) {
  if (regex && inputText) {
    return new RegExp(regex).test(inputText);
  }
  return true;
}

// Get object to display on Dropdown list
export function getProperty(model, key, list) {
  return model && model[key] ? list.find(e => e.value === model[key]) : list[0];
}

// Valid characters are "a-z", "A-Z" and space, and max length is 100 characters
export function IsValidAccountName(accountName) {
  return /^[a-zA-Z ]{1,100}$/.test(accountName);
}

// Valid characters are 0-9 and space
export function IsValidCardNumber(cardNumber) {
  return /^[\d ]*$/.test(cardNumber);
}

// Valid characters are 0-9 and space
export function IsValidIssueNumber(issueNumber) {
  return /^\d{1,2}$/.test(issueNumber);
}

// Valid characters are 0-9, and max length is 12 characters
export function IsValidNationalIdNumber(nationalIdNumber) {
  return /^\d{1,12}$/.test(nationalIdNumber);
}

export function IsValidOtherNationalIdNumber(nationalIdNumber) {
  return /^\d{1,15}$/.test(nationalIdNumber);
}

export function formatMoney(number, decPlaces) {
  return number.toFixed(decPlaces).replace(/-?\d(?=(?:\d{3}){1,5}\.)/g, '$&,'); // max 999,999,999,999,999,999.99'
}

export function isEmptyString(value: string) {
  return value.length === 0;
}

export const NumberMoneyFormat = ({ amount, operator = '' }) => {
  const splitedAmount = amount.split('.');
  if (splitedAmount[1]) {
    return (
      <p className="number-pay-now">
        {`${operator}${splitedAmount[0]}`}.<sup>{splitedAmount[1]}</sup>
      </p>
    );
  }
  return (
    <p className="number-pay-now">
      {`${operator}${splitedAmount[0]}`}.<sup>00</sup>
    </p>
  );
};

export function getParamFromWindowSearch(key: string): string {
  const params = new URLSearchParams(
    decodeURIComponent(window.location.search)
  );
  return params.get(key) || '';
}

export const uniqueId = () => {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

export const getValueData = (value: any) => {
  if (
    (value &&
      (typeof value === 'string' &&
        value.trim().length > 0 &&
        value !== 'COMMON.PLEASE_SELECT')) ||
    (Array.isArray(value) && value.length > 0)
  ) {
    return value;
  }

  return t('COMMON.NA');
};

export const safeLookupModel = (
  lookupModel: LookupModel | undefined,
  defaultLookupModel = new LookupModel()
) => {
  return lookupModel || defaultLookupModel;
};

export const getInputValue = (config: ConmanFieldConfigModel) => {
  if (config) {
    if (config.regEx) {
      return config.regEx;
    }
  }
  return '';
};

export const getErrorMessage = (errors, fieldName) => {
  if (errors[fieldName] && errors[fieldName].message) {
    return errors[fieldName].message;
  }
  return '';
};

export const getFormattedDateValue = date => {
  if (date) {
    return DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT);
  }
  return '';
};

export const focusInput = (name: string) => {
  const input = document.getElementById(name);
  if (input) {
    input.focus();
  }
};
