export type EzyPayFee = {
  description: string;
  value: number;
  fixedAmount: number;
  percentageAmount: number;
  feeType: string;
  applyTo: string;
};

export const EzyPayFeeUtils = {
  isPercentage(ezyPayFee: EzyPayFee) {
    return ezyPayFee.percentageAmount && !ezyPayFee.fixedAmount;
  },
  isBoth(ezyPayFee: EzyPayFee) {
    return ezyPayFee.fixedAmount && ezyPayFee.percentageAmount;
  },
  isAppliesTo(ezyPayFee: EzyPayFee) {
    return ezyPayFee.applyTo === 'ALL';
  },
  sortByAppliesTo(ezyPayFees: EzyPayFee[]) {
    return ezyPayFees.sort((feeA, feeB) => {
      if (EzyPayFeeUtils.isAppliesTo(feeA)) {
        return 1;
      } else if (EzyPayFeeUtils.isAppliesTo(feeB)) {
        return -1;
      }
      return 1;
    });
  },
  getValue(ezyPayFee: EzyPayFee) {
    if (isNaN(ezyPayFee.value)) {
      return ezyPayFee.value;
    }
    return Number(ezyPayFee.value).toPrecision(2);
  }
};
