import React from 'react';
import PackagePlanItem from './PackagePlanItem';
import Loading from 'components/Loading';
import { IPackagePlanItem } from 'interfaces/Membership';

interface PackagePlanListProps {
  isLoading: boolean;
  packagePlans: IPackagePlanItem[];
  selectedPackagePlanId: number;
  membershipConfiguration: any;
  onPackagePlanSelected: (packagePlanId: number) => void;
}

const PackagePlanList: React.FunctionComponent<PackagePlanListProps> = ({
  isLoading,
  packagePlans,
  selectedPackagePlanId,
  membershipConfiguration,
  onPackagePlanSelected
}) => {
  return (
    <div className="content select-package-plan">
      {isLoading ? (
        <Loading />
      ) : (
        packagePlans &&
        packagePlans.map((packagePlanItem, index) => (
          <PackagePlanItem
            key={packagePlanItem.originalPlanId}
            packagePlanItem={packagePlanItem}
            selectedPackagePlanId={selectedPackagePlanId}
            membershipConfiguration={membershipConfiguration}
            onSelected={onPackagePlanSelected}
          />
        ))
      )}
    </div>
  );
};

export default PackagePlanList;
