import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IMemberSummary } from './interface';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import {
  filterValuableFieldsOnly,
  getFieldConfiguration
} from 'util/FieldConfiguration';
import {
  CONTACT_LENGTH_CONTINUOUS,
  METHOD_CLUB,
  MONTH,
  PAYMENT_CONTINUOUS,
  PAYMENT_LUMP_SUM
} from 'constants/Payment';
import { IFee } from 'interfaces/Membership';
import Template from './template';
import { scrollToTop } from 'util/Common';
import { getFieldsConfiguration } from 'services/membership-service';

const MemberSummary: React.FC<IMemberSummary> = ({
  membership,
  onCancel,
  onFinish,
  setConfigurationAction
}) => {
  const [openDropdown, setOpenDropdown] = useState({
    memberSummary: true,
    personalDetails: false,
    healthQuestion: false,
    membershipDetails: false,
    memberPaymentDetails: false,
    memberPayment: false
  });
  const [minContractEndDate, setMinContractEndDate] = useState<Date>();
  const [fees, setFees] = useState<IFee[]>([]);

  const [fieldConfigs, setFieldConfigs] = useState(
    getFieldConfiguration(InitialFieldConfigs.data)
  );

  useEffect(() => {
    getFieldConfigs();
    getMinContractEndDate();
    getProjectedPayments();
    scrollToTop();
  }, []);

  const getAddonFee = () => {
    const addonFee: IFee[] = [];
    const { selectedPackagePlan } = membership;
    if (!selectedPackagePlan) {
      return [];
    }
    const payment = selectedPackagePlan.payment;
    if (payment && payment.payNow && payment.payNow.fees && payment.payNow.fees.length) {
      if (payment.payNow.addonFees && payment.payNow.addonFees.length) {
        payment.payNow.addonFees.forEach(addon => {
          addonFee.push({
            amount: addon.amount,
            typeName: addon.addonName,
            startDate: payment.payNow.fees[0].startDate,
            method: 'Club',
            status: '',
            collectionMethodCode: METHOD_CLUB
          });
        });
      }
    }

    return addonFee;
  };

  const getFirstBilling = () => {
    const feeFirstBilling: IFee[] = [];
    const { selectedPackagePlan } = membership;
    if (!selectedPackagePlan) {
      return [];
    }

    const payment = selectedPackagePlan.payment;
    const { firstBilling } = payment;

    if (!firstBilling && payment.paymentType !== PAYMENT_CONTINUOUS) {
      return [];
    }

    for (let i = 1; i < CONTACT_LENGTH_CONTINUOUS; i++) {
      feeFirstBilling.push(
        ...firstBilling.fees.map(fee => ({
          ...fee,
          startDate: moment(firstBilling.startDate)
            .add(i - 1, 'M')
            .format()
        }))
      );

      if (firstBilling.addonFees && firstBilling.addonFees.length > 0) {
        feeFirstBilling.push(
          ...firstBilling.addonFees.map(addon => ({
            amount: addon.amount,
            typeName: addon.addonName,
            startDate: moment(firstBilling.startDate)
              .add(i - 1, 'M')
              .format(),
            method: 'Club',
            status: '',
            collectionMethodCode: METHOD_CLUB
          }))
        );
      }
    }
    return feeFirstBilling;
  };

  const formatFirstBillingWithMinContact = (
    feeFirstBilling,
    minimumContract,
    firstBilling
  ) => {
    for (let i = 0; i <= minimumContract.value; i++) {
      feeFirstBilling.push(
        ...firstBilling.fees.map(fee => ({
          ...fee,
          startDate: moment(firstBilling.startDate)
            .add(i, 'M')
            .format()
        }))
      );
    }
  };

  const getProjectedPayments = () => {
    const { selectedPackagePlan, selectedAddons } = membership;
    if (
      !selectedPackagePlan ||
      !selectedAddons ||
      (selectedPackagePlan && typeof selectedPackagePlan.payment === undefined)
    ) {
      return false;
    }

    const payment = selectedPackagePlan.payment;
    if (payment && payment.payNow && payment.payNow.fees) {
      const payNowFees = payment.payNow.fees;
      const addonFeeFormat = getAddonFee();
      const feeFirstBilling: IFee[] = getFirstBilling();

      const { firstBilling } = payment;
      if (firstBilling && payment.paymentType === PAYMENT_LUMP_SUM) {
        const { minimumContract } = selectedPackagePlan;
        const isMonthContact = minimumContract.unit.id === MONTH;
        if (isMonthContact) {
          formatFirstBillingWithMinContact(
            feeFirstBilling,
            minimumContract,
            firstBilling
          );
        }
      }

      setFees([...payNowFees.concat(addonFeeFormat).concat(feeFirstBilling)]);
    }
  };

  const getMinContractEndDate = () => {
    const { selectedPackagePlan, membershipSearchFilter } = membership;

    if (!membershipSearchFilter || !selectedPackagePlan) {
      return;
    }
    if (membershipSearchFilter.startDate) {
      const day = new Date(membershipSearchFilter.startDate);
      const { minimumContract } = selectedPackagePlan;
      if (minimumContract.unit.id === MONTH) {
        day.setMonth(
          day.getMonth() + selectedPackagePlan.minimumContract.value
        );
      } else {
        day.setDate(day.getDate() + selectedPackagePlan.minimumContract.value);
      }
      setMinContractEndDate(day);
    }
  };

  const getFieldConfigs = () => {
    getFieldsConfiguration()
      .then(res => {
        if (res.data && res.data.length) {
          let fieldConfigsNew = filterValuableFieldsOnly(
            getFieldConfiguration(res.data)
          );

          setFieldConfigs({
            ...fieldConfigs,
            ...fieldConfigsNew
          });
        }
      })
      .catch(() => {
        setFieldConfigs(getFieldConfiguration(InitialFieldConfigs.data));
      });
  };

  const handlePrevious = () => {
    const { membershipConfiguration } = membership;
    membershipConfiguration.stepIndex = 4;
    membershipConfiguration.stepLatest = 4;
    setConfigurationAction(membershipConfiguration);
  };

  const handleOpenDropdown = key => {
    setOpenDropdown({
      ...openDropdown,
      [key]: !openDropdown[key]
    });
  };

  const handleEdit = index => {
    const { membershipConfiguration } = membership;
    membershipConfiguration.stepIndex = index;
    setConfigurationAction(membershipConfiguration);
  };

  const goToStep = stepIndex => {
    const { membershipConfiguration } = membership;
    membershipConfiguration.stepIndex = stepIndex;
    membershipConfiguration.stepLatest = stepIndex;
    setConfigurationAction(membershipConfiguration);
  };

  return (
    <Template
      membership={membership}
      openDropdown={openDropdown}
      minContractEndDate={minContractEndDate}
      fees={fees}
      fieldConfigs={fieldConfigs}
      onCancel={onCancel}
      onFinish={onFinish}
      handleOpenDropdown={handleOpenDropdown}
      handlePrevious={handlePrevious}
      handleEdit={handleEdit}
      goToStep={goToStep}
    />
  );
};

export default MemberSummary;
