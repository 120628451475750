// Conman
export const SET_CONFIGS = 'SET_CONFIGS';

//ADD NEW MEMBER
export const ADD_MEMBER_RESET_STATE = 'ADD_MEMBER_RESET_STATE';

// Step 1
export const ADD_MEMBER_SET_SEARCH_FILTER = 'ADD_MEMBER_SET_SEARCH_FILTER';
export const ADD_MEMBER_SET_CONFIGURATION = 'ADD_MEMBER_SET_CONFIGURATION';
export const ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN =
  'ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN';
export const ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS =
  'ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS';
export const ADD_MEMBER_SET_PAYMENT_DETAILS = 'ADD_MEMBER_SET_PAYMENT_DETAILS';
export const ADD_MEMBER_SET_QUESTION_CONFIGS =
  'ADD_MEMBER_SET_QUESTION_CONFIGS';

//Step 3
export const ADD_MEMBER_SET_PERSONAL_DETAILS =
  'ADD_MEMBER_SET_PERSONAL_DETAILS';
export const ADD_MEMBER_SET_ALL_PACKAGE_PLAN =
  'ADD_MEMBER_SET_ALL_PACKAGE_PLAN';
export const ADD_MEMBER_SET_MEMBER_SUMMARY = 'ADD_MEMBER_SET_MEMBER_SUMMARY';
// Step 4
export const REPLACE_NEW_MEMBERSHIP = 'REPLACE_NEW_MEMBERSHIP';

//ADD CORPORATE MEMBER
export const ADD_CORPORATE_MEMBER_RESET_STATE =
  'ADD_CORPORATE_MEMBER_RESET_STATE';
// Step 1
export const ADD_CORPORATE_MEMBER_SET_SEARCH_FILTER =
  'ADD_CORPORATE_MEMBER_SET_SEARCH_FILTER';
export const ADD_CORPORATE_MEMBER_SET_CONFIGURATION =
  'ADD_CORPORATE_MEMBER_SET_CONFIGURATION';
export const ADD_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN =
  'ADD_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN';
export const ADD_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS =
  'ADD_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS';
export const ADD_CORPORATE_MEMBER_SET_PAYMENT_DETAILS =
  'ADD_CORPORATE_MEMBER_SET_PAYMENT_DETAILS';
export const ADD_CORPORATE_MEMBER_SET_QUESTION_CONFIGS =
  'ADD_CORPORATE_MEMBER_SET_QUESTION_CONFIGS';
//Step 3
export const ADD_CORPORATE_MEMBER_SET_PERSONAL_DETAILS =
  'ADD_CORPORATE_MEMBER_SET_PERSONAL_DETAILS';
export const ADD_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN =
  'ADD_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN';
export const ADD_CORPORATE_MEMBER_SET_MEMBER_SUMMARY =
  'ADD_CORPORATE_MEMBER_SET_MEMBER_SUMMARY';
// Step 4
export const ADD_CORPORATE_MEMBER_REPLACE_MEMBERSHIP =
  'ADD_CORPORATE_MEMBER_REPLACE_MEMBERSHIP';

// CONVERT GUEST TO MEMBER
export const CONVERT_GUEST_TO_MEMBER_RESET_STATE =
  'CONVERT_GUEST_TO_MEMBER_RESET_STATE';

// Step 1
export const CONVERT_GUEST_TO_MEMBER_SET_SEARCH_FILTER =
  'CONVERT_GUEST_TO_MEMBER_SET_SEARCH_FILTER';
export const CONVERT_GUEST_TO_MEMBER_SET_GUEST_CORPORATE_ID =
  'CONVERT_GUEST_TO_MEMBER_SET_GUEST_CORPORATE_ID';
export const CONVERT_GUEST_TO_MEMBER_SET_CONFIGURATION =
  'CONVERT_GUEST_TO_MEMBER_SET_CONFIGURATION';
export const CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PRODUCT_EXTRAS =
  'CONVERT_GUEST_TO_MEMBER_SET_SELECTED_PRODUCT_EXTRAS';
export const CONVERT_GUEST_TO_MEMBER_SET_PAYMENT_DETAILS =
  'CONVERT_GUEST_TO_MEMBER_SET_PAYMENT_DETAILS';
export const CONVERT_GUEST_TO_MEMBER_SET_QUESTION_CONFIGS =
  'CONVERT_GUEST_TO_MEMBER_SET_QUESTION_CONFIGS';
//Step 3
export const CONVERT_GUEST_TO_MEMBER_SET_PERSONAL_DETAILS =
  'CONVERT_GUEST_TO_MEMBER_SET_PERSONAL_DETAILS';
export const CONVERT_GUEST_TO_MEMBER_SET_ALL_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_MEMBER_SET_ALL_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_MEMBER_SET_MEMBER_SUMMARY =
  'CONVERT_GUEST_TO_MEMBER_SET_MEMBER_SUMMARY';
//Step 4
export const CONVERT_GUEST_TO_MEMBER_REPLACE_DATA =
  'CONVERT_GUEST_TO_MEMBER_REPLACE_DATA';
// Payment
export const SET_ONLINE_PAYMENT = 'SET_ONLINE_PAYMENT';
export const SET_ONLINE_PAYMENT_STATE = 'SET_ONLINE_PAYMENT_STATE';
export const RESET_ONLINE_PAYMENT = 'RESET_ONLINE_PAYMENT';

// CONVERT GUEST TO GUEST PASS
export const CONVERT_GUEST_TO_GUESTPASS_SET_SEARCH_FILTER =
  'CONVERT_GUEST_TO_GUESTPASS_SET_SEARCH_FILTER';
export const CONVERT_GUEST_TO_GUEST_PASS_SET_CONFIGURATION =
  'CONVERT_GUEST_TO_GUESTPASS_SET_CONFIGURATION';
export const CONVERT_GUEST_TO_GUESTPASS_SET_SELECTED_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_GUESTPASS_SET_SELECTED_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_GUESTPASS_SET_ALL_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_GUESTPASS_SET_ALL_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_GUESTPASS_SET_GUEST_DETAILS =
  'CONVERT_GUEST_TO_GUESTPASS_SET_GUEST_DETAILS';

// Convert guest to corporate member

export const CONVERT_GUEST_TO_CORPORATE_MEMBER_RESET_STATE =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_RESET_STATE';

// Step 1
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SEARCH_FILTER =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SEARCH_FILTER';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_GUEST_CORPORATE_ID =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_GUEST_CORPORATE_ID';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_CONFIGURATION =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_CONFIGURATION';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_SELECTED_PRODUCT_EXTRAS';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PAYMENT_DETAILS =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PAYMENT_DETAILS';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_REPLACE_DATA =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_REPLACE_DATA';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_QUESTION_CONFIGS =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_QUESTION_CONFIGS';
//Step 3
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PERSONAL_DETAILS =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_PERSONAL_DETAILS';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_ALL_PACKAGE_PLAN';
export const CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_MEMBER_SUMMARY =
  'CONVERT_GUEST_TO_CORPORATE_MEMBER_SET_MEMBER_SUMMARY';

export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
export const UPDATE_PAYMENT_SHOW_INFO = 'UPDATE_PAYMENT_SHOW_INFO';
export const UPDATE_IS_LOADING_EZY_PAY = 'UPDATE_IS_LOADING_EZY_PAY';
export const UPDATE_CAN_USE_EZY_PAY = 'UPDATE_CAN_USE_EZY_PAY';
export const UPDATE_LOAD_FEE_EZYPAY = 'UPDATE_LOAD_FEE_EZYPAY';
export const UPDATE_LOAD_FAIL_EZYPAY = 'UPDATE_LOAD_FAIL_EZYPAY';
