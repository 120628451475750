import React, { useState, useEffect, MutableRefObject, useMemo } from 'react';
import { FormInput, FormPhoneInput, FormSelect } from 'components/Form';
import {
  EmergencyContactModel,
  LookupModel,
  PhoneNumberModel
} from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';
import {
  ACTIVE_STATUS_CODE,
  EMERGENCY_CONTACT_NAME_TEXT,
  EMERGENCY_CONTACT_PHONE_NUMBER_TEXT,
  GUARDIAN_ADDRESS_TEXT,
  GUARDIAN_IDENTITY_NO_TEXT,
  GUARDIAN_IDENTITY_TYPE_ID_TEXT,
  GUARDIAN_NAME_TEXT,
  GUARDIAN_PASS_TEXT,
  GUARDIAN_PHONE_NUMBER_TEXT,
  GUARDIAN_RELATIONSHIP_TEXT,
  RELATIONSHIP_TEXT
} from 'constants/DefaultConstants';
import { ConmanFieldConfigModel } from 'models/ConmanConfig';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import {
  onChangeInput,
  onChangePhoneNumber,
  required,
  validateEmptySelection,
  validateGuardianPhone,
  validateInputField,
  validatePhoneNumber
} from 'util/FormValidations';
import { getInputValue, safeLookupModel } from 'util/ControlUtils';
import { useTypedSelector } from 'reduxs';

interface IEmergencyContact {
  emergencyContact: EmergencyContactModel;
  guardianIdentityTypeIdList: LookupModel[];
  conmanConfig: ConmanFieldConfigModel[];
  emergencyContactRef: MutableRefObject<UseFormReturn<
    EmergencyContactFormInput
  > | null>;
}

export type EmergencyContactFormInput = {
  contactName: string;
  contactNumber: PhoneNumberModel;
  relationship: string;
  guardianName: string;
  guardianPhoneNumber: string;
  guardianAddress: string;
  guardianRelationship: string;
  guardianIdentityNo: string;
  guardianIdentityTypeId: LookupModel;
  guardianPass: string;
};

const EmergencyContact: React.FC<IEmergencyContact> = ({
  emergencyContact,
  conmanConfig,
  emergencyContactRef,
  guardianIdentityTypeIdList
}) => {
  const {
    contactName,
    contactNumber,
    relationship,
    guardianName,
    guardianPhoneNumber,
    guardianAddress,
    guardianRelationship,
    guardianIdentityNo,
    guardianIdentityTypeId,
    guardianPass
  } = emergencyContact;
  const newMembership = useTypedSelector(state => state.newMembership);
  const { t } = useTranslation();
  const [relationshipConfig, setRelationshipConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [guardianNameConfig, setGuardianNameConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [guardianPhoneNumberConfig, setGuardianPhoneNumberConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [guardianAddressConfig, setGuardianAddressConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [guardianRelationshipConfig, setGuardianRelationshipConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [guardianIdentityNoConfig, setGuardianIdentityNoConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [
    guardianIdentityTypeIdConfig,
    setGuardianIdentityTypeIdConfig
  ] = useState<ConmanFieldConfigModel>();
  const [guardianPassConfig, setGuardianPassConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [emergencyContactNameConfig, setEmergencyContactNameConfig] = useState<
    ConmanFieldConfigModel
  >();
  const [
    emergencyContactPhoneNumConfig,
    setEmergencyContactPhoneNumConfig
  ] = useState<ConmanFieldConfigModel>();

  useEffect(() => {
    if (conmanConfig && conmanConfig.length) {
      const relationshipCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === RELATIONSHIP_TEXT
      );
      setRelationshipConfig(relationshipCfg);
      const guardianNameCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_NAME_TEXT
      );
      setGuardianNameConfig(guardianNameCfg);
      const guardianPhoneNumberCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_PHONE_NUMBER_TEXT
      );
      setGuardianPhoneNumberConfig(guardianPhoneNumberCfg);
      const guardianAddressCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_ADDRESS_TEXT
      );
      setGuardianAddressConfig(guardianAddressCfg);
      const guardianRelationshipCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_RELATIONSHIP_TEXT
      );
      setGuardianRelationshipConfig(guardianRelationshipCfg);
      const guardianIdentityNoCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_IDENTITY_NO_TEXT
      );
      setGuardianIdentityNoConfig(guardianIdentityNoCfg);
      const guardianIdentityTypeIdCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_IDENTITY_TYPE_ID_TEXT
      );
      setGuardianIdentityTypeIdConfig(guardianIdentityTypeIdCfg);
      const guardianPassCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === GUARDIAN_PASS_TEXT
      );
      setGuardianPassConfig(guardianPassCfg);
      const emergencyNameCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === EMERGENCY_CONTACT_NAME_TEXT
      );
      setEmergencyContactNameConfig(emergencyNameCfg);
      const contactNumberNumberCfg = conmanConfig.find(
        (cnm: ConmanFieldConfigModel) =>
          cnm.questionDescriptorText === EMERGENCY_CONTACT_PHONE_NUMBER_TEXT
      );
      setEmergencyContactPhoneNumConfig(contactNumberNumberCfg);
    }
  }, [conmanConfig]);

  const isRequiredConmanField = (conmanConfig: ConmanFieldConfigModel) => {
    return (
      conmanConfig &&
      conmanConfig.isRequired === 1 &&
      conmanConfig.status === ACTIVE_STATUS_CODE
    );
  };

  const form = useForm<EmergencyContactFormInput>({
    defaultValues: {
      contactName: contactName,
      contactNumber: contactNumber,
      relationship: relationship,
      guardianName: guardianName,
      guardianPhoneNumber: guardianPhoneNumber,
      guardianAddress: guardianAddress,
      guardianRelationship: guardianRelationship,
      guardianIdentityNo: guardianIdentityNo,
      guardianIdentityTypeId: safeLookupModel(guardianIdentityTypeId),
      guardianPass: guardianPass
    }
  });

  const {
    control,
    clearErrors,
    formState: { errors }
  } = form;

  useEffect(() => {
    if (emergencyContactRef) {
      emergencyContactRef.current = form;
    }
  }, []);

  const isRequiredGuardian = useMemo(() => {
    const { personalDetails, membershipSearchFilter } = newMembership;
    const { clubSelect } = membershipSearchFilter;

    if (!clubSelect || !personalDetails.age) {
      return false;
    }

    return (
      personalDetails.age >= clubSelect.minimumAge &&
      personalDetails.age < clubSelect.ageOfConsent &&
      personalDetails.isParentalConsentProvided
    );
  }, [newMembership.personalDetails]);

  useEffect(() => {
    if (!isRequiredGuardian) {
      clearErrors([
        'guardianName',
        'guardianPhoneNumber',
        'guardianIdentityNo',
        'guardianIdentityTypeId'
      ]);
    }
  }, [isRequiredGuardian]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          {emergencyContactNameConfig &&
            emergencyContactNameConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="contactName"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(emergencyContactNameConfig)
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(emergencyContactNameConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT_NAME'
                    )}
                    require={isRequiredConmanField(emergencyContactNameConfig)}
                    errorMsg={t(
                      (errors.contactName && errors.contactName.message) || ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {emergencyContactPhoneNumConfig &&
            emergencyContactPhoneNumConfig.status === ACTIVE_STATUS_CODE && (
              <>
                <Controller
                  name="contactNumber"
                  control={control}
                  rules={{
                    required: required(
                      isRequiredConmanField(emergencyContactPhoneNumConfig)
                    ),
                    validate: {
                      validatePhoneNumber
                    }
                  }}
                  render={({ field }) => (
                    <FormPhoneInput
                      {...field}
                      inputClass="form-control"
                      label={t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT_PHONE_NUMBER'
                      )}
                      value={field.value.phoneNumber}
                      require={isRequiredConmanField(
                        emergencyContactPhoneNumConfig
                      )}
                      countryCode={contactNumber.countryCode}
                      onPhoneNumberChange={onChangePhoneNumber(field)}
                    />
                  )}
                />

                {errors.contactNumber && (
                  <span className="phone-require text-danger input-error">
                    {t(
                      (errors.contactNumber && errors.contactNumber.message) ||
                        ''
                    )}
                  </span>
                )}
              </>
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {relationshipConfig &&
            relationshipConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="relationship"
                control={control}
                rules={{
                  required: required(isRequiredConmanField(relationshipConfig)),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(relationshipConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_RELATIONSHIP'
                    )}
                    require={isRequiredConmanField(relationshipConfig)}
                    errorMsg={t(
                      (errors.relationship && errors.relationship.message) || ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {guardianNameConfig &&
            guardianNameConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianName"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianNameConfig) ||
                      isRequiredGuardian
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(guardianNameConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_NAME'
                    )}
                    require={
                      isRequiredConmanField(guardianNameConfig) ||
                      isRequiredGuardian
                    }
                    errorMsg={t(
                      (errors.guardianName && errors.guardianName.message) || ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {guardianPhoneNumberConfig &&
            guardianPhoneNumberConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianPhoneNumber"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianPhoneNumberConfig) ||
                      isRequiredGuardian
                  ),
                  validate: {
                    validateGuardianPhone: validateGuardianPhone(
                      getInputValue(guardianPhoneNumberConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_PHONE_NUMBER'
                    )}
                    require={
                      isRequiredConmanField(guardianPhoneNumberConfig) ||
                      isRequiredGuardian
                    }
                    errorMsg={t(
                      (errors.guardianPhoneNumber &&
                        errors.guardianPhoneNumber.message) ||
                        ''
                    )}
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {guardianAddressConfig &&
            guardianAddressConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianAddress"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianAddressConfig)
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(guardianAddressConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_ADDRESS'
                    )}
                    require={isRequiredConmanField(guardianAddressConfig)}
                    errorMsg={t(
                      (errors.guardianAddress &&
                        errors.guardianAddress.message) ||
                        ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {guardianRelationshipConfig &&
            guardianRelationshipConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianRelationship"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianRelationshipConfig)
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(guardianRelationshipConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_RELATIONSHIP'
                    )}
                    require={isRequiredConmanField(guardianRelationshipConfig)}
                    errorMsg={t(
                      (errors.guardianRelationship &&
                        errors.guardianRelationship.message) ||
                        ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {guardianIdentityNoConfig &&
            guardianIdentityNoConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianIdentityNo"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianIdentityNoConfig) ||
                      isRequiredGuardian
                  ),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(guardianIdentityNoConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_IDENTITY_NO'
                    )}
                    require={
                      isRequiredConmanField(guardianIdentityNoConfig) ||
                      isRequiredGuardian
                    }
                    errorMsg={t(
                      (errors.guardianIdentityNo &&
                        errors.guardianIdentityNo.message) ||
                        ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {guardianIdentityTypeIdConfig &&
            guardianIdentityTypeIdConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianIdentityTypeId"
                control={control}
                rules={{
                  required: required(
                    isRequiredConmanField(guardianIdentityTypeIdConfig) ||
                      isRequiredGuardian
                  ),
                  validate: {
                    validateEmptySelection: value =>
                      validateEmptySelection(
                        value,
                        isRequiredConmanField(guardianIdentityTypeIdConfig) ||
                          isRequiredGuardian
                      )
                  }
                }}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_IDENTITY_TYPE_ID'
                    )}
                    disabled={
                      guardianIdentityTypeIdList &&
                      guardianIdentityTypeIdList.length === 0
                    }
                    require={
                      isRequiredConmanField(guardianIdentityTypeIdConfig) ||
                      isRequiredGuardian
                    }
                    options={guardianIdentityTypeIdList}
                    errorMsg={t(
                      (errors.guardianIdentityTypeId &&
                        errors.guardianIdentityTypeId.message) ||
                        ''
                    )}
                  />
                )}
              />
            )}
        </div>
        <div className="col-md-6">
          {guardianPassConfig &&
            guardianPassConfig.status === ACTIVE_STATUS_CODE && (
              <Controller
                name="guardianPass"
                control={control}
                rules={{
                  required: required(isRequiredConmanField(guardianPassConfig)),
                  validate: {
                    validateInputField: validateInputField(
                      getInputValue(guardianPassConfig)
                    )
                  }
                }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    inputClass="form-control"
                    label={t(
                      'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_GUARDIAN_PASS'
                    )}
                    require={isRequiredConmanField(guardianPassConfig)}
                    errorMsg={t(
                      (errors.guardianPass && errors.guardianPass.message) || ''
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeInput(field)(event)
                    }
                  />
                )}
              />
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmergencyContact;
