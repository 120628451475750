import React, { MutableRefObject, useEffect } from 'react';
import { FormInput, FormPhoneInput } from 'components/Form';
import {
  ContactDetailsModel,
  ContactDetailsFieldsModel
} from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';
import * as FieldKeys from 'util/FieldConfiguration/Keys';

import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { ContactDetailsFormInput } from './ContactDetails';
import {
  onChangeInput,
  onChangePhoneNumber,
  validatePhoneNumber
} from 'util/FormValidations';
import { ContactedDetailsFormInput } from './ContactedDetails';

interface IContactDetails {
  contactDetails: ContactDetailsModel;
  contactDetailsFieldConfigs: ContactDetailsFieldsModel;
  contactedDetailRef: MutableRefObject<UseFormReturn<
    ContactedDetailsFormInput
  > | null>;
  contactDetailRef: MutableRefObject<UseFormReturn<
    ContactDetailsFormInput,
    any
  > | null>;
}

const ConvertGuestToMemberContactDetails: React.FC<IContactDetails> = ({
  contactDetails,
  contactDetailRef,
  contactedDetailRef,
  contactDetailsFieldConfigs
}) => {
  const { mobilePhone, homePhone, workPhone, email } = contactDetails;
  const {
    telephoneNumberConfig,
    emailConfig,
    homePhoneConfig,
    mobilePhoneConfig,
    workPhoneConfig
  } = contactDetailsFieldConfigs;

  const form = useForm<ContactDetailsFormInput>({
    defaultValues: {
      email: email,
      mobilePhone: mobilePhone,
      homePhone: homePhone,
      workPhone: workPhone
    }
  });

  const {
    control,
    formState: { errors }
  } = form;

  const { t } = useTranslation();

  useEffect(() => {
    if (contactDetailRef) {
      contactDetailRef.current = form;
    }
  }, []);

  return (
    <div className="personal-group">
      <p className="title">
        {t(
          'PAGE.MEMBERSHIPS.CREATE.STEP_3.PLEASE_PROVIDE_AT_LEAST_ONE_TELEPHONE_NUMBER'
        )}
      </p>
      <div className="row">
        {telephoneNumberConfig.isVisible && (
          <>
            <div className="col-md-6">
              <Controller
                name="mobilePhone"
                control={control}
                rules={{
                  validate: {
                    validatePhoneNumber
                  }
                }}
                render={({ field }) => (
                  <FormPhoneInput
                    {...field}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE')}
                    countryCode={mobilePhone.countryCode}
                    errorMsg={t(
                      (errors.mobilePhone && errors.mobilePhone.message) || ''
                    )}
                    phoneConfig={mobilePhoneConfig}
                    value={field.value.phoneNumber}
                    onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                      if (!phoneNumber && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                              FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS &&
                            cm.key !==
                              FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangePhoneNumber(field)(
                        phoneNumber,
                        regionCode,
                        isValid
                      );
                    }}
                  />
                )}
              />
              {errors.mobilePhone && (
                <span className="phone-require text-danger input-error">
                  {t((errors.mobilePhone && errors.mobilePhone.message) || '')}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <Controller
                name="homePhone"
                control={control}
                rules={{
                  validate: {
                    validatePhoneNumber
                  }
                }}
                render={({ field }) => (
                  <FormPhoneInput
                    {...field}
                    phoneConfig={homePhoneConfig}
                    inputClass="form-control"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE')}
                    countryCode={homePhone.countryCode}
                    errorMsg={t(
                      (errors.homePhone && errors.homePhone.message) || ''
                    )}
                    value={field.value.phoneNumber}
                    onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                      if (!phoneNumber && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangePhoneNumber(field)(
                        phoneNumber,
                        regionCode,
                        isValid
                      );
                    }}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
      <div className="row">
        {telephoneNumberConfig.isVisible && (
          <div className="col-md-6">
            <Controller
              name="workPhone"
              control={control}
              rules={{
                validate: {
                  validatePhoneNumber
                }
              }}
              render={({ field }) => (
                <FormPhoneInput
                  {...field}
                  phoneConfig={workPhoneConfig}
                  className="pt-3"
                  inputClass="form-control "
                  label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE')}
                  countryCode={workPhone.countryCode}
                  errorMsg={t(
                    (errors.workPhone && errors.workPhone.message) || ''
                  )}
                  value={field.value.phoneNumber}
                  onPhoneNumberChange={(phoneNumber, regionCode, isValid) => {
                    if (!phoneNumber && contactedDetailRef.current) {
                      const preferredContactMethodWatch = contactedDetailRef.current.watch(
                        'preferredContactMethod'
                      );
                      const newPreferred = preferredContactMethodWatch.filter(
                        cm =>
                          cm.key !==
                          FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
                      );
                      contactedDetailRef.current.setValue(
                        'preferredContactMethod',
                        newPreferred
                      );
                    }
                    onChangePhoneNumber(field)(
                      phoneNumber,
                      regionCode,
                      isValid
                    );
                  }}
                />
              )}
            />
          </div>
        )}
        {emailConfig.isVisible && (
          <div className="col-md-6">
            <div className="form-group pt-3">
              <label>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL_ADDRESS')}</label>
              {emailConfig.isRequired && (
                <span className="text-primary bold">&nbsp;*</span>
              )}

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    disabled
                    inputClass="form-control"
                    maxlength={emailConfig.maxLength}
                    require={emailConfig.isRequired}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!event.target.value && contactedDetailRef.current) {
                        const preferredContactMethodWatch = contactedDetailRef.current.watch(
                          'preferredContactMethod'
                        );
                        const newPreferred = preferredContactMethodWatch.filter(
                          cm =>
                            cm.key !==
                            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                        );
                        contactedDetailRef.current.setValue(
                          'preferredContactMethod',
                          newPreferred
                        );
                      }
                      onChangeInput(field)(event);
                    }}
                    errorMsg={t((errors.email && errors.email.message) || '')}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConvertGuestToMemberContactDetails;
