import React from 'react';
export const PromotionIcon: React.FC = () => (
  <svg
    width="309"
    height="219"
    viewBox="0 0 309 219"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="309" height="219" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130 89C133.866 89 137 85.866 137 82C137 78.134 133.866 75 130 75C126.134 75 123 78.134 123 82C123 85.866 126.134 89 130 89ZM130 97C138.284 97 145 90.2843 145 82C145 73.7157 138.284 67 130 67C121.716 67 115 73.7157 115 82C115 90.2843 121.716 97 130 97ZM192.088 69.9082C193.721 71.3954 193.84 73.9253 192.353 75.5589L123.479 151.212C121.992 152.846 119.462 152.965 117.828 151.477C116.194 149.99 116.076 147.46 117.563 145.827L186.437 70.1733C187.924 68.5397 190.454 68.4211 192.088 69.9082ZM181 134C181 137.866 177.866 141 174 141C170.134 141 167 137.866 167 134C167 130.134 170.134 127 174 127C177.866 127 181 130.134 181 134ZM189 134C189 142.284 182.284 149 174 149C165.716 149 159 142.284 159 134C159 125.716 165.716 119 174 119C182.284 119 189 125.716 189 134Z"
      fill="#00d6d0"
    />
    <mask id="path-2-inside-1_8_114" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 40H278V75C258.67 75 243 90.67 243 110C243 129.33 258.67 145 278 145V180H31V144.774C32.3127 144.923 33.6474 145 35 145C54.33 145 70 129.33 70 110C70 90.67 54.33 75 35 75C33.6474 75 32.3127 75.0767 31 75.2261V40Z"
      />
    </mask>
    <path
      d="M278 40H286V32H278V40ZM31 40V32H23V40H31ZM278 75V83H286V75H278ZM278 145H286V137H278V145ZM278 180V188H286V180H278ZM31 180H23V188H31V180ZM31 144.774L31.9043 136.825L23 135.812V144.774H31ZM31 75.2261H23V84.1879L31.9043 83.1748L31 75.2261ZM278 32H31V48H278V32ZM286 75V40H270V75H286ZM251 110C251 95.0883 263.088 83 278 83V67C254.252 67 235 86.2518 235 110H251ZM278 137C263.088 137 251 124.912 251 110H235C235 133.748 254.252 153 278 153V137ZM286 180V145H270V180H286ZM31 188H278V172H31V188ZM23 144.774V180H39V144.774H23ZM35 137C33.9502 137 32.9174 136.94 31.9043 136.825L30.0957 152.723C31.708 152.906 33.3446 153 35 153V137ZM62 110C62 124.912 49.9117 137 35 137V153C58.7482 153 78 133.748 78 110H62ZM35 83C49.9117 83 62 95.0883 62 110H78C78 86.2518 58.7482 67 35 67V83ZM31.9043 83.1748C32.9174 83.0595 33.9502 83 35 83V67C33.3446 67 31.708 67.0939 30.0957 67.2774L31.9043 83.1748ZM23 40V75.2261H39V40H23Z"
      fill="#00d6d0"
      mask="url(#path-2-inside-1_8_114)"
    />
  </svg>
);
