import * as types from 'constants/ActionTypes';
import OnlinePayment from 'models/OnlinePayment';
import { PaymentState } from '../actions/Payment';

import { EzyPayFee } from 'models/EzyPay/EzyPayFee';

export interface IPayment {
  onlinePayment: OnlinePayment;
  state: PaymentState;
  isDisabledEzyPay: boolean | null;
  ezyPayFees: EzyPayFee[] | null;
  failLoadEzyPay: boolean;
}

const payment = {
  onlinePayment: new OnlinePayment(),
  state: PaymentState.INITIAL
};

const paymentReducer = (state = payment, action) => {
  switch (action.type) {
    case types.RESET_ONLINE_PAYMENT:
      return payment;
    case types.SET_ONLINE_PAYMENT:
      return {
        ...state,
        onlinePayment: action.onlinePayment
      };
    case types.SET_ONLINE_PAYMENT_STATE:
      return {
        ...state,
        state: action.state
      };
    case types.UPDATE_CAN_USE_EZY_PAY:
      return {
        ...state,
        isDisabledEzyPay: action.isDisabledEzyPay
      };
    case types.UPDATE_LOAD_FEE_EZYPAY:
      return {
        ...state,
        ezyPayFees: action.fees
      };

    case types.UPDATE_LOAD_FAIL_EZYPAY:
      return {
        ...state,
        failLoadEzyPay: action.failLoadEzyPay,
        ezyPayFees: []
      };
    default:
      return state;
  }
};

export default paymentReducer;
