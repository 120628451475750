import * as types from 'constants/ActionTypes';
import PaymentDetails from 'models/PaymentDetails';
import IPromotionCatergory from 'interfaces/Promotion';
import AddOn from 'models/AddOn';
import { IAllPackagePlans, IPackagePlanItem } from 'interfaces/Membership';
import { PersonalDetailsModel } from 'models/PersonalDetails';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import { IQuestionConfigs } from 'src/models/IQuestionConfigs';
import { EzyPayInfo } from 'models/EzyPay/EzyPayInfo';

export function resetAddMemberState() {
  return {
    type: types.ADD_MEMBER_RESET_STATE
  };
}

export function setAddMemberPackagePlanSearchFilter(
  membershipSearchFilter: MembershipSearchFilter
) {
  return { type: types.ADD_MEMBER_SET_SEARCH_FILTER, membershipSearchFilter };
}

export function setsAddMemberQuestionConfig(questionConfigs: IQuestionConfigs) {
  return { type: types.ADD_MEMBER_SET_QUESTION_CONFIGS, questionConfigs };
}

export function setAddMemberConfiguration(
  membershipConfiguration: MembershipConfiguration
) {
  return { type: types.ADD_MEMBER_SET_CONFIGURATION, membershipConfiguration };
}

export function setAddMemberProductExtras(
  selectedPromotions: IPromotionCatergory[],
  selectedAddons: AddOn[]
) {
  return {
    type: types.ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS,
    selectedPromotions,
    selectedAddons
  };
}

export function setAddMemberSelectedPackagePlan(packagePlan: IPackagePlanItem) {
  return { type: types.ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN, packagePlan };
}

export function setAddMemberPersonalDetails(
  personalDetails: PersonalDetailsModel
) {
  return {
    type: types.ADD_MEMBER_SET_PERSONAL_DETAILS,
    data: personalDetails
  };
}

export function setAddMemberAllPackagePlans(packagePlans: IAllPackagePlans) {
  return {
    type: types.ADD_MEMBER_SET_ALL_PACKAGE_PLAN,
    packagePlans
  };
}

export function setAddMemberSummary(memberSummary) {
  return {
    type: types.ADD_MEMBER_SET_MEMBER_SUMMARY,
    memberSummary
  };
}

export function setAddMemberPaymentDetails(paymentDetails: PaymentDetails) {
  return { type: types.ADD_MEMBER_SET_PAYMENT_DETAILS, paymentDetails };
}

export function replaceNewMembership(data: any) {
  return { type: types.REPLACE_NEW_MEMBERSHIP, data };
}

export function updatePaymentInfo(dataPayment: EzyPayInfo) {
  return {
    type: types.UPDATE_PAYMENT_INFO,
    paymentInfo: { ...dataPayment, disableShowIframe: true }
  };
}

export function updateDisableShowIframe(disableShowIframe: boolean) {
  return {
    type: types.UPDATE_PAYMENT_SHOW_INFO,
    disableShowIframe: disableShowIframe
  };
}

export function updateIsLoadingEzyPay(isLoadingEzyPay: boolean) {
  return {
    type: types.UPDATE_IS_LOADING_EZY_PAY,
    isLoadingEzyPay
  };
}
