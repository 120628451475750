import * as types from 'constants/ActionTypes';
import { IConfig } from 'interfaces/SystemConfig';

export interface IConman {
  configs: IConfig[];
}

const conman: IConman = {
  configs: []
};

const Conman = (state = conman, action) => {
  if (action.type === types.SET_CONFIGS) {
    return {
      ...state,
      configs: action.configs
    };
  }
  return state;
};

export default Conman;
