import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRawResponse } from 'services/membership-service';
import {
  replaceNewMembership,
  resetAddMemberState
} from 'reduxs/actions/NewMembership';
import { clearFromLocal, getFromLocal } from 'util/SaveToLocal';
import {
  PaymentState,
  setOnlinePaymentData,
  setOnlinePaymentDataState
} from 'reduxs/actions/Payment';
import { retry } from 'util/Common';
import {
  PAYMENT_DECISION,
  RAW_RESPONSE_EXPIRY_DATE_FORMAT,
  STORAGE
} from 'constants/Constants';
import moment from 'moment';
import {
  replaceCorporateMembership,
  resetCorporateMemberState
} from 'reduxs/actions/NewCorporateMembership';
import { replaceConvertGuestToMemberData } from 'reduxs/actions/ConvertGuestToMember';
import { replaceConvertGuestToCorporateMemberData } from 'reduxs/actions/ConvertGuestToCorporateMember';

declare const window: Record<string, Record<string, any>>;

const ONLINE_PAYMENT_RETRY_TIMES = window._env.ONLINE_PAYMENT_RETRY_TIMES;
const ONLINE_PAYMENT_RETRY_DELAY_TIME =
  window._env.ONLINE_PAYMENT_RETRY_DELAY_TIME;

const PaymentProcess: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const getActions = () => {
    const isExistDataNewMembership = getFromLocal(STORAGE.NEW_MEMBERSHIP);
    const isExistDataNewCorporateMembership = getFromLocal(
      STORAGE.NEW_CORPORATE_MEMBERSHIP
    );
    const isExistDataConvertGuestToMember = getFromLocal(
      STORAGE.CONVERT_GUEST_TO_MEMBER
    );
    const isExistDataConvertGuestToCorporateMember = getFromLocal(
      STORAGE.CONVERT_GUEST_TO_CORPORATE_MEMBER
    );

    const action = {
      makePaymentFor: '',
      redirectTo: ''
    };
    const operatorId = getFromLocal(STORAGE.OPERATOR_ID);
    switch (true) {
      case !!isExistDataNewMembership:
        dispatch(replaceNewMembership(isExistDataNewMembership));
        clearFromLocal(STORAGE.NEW_MEMBERSHIP);
        action.makePaymentFor = STORAGE.NEW_MEMBERSHIP;
        action.redirectTo = `/memberships/create?operator_id=${operatorId}`;
        break;
      case !!isExistDataNewCorporateMembership:
        dispatch(replaceCorporateMembership(isExistDataNewCorporateMembership));
        clearFromLocal(STORAGE.NEW_CORPORATE_MEMBERSHIP);
        action.makePaymentFor = STORAGE.NEW_CORPORATE_MEMBERSHIP;
        action.redirectTo = `/memberships/createcorporate?operator_id=${operatorId}`;
        break;
      case !!isExistDataConvertGuestToMember:
        let externalId = getFromLocal(STORAGE.EXTERNAL_ID);

        dispatch(
          replaceConvertGuestToMemberData(isExistDataConvertGuestToMember)
        );
        clearFromLocal(STORAGE.CONVERT_GUEST_TO_MEMBER);
        clearFromLocal(STORAGE.EXTERNAL_ID);
        action.makePaymentFor = STORAGE.CONVERT_GUEST_TO_MEMBER;
        action.redirectTo = `/memberships/convertGuestToMember?guestId=${externalId}&operator_id=${operatorId}&isRedirected=true`;
        break;
      case !!isExistDataConvertGuestToCorporateMember:
        externalId = getFromLocal(STORAGE.EXTERNAL_ID);
        dispatch(
          replaceConvertGuestToCorporateMemberData(
            isExistDataConvertGuestToCorporateMember
          )
        );
        clearFromLocal(STORAGE.CONVERT_GUEST_TO_CORPORATE_MEMBER);
        clearFromLocal(STORAGE.EXTERNAL_ID);
        action.makePaymentFor = STORAGE.CONVERT_GUEST_TO_CORPORATE_MEMBER;
        action.redirectTo = `/memberships/convertGuestToCorporateMember?guestId=${externalId}&operator_id=${operatorId}&isRedirected=true`;
        break;

      default:
        break;
    }

    return action;
  };

  const dispatchPaymentState = (decision: string) => {
    if (decision === PAYMENT_DECISION.ACCEPT) {
      dispatch(setOnlinePaymentDataState(PaymentState.SUCCESS));
    }
    if (decision === PAYMENT_DECISION.DECLINE) {
      dispatch(setOnlinePaymentDataState(PaymentState.DECLINE));
    }
  }

  useEffect(() => {
    const action = getActions();

    if (action.makePaymentFor) {
      const sPageURL = window.location.search.split('?transactionUUID=');
      const transactionUUID = decodeURIComponent(sPageURL[1]);

      retry(
        () =>
          new Promise((_, reject) =>
            getRawResponse({ transactionUUID }).then(res => {
              if (!res) {
                reject('Response is blanked');
                dispatch(setOnlinePaymentDataState(PaymentState.LOADING));
              } else {
                const expiryDate = new Date(
                  moment(
                    res.reqCardExpiryDate,
                    RAW_RESPONSE_EXPIRY_DATE_FORMAT
                  ).toString()
                );
                const expiryMonth = expiryDate.getMonth() + 1;
                const expiryYear = expiryDate.getFullYear().toString();
                dispatch(
                  setOnlinePaymentData({
                    cardType: res.cardTypeName,
                    cardHolderName: `${res.reqBillToForename} ${
                      res.reqBillToSurname
                    }`,
                    cardExpiryDate: res.reqCardExpiryDate,
                    paymentToken: res.paymentToken || res.reqPaymentToken,
                    paymentTokenInstrumentIdentifierId:
                      res.paymentTokenInstrumentIdentifierId,
                    cardNumber: res.reqCardNumber,
                    expirationDate:
                      expiryMonth.toString().padStart(2, '0') +
                      expiryYear
                        .substring(expiryYear.length - 2)
                        .padStart(2, '0')
                  })
                );
                dispatchPaymentState(res.decision);
              }
              history.push(action.redirectTo);
            })
          ),
        ONLINE_PAYMENT_RETRY_TIMES,
        ONLINE_PAYMENT_RETRY_DELAY_TIME,
        () => {
          dispatch(setOnlinePaymentDataState(PaymentState.FAIL));
        }
      );
    } else {
      dispatch(resetAddMemberState());
      dispatch(resetCorporateMemberState());
    }
  }, []);
  return <></>;
};

export default PaymentProcess;
