import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { getEsignatureUrl, getTimeExpired } from 'services/common-service';
import { t } from 'util/I18nMessages';

import Popup from 'util/Popup';

interface RestrictProps {
  component: React.ComponentType<any>;
  path: string;
}

const RestrictedRoute: React.FC<RestrictProps> = ({
  component: Component,
  ...rest
}) => {
  const targetTimestamp = Number(getTimeExpired());
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const checkTimestamp = () => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (currentTimestamp > targetTimestamp) {
        setIsSessionExpired(true);
      }
    };
    const intervalId = setInterval(checkTimestamp, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [targetTimestamp]);

  useEffect(() => {
    if (isSessionExpired) {
      Popup.showMessageCustom(
        {
          text: t('COMMON.EXPIRED_SESSION'),
          confirmButtonText: t('COMMON.OK'),
          showCancelButton: false,
          allowOutsideClick: false
        },
        () => {
          window.parent.postMessage('logout', getEsignatureUrl());
        }
      );
    }
  }, [isSessionExpired, t]);

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default RestrictedRoute;
