import React from 'react';
import * as Utils from 'util/ControlUtils';
import FormButton from 'components/Membership/FormButton';
import { useTranslation } from 'react-i18next';
import { IPackagePlanItem } from 'interfaces/Membership';
import ConvertGuestPassConfiguration from 'models/ConvertGuestPassConfiguration';
import ConvertGuestPassSearchFilter from 'models/ConvertGuestPassSearchFilter';
import { printUnits } from 'util/Common';

interface IConvertGuestToGuestPassProductSummary {
  productSummary: IPackagePlanItem;
  packageEndDate: string;
  searchFilter: ConvertGuestPassSearchFilter;
  configuration: ConvertGuestPassConfiguration;
  handlePrevious?: () => void;
  handleCancel?: () => void;
  handleFinish?: (endDate: string) => void;
}

const ConvertGuestToGuestPassProductSummary: React.FC<
  IConvertGuestToGuestPassProductSummary
> = ({
  productSummary,
  searchFilter,
  packageEndDate,
  configuration,
  handlePrevious,
  handleCancel,
  handleFinish
}) => {
  const { t } = useTranslation();

  const finalStep = 2;
  const payNow = Utils.formatMoney(
    productSummary.payment.payNow.value,
    2
  ).toString();
  const planPeriod = productSummary.planPeriods.find(
    period => period.code === productSummary.minimumContract.unit.id
  );

  const payNows = payNow.indexOf('.') > 0 ? payNow.split('.') : [payNow, '00'];
  const startDate = searchFilter.startDate;
  const handleFinish_ = () =>
    handleFinish ? handleFinish(packageEndDate) : {};
  return (
    <div className="aside-summary">
      <div className="product-summary">
        <p className="title-summary">
          {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PRODUCT_SUMMARY')}
        </p>
        <div className="pay-now">
          <p className="title-pay">
            {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.PAY_NOW')}
          </p>
          <p className="number-pay-now">
            {payNows[0]}.<sup>{payNows[1].padEnd(2, '0')}</sup>
          </p>
        </div>

        <div className="section-info">
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.START')}
            </span>
            <span className="dots" />
            <span className="last-span">
              {Utils.DisplayDateFormat(
                startDate,
                configuration.newMemberTimeFormat
              )}
            </span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.END')}
            </span>
            <span className="dots" />
            <span className="last-span">
              {Utils.DisplayDateFormat(
                packageEndDate,
                configuration.newMemberTimeFormat
              )}
            </span>
          </div>

          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.TIER')}
            </span>
            <span className="dots" />
            <span className="last-span">{productSummary.tier.name}</span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.MCP')}
            </span>
            <span className="dots" />
            <span>
              {productSummary.minimumContract.value}{' '}
              {printUnits(
                productSummary.minimumContract.value,
                planPeriod ? planPeriod.unit.code : ''
              )}
            </span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.ACCESS')}
            </span>
            <span className="dots" />
            <span className="last-span">
              {productSummary.package.timeGroup
                ? productSummary.package.timeGroup.name
                : 'Unknown'}
            </span>
          </div>
          <div className="row-span">
            <span className="first-span">
              {t('PAGE.MEMBERSHIPS.CREATE_GUESTPASS.SUMMARY.PLAN')}
            </span>
            <span className="dots" />
            <span className="last-span">{productSummary.name}</span>
          </div>
        </div>
      </div>
      <div className="sticky-aside">
        <FormButton
          isFinalStep={finalStep === configuration.stepIndex}
          isOnProductSummary={true}
          handlePrevious={handlePrevious}
          handleCancel={handleCancel}
          handleFinish={handleFinish_}
        />
      </div>
    </div>
  );
};

export default ConvertGuestToGuestPassProductSummary;
