import React from 'react';

interface SubContentProps {
  data: {
    id: string;
    name: string;
    value: string;
    label: string;
  }[];
}

const SubContent = (props: SubContentProps) => {
  const { data } = props;
  return (
    <div className="row">
      {data.map(field => (
        <div className="col-md-6" key={field.label}>
          <div className="form-check">
            <input type="checkbox" checked disabled />
            <label className="icon-checked-ico">
              <span>{field.name}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubContent;
